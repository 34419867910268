'use strict';

var React = require("react");
var Belt_List = require("rescript/lib/js/belt_List.js");
var WebApi$Web = require("./WebApi.bs.js");
var Prelude$Web = require("./Prelude.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var ReactIcons = require("@patternfly/react-icons");

function GroupsView$GroupItem(Props) {
  var store = Props.store;
  var group = Props.group;
  var link = "/" + store[0].index + "/group/" + group.name;
  return React.createElement(Prelude$Web.MSimpleCard.make, {
              children: Prelude$Web.str(group.name + " (" + String(group.members) + " member)"),
              style: {
                cursor: "pointer"
              },
              onClick: (function (param) {
                  return RescriptReactRouter.push(link);
                })
            });
}

var GroupItem = {
  make: GroupsView$GroupItem
};

function GroupsView(Props) {
  var store = Props.store;
  var state = store[0];
  var icon = React.createElement(ReactIcons.UsersIcon, {});
  var toItems = function (resp) {
    return Belt_List.map(resp.items, (function (group) {
                  return React.createElement(GroupsView$GroupItem, {
                              store: store,
                              group: group,
                              key: group.name
                            });
                }));
  };
  return React.createElement(Prelude$Web.MonoCard.make, {
              title: "User Groups",
              tooltip_content: "This shows the list of available user groups",
              icon: icon,
              children: React.createElement(Prelude$Web.NetworkRender.make, {
                    get: (function (param) {
                        return WebApi$Web.Config.getGroups({
                                    index: state.index
                                  });
                      }),
                    trigger: "",
                    render: (function (resp) {
                        return Belt_List.toArray(toItems(resp));
                      })
                  })
            });
}

var make = GroupsView;

var $$default = GroupsView;

exports.GroupItem = GroupItem;
exports.make = make;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
/* react Not a pure module */
