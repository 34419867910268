'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Store$Web = require("./Store.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Change$Web = require("./Change.bs.js");
var Search$Web = require("./Search.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Prelude$Web = require("./Prelude.bs.js");
var ChartjsJsx = require("./chartjs.jsx");
var HiddenChanges$Web = require("./HiddenChanges.bs.js");
var MaskedChanges$Web = require("./MaskedChanges.bs.js");
var PinnedChanges$Web = require("./PinnedChanges.bs.js");
var VisibleChanges$Web = require("./VisibleChanges.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var ReactCore = require("@patternfly/react-core");
var ReactIcons = require("@patternfly/react-icons");

function getColorFromNamedPalette(label, namedPalette) {
  switch (label) {
    case "APPROVED" :
        return namedPalette.APPROVED;
    case "CHANGES_REQUESTED" :
        return namedPalette.CHANGES_REQUESTED;
    case "COMMENTED" :
        return namedPalette.COMMENTED;
    case "Code-Review+1" :
        return namedPalette["Code-Review+1"];
    case "Code-Review+2" :
        return namedPalette["Code-Review+2"];
    case "Code-Review-1" :
        return namedPalette["Code-Review-1"];
    case "Code-Review-2" :
        return namedPalette["Code-Review-2"];
    case "DISMISSED" :
        return namedPalette.DISMISSED;
    case "Workflow+1" :
        return namedPalette["Workflow+1"];
    case "Workflow-1" :
        return namedPalette["Workflow-1"];
    default:
      return ;
  }
}

var defaultPalette = [
  "#247ba0",
  "#70c1b3",
  "#b2dbbf",
  "#f3ffbd",
  "#ff1654",
  "#247ba0",
  "#70c1b3",
  "#b2dbbf",
  "#f3ffbd",
  "#ff1654",
  "#b2dbbf"
];

function getColorFromPalette(index, label, namedPaletteM) {
  var getFromDefaultPalette = function (i) {
    var color = Belt_Array.get(defaultPalette, i);
    if (color !== undefined) {
      return color;
    } else {
      return "black";
    }
  };
  if (namedPaletteM === undefined) {
    return getFromDefaultPalette(index);
  }
  var color = getColorFromNamedPalette(label, Caml_option.valFromOption(namedPaletteM));
  if (color !== undefined) {
    return color;
  } else {
    return getFromDefaultPalette(index);
  }
}

var make = ChartjsJsx.PieChart;

var PieChart = {
  make: make
};

function NChangeView$PieWithLegend$PieChartLegend(Props) {
  var data = Props.data;
  var namedPalette = Props.namedPalette;
  var handleClick = Props.handleClick;
  return Belt_Array.mapWithIndex(data.items, (function (i, e) {
                return React.createElement("div", {
                            key: e.key
                          }, React.createElement("span", {
                                style: {
                                  backgroundColor: getColorFromPalette(i, e.key, namedPalette),
                                  cursor: "pointer",
                                  display: "inline-block",
                                  height: "10px",
                                  width: "10px"
                                }
                              }), React.createElement("span", undefined, Prelude$Web.str(" ")), React.createElement("span", {
                                key: e.key,
                                style: {
                                  cursor: "pointer"
                                },
                                onClick: (function (param) {
                                    return Curry._1(handleClick, e.key);
                                  })
                              }, Prelude$Web.str(e.key)));
              }));
}

var PieChartLegend = {
  make: NChangeView$PieWithLegend$PieChartLegend
};

function NChangeView$PieWithLegend(Props) {
  var data = Props.data;
  var title = Props.title;
  var handleClick = Props.handleClick;
  var namedPalette = Props.namedPalette;
  var other_label = Props.other_label;
  var tmp = {
    data: data,
    palette: defaultPalette,
    handleClick: handleClick
  };
  if (namedPalette !== undefined) {
    tmp.namedPalette = Caml_option.valFromOption(namedPalette);
  }
  if (other_label !== undefined) {
    tmp.other_label = other_label;
  }
  return React.createElement(React.Fragment, {
              children: React.createElement(ReactCore.Card, {
                    children: null,
                    isCompact: true
                  }, React.createElement(ReactCore.CardTitle, {
                        children: React.createElement(ReactCore.Title, {
                              children: Prelude$Web.str(title),
                              headingLevel: "h3"
                            })
                      }), React.createElement(ReactCore.CardBody, {
                        children: null
                      }, React.createElement(make, tmp), React.createElement(NChangeView$PieWithLegend$PieChartLegend, {
                            data: data,
                            namedPalette: namedPalette,
                            handleClick: handleClick
                          })))
            });
}

var PieWithLegend = {
  getColorFromNamedPalette: getColorFromNamedPalette,
  defaultPalette: defaultPalette,
  getColorFromPalette: getColorFromPalette,
  PieChart: PieChart,
  PieChartLegend: PieChartLegend,
  make: NChangeView$PieWithLegend
};

function NChangeView$ChangeList(Props) {
  var store = Props.store;
  var changes = Props.changes;
  var hiddenDispatchChange = Props.hiddenDispatchChange;
  var disableHiddenChange = Props.disableHiddenChange;
  var pinnedChanges = Props.pinnedChanges;
  var pinnedDispatchChange = Props.pinnedDispatchChange;
  var maskedChanges = Props.maskedChanges;
  var maskedDispatchChange = Props.maskedDispatchChange;
  var match = disableHiddenChange !== undefined && disableHiddenChange ? [
      null,
      (function (param, param$1) {
          return true;
        })
    ] : VisibleChanges$Web.use(undefined);
  var isChangeVisible = match[1];
  var match$1 = Prelude$Web.usePagination(changes);
  return React.createElement(Prelude$Web.MStack.make, {
              children: null
            }, React.createElement(Prelude$Web.MStackItem.make, {
                  children: match[0]
                }), React.createElement(Prelude$Web.MStackItem.make, {
                  children: match$1[1]
                }), React.createElement(Prelude$Web.MStackItem.make, {
                  children: React.createElement(ReactCore.DataList, {
                        children: Belt_Array.map(match$1[0], (function (param) {
                                var change = param[1];
                                var hiddenStatus = param[0];
                                var maskedState = MaskedChanges$Web.simpleGetStatus(maskedChanges, change);
                                if (Curry._2(isChangeVisible, hiddenStatus, maskedState)) {
                                  return React.createElement(Change$Web.DataItem.make, {
                                              store: store,
                                              change: change,
                                              hiddenStatus: hiddenStatus,
                                              hiddenDispatchChange: hiddenDispatchChange,
                                              pinnedStatus: PinnedChanges$Web.simpleGetStatus(pinnedChanges, change),
                                              pinnedDispatchChange: pinnedDispatchChange,
                                              maskedStatus: MaskedChanges$Web.simpleGetStatus(maskedChanges, change),
                                              maskedDispatchChange: maskedDispatchChange,
                                              key: change.change_id
                                            });
                                } else {
                                  return null;
                                }
                              })),
                        isCompact: true
                      })
                }));
}

var ChangeList = {
  make: NChangeView$ChangeList
};

function NChangeView$ChangesTopPies(Props) {
  var store = Props.store;
  var extraQuery = Props.extraQuery;
  var hideAuthors = Props.hideAuthors;
  var dispatch = store[1];
  var state = store[0];
  var baseRequest = Store$Web.mkSearchRequest(state, /* Query_changes_tops */14);
  var query = Prelude$Web.addQuery(baseRequest.query, state.filter);
  var request_index = baseRequest.index;
  var request_username = baseRequest.username;
  var request_query = extraQuery !== undefined ? Prelude$Web.addQuery(query, extraQuery) : query;
  var request_query_type = baseRequest.query_type;
  var request_order = baseRequest.order;
  var request_change_id = baseRequest.change_id;
  var request = {
    index: request_index,
    username: request_username,
    query: request_query,
    query_type: request_query_type,
    order: request_order,
    limit: 10,
    change_id: request_change_id
  };
  var getEntry = function (e) {
    return {
            key: e.term,
            doc_count: e.count
          };
  };
  var adapt = function (elms, kf) {
    return {
            items: Belt_List.toArray(Belt_List.keep(Belt_List.map(elms.termcount, getEntry), kf)),
            total_hits: elms.total_hits
          };
  };
  var tee = function (param) {
    return Curry._1(dispatch, /* ReverseChangesPiePanelState */0);
  };
  var approvals_palette = {
    "Code-Review+2": "#00ff9f",
    "Code-Review+1": "#B6FCD5",
    "Code-Review-1": "#CA5462",
    "Code-Review-2": "#AB0000",
    "Workflow+1": "#00ff9f",
    "Workflow-1": "#AB0000",
    APPROVED: "#00ff9f",
    DISMISSED: "#AB0000",
    COMMENTED: "#B6FCD5",
    CHANGES_REQUESTED: "#CA5462"
  };
  var ignoredApproval = [
    "Code-Review+0",
    "Verified+0",
    "Workflow+0",
    "COMMENTED"
  ];
  var handlePieClick = function (state, dispatch, field, value) {
    var newFilter = field + ":\"" + value + "\"";
    var filter = state.filter.includes(newFilter) ? state.filter : Prelude$Web.addQuery(state.filter, newFilter);
    var base = Prelude$Web.readWindowLocationPathname(undefined) + "?";
    var query = state.query;
    var query$1 = query === "" ? "" : "q=" + query + "&";
    var href = base + query$1 + "f=" + filter;
    Curry._1(dispatch, {
          TAG: /* SetFilter */2,
          _0: filter
        });
    return RescriptReactRouter.push(href);
  };
  return React.createElement(Prelude$Web.QueryRender.make, {
              request: request,
              trigger: query + Belt_Option.getWithDefault(extraQuery, ""),
              render: (function (resp) {
                  if (resp.TAG !== /* Changes_tops */10) {
                    return React.createElement(ReactCore.Alert, {
                                title: "Invalid response"
                              });
                  }
                  var items = resp._0;
                  return React.createElement(Prelude$Web.MExpandablePanel.make, {
                              title: "Show changes pie charts",
                              children: React.createElement(ReactCore.Bullseye, {
                                    children: React.createElement(ReactCore.Flex, {
                                          children: null
                                        }, Prelude$Web.maybeHide(hideAuthors, React.createElement(ReactCore.FlexItem, {
                                                  children: React.createElement(NChangeView$PieWithLegend, {
                                                        data: adapt(Belt_Option.getExn(items.authors), (function (param) {
                                                                return true;
                                                              })),
                                                        title: "Changes per author",
                                                        handleClick: (function (param) {
                                                            return handlePieClick(state, dispatch, "author", param);
                                                          })
                                                      })
                                                })), React.createElement(ReactCore.FlexItem, {
                                              children: React.createElement(NChangeView$PieWithLegend, {
                                                    data: adapt(Belt_Option.getExn(items.repos), (function (param) {
                                                            return true;
                                                          })),
                                                    title: "Changes per repository",
                                                    handleClick: (function (param) {
                                                        return handlePieClick(state, dispatch, "repo", param);
                                                      })
                                                  })
                                            }), React.createElement(ReactCore.FlexItem, {
                                              children: React.createElement(NChangeView$PieWithLegend, {
                                                    data: adapt(Belt_Option.getExn(items.approvals), (function (e) {
                                                            return Belt_Array.some(ignoredApproval, (function (e$p) {
                                                                          return e$p !== e.key;
                                                                        }));
                                                          })),
                                                    title: "Changes per approval",
                                                    handleClick: (function (param) {
                                                        return handlePieClick(state, dispatch, "approval", param);
                                                      }),
                                                    namedPalette: approvals_palette
                                                  })
                                            }))
                                  }),
                              stateControler: [
                                state.changes_pies_panel,
                                tee
                              ]
                            });
                })
            });
}

var ChangesTopPies = {
  make: NChangeView$ChangesTopPies
};

function NChangeView$View(Props) {
  var store = Props.store;
  var changesAll = Props.changesAll;
  var extraQuery = Props.extraQuery;
  var hideAuthors = Props.hideAuthors;
  var disableHiddenChange = Props.disableHiddenChange;
  var state = store[0];
  var match = HiddenChanges$Web.use(state.dexie, changesAll);
  var hiddenChanges = match[0];
  var match$1 = PinnedChanges$Web.use(state.dexie, changesAll);
  var match$2 = MaskedChanges$Web.use(state.dexie, changesAll);
  var match$3 = hiddenChanges.length;
  if (match$3 === 0) {
    return React.createElement(Prelude$Web.MStack.make, {
                children: null
              }, React.createElement(Prelude$Web.MStackItem.make, {
                    children: React.createElement(Search$Web.Filter.make, {
                          store: store
                        })
                  }), React.createElement(Prelude$Web.MStackItem.make, {
                    children: React.createElement("p", undefined, Prelude$Web.str("No changes matched"))
                  }));
  }
  var tmp = {
    store: store
  };
  if (extraQuery !== undefined) {
    tmp.extraQuery = extraQuery;
  }
  if (hideAuthors !== undefined) {
    tmp.hideAuthors = hideAuthors;
  }
  var tmp$1 = {
    store: store,
    changes: hiddenChanges,
    hiddenDispatchChange: match[1],
    pinnedChanges: match$1[0],
    pinnedDispatchChange: match$1[1],
    maskedChanges: match$2[0],
    maskedDispatchChange: match$2[1]
  };
  if (disableHiddenChange !== undefined) {
    tmp$1.disableHiddenChange = disableHiddenChange;
  }
  return React.createElement(Prelude$Web.MStack.make, {
              children: null
            }, React.createElement(Prelude$Web.MStackItem.make, {
                  children: React.createElement(NChangeView$ChangesTopPies, tmp)
                }), React.createElement(Prelude$Web.MStackItem.make, {
                  children: React.createElement(Search$Web.Filter.make, {
                        store: store
                      })
                }), React.createElement(Prelude$Web.MStackItem.make, {
                  children: React.createElement(NChangeView$ChangeList, tmp$1)
                }));
}

var View = {
  make: NChangeView$View
};

function NChangeView(Props) {
  var store = Props.store;
  var extraQuery = Props.extraQuery;
  var hideAuthors = Props.hideAuthors;
  var disableHiddenChange = Props.disableHiddenChange;
  var state = store[0];
  var baseRequest = Store$Web.mkSearchRequest(state, /* Query_change */0);
  var query = Prelude$Web.addQuery(baseRequest.query, state.filter);
  var request_index = baseRequest.index;
  var request_username = baseRequest.username;
  var request_query = extraQuery !== undefined ? Prelude$Web.addQuery(query, extraQuery) : query;
  var request_query_type = baseRequest.query_type;
  var request_order = baseRequest.order;
  var request_change_id = baseRequest.change_id;
  var request = {
    index: request_index,
    username: request_username,
    query: request_query,
    query_type: request_query_type,
    order: request_order,
    limit: 256,
    change_id: request_change_id
  };
  var trigger = query + Prelude$Web.orderToQS(state.order) + Belt_Option.getWithDefault(extraQuery, "");
  var icon = React.createElement(ReactIcons.IntegrationIcon, {});
  var match = function (resp) {
    if (resp.TAG === /* Changes */1) {
      return resp._0.changes;
    }
    
  };
  var childrenBuilder = function (changes) {
    return React.createElement(NChangeView$View, {
                store: store,
                changesAll: Belt_List.toArray(changes),
                extraQuery: extraQuery,
                hideAuthors: hideAuthors,
                disableHiddenChange: disableHiddenChange
              });
  };
  return React.createElement(Prelude$Web.QueryRenderCard.make, {
              request: request,
              trigger: trigger,
              title: "Changes",
              tooltip_content: "This shows the list of changes",
              icon: icon,
              match: match,
              childrenBuilder: childrenBuilder
            });
}

var make$1 = NChangeView;

var $$default = NChangeView;

exports.PieWithLegend = PieWithLegend;
exports.ChangeList = ChangeList;
exports.ChangesTopPies = ChangesTopPies;
exports.View = View;
exports.make = make$1;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
/* make Not a pure module */
