'use strict';

var React = require("react");
var Store$Web = require("./Store.bs.js");
var Prelude$Web = require("./Prelude.bs.js");
var ActivePeopleView$Web = require("./ActivePeopleView.bs.js");
var ReactIcons = require("@patternfly/react-icons");

function NewContributorsView(Props) {
  var store = Props.store;
  var state = store[0];
  var match = React.useState(function () {
        return 25;
      });
  var limit = match[0];
  var columnNames = [
    "Author",
    "Change count"
  ];
  var init = Store$Web.mkSearchRequest(state, /* Query_new_changes_authors */9);
  var request_index = init.index;
  var request_username = init.username;
  var request_query = init.query;
  var request_query_type = init.query_type;
  var request_order = init.order;
  var request_change_id = init.change_id;
  var request = {
    index: request_index,
    username: request_username,
    query: request_query,
    query_type: request_query_type,
    order: request_order,
    limit: limit,
    change_id: request_change_id
  };
  var trigger = state.query + String(limit);
  var limitSelector = React.createElement(Prelude$Web.LimitSelector.make, {
        limit: limit,
        setLimit: match[1],
        default: 25,
        values: {
          hd: 10,
          tl: {
            hd: 25,
            tl: {
              hd: 50,
              tl: {
                hd: 100,
                tl: {
                  hd: 500,
                  tl: /* [] */0
                }
              }
            }
          }
        }
      });
  var icon = React.createElement(ReactIcons.PlusIcon, {});
  var match$1 = function (resp) {
    if (resp.TAG === /* New_authors */5) {
      return resp._0;
    }
    
  };
  var childrenBuilder = function (data) {
    return React.createElement(ActivePeopleView$Web.TopTermsTable.make, {
                store: store,
                items: data.termcount,
                columnNames: columnNames,
                link: /* AuthorLink */1
              });
  };
  return React.createElement(Prelude$Web.QueryRenderCard.make, {
              request: request,
              trigger: trigger,
              title: "New change' authors",
              tooltip_content: "This shows the list of authors that are new contributors of changes",
              icon: icon,
              limitSelector: limitSelector,
              match: match$1,
              childrenBuilder: childrenBuilder
            });
}

var make = NewContributorsView;

var $$default = NewContributorsView;

exports.make = make;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
/* react Not a pure module */
