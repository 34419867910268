'use strict';

var Caml_option = require("rescript/lib/js/caml_option.js");

function default_task_data(updated_atOpt, change_urlOpt, ttypeOpt, tidOpt, urlOpt, titleOpt, severityOpt, priorityOpt, scoreOpt, prefixOpt, param) {
  var updated_at = updated_atOpt !== undefined ? Caml_option.valFromOption(updated_atOpt) : undefined;
  var change_url = change_urlOpt !== undefined ? change_urlOpt : "";
  var ttype = ttypeOpt !== undefined ? ttypeOpt : /* [] */0;
  var tid = tidOpt !== undefined ? tidOpt : "";
  var url = urlOpt !== undefined ? urlOpt : "";
  var title = titleOpt !== undefined ? titleOpt : "";
  var severity = severityOpt !== undefined ? severityOpt : "";
  var priority = priorityOpt !== undefined ? priorityOpt : "";
  var score = scoreOpt !== undefined ? scoreOpt : 0;
  var prefix = prefixOpt !== undefined ? prefixOpt : "";
  return {
          updated_at: updated_at,
          change_url: change_url,
          ttype: ttype,
          tid: tid,
          url: url,
          title: title,
          severity: severity,
          priority: priority,
          score: score,
          prefix: prefix
        };
}

function default_suggestions_request(indexOpt, param) {
  var index = indexOpt !== undefined ? indexOpt : "";
  return {
          index: index
        };
}

function default_suggestions_response(task_typesOpt, authorsOpt, approvalsOpt, prioritiesOpt, severitiesOpt, projectsOpt, groupsOpt, labelsOpt, param) {
  var task_types = task_typesOpt !== undefined ? task_typesOpt : /* [] */0;
  var authors = authorsOpt !== undefined ? authorsOpt : /* [] */0;
  var approvals = approvalsOpt !== undefined ? approvalsOpt : /* [] */0;
  var priorities = prioritiesOpt !== undefined ? prioritiesOpt : /* [] */0;
  var severities = severitiesOpt !== undefined ? severitiesOpt : /* [] */0;
  var projects = projectsOpt !== undefined ? projectsOpt : /* [] */0;
  var groups = groupsOpt !== undefined ? groupsOpt : /* [] */0;
  var labels = labelsOpt !== undefined ? labelsOpt : /* [] */0;
  return {
          task_types: task_types,
          authors: authors,
          approvals: approvals,
          priorities: priorities,
          severities: severities,
          projects: projects,
          groups: groups,
          labels: labels
        };
}

function default_fields_request(versionOpt, param) {
  var version = versionOpt !== undefined ? versionOpt : "";
  return {
          version: version
        };
}

function default_field_type(param) {
  return /* Field_date */0;
}

function default_field(nameOpt, descriptionOpt, type_Opt, param) {
  var name = nameOpt !== undefined ? nameOpt : "";
  var description = descriptionOpt !== undefined ? descriptionOpt : "";
  var type_ = type_Opt !== undefined ? type_Opt : /* Field_date */0;
  return {
          name: name,
          description: description,
          type_: type_
        };
}

function default_fields_response(fieldsOpt, param) {
  var fields = fieldsOpt !== undefined ? fieldsOpt : /* [] */0;
  return {
          fields: fields
        };
}

function default_query_error(messageOpt, positionOpt, param) {
  var message = messageOpt !== undefined ? messageOpt : "";
  var position = positionOpt !== undefined ? positionOpt : 0;
  return {
          message: message,
          position: position
        };
}

function default_check_request(indexOpt, usernameOpt, queryOpt, param) {
  var index = indexOpt !== undefined ? indexOpt : "";
  var username = usernameOpt !== undefined ? usernameOpt : "";
  var query = queryOpt !== undefined ? queryOpt : "";
  return {
          index: index,
          username: username,
          query: query
        };
}

function default_check_response(param) {
  return {
          TAG: /* Success */0,
          _0: ""
        };
}

function default_author(muidOpt, aliasesOpt, groupsOpt, param) {
  var muid = muidOpt !== undefined ? muidOpt : "";
  var aliases = aliasesOpt !== undefined ? aliasesOpt : /* [] */0;
  var groups = groupsOpt !== undefined ? groupsOpt : /* [] */0;
  return {
          muid: muid,
          aliases: aliases,
          groups: groups
        };
}

function default_author_request(indexOpt, queryOpt, param) {
  var index = indexOpt !== undefined ? indexOpt : "";
  var query = queryOpt !== undefined ? queryOpt : "";
  return {
          index: index,
          query: query
        };
}

function default_author_response(authorsOpt, param) {
  var authors = authorsOpt !== undefined ? authorsOpt : /* [] */0;
  return {
          authors: authors
        };
}

function default_order_direction(param) {
  return /* Asc */0;
}

function default_order(fieldOpt, directionOpt, param) {
  var field = fieldOpt !== undefined ? fieldOpt : "";
  var direction = directionOpt !== undefined ? directionOpt : /* Asc */0;
  return {
          field: field,
          direction: direction
        };
}

function default_query_request_query_type(param) {
  return /* Query_change */0;
}

function default_query_request(indexOpt, usernameOpt, queryOpt, query_typeOpt, orderOpt, limitOpt, change_idOpt, param) {
  var index = indexOpt !== undefined ? indexOpt : "";
  var username = usernameOpt !== undefined ? usernameOpt : "";
  var query = queryOpt !== undefined ? queryOpt : "";
  var query_type = query_typeOpt !== undefined ? query_typeOpt : /* Query_change */0;
  var order = orderOpt !== undefined ? Caml_option.valFromOption(orderOpt) : undefined;
  var limit = limitOpt !== undefined ? limitOpt : 0;
  var change_id = change_idOpt !== undefined ? change_idOpt : "";
  return {
          index: index,
          username: username,
          query: query,
          query_type: query_type,
          order: order,
          limit: limit,
          change_id: change_id
        };
}

function default_file(additionsOpt, deletionsOpt, pathOpt, param) {
  var additions = additionsOpt !== undefined ? additionsOpt : 0;
  var deletions = deletionsOpt !== undefined ? deletionsOpt : 0;
  var path = pathOpt !== undefined ? pathOpt : "";
  return {
          additions: additions,
          deletions: deletions,
          path: path
        };
}

function default_commit(shaOpt, titleOpt, authorOpt, authored_atOpt, committerOpt, committed_atOpt, additionsOpt, deletionsOpt, param) {
  var sha = shaOpt !== undefined ? shaOpt : "";
  var title = titleOpt !== undefined ? titleOpt : "";
  var author = authorOpt !== undefined ? authorOpt : "";
  var authored_at = authored_atOpt !== undefined ? Caml_option.valFromOption(authored_atOpt) : undefined;
  var committer = committerOpt !== undefined ? committerOpt : "";
  var committed_at = committed_atOpt !== undefined ? Caml_option.valFromOption(committed_atOpt) : undefined;
  var additions = additionsOpt !== undefined ? additionsOpt : 0;
  var deletions = deletionsOpt !== undefined ? deletionsOpt : 0;
  return {
          sha: sha,
          title: title,
          author: author,
          authored_at: authored_at,
          committer: committer,
          committed_at: committed_at,
          additions: additions,
          deletions: deletions
        };
}

function default_change_merged_by_m(param) {
  return /* Merged_by */{
          _0: ""
        };
}

function default_change_ttm(param) {
  return /* Duration */{
          _0: 0
        };
}

function default_change(change_idOpt, authorOpt, titleOpt, urlOpt, repository_fullnameOpt, stateOpt, branchOpt, target_branchOpt, created_atOpt, updated_atOpt, merged_atOpt, merged_by_mOpt, textOpt, additionsOpt, deletionsOpt, approvalOpt, assigneesOpt, labelsOpt, draftOpt, mergeableOpt, changed_filesOpt, changed_files_countOpt, commitsOpt, commits_countOpt, task_dataOpt, ttmOpt, param) {
  var change_id = change_idOpt !== undefined ? change_idOpt : "";
  var author = authorOpt !== undefined ? authorOpt : "";
  var title = titleOpt !== undefined ? titleOpt : "";
  var url = urlOpt !== undefined ? urlOpt : "";
  var repository_fullname = repository_fullnameOpt !== undefined ? repository_fullnameOpt : "";
  var state = stateOpt !== undefined ? stateOpt : "";
  var branch = branchOpt !== undefined ? branchOpt : "";
  var target_branch = target_branchOpt !== undefined ? target_branchOpt : "";
  var created_at = created_atOpt !== undefined ? Caml_option.valFromOption(created_atOpt) : undefined;
  var updated_at = updated_atOpt !== undefined ? Caml_option.valFromOption(updated_atOpt) : undefined;
  var merged_at = merged_atOpt !== undefined ? Caml_option.valFromOption(merged_atOpt) : undefined;
  var merged_by_m = merged_by_mOpt !== undefined ? merged_by_mOpt : /* Merged_by */({
        _0: ""
      });
  var text = textOpt !== undefined ? textOpt : "";
  var additions = additionsOpt !== undefined ? additionsOpt : 0;
  var deletions = deletionsOpt !== undefined ? deletionsOpt : 0;
  var approval = approvalOpt !== undefined ? approvalOpt : /* [] */0;
  var assignees = assigneesOpt !== undefined ? assigneesOpt : /* [] */0;
  var labels = labelsOpt !== undefined ? labelsOpt : /* [] */0;
  var draft = draftOpt !== undefined ? draftOpt : false;
  var mergeable = mergeableOpt !== undefined ? mergeableOpt : false;
  var changed_files = changed_filesOpt !== undefined ? changed_filesOpt : /* [] */0;
  var changed_files_count = changed_files_countOpt !== undefined ? changed_files_countOpt : 0;
  var commits = commitsOpt !== undefined ? commitsOpt : /* [] */0;
  var commits_count = commits_countOpt !== undefined ? commits_countOpt : 0;
  var task_data = task_dataOpt !== undefined ? task_dataOpt : /* [] */0;
  var ttm = ttmOpt !== undefined ? ttmOpt : /* Duration */({
        _0: 0
      });
  return {
          change_id: change_id,
          author: author,
          title: title,
          url: url,
          repository_fullname: repository_fullname,
          state: state,
          branch: branch,
          target_branch: target_branch,
          created_at: created_at,
          updated_at: updated_at,
          merged_at: merged_at,
          merged_by_m: merged_by_m,
          text: text,
          additions: additions,
          deletions: deletions,
          approval: approval,
          assignees: assignees,
          labels: labels,
          draft: draft,
          mergeable: mergeable,
          changed_files: changed_files,
          changed_files_count: changed_files_count,
          commits: commits,
          commits_count: commits_count,
          task_data: task_data,
          ttm: ttm
        };
}

function default_changes(changesOpt, param) {
  var changes = changesOpt !== undefined ? changesOpt : /* [] */0;
  return {
          changes: changes
        };
}

function default_ratio(ratioOpt, param) {
  var ratio = ratioOpt !== undefined ? ratioOpt : 0;
  return {
          ratio: ratio
        };
}

function default_change_event(idOpt, type_Opt, change_idOpt, created_atOpt, on_created_atOpt, authorOpt, on_authorOpt, branchOpt, param) {
  var id = idOpt !== undefined ? idOpt : "";
  var type_ = type_Opt !== undefined ? type_Opt : "";
  var change_id = change_idOpt !== undefined ? change_idOpt : "";
  var created_at = created_atOpt !== undefined ? Caml_option.valFromOption(created_atOpt) : undefined;
  var on_created_at = on_created_atOpt !== undefined ? Caml_option.valFromOption(on_created_atOpt) : undefined;
  var author = authorOpt !== undefined ? authorOpt : "";
  var on_author = on_authorOpt !== undefined ? on_authorOpt : "";
  var branch = branchOpt !== undefined ? branchOpt : "";
  return {
          id: id,
          type_: type_,
          change_id: change_id,
          created_at: created_at,
          on_created_at: on_created_at,
          author: author,
          on_author: on_author,
          branch: branch
        };
}

function default_change_and_events(changeOpt, eventsOpt, param) {
  var change = changeOpt !== undefined ? Caml_option.valFromOption(changeOpt) : undefined;
  var events = eventsOpt !== undefined ? eventsOpt : /* [] */0;
  return {
          change: change,
          events: events
        };
}

function default_review_count(authors_countOpt, events_countOpt, param) {
  var authors_count = authors_countOpt !== undefined ? authors_countOpt : 0;
  var events_count = events_countOpt !== undefined ? events_countOpt : 0;
  return {
          authors_count: authors_count,
          events_count: events_count
        };
}

function default_review_stats(comment_countOpt, review_countOpt, comment_delayOpt, review_delayOpt, comment_histoOpt, review_histoOpt, param) {
  var comment_count = comment_countOpt !== undefined ? Caml_option.valFromOption(comment_countOpt) : undefined;
  var review_count = review_countOpt !== undefined ? Caml_option.valFromOption(review_countOpt) : undefined;
  var comment_delay = comment_delayOpt !== undefined ? comment_delayOpt : 0;
  var review_delay = review_delayOpt !== undefined ? review_delayOpt : 0;
  var comment_histo = comment_histoOpt !== undefined ? comment_histoOpt : /* [] */0;
  var review_histo = review_histoOpt !== undefined ? review_histoOpt : /* [] */0;
  return {
          comment_count: comment_count,
          review_count: review_count,
          comment_delay: comment_delay,
          review_delay: review_delay,
          comment_histo: comment_histo,
          review_histo: review_histo
        };
}

function default_activity_stats(change_authorsOpt, comment_authorsOpt, review_authorsOpt, comments_histoOpt, reviews_histoOpt, changes_histoOpt, param) {
  var change_authors = change_authorsOpt !== undefined ? change_authorsOpt : 0;
  var comment_authors = comment_authorsOpt !== undefined ? comment_authorsOpt : 0;
  var review_authors = review_authorsOpt !== undefined ? review_authorsOpt : 0;
  var comments_histo = comments_histoOpt !== undefined ? comments_histoOpt : /* [] */0;
  var reviews_histo = reviews_histoOpt !== undefined ? reviews_histoOpt : /* [] */0;
  var changes_histo = changes_histoOpt !== undefined ? changes_histoOpt : /* [] */0;
  return {
          change_authors: change_authors,
          comment_authors: comment_authors,
          review_authors: review_authors,
          comments_histo: comments_histo,
          reviews_histo: reviews_histo,
          changes_histo: changes_histo
        };
}

function default_repo_summary(fullnameOpt, created_changesOpt, abandoned_changesOpt, merged_changesOpt, updated_changesOpt, open_changesOpt, param) {
  var fullname = fullnameOpt !== undefined ? fullnameOpt : "";
  var created_changes = created_changesOpt !== undefined ? created_changesOpt : 0;
  var abandoned_changes = abandoned_changesOpt !== undefined ? abandoned_changesOpt : 0;
  var merged_changes = merged_changesOpt !== undefined ? merged_changesOpt : 0;
  var updated_changes = updated_changesOpt !== undefined ? updated_changesOpt : 0;
  var open_changes = open_changesOpt !== undefined ? open_changesOpt : 0;
  return {
          fullname: fullname,
          created_changes: created_changes,
          abandoned_changes: abandoned_changes,
          merged_changes: merged_changes,
          updated_changes: updated_changes,
          open_changes: open_changes
        };
}

function default_repos_summary(reposumOpt, param) {
  var reposum = reposumOpt !== undefined ? reposumOpt : /* [] */0;
  return {
          reposum: reposum
        };
}

function default_author_peer(authorOpt, peerOpt, strengthOpt, param) {
  var author = authorOpt !== undefined ? authorOpt : "";
  var peer = peerOpt !== undefined ? peerOpt : "";
  var strength = strengthOpt !== undefined ? strengthOpt : 0;
  return {
          author: author,
          peer: peer,
          strength: strength
        };
}

function default_authors_peers(author_peerOpt, param) {
  var author_peer = author_peerOpt !== undefined ? author_peerOpt : /* [] */0;
  return {
          author_peer: author_peer
        };
}

function default_lifecycle_stats(created_histoOpt, updated_histoOpt, merged_histoOpt, abandoned_histoOpt, createdOpt, abandonedOpt, mergedOpt, self_mergedOpt, self_merged_ratioOpt, ttm_meanOpt, ttm_variabilityOpt, updates_of_changesOpt, changes_with_testsOpt, iterations_per_changeOpt, commits_per_changeOpt, param) {
  var created_histo = created_histoOpt !== undefined ? created_histoOpt : /* [] */0;
  var updated_histo = updated_histoOpt !== undefined ? updated_histoOpt : /* [] */0;
  var merged_histo = merged_histoOpt !== undefined ? merged_histoOpt : /* [] */0;
  var abandoned_histo = abandoned_histoOpt !== undefined ? abandoned_histoOpt : /* [] */0;
  var created = createdOpt !== undefined ? Caml_option.valFromOption(createdOpt) : undefined;
  var abandoned = abandonedOpt !== undefined ? abandonedOpt : 0;
  var merged = mergedOpt !== undefined ? mergedOpt : 0;
  var self_merged = self_mergedOpt !== undefined ? self_mergedOpt : 0;
  var self_merged_ratio = self_merged_ratioOpt !== undefined ? self_merged_ratioOpt : 0;
  var ttm_mean = ttm_meanOpt !== undefined ? ttm_meanOpt : 0;
  var ttm_variability = ttm_variabilityOpt !== undefined ? ttm_variabilityOpt : 0;
  var updates_of_changes = updates_of_changesOpt !== undefined ? updates_of_changesOpt : 0;
  var changes_with_tests = changes_with_testsOpt !== undefined ? changes_with_testsOpt : 0;
  var iterations_per_change = iterations_per_changeOpt !== undefined ? iterations_per_changeOpt : 0;
  var commits_per_change = commits_per_changeOpt !== undefined ? commits_per_changeOpt : 0;
  return {
          created_histo: created_histo,
          updated_histo: updated_histo,
          merged_histo: merged_histo,
          abandoned_histo: abandoned_histo,
          created: created,
          abandoned: abandoned,
          merged: merged,
          self_merged: self_merged,
          self_merged_ratio: self_merged_ratio,
          ttm_mean: ttm_mean,
          ttm_variability: ttm_variability,
          updates_of_changes: updates_of_changes,
          changes_with_tests: changes_with_tests,
          iterations_per_change: iterations_per_change,
          commits_per_change: commits_per_change
        };
}

function default_changes_tops(authorsOpt, reposOpt, approvalsOpt, param) {
  var authors = authorsOpt !== undefined ? Caml_option.valFromOption(authorsOpt) : undefined;
  var repos = reposOpt !== undefined ? Caml_option.valFromOption(reposOpt) : undefined;
  var approvals = approvalsOpt !== undefined ? Caml_option.valFromOption(approvalsOpt) : undefined;
  return {
          authors: authors,
          repos: repos,
          approvals: approvals
        };
}

function default_query_response(param) {
  return {
          TAG: /* Error */0,
          _0: default_query_error(undefined, undefined, undefined)
        };
}

exports.default_task_data = default_task_data;
exports.default_suggestions_request = default_suggestions_request;
exports.default_suggestions_response = default_suggestions_response;
exports.default_fields_request = default_fields_request;
exports.default_field_type = default_field_type;
exports.default_field = default_field;
exports.default_fields_response = default_fields_response;
exports.default_query_error = default_query_error;
exports.default_check_request = default_check_request;
exports.default_check_response = default_check_response;
exports.default_author = default_author;
exports.default_author_request = default_author_request;
exports.default_author_response = default_author_response;
exports.default_order_direction = default_order_direction;
exports.default_order = default_order;
exports.default_query_request_query_type = default_query_request_query_type;
exports.default_query_request = default_query_request;
exports.default_file = default_file;
exports.default_commit = default_commit;
exports.default_change_merged_by_m = default_change_merged_by_m;
exports.default_change_ttm = default_change_ttm;
exports.default_change = default_change;
exports.default_changes = default_changes;
exports.default_ratio = default_ratio;
exports.default_change_event = default_change_event;
exports.default_change_and_events = default_change_and_events;
exports.default_review_count = default_review_count;
exports.default_review_stats = default_review_stats;
exports.default_activity_stats = default_activity_stats;
exports.default_repo_summary = default_repo_summary;
exports.default_repos_summary = default_repos_summary;
exports.default_author_peer = default_author_peer;
exports.default_authors_peers = default_authors_peers;
exports.default_lifecycle_stats = default_lifecycle_stats;
exports.default_changes_tops = default_changes_tops;
exports.default_query_response = default_query_response;
/* No side effect */
