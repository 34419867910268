'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var PFTypes = require("@softwarefactory-project/re-patternfly/src/PFTypes.bs.js");
var Prelude$Web = require("./Prelude.bs.js");
var Activity$Web = require("./Activity.bs.js");
var HistoBox$Web = require("./HistoBox.bs.js");
var GroupView$Web = require("./GroupView.bs.js");
var ReposView$Web = require("./ReposView.bs.js");
var NChangeView$Web = require("./NChangeView.bs.js");
var ActivePeopleView$Web = require("./ActivePeopleView.bs.js");
var PeersStrengthView$Web = require("./PeersStrengthView.bs.js");
var ReactCore = require("@patternfly/react-core");
var ReactIcons = require("@patternfly/react-icons");
var CommitsReviewsRatio$Web = require("./CommitsReviewsRatio.bs.js");

function changeActivityTab(store, isGroup, link, extraQuery, hideAuthors) {
  var tmp;
  if (isGroup) {
    var match = ActivePeopleView$Web.TopMetricsInfo.getQD(/* ByChangeCreated */0);
    var byChangeCreated = React.createElement(ActivePeopleView$Web.MostActiveAuthor.make, {
          store: store,
          qtype: match[0],
          title: "Top authors by change created",
          tooltip_content: match[2],
          link: link,
          extraQuery: extraQuery
        });
    var match$1 = ActivePeopleView$Web.TopMetricsInfo.getQD(/* ByChangeMerged */1);
    var byChangeMerged = React.createElement(ActivePeopleView$Web.MostActiveAuthor.make, {
          store: store,
          qtype: match$1[0],
          title: "Top authors by change merged",
          tooltip_content: match$1[2],
          link: link,
          extraQuery: extraQuery
        });
    tmp = React.createElement(ReactCore.Grid, {
          children: null,
          hasGutter: true,
          sm: PFTypes.Column._12,
          xl: PFTypes.Column._6
        }, React.createElement(ReactCore.GridItem, {
              children: React.createElement(Activity$Web.ChangesLifeCycleStats.make, {
                    store: store,
                    extraQuery: extraQuery,
                    hideAuthors: hideAuthors,
                    isScoped: true
                  })
            }), React.createElement(ReactCore.GridItem, {
              children: React.createElement(Prelude$Web.MStack.make, {
                    children: null
                  }, React.createElement(Prelude$Web.MStackItem.make, {
                        children: byChangeCreated
                      }), React.createElement(Prelude$Web.MStackItem.make, {
                        children: byChangeMerged
                      }))
            }));
  } else {
    tmp = React.createElement(Prelude$Web.MStackItem.make, {
          children: React.createElement(Activity$Web.ChangesLifeCycleStats.make, {
                store: store,
                extraQuery: extraQuery,
                hideAuthors: hideAuthors,
                isScoped: true
              })
        });
  }
  return React.createElement(Prelude$Web.MStack.make, {
              children: null
            }, React.createElement(Prelude$Web.MStackItem.make, {
                  children: React.createElement("p", undefined, Prelude$Web.str(" "))
                }), tmp);
}

function reviewActivityTab(store, isGroup, link, extraQuery, hideAuthors) {
  var match = ActivePeopleView$Web.TopMetricsInfo.getQD(/* ByMostReviewed */4);
  var match$1 = ActivePeopleView$Web.TopMetricsInfo.getQD(/* ByMostCommented */5);
  var match$2 = ActivePeopleView$Web.TopMetricsInfo.getQD(/* ByChangeReviewed */2);
  var byChangeReviewed = React.createElement(ActivePeopleView$Web.MostActiveAuthor.make, {
        store: store,
        qtype: match$2[0],
        title: "Top authors by reviews",
        tooltip_content: match$2[2],
        link: link,
        extraQuery: extraQuery
      });
  var match$3 = ActivePeopleView$Web.TopMetricsInfo.getQD(/* ByChangeCommented */3);
  var byChangeCommented = React.createElement(ActivePeopleView$Web.MostActiveAuthor.make, {
        store: store,
        qtype: match$3[0],
        title: "Top authors by comments",
        tooltip_content: match$3[2],
        link: link,
        extraQuery: extraQuery
      });
  return React.createElement(Prelude$Web.MStack.make, {
              children: null
            }, React.createElement(Prelude$Web.MStackItem.make, {
                  children: React.createElement("p", undefined, Prelude$Web.str(" "))
                }), React.createElement(Prelude$Web.MStackItem.make, {
                  children: React.createElement(ReactCore.Grid, {
                        children: null,
                        hasGutter: true,
                        sm: PFTypes.Column._12,
                        xl: PFTypes.Column._6
                      }, React.createElement(ReactCore.GridItem, {
                            children: React.createElement(Prelude$Web.MStack.make, {
                                  children: null
                                }, React.createElement(Prelude$Web.MStackItem.make, {
                                      children: React.createElement(Activity$Web.ChangesReviewStats.make, {
                                            store: store,
                                            extraQuery: extraQuery,
                                            hideAuthors: hideAuthors
                                          })
                                    }), React.createElement(Prelude$Web.MStackItem.make, {
                                      children: React.createElement(PeersStrengthView$Web.make, {
                                            store: store,
                                            stacked: true,
                                            extraQuery: extraQuery
                                          })
                                    }))
                          }), React.createElement(ReactCore.GridItem, {
                            children: React.createElement(Prelude$Web.MStack.make, {
                                  children: null
                                }, React.createElement(Prelude$Web.MStackItem.make, {
                                      children: React.createElement(CommitsReviewsRatio$Web.make, {
                                            store: store,
                                            extraQuery: extraQuery
                                          })
                                    }), React.createElement(Prelude$Web.MStackItem.make, {
                                      children: React.createElement(HistoBox$Web.ReviewsCommentsHistoBox.make, {
                                            store: store,
                                            extraQuery: extraQuery
                                          })
                                    }), React.createElement(Prelude$Web.MStackItem.make, {
                                      children: React.createElement(HistoBox$Web.CommitsHistoBox.make, {
                                            store: store,
                                            extraQuery: extraQuery
                                          })
                                    }), React.createElement(Prelude$Web.MStackItem.make, {
                                      children: React.createElement(ActivePeopleView$Web.MostActiveAuthor.make, {
                                            store: store,
                                            qtype: match[0],
                                            title: "Most reviewed authors",
                                            tooltip_content: match[2],
                                            link: link,
                                            extraQuery: extraQuery
                                          })
                                    }), React.createElement(Prelude$Web.MStackItem.make, {
                                      children: React.createElement(ActivePeopleView$Web.MostActiveAuthor.make, {
                                            store: store,
                                            qtype: match$1[0],
                                            title: "Most commented authors",
                                            tooltip_content: match$1[2],
                                            link: link,
                                            extraQuery: extraQuery
                                          })
                                    }), Prelude$Web.maybeRender(isGroup, React.createElement(React.Fragment, {
                                          children: null
                                        }, React.createElement(Prelude$Web.MStackItem.make, {
                                              children: byChangeReviewed
                                            }), React.createElement(Prelude$Web.MStackItem.make, {
                                              children: byChangeCommented
                                            }))))
                          }))
                }));
}

function tabTypeToName(tab) {
  switch (tab) {
    case /* ChangeActivity */0 :
        return "activity";
    case /* ReviewActivity */1 :
        return "review";
    case /* OpenChanges */2 :
        return "openChanges";
    case /* MergedChanges */3 :
        return "mergedChanges";
    case /* AbandonedChanges */4 :
        return "abandonedChanges";
    case /* RepoSummary */5 :
        return "repoSummary";
    case /* GroupMembers */6 :
        return "groupMembers";
    
  }
}

function tabNameToType(tab) {
  switch (tab) {
    case "abandonedChanges" :
        return /* AbandonedChanges */4;
    case "activity" :
        return /* ChangeActivity */0;
    case "groupMembers" :
        return /* GroupMembers */6;
    case "mergedChanges" :
        return /* MergedChanges */3;
    case "openChanges" :
        return /* OpenChanges */2;
    case "repoSummary" :
        return /* RepoSummary */5;
    case "review" :
        return /* ReviewActivity */1;
    default:
      return /* ChangeActivity */0;
  }
}

function buildView(store, entityTypeAsText, entityName, isGroup) {
  var dispatch = store[1];
  React.useEffect((function () {
          Curry._1(dispatch, {
                TAG: /* SetFilter */2,
                _0: ""
              });
          Curry._1(dispatch, {
                TAG: /* SetAuthorScopedTab */5,
                _0: /* ChangeActivity */0
              });
          
        }), []);
  var hideAuthors = isGroup ? false : true;
  var toSearchValue = function (value) {
    return "\"" + value + "\"";
  };
  var extraQuery = isGroup ? "group:" + toSearchValue(entityName) : "author:" + toSearchValue(entityName);
  return React.createElement(Prelude$Web.MonoCard.make, {
              title: entityTypeAsText + " details for " + entityName,
              tooltip_content: "This view is scoped to the " + entityName + " " + entityTypeAsText,
              icon: React.createElement(ReactIcons.UserIcon, {}),
              children: React.createElement(ReactCore.Tabs, {
                    children: null,
                    activeKey: tabTypeToName(store[0].author_scoped_tab),
                    isBox: true,
                    onSelect: (function (param, key) {
                        Curry._1(dispatch, {
                              TAG: /* SetFilter */2,
                              _0: ""
                            });
                        return Curry._1(dispatch, {
                                    TAG: /* SetAuthorScopedTab */5,
                                    _0: tabNameToType(key)
                                  });
                      })
                  }, React.createElement(ReactCore.Tab, {
                        children: changeActivityTab(store, isGroup, /* ScopedAuthorLink */2, extraQuery, hideAuthors),
                        eventKey: "activity",
                        title: React.createElement(ReactCore.TabTitleText, {
                              children: "Change activity"
                            })
                      }), React.createElement(ReactCore.Tab, {
                        children: reviewActivityTab(store, isGroup, /* ScopedAuthorLink */2, extraQuery, hideAuthors),
                        eventKey: "review",
                        title: React.createElement(ReactCore.TabTitleText, {
                              children: "Review activity"
                            })
                      }), React.createElement(ReactCore.Tab, {
                        children: React.createElement(Prelude$Web.MStack.make, {
                              children: React.createElement(Prelude$Web.MStackItem.make, {
                                    children: React.createElement(NChangeView$Web.make, {
                                          store: store,
                                          extraQuery: extraQuery + " state:open",
                                          hideAuthors: hideAuthors,
                                          disableHiddenChange: true
                                        })
                                  })
                            }),
                        eventKey: "openChanges",
                        title: React.createElement(ReactCore.TabTitleText, {
                              children: "Open changes"
                            })
                      }), React.createElement(ReactCore.Tab, {
                        children: React.createElement(Prelude$Web.MStack.make, {
                              children: React.createElement(Prelude$Web.MStackItem.make, {
                                    children: React.createElement(NChangeView$Web.make, {
                                          store: store,
                                          extraQuery: extraQuery + " state:merged",
                                          hideAuthors: hideAuthors,
                                          disableHiddenChange: true
                                        })
                                  })
                            }),
                        eventKey: "mergedChanges",
                        title: React.createElement(ReactCore.TabTitleText, {
                              children: "Merged changes"
                            })
                      }), React.createElement(ReactCore.Tab, {
                        children: React.createElement(Prelude$Web.MStack.make, {
                              children: React.createElement(Prelude$Web.MStackItem.make, {
                                    children: React.createElement(NChangeView$Web.make, {
                                          store: store,
                                          extraQuery: extraQuery + " state:abandoned",
                                          hideAuthors: hideAuthors,
                                          disableHiddenChange: true
                                        })
                                  })
                            }),
                        eventKey: "abandonedChanges",
                        title: React.createElement(ReactCore.TabTitleText, {
                              children: "Abandoned changes"
                            })
                      }), React.createElement(ReactCore.Tab, {
                        children: React.createElement(Prelude$Web.MStack.make, {
                              children: React.createElement(Prelude$Web.MStackItem.make, {
                                    children: React.createElement(ReposView$Web.make, {
                                          store: store,
                                          extraQuery: extraQuery,
                                          isScoped: true
                                        })
                                  })
                            }),
                        eventKey: "repoSummary",
                        title: React.createElement(ReactCore.TabTitleText, {
                              children: "Repositories"
                            })
                      }), isGroup ? React.createElement(ReactCore.Tab, {
                          children: React.createElement(Prelude$Web.MStack.make, {
                                children: React.createElement(Prelude$Web.MStackItem.make, {
                                      children: React.createElement(GroupView$Web.make, {
                                            group: entityName,
                                            store: store
                                          })
                                    })
                              }),
                          eventKey: "groupMembers",
                          title: React.createElement(ReactCore.TabTitleText, {
                                children: "Group members"
                              })
                        }) : null)
            });
}

function ScopedView$AuthorScopedView(Props) {
  var store = Props.store;
  var name = Props.name;
  return buildView(store, "Author", decodeURIComponent(name), false);
}

var AuthorScopedView = {
  make: ScopedView$AuthorScopedView
};

function ScopedView$GroupScopedView(Props) {
  var store = Props.store;
  var name = Props.name;
  return buildView(store, "Group", decodeURIComponent(name), true);
}

var GroupScopedView = {
  make: ScopedView$GroupScopedView
};

exports.changeActivityTab = changeActivityTab;
exports.reviewActivityTab = reviewActivityTab;
exports.tabTypeToName = tabTypeToName;
exports.tabNameToType = tabNameToType;
exports.buildView = buildView;
exports.AuthorScopedView = AuthorScopedView;
exports.GroupScopedView = GroupScopedView;
/* react Not a pure module */
