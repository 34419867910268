'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Prelude$Web = require("./Prelude.bs.js");
var HiddenChanges$Web = require("./HiddenChanges.bs.js");

function LocalSettings$View(Props) {
  var store = Props.store;
  var match = React.useState(function () {
        return [];
      });
  var state = store[0];
  var setHiddens = match[1];
  var reload = function (param) {
    HiddenChanges$Web.getAll(state.dexie).then(function (xs) {
          return Promise.resolve(Curry._1(setHiddens, (function (param) {
                            return xs;
                          })));
        });
    
  };
  React.useEffect((function () {
          reload(undefined);
          
        }), []);
  return React.createElement(React.Fragment, undefined, React.createElement("h3", undefined, Prelude$Web.str("Hidden changes")), React.createElement("ul", undefined, Belt_Array.mapWithIndex(match[0], (function (idx, hidden) {
                        return React.createElement("li", {
                                    key: String(idx)
                                  }, React.createElement("a", {
                                        style: {
                                          color: "#007bff",
                                          cursor: "pointer",
                                          paddingLeft: "5px"
                                        },
                                        onClick: (function (param) {
                                            return reload(HiddenChanges$Web.remove(state.dexie, hidden.id));
                                          })
                                      }, Prelude$Web.str("🗑")), Prelude$Web.str(hidden.id));
                      }))));
}

var View = {
  make: LocalSettings$View
};

var AboutModal = Prelude$Web.AboutModal;

var AboutModalBox = Prelude$Web.AboutModalBox;

var AboutModalBoxBrand = Prelude$Web.AboutModalBoxBrand;

var AboutModalBoxCloseButton = Prelude$Web.AboutModalBoxCloseButton;

var AboutModalBoxContent = Prelude$Web.AboutModalBoxContent;

var AboutModalBoxHeader = Prelude$Web.AboutModalBoxHeader;

var AboutModalBoxHero = Prelude$Web.AboutModalBoxHero;

var AboutModalContainer = Prelude$Web.AboutModalContainer;

var Accordion = Prelude$Web.Accordion;

var AccordionContent = Prelude$Web.AccordionContent;

var AccordionItem = Prelude$Web.AccordionItem;

var AccordionToggle = Prelude$Web.AccordionToggle;

var Alert = Prelude$Web.Alert;

var AlertActionCloseButton = Prelude$Web.AlertActionCloseButton;

var AlertActionLink = Prelude$Web.AlertActionLink;

var AlertIcon = Prelude$Web.AlertIcon;

var AlertGroup = Prelude$Web.AlertGroup;

var ApplicationLauncher = Prelude$Web.ApplicationLauncher;

var ApplicationLauncherContent = Prelude$Web.ApplicationLauncherContent;

var ApplicationLauncherIcon = Prelude$Web.ApplicationLauncherIcon;

var ApplicationLauncherItem = Prelude$Web.ApplicationLauncherItem;

var ApplicationLauncherText = Prelude$Web.ApplicationLauncherText;

var Avatar = Prelude$Web.Avatar;

var Backdrop = Prelude$Web.Backdrop;

var BackgroundImage = Prelude$Web.BackgroundImage;

var Badge = Prelude$Web.Badge;

var Banner = Prelude$Web.Banner;

var Brand = Prelude$Web.Brand;

var Breadcrumb = Prelude$Web.Breadcrumb;

var BreadcrumbHeading = Prelude$Web.BreadcrumbHeading;

var BreadcrumbItem = Prelude$Web.BreadcrumbItem;

var Button = Prelude$Web.Button;

var Card = Prelude$Web.Card;

var CardActions = Prelude$Web.CardActions;

var CardBody = Prelude$Web.CardBody;

var CardExpandableContent = Prelude$Web.CardExpandableContent;

var CardFooter = Prelude$Web.CardFooter;

var CardHeadMain = Prelude$Web.CardHeadMain;

var CardHeader = Prelude$Web.CardHeader;

var CardHeaderMain = Prelude$Web.CardHeaderMain;

var CardTitle = Prelude$Web.CardTitle;

var Checkbox = Prelude$Web.Checkbox;

var ClipboardCopy = Prelude$Web.ClipboardCopy;

var ClipboardCopyAction = Prelude$Web.ClipboardCopyAction;

var ClipboardCopyButton = Prelude$Web.ClipboardCopyButton;

var ClipboardCopyExpanded = Prelude$Web.ClipboardCopyExpanded;

var ClipboardCopyToggle = Prelude$Web.ClipboardCopyToggle;

var DataList = Prelude$Web.DataList;

var DataListAction = Prelude$Web.DataListAction;

var DataListCell = Prelude$Web.DataListCell;

var DataListCheck = Prelude$Web.DataListCheck;

var DataListContent = Prelude$Web.DataListContent;

var DataListControl = Prelude$Web.DataListControl;

var DataListDragButton = Prelude$Web.DataListDragButton;

var DataListItemChild = Prelude$Web.DataListItemChild;

var DataListItem = Prelude$Web.DataListItem;

var DataListItemCells = Prelude$Web.DataListItemCells;

var DataListItemRow = Prelude$Web.DataListItemRow;

var DataListText = Prelude$Web.DataListText;

var DataListToggle = Prelude$Web.DataListToggle;

var DatePicker = Prelude$Web.DatePicker;

var DescriptionList = Prelude$Web.DescriptionList;

var DescriptionListDescription = Prelude$Web.DescriptionListDescription;

var DescriptionListGroup = Prelude$Web.DescriptionListGroup;

var DescriptionListTerm = Prelude$Web.DescriptionListTerm;

var Divider = Prelude$Web.Divider;

var DrawerContext = Prelude$Web.DrawerContext;

var Drawer = Prelude$Web.Drawer;

var DrawerActions = Prelude$Web.DrawerActions;

var DrawerCloseButton = Prelude$Web.DrawerCloseButton;

var DrawerContent = Prelude$Web.DrawerContent;

var DrawerContentBody = Prelude$Web.DrawerContentBody;

var DrawerHead = Prelude$Web.DrawerHead;

var DrawerMain = Prelude$Web.DrawerMain;

var DrawerPanelBody = Prelude$Web.DrawerPanelBody;

var DrawerPanelContent = Prelude$Web.DrawerPanelContent;

var DrawerSection = Prelude$Web.DrawerSection;

var BadgeToggle = Prelude$Web.BadgeToggle;

var Dropdown = Prelude$Web.Dropdown;

var DropdownGroup = Prelude$Web.DropdownGroup;

var DropdownItem = Prelude$Web.DropdownItem;

var Separator = Prelude$Web.Separator;

var DropdownToggle = Prelude$Web.DropdownToggle;

var DropdownToggleAction = Prelude$Web.DropdownToggleAction;

var DropdownToggleCheckbox = Prelude$Web.DropdownToggleCheckbox;

var InternalDropdownItem = Prelude$Web.InternalDropdownItem;

var KebabToggle = Prelude$Web.KebabToggle;

var Toggle = Prelude$Web.Toggle;

var EmptyState = Prelude$Web.EmptyState;

var EmptyStateBody = Prelude$Web.EmptyStateBody;

var EmptyStateIcon = Prelude$Web.EmptyStateIcon;

var Icon = Prelude$Web.Icon;

var EmptyStatePrimary = Prelude$Web.EmptyStatePrimary;

var EmptyStateSecondaryActions = Prelude$Web.EmptyStateSecondaryActions;

var ActionGroup = Prelude$Web.ActionGroup;

var Form = Prelude$Web.Form;

var FormAlert = Prelude$Web.FormAlert;

var FormFieldGroup = Prelude$Web.FormFieldGroup;

var FormFieldGroupExpandable = Prelude$Web.FormFieldGroupExpandable;

var FormFieldGroupHeader = Prelude$Web.FormFieldGroupHeader;

var FormFieldGroupToggle = Prelude$Web.FormFieldGroupToggle;

var FormGroup = Prelude$Web.FormGroup;

var FormHelperText = Prelude$Web.FormHelperText;

var FormSection = Prelude$Web.FormSection;

var InternalFormFieldGroup = Prelude$Web.InternalFormFieldGroup;

var Hint = Prelude$Web.Hint;

var HintBody = Prelude$Web.HintBody;

var HintFooter = Prelude$Web.HintFooter;

var HintTitle = Prelude$Web.HintTitle;

var InputGroup = Prelude$Web.InputGroup;

var InputGroupText = Prelude$Web.InputGroupText;

var Label = Prelude$Web.Label;

var LabelGroup = Prelude$Web.LabelGroup;

var List = Prelude$Web.List;

var ListItem = Prelude$Web.ListItem;

var Login = Prelude$Web.Login;

var LoginFooter = Prelude$Web.LoginFooter;

var LoginFooterItem = Prelude$Web.LoginFooterItem;

var LoginForm = Prelude$Web.LoginForm;

var LoginHeader = Prelude$Web.LoginHeader;

var LoginMainBody = Prelude$Web.LoginMainBody;

var LoginMainFooter = Prelude$Web.LoginMainFooter;

var LoginMainFooterBandItem = Prelude$Web.LoginMainFooterBandItem;

var LoginMainFooterLinksItem = Prelude$Web.LoginMainFooterLinksItem;

var LoginMainHeader = Prelude$Web.LoginMainHeader;

var LoginPage = Prelude$Web.LoginPage;

var DrilldownMenu = Prelude$Web.DrilldownMenu;

var Menu = Prelude$Web.Menu;

var MenuBreadcrumb = Prelude$Web.MenuBreadcrumb;

var MenuContent = Prelude$Web.MenuContent;

var MenuGroup = Prelude$Web.MenuGroup;

var MenuInput = Prelude$Web.MenuInput;

var MenuItem = Prelude$Web.MenuItem;

var MenuItemAction = Prelude$Web.MenuItemAction;

var MenuList = Prelude$Web.MenuList;

var Modal = Prelude$Web.Modal;

var ModalBox = Prelude$Web.ModalBox;

var ModalBoxBody = Prelude$Web.ModalBoxBody;

var ModalBoxCloseButton = Prelude$Web.ModalBoxCloseButton;

var ModalBoxDescription = Prelude$Web.ModalBoxDescription;

var ModalBoxFooter = Prelude$Web.ModalBoxFooter;

var ModalBoxHeader = Prelude$Web.ModalBoxHeader;

var ModalBoxTitle = Prelude$Web.ModalBoxTitle;

var ModalContent = Prelude$Web.ModalContent;

var Nav = Prelude$Web.Nav;

var NavExpandable = Prelude$Web.NavExpandable;

var NavGroup = Prelude$Web.NavGroup;

var NavItem = Prelude$Web.NavItem;

var NavList = Prelude$Web.NavList;

var Page = Prelude$Web.Page;

var PageContext = Prelude$Web.PageContext;

var PageBreadcrumb = Prelude$Web.PageBreadcrumb;

var PageGroup = Prelude$Web.PageGroup;

var PageHeader = Prelude$Web.PageHeader;

var PageHeaderTools = Prelude$Web.PageHeaderTools;

var PageHeaderToolsGroup = Prelude$Web.PageHeaderToolsGroup;

var PageHeaderToolsItem = Prelude$Web.PageHeaderToolsItem;

var PageNavigation = Prelude$Web.PageNavigation;

var PageSection = Prelude$Web.PageSection;

var PageSidebarContext = Prelude$Web.PageSidebarContext;

var PageSidebar = Prelude$Web.PageSidebar;

var Navigation = Prelude$Web.Navigation;

var OptionsToggle = Prelude$Web.OptionsToggle;

var Pagination = Prelude$Web.Pagination;

var PaginationOptionsMenu = Prelude$Web.PaginationOptionsMenu;

var ToggleTemplate = Prelude$Web.ToggleTemplate;

var Radio = Prelude$Web.Radio;

var Select = Prelude$Web.Select;

var SelectGroup = Prelude$Web.SelectGroup;

var SelectMenu = Prelude$Web.SelectMenu;

var SelectOption = Prelude$Web.SelectOption;

var SelectToggle = Prelude$Web.SelectToggle;

var Spinner = Prelude$Web.Spinner;

var Tab = Prelude$Web.Tab;

var TabButton = Prelude$Web.TabButton;

var TabContent = Prelude$Web.TabContent;

var TabTitleIcon = Prelude$Web.TabTitleIcon;

var TabTitleText = Prelude$Web.TabTitleText;

var Tabs = Prelude$Web.Tabs;

var $$Text = Prelude$Web.$$Text;

var TextContent = Prelude$Web.TextContent;

var TextList = Prelude$Web.TextList;

var TextListItem = Prelude$Web.TextListItem;

var TextArea = Prelude$Web.TextArea;

var TextInput = Prelude$Web.TextInput;

var Tile = Prelude$Web.Tile;

var Title = Prelude$Web.Title;

var Toolbar = Prelude$Web.Toolbar;

var ToolbarChipGroupContent = Prelude$Web.ToolbarChipGroupContent;

var ToolbarContent = Prelude$Web.ToolbarContent;

var ToolbarExpandIconWrapper = Prelude$Web.ToolbarExpandIconWrapper;

var ToolbarExpandableContent = Prelude$Web.ToolbarExpandableContent;

var ToolbarFilter = Prelude$Web.ToolbarFilter;

var ToolbarItem = Prelude$Web.ToolbarItem;

var ToolbarToggleGroup = Prelude$Web.ToolbarToggleGroup;

var Tooltip = Prelude$Web.Tooltip;

var TooltipArrow = Prelude$Web.TooltipArrow;

var TooltipContent = Prelude$Web.TooltipContent;

var Column = Prelude$Web.Column;

var Icons = Prelude$Web.Icons;

var Layout = Prelude$Web.Layout;

var mkFormatter = Prelude$Web.mkFormatter;

var mkRows = Prelude$Web.mkRows;

var sortRows = Prelude$Web.sortRows;

var Table = Prelude$Web.Table;

var TableBody = Prelude$Web.TableBody;

var TableHeader = Prelude$Web.TableHeader;

var readWindowLocationSearch = Prelude$Web.readWindowLocationSearch;

var readWindowLocationHash = Prelude$Web.readWindowLocationHash;

var readWindowLocationPathname = Prelude$Web.readWindowLocationPathname;

var replaceWindowLocation = Prelude$Web.replaceWindowLocation;

var readWindowLocationFull = Prelude$Web.readWindowLocationFull;

var getCookies = Prelude$Web.getCookies;

var delCookie = Prelude$Web.delCookie;

var momentFromNow = Prelude$Web.momentFromNow;

var momentHumanizeDuration = Prelude$Web.momentHumanizeDuration;

var resetLocationSearch = Prelude$Web.resetLocationSearch;

var setLocationSearch = Prelude$Web.setLocationSearch;

var Link = Prelude$Web.Link;

var Env = Prelude$Web.Env;

var Time = Prelude$Web.Time;

var $$URLSearchParams = Prelude$Web.$$URLSearchParams;

var usePagination = Prelude$Web.usePagination;

var useAutoGetOn = Prelude$Web.useAutoGetOn;

var useAutoGet = Prelude$Web.useAutoGet;

var NetworkRender = Prelude$Web.NetworkRender;

var getDate = Prelude$Web.getDate;

var getCurrentTime = Prelude$Web.getCurrentTime;

var str = Prelude$Web.str;

var maybeRender = Prelude$Web.maybeRender;

var maybeHide = Prelude$Web.maybeHide;

var maybeRenderArray = Prelude$Web.maybeRenderArray;

var maybeRenderList = Prelude$Web.maybeRenderList;

var lower = Prelude$Web.lower;

var int32_str = Prelude$Web.int32_str;

var float_str = Prelude$Web.float_str;

var quoteValue = Prelude$Web.quoteValue;

var take = Prelude$Web.take;

var elemText = Prelude$Web.elemText;

var fromMaybe = Prelude$Web.fromMaybe;

var mapWithKey = Prelude$Web.mapWithKey;

var catMaybes = Prelude$Web.catMaybes;

var prependToAll = Prelude$Web.prependToAll;

var concatSep = Prelude$Web.concatSep;

var useToggle = Prelude$Web.useToggle;

var addQuery = Prelude$Web.addQuery;

var orderFromQS = Prelude$Web.orderFromQS;

var orderDirToQS = Prelude$Web.orderDirToQS;

var orderToQS = Prelude$Web.orderToQS;

var orderDirToString = Prelude$Web.orderDirToString;

var MExpandablePanel = Prelude$Web.MExpandablePanel;

var MGrid = Prelude$Web.MGrid;

var MGridItem = Prelude$Web.MGridItem;

var MGridItemXl11 = Prelude$Web.MGridItemXl11;

var MGridItemXl10 = Prelude$Web.MGridItemXl10;

var MGridItemXl9 = Prelude$Web.MGridItemXl9;

var MGridItemXl8 = Prelude$Web.MGridItemXl8;

var MGridItemXl7 = Prelude$Web.MGridItemXl7;

var MGridItemXl6 = Prelude$Web.MGridItemXl6;

var MGridItemXl5 = Prelude$Web.MGridItemXl5;

var MGridItemXl4 = Prelude$Web.MGridItemXl4;

var MGridItemXl3 = Prelude$Web.MGridItemXl3;

var MGridItemXl2 = Prelude$Web.MGridItemXl2;

var MGridItemXl1 = Prelude$Web.MGridItemXl1;

var MStack = Prelude$Web.MStack;

var MStackItem = Prelude$Web.MStackItem;

var MCenteredContent = Prelude$Web.MCenteredContent;

var SortableTable = Prelude$Web.SortableTable;

var MSelect = Prelude$Web.MSelect;

var addProp = Prelude$Web.addProp;

var MSimpleCard = Prelude$Web.MSimpleCard;

var LimitSelector = Prelude$Web.LimitSelector;

var horizontalSpacing = Prelude$Web.horizontalSpacing;

var ExternalLink = Prelude$Web.ExternalLink;

var MonoCard = Prelude$Web.MonoCard;

var QueryRender = Prelude$Web.QueryRender;

var Renderer = Prelude$Web.Renderer;

var QueryRenderCard = Prelude$Web.QueryRenderCard;

var MonoTabs = Prelude$Web.MonoTabs;

var HTMXGetHook = Prelude$Web.HTMXGetHook;

exports.AboutModal = AboutModal;
exports.AboutModalBox = AboutModalBox;
exports.AboutModalBoxBrand = AboutModalBoxBrand;
exports.AboutModalBoxCloseButton = AboutModalBoxCloseButton;
exports.AboutModalBoxContent = AboutModalBoxContent;
exports.AboutModalBoxHeader = AboutModalBoxHeader;
exports.AboutModalBoxHero = AboutModalBoxHero;
exports.AboutModalContainer = AboutModalContainer;
exports.Accordion = Accordion;
exports.AccordionContent = AccordionContent;
exports.AccordionItem = AccordionItem;
exports.AccordionToggle = AccordionToggle;
exports.Alert = Alert;
exports.AlertActionCloseButton = AlertActionCloseButton;
exports.AlertActionLink = AlertActionLink;
exports.AlertIcon = AlertIcon;
exports.AlertGroup = AlertGroup;
exports.ApplicationLauncher = ApplicationLauncher;
exports.ApplicationLauncherContent = ApplicationLauncherContent;
exports.ApplicationLauncherIcon = ApplicationLauncherIcon;
exports.ApplicationLauncherItem = ApplicationLauncherItem;
exports.ApplicationLauncherText = ApplicationLauncherText;
exports.Avatar = Avatar;
exports.Backdrop = Backdrop;
exports.BackgroundImage = BackgroundImage;
exports.Badge = Badge;
exports.Banner = Banner;
exports.Brand = Brand;
exports.Breadcrumb = Breadcrumb;
exports.BreadcrumbHeading = BreadcrumbHeading;
exports.BreadcrumbItem = BreadcrumbItem;
exports.Button = Button;
exports.Card = Card;
exports.CardActions = CardActions;
exports.CardBody = CardBody;
exports.CardExpandableContent = CardExpandableContent;
exports.CardFooter = CardFooter;
exports.CardHeadMain = CardHeadMain;
exports.CardHeader = CardHeader;
exports.CardHeaderMain = CardHeaderMain;
exports.CardTitle = CardTitle;
exports.Checkbox = Checkbox;
exports.ClipboardCopy = ClipboardCopy;
exports.ClipboardCopyAction = ClipboardCopyAction;
exports.ClipboardCopyButton = ClipboardCopyButton;
exports.ClipboardCopyExpanded = ClipboardCopyExpanded;
exports.ClipboardCopyToggle = ClipboardCopyToggle;
exports.DataList = DataList;
exports.DataListAction = DataListAction;
exports.DataListCell = DataListCell;
exports.DataListCheck = DataListCheck;
exports.DataListContent = DataListContent;
exports.DataListControl = DataListControl;
exports.DataListDragButton = DataListDragButton;
exports.DataListItemChild = DataListItemChild;
exports.DataListItem = DataListItem;
exports.DataListItemCells = DataListItemCells;
exports.DataListItemRow = DataListItemRow;
exports.DataListText = DataListText;
exports.DataListToggle = DataListToggle;
exports.DatePicker = DatePicker;
exports.DescriptionList = DescriptionList;
exports.DescriptionListDescription = DescriptionListDescription;
exports.DescriptionListGroup = DescriptionListGroup;
exports.DescriptionListTerm = DescriptionListTerm;
exports.Divider = Divider;
exports.DrawerContext = DrawerContext;
exports.Drawer = Drawer;
exports.DrawerActions = DrawerActions;
exports.DrawerCloseButton = DrawerCloseButton;
exports.DrawerContent = DrawerContent;
exports.DrawerContentBody = DrawerContentBody;
exports.DrawerHead = DrawerHead;
exports.DrawerMain = DrawerMain;
exports.DrawerPanelBody = DrawerPanelBody;
exports.DrawerPanelContent = DrawerPanelContent;
exports.DrawerSection = DrawerSection;
exports.BadgeToggle = BadgeToggle;
exports.Dropdown = Dropdown;
exports.DropdownGroup = DropdownGroup;
exports.DropdownItem = DropdownItem;
exports.Separator = Separator;
exports.DropdownToggle = DropdownToggle;
exports.DropdownToggleAction = DropdownToggleAction;
exports.DropdownToggleCheckbox = DropdownToggleCheckbox;
exports.InternalDropdownItem = InternalDropdownItem;
exports.KebabToggle = KebabToggle;
exports.Toggle = Toggle;
exports.EmptyState = EmptyState;
exports.EmptyStateBody = EmptyStateBody;
exports.EmptyStateIcon = EmptyStateIcon;
exports.Icon = Icon;
exports.EmptyStatePrimary = EmptyStatePrimary;
exports.EmptyStateSecondaryActions = EmptyStateSecondaryActions;
exports.ActionGroup = ActionGroup;
exports.Form = Form;
exports.FormAlert = FormAlert;
exports.FormFieldGroup = FormFieldGroup;
exports.FormFieldGroupExpandable = FormFieldGroupExpandable;
exports.FormFieldGroupHeader = FormFieldGroupHeader;
exports.FormFieldGroupToggle = FormFieldGroupToggle;
exports.FormGroup = FormGroup;
exports.FormHelperText = FormHelperText;
exports.FormSection = FormSection;
exports.InternalFormFieldGroup = InternalFormFieldGroup;
exports.Hint = Hint;
exports.HintBody = HintBody;
exports.HintFooter = HintFooter;
exports.HintTitle = HintTitle;
exports.InputGroup = InputGroup;
exports.InputGroupText = InputGroupText;
exports.Label = Label;
exports.LabelGroup = LabelGroup;
exports.List = List;
exports.ListItem = ListItem;
exports.Login = Login;
exports.LoginFooter = LoginFooter;
exports.LoginFooterItem = LoginFooterItem;
exports.LoginForm = LoginForm;
exports.LoginHeader = LoginHeader;
exports.LoginMainBody = LoginMainBody;
exports.LoginMainFooter = LoginMainFooter;
exports.LoginMainFooterBandItem = LoginMainFooterBandItem;
exports.LoginMainFooterLinksItem = LoginMainFooterLinksItem;
exports.LoginMainHeader = LoginMainHeader;
exports.LoginPage = LoginPage;
exports.DrilldownMenu = DrilldownMenu;
exports.Menu = Menu;
exports.MenuBreadcrumb = MenuBreadcrumb;
exports.MenuContent = MenuContent;
exports.MenuGroup = MenuGroup;
exports.MenuInput = MenuInput;
exports.MenuItem = MenuItem;
exports.MenuItemAction = MenuItemAction;
exports.MenuList = MenuList;
exports.Modal = Modal;
exports.ModalBox = ModalBox;
exports.ModalBoxBody = ModalBoxBody;
exports.ModalBoxCloseButton = ModalBoxCloseButton;
exports.ModalBoxDescription = ModalBoxDescription;
exports.ModalBoxFooter = ModalBoxFooter;
exports.ModalBoxHeader = ModalBoxHeader;
exports.ModalBoxTitle = ModalBoxTitle;
exports.ModalContent = ModalContent;
exports.Nav = Nav;
exports.NavExpandable = NavExpandable;
exports.NavGroup = NavGroup;
exports.NavItem = NavItem;
exports.NavList = NavList;
exports.Page = Page;
exports.PageContext = PageContext;
exports.PageBreadcrumb = PageBreadcrumb;
exports.PageGroup = PageGroup;
exports.PageHeader = PageHeader;
exports.PageHeaderTools = PageHeaderTools;
exports.PageHeaderToolsGroup = PageHeaderToolsGroup;
exports.PageHeaderToolsItem = PageHeaderToolsItem;
exports.PageNavigation = PageNavigation;
exports.PageSection = PageSection;
exports.PageSidebarContext = PageSidebarContext;
exports.PageSidebar = PageSidebar;
exports.Navigation = Navigation;
exports.OptionsToggle = OptionsToggle;
exports.Pagination = Pagination;
exports.PaginationOptionsMenu = PaginationOptionsMenu;
exports.ToggleTemplate = ToggleTemplate;
exports.Radio = Radio;
exports.Select = Select;
exports.SelectGroup = SelectGroup;
exports.SelectMenu = SelectMenu;
exports.SelectOption = SelectOption;
exports.SelectToggle = SelectToggle;
exports.Spinner = Spinner;
exports.Tab = Tab;
exports.TabButton = TabButton;
exports.TabContent = TabContent;
exports.TabTitleIcon = TabTitleIcon;
exports.TabTitleText = TabTitleText;
exports.Tabs = Tabs;
exports.$$Text = $$Text;
exports.TextContent = TextContent;
exports.TextList = TextList;
exports.TextListItem = TextListItem;
exports.TextArea = TextArea;
exports.TextInput = TextInput;
exports.Tile = Tile;
exports.Title = Title;
exports.Toolbar = Toolbar;
exports.ToolbarChipGroupContent = ToolbarChipGroupContent;
exports.ToolbarContent = ToolbarContent;
exports.ToolbarExpandIconWrapper = ToolbarExpandIconWrapper;
exports.ToolbarExpandableContent = ToolbarExpandableContent;
exports.ToolbarFilter = ToolbarFilter;
exports.ToolbarItem = ToolbarItem;
exports.ToolbarToggleGroup = ToolbarToggleGroup;
exports.Tooltip = Tooltip;
exports.TooltipArrow = TooltipArrow;
exports.TooltipContent = TooltipContent;
exports.Column = Column;
exports.Icons = Icons;
exports.Layout = Layout;
exports.mkFormatter = mkFormatter;
exports.mkRows = mkRows;
exports.sortRows = sortRows;
exports.Table = Table;
exports.TableBody = TableBody;
exports.TableHeader = TableHeader;
exports.readWindowLocationSearch = readWindowLocationSearch;
exports.readWindowLocationHash = readWindowLocationHash;
exports.readWindowLocationPathname = readWindowLocationPathname;
exports.replaceWindowLocation = replaceWindowLocation;
exports.readWindowLocationFull = readWindowLocationFull;
exports.getCookies = getCookies;
exports.delCookie = delCookie;
exports.momentFromNow = momentFromNow;
exports.momentHumanizeDuration = momentHumanizeDuration;
exports.resetLocationSearch = resetLocationSearch;
exports.setLocationSearch = setLocationSearch;
exports.Link = Link;
exports.Env = Env;
exports.Time = Time;
exports.$$URLSearchParams = $$URLSearchParams;
exports.usePagination = usePagination;
exports.useAutoGetOn = useAutoGetOn;
exports.useAutoGet = useAutoGet;
exports.NetworkRender = NetworkRender;
exports.getDate = getDate;
exports.getCurrentTime = getCurrentTime;
exports.str = str;
exports.maybeRender = maybeRender;
exports.maybeHide = maybeHide;
exports.maybeRenderArray = maybeRenderArray;
exports.maybeRenderList = maybeRenderList;
exports.lower = lower;
exports.int32_str = int32_str;
exports.float_str = float_str;
exports.quoteValue = quoteValue;
exports.take = take;
exports.elemText = elemText;
exports.fromMaybe = fromMaybe;
exports.mapWithKey = mapWithKey;
exports.catMaybes = catMaybes;
exports.prependToAll = prependToAll;
exports.concatSep = concatSep;
exports.useToggle = useToggle;
exports.addQuery = addQuery;
exports.orderFromQS = orderFromQS;
exports.orderDirToQS = orderDirToQS;
exports.orderToQS = orderToQS;
exports.orderDirToString = orderDirToString;
exports.MExpandablePanel = MExpandablePanel;
exports.MGrid = MGrid;
exports.MGridItem = MGridItem;
exports.MGridItemXl11 = MGridItemXl11;
exports.MGridItemXl10 = MGridItemXl10;
exports.MGridItemXl9 = MGridItemXl9;
exports.MGridItemXl8 = MGridItemXl8;
exports.MGridItemXl7 = MGridItemXl7;
exports.MGridItemXl6 = MGridItemXl6;
exports.MGridItemXl5 = MGridItemXl5;
exports.MGridItemXl4 = MGridItemXl4;
exports.MGridItemXl3 = MGridItemXl3;
exports.MGridItemXl2 = MGridItemXl2;
exports.MGridItemXl1 = MGridItemXl1;
exports.MStack = MStack;
exports.MStackItem = MStackItem;
exports.MCenteredContent = MCenteredContent;
exports.SortableTable = SortableTable;
exports.MSelect = MSelect;
exports.addProp = addProp;
exports.MSimpleCard = MSimpleCard;
exports.LimitSelector = LimitSelector;
exports.horizontalSpacing = horizontalSpacing;
exports.ExternalLink = ExternalLink;
exports.MonoCard = MonoCard;
exports.QueryRender = QueryRender;
exports.Renderer = Renderer;
exports.QueryRenderCard = QueryRenderCard;
exports.MonoTabs = MonoTabs;
exports.HTMXGetHook = HTMXGetHook;
exports.View = View;
/* react Not a pure module */
