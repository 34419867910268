'use strict';

var React = require("react");
var PFTypes = require("@softwarefactory-project/re-patternfly/src/PFTypes.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var MLink$Web = require("./MLink.bs.js");
var Store$Web = require("./Store.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Prelude$Web = require("./Prelude.bs.js");
var ChartjsJsx = require("./chartjs.jsx");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var ReactCore = require("@patternfly/react-core");
var ReactIcons = require("@patternfly/react-icons");

function Activity$GraphWithStats(Props) {
  var graph = Props.graph;
  var stats = Props.stats;
  return React.createElement(ReactCore.Stack, {
              children: null,
              hasGutter: true
            }, React.createElement(ReactCore.StackItem, {
                  children: stats
                }), React.createElement(ReactCore.StackItem, {
                  children: React.createElement(ReactCore.Card, {
                        children: React.createElement(ReactCore.CardBody, {
                              children: graph
                            })
                      })
                }));
}

var GraphWithStats = {
  make: Activity$GraphWithStats
};

function item(title, value) {
  return React.createElement(ReactCore.ListItem, {
              children: null,
              key: title
            }, React.createElement("span", undefined, React.createElement("b", undefined, Prelude$Web.str(title))), React.createElement("span", undefined, value));
}

var make = ChartjsJsx.CChangesLifeCycleHisto;

var ChangesLifeCycleHisto = {
  make: make
};

function Activity$ChangesLifeCycleStats(Props) {
  var store = Props.store;
  var extraQuery = Props.extraQuery;
  var hideAuthors = Props.hideAuthors;
  var isScoped = Props.isScoped;
  var state = store[0];
  var baseRequest = Store$Web.mkSearchRequest(state, /* Query_changes_lifecycle_stats */11);
  var request_index = baseRequest.index;
  var request_username = baseRequest.username;
  var request_query = extraQuery !== undefined ? Prelude$Web.addQuery(baseRequest.query, extraQuery) : baseRequest.query;
  var request_query_type = baseRequest.query_type;
  var request_order = baseRequest.order;
  var request_limit = baseRequest.limit;
  var request_change_id = baseRequest.change_id;
  var request = {
    index: request_index,
    username: request_username,
    query: request_query,
    query_type: request_query_type,
    order: request_order,
    limit: request_limit,
    change_id: request_change_id
  };
  var trigger = state.query + Belt_Option.getWithDefault(extraQuery, "");
  var icon = React.createElement(ReactIcons.RunningIcon, {});
  var match = function (resp) {
    if (resp.TAG === /* Lifecycle_stats */7) {
      return resp._0;
    }
    
  };
  var childrenBuilder = function (data) {
    var graph = React.createElement(make, {
          created: Belt_List.toArray(data.created_histo),
          updated: Belt_List.toArray(data.updated_histo),
          merged: Belt_List.toArray(data.merged_histo),
          abandoned: Belt_List.toArray(data.abandoned_histo)
        });
    var created = data.created;
    var tmp;
    var exit = 0;
    if (isScoped !== undefined && isScoped) {
      tmp = React.createElement(MLink$Web.MonoLink.make, {
            store: store,
            filter: "",
            name: Prelude$Web.int32_str(data.merged),
            action: {
              TAG: /* SetAuthorScopedTab */5,
              _0: /* MergedChanges */3
            }
          });
    } else {
      exit = 1;
    }
    if (exit === 1) {
      tmp = React.createElement(MLink$Web.MonoLink.make, {
            store: store,
            filter: "state:merged",
            path: "changes",
            name: Prelude$Web.int32_str(data.merged)
          });
    }
    var tmp$1;
    var exit$1 = 0;
    if (isScoped !== undefined && isScoped) {
      tmp$1 = React.createElement(MLink$Web.MonoLink.make, {
            store: store,
            filter: "",
            name: Prelude$Web.int32_str(data.abandoned),
            action: {
              TAG: /* SetAuthorScopedTab */5,
              _0: /* AbandonedChanges */4
            }
          });
    } else {
      exit$1 = 1;
    }
    if (exit$1 === 1) {
      tmp$1 = React.createElement(MLink$Web.MonoLink.make, {
            store: store,
            filter: "state:abandoned",
            path: "changes",
            name: Prelude$Web.int32_str(data.abandoned)
          });
    }
    var match = Belt_Option.getWithDefault(hideAuthors, false);
    var match$1 = data.created;
    var stats = React.createElement(ReactCore.Grid, {
          children: null,
          md: PFTypes.Column._4
        }, React.createElement(ReactCore.GridItem, {
              children: React.createElement(ReactCore.List, {
                    children: Belt_Array.concatMany([
                          created !== undefined ? [item("Changes created: ", Prelude$Web.str(Prelude$Web.int32_str(created.events_count)))] : [],
                          [item("Changes merged: ", tmp)],
                          [item("Changes self-merged: ", React.createElement(MLink$Web.MonoLink.make, {
                                      store: store,
                                      filter: "state:self_merged",
                                      path: "changes",
                                      name: Prelude$Web.int32_str(data.self_merged)
                                    }))],
                          [item("Changes abandoned: ", tmp$1)]
                        ])
                  })
            }), React.createElement(ReactCore.List, {
              children: [
                item("Changes with tests: ", Prelude$Web.str(Prelude$Web.float_str(data.changes_with_tests) + "%")),
                item("Mean TTM: ", Prelude$Web.str(Prelude$Web.momentHumanizeDuration(data.ttm_mean))),
                item("TTM Median Deviation: ", Prelude$Web.str(Prelude$Web.momentHumanizeDuration(data.ttm_variability))),
                item("Changes updates: ", Prelude$Web.str(Prelude$Web.int32_str(data.updates_of_changes)))
              ]
            }), React.createElement(ReactCore.GridItem, {
              children: React.createElement(ReactCore.List, {
                    children: Belt_Array.concatMany([
                          [item("Commits by change: ", Prelude$Web.str(Prelude$Web.float_str(data.commits_per_change)))],
                          [item("Iterations by change: ", Prelude$Web.str(Prelude$Web.float_str(data.iterations_per_change)))],
                          match ? [] : (
                              match$1 !== undefined ? [item("Changes authors: ", Prelude$Web.str(Prelude$Web.int32_str(match$1.authors_count)))] : []
                            )
                        ])
                  })
            }));
    return React.createElement(Activity$GraphWithStats, {
                graph: graph,
                stats: stats
              });
  };
  return React.createElement(Prelude$Web.QueryRenderCard.make, {
              request: request,
              trigger: trigger,
              title: "Changes lifecycle stats",
              tooltip_content: "This shows trends of change related metrics such as the evolution of the amount of change created",
              icon: icon,
              match: match,
              childrenBuilder: childrenBuilder
            });
}

var ChangesLifeCycleStats = {
  ChangesLifeCycleHisto: ChangesLifeCycleHisto,
  make: Activity$ChangesLifeCycleStats
};

var make$1 = ChartjsJsx.CChangeReviewEventsHisto;

var CChangesReviewHisto = {
  make: make$1
};

function Activity$ChangesReviewStats(Props) {
  var store = Props.store;
  var extraQuery = Props.extraQuery;
  var hideAuthors = Props.hideAuthors;
  var state = store[0];
  var baseRequest = Store$Web.mkSearchRequest(state, /* Query_changes_review_stats */10);
  var request_index = baseRequest.index;
  var request_username = baseRequest.username;
  var request_query = extraQuery !== undefined ? Prelude$Web.addQuery(baseRequest.query, extraQuery) : baseRequest.query;
  var request_query_type = baseRequest.query_type;
  var request_order = baseRequest.order;
  var request_limit = baseRequest.limit;
  var request_change_id = baseRequest.change_id;
  var request = {
    index: request_index,
    username: request_username,
    query: request_query,
    query_type: request_query_type,
    order: request_order,
    limit: request_limit,
    change_id: request_change_id
  };
  var trigger = state.query + Belt_Option.getWithDefault(extraQuery, "");
  var icon = React.createElement(ReactIcons.OutlinedCommentsIcon, {});
  var match = function (resp) {
    if (resp.TAG === /* Review_stats */6) {
      return resp._0;
    }
    
  };
  var childrenBuilder = function (data) {
    var graph = React.createElement(make$1, {
          comment_histo: Belt_List.toArray(data.comment_histo),
          review_histo: Belt_List.toArray(data.review_histo)
        });
    var review = data.review_count;
    var match = Belt_Option.getWithDefault(hideAuthors, false);
    var match$1 = data.review_count;
    var comment = data.comment_count;
    var match$2 = Belt_Option.getWithDefault(hideAuthors, false);
    var match$3 = data.comment_count;
    var stats = React.createElement(ReactCore.Grid, {
          children: null,
          md: PFTypes.Column._4
        }, React.createElement(ReactCore.GridItem, {
              children: React.createElement(ReactCore.List, {
                    children: Belt_Array.concatMany([
                          review !== undefined ? [item("Changes reviewed: ", Prelude$Web.str(Prelude$Web.int32_str(review.events_count)))] : [],
                          [item("1st review mean time: ", Prelude$Web.str(Prelude$Web.momentHumanizeDuration(data.review_delay)))],
                          match ? [] : (
                              match$1 !== undefined ? [item("Reviews authors: ", Prelude$Web.str(Prelude$Web.int32_str(match$1.authors_count)))] : []
                            )
                        ])
                  })
            }), React.createElement(ReactCore.GridItem, {
              children: React.createElement(ReactCore.List, {
                    children: Belt_Array.concatMany([
                          comment !== undefined ? [item("Changes commented: ", Prelude$Web.str(Prelude$Web.int32_str(comment.events_count)))] : [],
                          [item("1st comment mean time: ", Prelude$Web.str(Prelude$Web.momentHumanizeDuration(data.comment_delay)))],
                          match$2 ? [] : (
                              match$3 !== undefined ? [item("Comments authors: ", Prelude$Web.str(Prelude$Web.int32_str(match$3.authors_count)))] : []
                            )
                        ])
                  })
            }));
    return React.createElement(Activity$GraphWithStats, {
                graph: graph,
                stats: stats
              });
  };
  return React.createElement(Prelude$Web.QueryRenderCard.make, {
              request: request,
              trigger: trigger,
              title: "Changes review stats",
              tooltip_content: "This shows trends of reviews and comments",
              icon: icon,
              match: match,
              childrenBuilder: childrenBuilder
            });
}

var ChangesReviewStats = {
  CChangesReviewHisto: CChangesReviewHisto,
  make: Activity$ChangesReviewStats
};

var make$2 = ChartjsJsx.ChangesReviewStats;

var DurationComplexityGraph = {
  make: make$2
};

function Activity$ChangesMergedDuration(Props) {
  var store = Props.store;
  var state = store[0];
  var query = Prelude$Web.addQuery(state.query, "state:merged");
  var init = Store$Web.mkSearchRequest(state, /* Query_change */0);
  var request_index = init.index;
  var request_username = init.username;
  var request_query_type = init.query_type;
  var request_order = init.order;
  var request_limit = init.limit;
  var request_change_id = init.change_id;
  var request = {
    index: request_index,
    username: request_username,
    query: query,
    query_type: request_query_type,
    order: request_order,
    limit: request_limit,
    change_id: request_change_id
  };
  var onClick = function (changeId) {
    return RescriptReactRouter.push("/" + state.index + "/change/" + changeId);
  };
  var trigger = state.query;
  var icon = React.createElement(ReactIcons.OutlinedClockIcon, {});
  var match = function (resp) {
    if (resp.TAG === /* Changes */1) {
      return resp._0;
    }
    
  };
  var childrenBuilder = function (data) {
    var graph = React.createElement(make$2, {
          data: Belt_List.toArray(data.changes),
          onClick: onClick
        });
    return React.createElement(Activity$GraphWithStats, {
                graph: graph,
                stats: null
              });
  };
  return React.createElement(Prelude$Web.QueryRenderCard.make, {
              request: request,
              trigger: trigger,
              title: "Changes merge duration",
              tooltip_content: "This shows the delay in days to merge by change complexity",
              icon: icon,
              match: match,
              childrenBuilder: childrenBuilder
            });
}

var ChangesMergedDuration = {
  DurationComplexityGraph: DurationComplexityGraph,
  make: Activity$ChangesMergedDuration
};

var make$3 = ChartjsJsx.CAuthorsHistoStats;

var CAuthorsHistoChart = {
  make: make$3
};

function Activity$AuthorHistoStats(Props) {
  var store = Props.store;
  var state = store[0];
  var request = Store$Web.mkSearchRequest(state, /* Query_active_authors_stats */12);
  var trigger = state.query;
  var icon = React.createElement(ReactIcons.UsersIcon, {});
  var match = function (resp) {
    if (resp.TAG === /* Activity_stats */8) {
      return resp._0;
    }
    
  };
  var childrenBuilder = function (data) {
    var graph = React.createElement(make$3, {
          change_histo: Belt_List.toArray(data.changes_histo),
          comment_histo: Belt_List.toArray(data.comments_histo),
          review_histo: Belt_List.toArray(data.reviews_histo)
        });
    var stats = React.createElement(ReactCore.Grid, {
          children: React.createElement(ReactCore.GridItem, {
                children: React.createElement(ReactCore.List, {
                      children: [
                        item("Change authors: ", Prelude$Web.str(Prelude$Web.int32_str(data.change_authors))),
                        item("Review authors: ", Prelude$Web.str(Prelude$Web.int32_str(data.review_authors))),
                        item("Comment authors: ", Prelude$Web.str(Prelude$Web.int32_str(data.comment_authors)))
                      ]
                    })
              }),
          md: PFTypes.Column._4
        });
    return React.createElement(Activity$GraphWithStats, {
                graph: graph,
                stats: stats
              });
  };
  return React.createElement(Prelude$Web.QueryRenderCard.make, {
              request: request,
              trigger: trigger,
              title: "Active authors",
              tooltip_content: "This shows trends of review and comment activities",
              icon: icon,
              match: match,
              childrenBuilder: childrenBuilder
            });
}

var AuthorHistoStats = {
  CAuthorsHistoChart: CAuthorsHistoChart,
  make: Activity$AuthorHistoStats
};

function Activity(Props) {
  var store = Props.store;
  return React.createElement(Prelude$Web.MGrid.make, {
              children: null
            }, React.createElement(Prelude$Web.MGridItemXl6.make, {
                  children: React.createElement(Activity$ChangesLifeCycleStats, {
                        store: store
                      })
                }), React.createElement(Prelude$Web.MGridItemXl6.make, {
                  children: React.createElement(Activity$ChangesReviewStats, {
                        store: store
                      })
                }), React.createElement(Prelude$Web.MGridItemXl6.make, {
                  children: React.createElement(Activity$AuthorHistoStats, {
                        store: store
                      })
                }), React.createElement(Prelude$Web.MGridItemXl6.make, {
                  children: React.createElement(Activity$ChangesMergedDuration, {
                        store: store
                      })
                }));
}

var make$4 = Activity;

var $$default = Activity;

exports.GraphWithStats = GraphWithStats;
exports.item = item;
exports.ChangesLifeCycleStats = ChangesLifeCycleStats;
exports.ChangesReviewStats = ChangesReviewStats;
exports.ChangesMergedDuration = ChangesMergedDuration;
exports.AuthorHistoStats = AuthorHistoStats;
exports.make = make$4;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
/* make Not a pure module */
