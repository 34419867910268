'use strict';

var $$Array = require("rescript/lib/js/array.js");
var Pbrt_bs = require("bs-ocaml-protoc-json/src/pbrt_bs.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var MetricBs$Web = require("./MetricBs.bs.js");
var SearchTypes$Web = require("./SearchTypes.bs.js");
var TimestampBs$Web = require("../components/TimestampBs.bs.js");

function default_task_data_mutable(param) {
  return {
          updated_at: undefined,
          change_url: "",
          ttype: /* [] */0,
          tid: "",
          url: "",
          title: "",
          severity: "",
          priority: "",
          score: 0,
          prefix: ""
        };
}

function default_field_mutable(param) {
  return {
          name: "",
          description: "",
          type_: SearchTypes$Web.default_field_type(undefined)
        };
}

function default_query_request_mutable(param) {
  return {
          index: "",
          username: "",
          query: "",
          query_type: SearchTypes$Web.default_query_request_query_type(undefined),
          order: undefined,
          limit: 0,
          change_id: ""
        };
}

function default_change_mutable(param) {
  return {
          change_id: "",
          author: "",
          title: "",
          url: "",
          repository_fullname: "",
          state: "",
          branch: "",
          target_branch: "",
          created_at: undefined,
          updated_at: undefined,
          merged_at: undefined,
          merged_by_m: /* Merged_by */{
            _0: ""
          },
          text: "",
          additions: 0,
          deletions: 0,
          approval: /* [] */0,
          assignees: /* [] */0,
          labels: /* [] */0,
          draft: false,
          mergeable: false,
          changed_files: /* [] */0,
          changed_files_count: 0,
          commits: /* [] */0,
          commits_count: 0,
          task_data: /* [] */0,
          ttm: /* Duration */{
            _0: 0
          }
        };
}

function default_lifecycle_stats_mutable(param) {
  return {
          created_histo: /* [] */0,
          updated_histo: /* [] */0,
          merged_histo: /* [] */0,
          abandoned_histo: /* [] */0,
          created: undefined,
          abandoned: 0,
          merged: 0,
          self_merged: 0,
          self_merged_ratio: 0,
          ttm_mean: 0,
          ttm_variability: 0,
          updates_of_changes: 0,
          changes_with_tests: 0,
          iterations_per_change: 0,
          commits_per_change: 0
        };
}

function decode_task_data(json) {
  var v = default_task_data_mutable(undefined);
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    switch (match) {
      case "change_url" :
          var json$1 = json["change_url"];
          v.change_url = Pbrt_bs.string(json$1, "task_data", "change_url");
          break;
      case "prefix" :
          var json$2 = json["prefix"];
          v.prefix = Pbrt_bs.string(json$2, "task_data", "prefix");
          break;
      case "priority" :
          var json$3 = json["priority"];
          v.priority = Pbrt_bs.string(json$3, "task_data", "priority");
          break;
      case "score" :
          var json$4 = json["score"];
          v.score = Pbrt_bs.int32(json$4, "task_data", "score");
          break;
      case "severity" :
          var json$5 = json["severity"];
          v.severity = Pbrt_bs.string(json$5, "task_data", "severity");
          break;
      case "tid" :
          var json$6 = json["tid"];
          v.tid = Pbrt_bs.string(json$6, "task_data", "tid");
          break;
      case "title" :
          var json$7 = json["title"];
          v.title = Pbrt_bs.string(json$7, "task_data", "title");
          break;
      case "ttype" :
          var a = json["ttype"];
          var a$1 = Pbrt_bs.array_(a, "task_data", "ttype");
          v.ttype = $$Array.to_list($$Array.map((function (json) {
                      return Pbrt_bs.string(json, "task_data", "ttype");
                    }), a$1));
          break;
      case "updated_at" :
          var json$8 = json["updated_at"];
          v.updated_at = Caml_option.some(TimestampBs$Web.decode_timestamp(Pbrt_bs.string(json$8, "task_data", "updated_at")));
          break;
      case "url" :
          var json$9 = json["url"];
          v.url = Pbrt_bs.string(json$9, "task_data", "url");
          break;
      default:
        
    }
  }
  return {
          updated_at: v.updated_at,
          change_url: v.change_url,
          ttype: v.ttype,
          tid: v.tid,
          url: v.url,
          title: v.title,
          severity: v.severity,
          priority: v.priority,
          score: v.score,
          prefix: v.prefix
        };
}

function decode_suggestions_request(json) {
  var v = "";
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    if (match === "index") {
      var json$1 = json["index"];
      v = Pbrt_bs.string(json$1, "suggestions_request", "index");
    }
    
  }
  return {
          index: v
        };
}

function decode_suggestions_response(json) {
  var v = {
    task_types: /* [] */0,
    authors: /* [] */0,
    approvals: /* [] */0,
    priorities: /* [] */0,
    severities: /* [] */0,
    projects: /* [] */0,
    groups: /* [] */0,
    labels: /* [] */0
  };
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    switch (match) {
      case "approvals" :
          var a = json["approvals"];
          var a$1 = Pbrt_bs.array_(a, "suggestions_response", "approvals");
          v.approvals = $$Array.to_list($$Array.map((function (json) {
                      return Pbrt_bs.string(json, "suggestions_response", "approvals");
                    }), a$1));
          break;
      case "authors" :
          var a$2 = json["authors"];
          var a$3 = Pbrt_bs.array_(a$2, "suggestions_response", "authors");
          v.authors = $$Array.to_list($$Array.map((function (json) {
                      return Pbrt_bs.string(json, "suggestions_response", "authors");
                    }), a$3));
          break;
      case "groups" :
          var a$4 = json["groups"];
          var a$5 = Pbrt_bs.array_(a$4, "suggestions_response", "groups");
          v.groups = $$Array.to_list($$Array.map((function (json) {
                      return Pbrt_bs.string(json, "suggestions_response", "groups");
                    }), a$5));
          break;
      case "labels" :
          var a$6 = json["labels"];
          var a$7 = Pbrt_bs.array_(a$6, "suggestions_response", "labels");
          v.labels = $$Array.to_list($$Array.map((function (json) {
                      return Pbrt_bs.string(json, "suggestions_response", "labels");
                    }), a$7));
          break;
      case "priorities" :
          var a$8 = json["priorities"];
          var a$9 = Pbrt_bs.array_(a$8, "suggestions_response", "priorities");
          v.priorities = $$Array.to_list($$Array.map((function (json) {
                      return Pbrt_bs.string(json, "suggestions_response", "priorities");
                    }), a$9));
          break;
      case "projects" :
          var a$10 = json["projects"];
          var a$11 = Pbrt_bs.array_(a$10, "suggestions_response", "projects");
          v.projects = $$Array.to_list($$Array.map((function (json) {
                      return Pbrt_bs.string(json, "suggestions_response", "projects");
                    }), a$11));
          break;
      case "severities" :
          var a$12 = json["severities"];
          var a$13 = Pbrt_bs.array_(a$12, "suggestions_response", "severities");
          v.severities = $$Array.to_list($$Array.map((function (json) {
                      return Pbrt_bs.string(json, "suggestions_response", "severities");
                    }), a$13));
          break;
      case "task_types" :
          var a$14 = json["task_types"];
          var a$15 = Pbrt_bs.array_(a$14, "suggestions_response", "task_types");
          v.task_types = $$Array.to_list($$Array.map((function (json) {
                      return Pbrt_bs.string(json, "suggestions_response", "task_types");
                    }), a$15));
          break;
      default:
        
    }
  }
  return {
          task_types: v.task_types,
          authors: v.authors,
          approvals: v.approvals,
          priorities: v.priorities,
          severities: v.severities,
          projects: v.projects,
          groups: v.groups,
          labels: v.labels
        };
}

function decode_fields_request(json) {
  var v = "";
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    if (match === "version") {
      var json$1 = json["version"];
      v = Pbrt_bs.string(json$1, "fields_request", "version");
    }
    
  }
  return {
          version: v
        };
}

function decode_field_type(json) {
  var match = Pbrt_bs.string(json, "field_type", "value");
  switch (match) {
    case "FIELD_BOOL" :
        return /* Field_bool */3;
    case "" :
    case "FIELD_DATE" :
        return /* Field_date */0;
    case "FIELD_NUMBER" :
        return /* Field_number */1;
    case "FIELD_REGEX" :
        return /* Field_regex */4;
    case "FIELD_TEXT" :
        return /* Field_text */2;
    default:
      return Pbrt_bs.E.malformed_variant("field_type");
  }
}

function decode_field(json) {
  var v = default_field_mutable(undefined);
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    switch (match) {
      case "description" :
          var json$1 = json["description"];
          v.description = Pbrt_bs.string(json$1, "field", "description");
          break;
      case "name" :
          var json$2 = json["name"];
          v.name = Pbrt_bs.string(json$2, "field", "name");
          break;
      case "type" :
          var json$3 = json["type"];
          v.type_ = decode_field_type(json$3);
          break;
      default:
        
    }
  }
  return {
          name: v.name,
          description: v.description,
          type_: v.type_
        };
}

function decode_fields_response(json) {
  var v = /* [] */0;
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    if (match === "fields") {
      var a = json["fields"];
      var a$1 = Pbrt_bs.array_(a, "fields_response", "fields");
      v = $$Array.to_list($$Array.map((function (json) {
                  return decode_field(Pbrt_bs.object_(json, "fields_response", "fields"));
                }), a$1));
    }
    
  }
  return {
          fields: v
        };
}

function decode_query_error(json) {
  var v = {
    message: "",
    position: 0
  };
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    switch (match) {
      case "message" :
          var json$1 = json["message"];
          v.message = Pbrt_bs.string(json$1, "query_error", "message");
          break;
      case "position" :
          var json$2 = json["position"];
          v.position = Pbrt_bs.int32(json$2, "query_error", "position");
          break;
      default:
        
    }
  }
  return {
          message: v.message,
          position: v.position
        };
}

function decode_check_request(json) {
  var v = {
    index: "",
    username: "",
    query: ""
  };
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    switch (match) {
      case "index" :
          var json$1 = json["index"];
          v.index = Pbrt_bs.string(json$1, "check_request", "index");
          break;
      case "query" :
          var json$2 = json["query"];
          v.query = Pbrt_bs.string(json$2, "check_request", "query");
          break;
      case "username" :
          var json$3 = json["username"];
          v.username = Pbrt_bs.string(json$3, "check_request", "username");
          break;
      default:
        
    }
  }
  return {
          index: v.index,
          username: v.username,
          query: v.query
        };
}

function decode_check_response(json) {
  var keys = Object.keys(json);
  var _i = keys.length - 1 | 0;
  while(true) {
    var i = _i;
    if (i === -1) {
      return Pbrt_bs.E.malformed_variant("check_response");
    }
    var match = keys[i];
    switch (match) {
      case "error" :
          var json$1 = json["error"];
          return {
                  TAG: /* Error */1,
                  _0: decode_query_error(Pbrt_bs.object_(json$1, "check_response", "Error"))
                };
      case "success" :
          var json$2 = json["success"];
          return {
                  TAG: /* Success */0,
                  _0: Pbrt_bs.string(json$2, "check_response", "Success")
                };
      default:
        _i = i - 1 | 0;
        continue ;
    }
  };
}

function decode_author(json) {
  var v = {
    muid: "",
    aliases: /* [] */0,
    groups: /* [] */0
  };
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    switch (match) {
      case "aliases" :
          var a = json["aliases"];
          var a$1 = Pbrt_bs.array_(a, "author", "aliases");
          v.aliases = $$Array.to_list($$Array.map((function (json) {
                      return Pbrt_bs.string(json, "author", "aliases");
                    }), a$1));
          break;
      case "groups" :
          var a$2 = json["groups"];
          var a$3 = Pbrt_bs.array_(a$2, "author", "groups");
          v.groups = $$Array.to_list($$Array.map((function (json) {
                      return Pbrt_bs.string(json, "author", "groups");
                    }), a$3));
          break;
      case "muid" :
          var json$1 = json["muid"];
          v.muid = Pbrt_bs.string(json$1, "author", "muid");
          break;
      default:
        
    }
  }
  return {
          muid: v.muid,
          aliases: v.aliases,
          groups: v.groups
        };
}

function decode_author_request(json) {
  var v = {
    index: "",
    query: ""
  };
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    switch (match) {
      case "index" :
          var json$1 = json["index"];
          v.index = Pbrt_bs.string(json$1, "author_request", "index");
          break;
      case "query" :
          var json$2 = json["query"];
          v.query = Pbrt_bs.string(json$2, "author_request", "query");
          break;
      default:
        
    }
  }
  return {
          index: v.index,
          query: v.query
        };
}

function decode_author_response(json) {
  var v = /* [] */0;
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    if (match === "authors") {
      var a = json["authors"];
      var a$1 = Pbrt_bs.array_(a, "author_response", "authors");
      v = $$Array.to_list($$Array.map((function (json) {
                  return decode_author(Pbrt_bs.object_(json, "author_response", "authors"));
                }), a$1));
    }
    
  }
  return {
          authors: v
        };
}

function decode_order_direction(json) {
  var match = Pbrt_bs.string(json, "order_direction", "value");
  switch (match) {
    case "" :
    case "ASC" :
        return /* Asc */0;
    case "DESC" :
        return /* Desc */1;
    default:
      return Pbrt_bs.E.malformed_variant("order_direction");
  }
}

function decode_order(json) {
  var v = {
    field: "",
    direction: SearchTypes$Web.default_order_direction(undefined)
  };
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    switch (match) {
      case "direction" :
          var json$1 = json["direction"];
          v.direction = decode_order_direction(json$1);
          break;
      case "field" :
          var json$2 = json["field"];
          v.field = Pbrt_bs.string(json$2, "order", "field");
          break;
      default:
        
    }
  }
  return {
          field: v.field,
          direction: v.direction
        };
}

function decode_query_request_query_type(json) {
  var match = Pbrt_bs.string(json, "query_request_query_type", "value");
  switch (match) {
    case "QUERY_ACTIVE_AUTHORS_STATS" :
        return /* Query_active_authors_stats */12;
    case "" :
    case "QUERY_CHANGE" :
        return /* Query_change */0;
    case "QUERY_CHANGES_LIFECYCLE_STATS" :
        return /* Query_changes_lifecycle_stats */11;
    case "QUERY_CHANGES_REVIEW_STATS" :
        return /* Query_changes_review_stats */10;
    case "QUERY_CHANGES_TOPS" :
        return /* Query_changes_tops */14;
    case "QUERY_CHANGE_AND_EVENTS" :
        return /* Query_change_and_events */13;
    case "QUERY_HISTO_COMMITS" :
        return /* Query_histo_commits */16;
    case "QUERY_HISTO_REVIEWS_AND_COMMENTS" :
        return /* Query_histo_reviews_and_comments */17;
    case "QUERY_NEW_CHANGES_AUTHORS" :
        return /* Query_new_changes_authors */9;
    case "QUERY_RATIO_COMMITS_VS_REVIEWS" :
        return /* Query_ratio_commits_vs_reviews */15;
    case "QUERY_REPOS_SUMMARY" :
        return /* Query_repos_summary */1;
    case "QUERY_TOP_AUTHORS_CHANGES_COMMENTED" :
        return /* Query_top_authors_changes_commented */5;
    case "QUERY_TOP_AUTHORS_CHANGES_CREATED" :
        return /* Query_top_authors_changes_created */2;
    case "QUERY_TOP_AUTHORS_CHANGES_MERGED" :
        return /* Query_top_authors_changes_merged */3;
    case "QUERY_TOP_AUTHORS_CHANGES_REVIEWED" :
        return /* Query_top_authors_changes_reviewed */4;
    case "QUERY_TOP_AUTHORS_PEERS" :
        return /* Query_top_authors_peers */8;
    case "QUERY_TOP_COMMENTED_AUTHORS" :
        return /* Query_top_commented_authors */7;
    case "QUERY_TOP_REVIEWED_AUTHORS" :
        return /* Query_top_reviewed_authors */6;
    default:
      return Pbrt_bs.E.malformed_variant("query_request_query_type");
  }
}

function decode_query_request(json) {
  var v = default_query_request_mutable(undefined);
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    switch (match) {
      case "change_id" :
          var json$1 = json["change_id"];
          v.change_id = Pbrt_bs.string(json$1, "query_request", "change_id");
          break;
      case "index" :
          var json$2 = json["index"];
          v.index = Pbrt_bs.string(json$2, "query_request", "index");
          break;
      case "limit" :
          var json$3 = json["limit"];
          v.limit = Pbrt_bs.int32(json$3, "query_request", "limit");
          break;
      case "order" :
          var json$4 = json["order"];
          v.order = decode_order(Pbrt_bs.object_(json$4, "query_request", "order"));
          break;
      case "query" :
          var json$5 = json["query"];
          v.query = Pbrt_bs.string(json$5, "query_request", "query");
          break;
      case "query_type" :
          var json$6 = json["query_type"];
          v.query_type = decode_query_request_query_type(json$6);
          break;
      case "username" :
          var json$7 = json["username"];
          v.username = Pbrt_bs.string(json$7, "query_request", "username");
          break;
      default:
        
    }
  }
  return {
          index: v.index,
          username: v.username,
          query: v.query,
          query_type: v.query_type,
          order: v.order,
          limit: v.limit,
          change_id: v.change_id
        };
}

function decode_file(json) {
  var v = {
    additions: 0,
    deletions: 0,
    path: ""
  };
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    switch (match) {
      case "additions" :
          var json$1 = json["additions"];
          v.additions = Pbrt_bs.int32(json$1, "file", "additions");
          break;
      case "deletions" :
          var json$2 = json["deletions"];
          v.deletions = Pbrt_bs.int32(json$2, "file", "deletions");
          break;
      case "path" :
          var json$3 = json["path"];
          v.path = Pbrt_bs.string(json$3, "file", "path");
          break;
      default:
        
    }
  }
  return {
          additions: v.additions,
          deletions: v.deletions,
          path: v.path
        };
}

function decode_commit(json) {
  var v = {
    sha: "",
    title: "",
    author: "",
    authored_at: undefined,
    committer: "",
    committed_at: undefined,
    additions: 0,
    deletions: 0
  };
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    switch (match) {
      case "additions" :
          var json$1 = json["additions"];
          v.additions = Pbrt_bs.int32(json$1, "commit", "additions");
          break;
      case "author" :
          var json$2 = json["author"];
          v.author = Pbrt_bs.string(json$2, "commit", "author");
          break;
      case "authored_at" :
          var json$3 = json["authored_at"];
          v.authored_at = Caml_option.some(TimestampBs$Web.decode_timestamp(Pbrt_bs.string(json$3, "commit", "authored_at")));
          break;
      case "committed_at" :
          var json$4 = json["committed_at"];
          v.committed_at = Caml_option.some(TimestampBs$Web.decode_timestamp(Pbrt_bs.string(json$4, "commit", "committed_at")));
          break;
      case "committer" :
          var json$5 = json["committer"];
          v.committer = Pbrt_bs.string(json$5, "commit", "committer");
          break;
      case "deletions" :
          var json$6 = json["deletions"];
          v.deletions = Pbrt_bs.int32(json$6, "commit", "deletions");
          break;
      case "sha" :
          var json$7 = json["sha"];
          v.sha = Pbrt_bs.string(json$7, "commit", "sha");
          break;
      case "title" :
          var json$8 = json["title"];
          v.title = Pbrt_bs.string(json$8, "commit", "title");
          break;
      default:
        
    }
  }
  return {
          sha: v.sha,
          title: v.title,
          author: v.author,
          authored_at: v.authored_at,
          committer: v.committer,
          committed_at: v.committed_at,
          additions: v.additions,
          deletions: v.deletions
        };
}

function decode_change_merged_by_m(json) {
  var keys = Object.keys(json);
  var _i = keys.length - 1 | 0;
  while(true) {
    var i = _i;
    if (i === -1) {
      return Pbrt_bs.E.malformed_variant("change_merged_by_m");
    }
    var match = keys[i];
    if (match === "merged_by") {
      var json$1 = json["merged_by"];
      return /* Merged_by */{
              _0: Pbrt_bs.string(json$1, "change_merged_by_m", "Merged_by")
            };
    }
    _i = i - 1 | 0;
    continue ;
  };
}

function decode_change_ttm(json) {
  var keys = Object.keys(json);
  var _i = keys.length - 1 | 0;
  while(true) {
    var i = _i;
    if (i === -1) {
      return Pbrt_bs.E.malformed_variant("change_ttm");
    }
    var match = keys[i];
    if (match === "duration") {
      var json$1 = json["duration"];
      return /* Duration */{
              _0: Pbrt_bs.int32(json$1, "change_ttm", "Duration")
            };
    }
    _i = i - 1 | 0;
    continue ;
  };
}

function decode_change(json) {
  var v = default_change_mutable(undefined);
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    switch (match) {
      case "additions" :
          var json$1 = json["additions"];
          v.additions = Pbrt_bs.int32(json$1, "change", "additions");
          break;
      case "approval" :
          var a = json["approval"];
          var a$1 = Pbrt_bs.array_(a, "change", "approval");
          v.approval = $$Array.to_list($$Array.map((function (json) {
                      return Pbrt_bs.string(json, "change", "approval");
                    }), a$1));
          break;
      case "assignees" :
          var a$2 = json["assignees"];
          var a$3 = Pbrt_bs.array_(a$2, "change", "assignees");
          v.assignees = $$Array.to_list($$Array.map((function (json) {
                      return Pbrt_bs.string(json, "change", "assignees");
                    }), a$3));
          break;
      case "author" :
          var json$2 = json["author"];
          v.author = Pbrt_bs.string(json$2, "change", "author");
          break;
      case "branch" :
          var json$3 = json["branch"];
          v.branch = Pbrt_bs.string(json$3, "change", "branch");
          break;
      case "change_id" :
          var json$4 = json["change_id"];
          v.change_id = Pbrt_bs.string(json$4, "change", "change_id");
          break;
      case "changed_files" :
          var a$4 = json["changed_files"];
          var a$5 = Pbrt_bs.array_(a$4, "change", "changed_files");
          v.changed_files = $$Array.to_list($$Array.map((function (json) {
                      return decode_file(Pbrt_bs.object_(json, "change", "changed_files"));
                    }), a$5));
          break;
      case "changed_files_count" :
          var json$5 = json["changed_files_count"];
          v.changed_files_count = Pbrt_bs.int32(json$5, "change", "changed_files_count");
          break;
      case "commits" :
          var a$6 = json["commits"];
          var a$7 = Pbrt_bs.array_(a$6, "change", "commits");
          v.commits = $$Array.to_list($$Array.map((function (json) {
                      return decode_commit(Pbrt_bs.object_(json, "change", "commits"));
                    }), a$7));
          break;
      case "commits_count" :
          var json$6 = json["commits_count"];
          v.commits_count = Pbrt_bs.int32(json$6, "change", "commits_count");
          break;
      case "created_at" :
          var json$7 = json["created_at"];
          v.created_at = Caml_option.some(TimestampBs$Web.decode_timestamp(Pbrt_bs.string(json$7, "change", "created_at")));
          break;
      case "deletions" :
          var json$8 = json["deletions"];
          v.deletions = Pbrt_bs.int32(json$8, "change", "deletions");
          break;
      case "draft" :
          var json$9 = json["draft"];
          v.draft = Pbrt_bs.bool(json$9, "change", "draft");
          break;
      case "duration" :
          var json$10 = json["duration"];
          v.ttm = /* Duration */{
            _0: Pbrt_bs.int32(json$10, "change", "ttm")
          };
          break;
      case "labels" :
          var a$8 = json["labels"];
          var a$9 = Pbrt_bs.array_(a$8, "change", "labels");
          v.labels = $$Array.to_list($$Array.map((function (json) {
                      return Pbrt_bs.string(json, "change", "labels");
                    }), a$9));
          break;
      case "mergeable" :
          var json$11 = json["mergeable"];
          v.mergeable = Pbrt_bs.bool(json$11, "change", "mergeable");
          break;
      case "merged_at" :
          var json$12 = json["merged_at"];
          v.merged_at = Caml_option.some(TimestampBs$Web.decode_timestamp(Pbrt_bs.string(json$12, "change", "merged_at")));
          break;
      case "merged_by" :
          var json$13 = json["merged_by"];
          v.merged_by_m = /* Merged_by */{
            _0: Pbrt_bs.string(json$13, "change", "merged_by_m")
          };
          break;
      case "repository_fullname" :
          var json$14 = json["repository_fullname"];
          v.repository_fullname = Pbrt_bs.string(json$14, "change", "repository_fullname");
          break;
      case "state" :
          var json$15 = json["state"];
          v.state = Pbrt_bs.string(json$15, "change", "state");
          break;
      case "target_branch" :
          var json$16 = json["target_branch"];
          v.target_branch = Pbrt_bs.string(json$16, "change", "target_branch");
          break;
      case "task_data" :
          var a$10 = json["task_data"];
          var a$11 = Pbrt_bs.array_(a$10, "change", "task_data");
          v.task_data = $$Array.to_list($$Array.map((function (json) {
                      return decode_task_data(Pbrt_bs.object_(json, "change", "task_data"));
                    }), a$11));
          break;
      case "text" :
          var json$17 = json["text"];
          v.text = Pbrt_bs.string(json$17, "change", "text");
          break;
      case "title" :
          var json$18 = json["title"];
          v.title = Pbrt_bs.string(json$18, "change", "title");
          break;
      case "updated_at" :
          var json$19 = json["updated_at"];
          v.updated_at = Caml_option.some(TimestampBs$Web.decode_timestamp(Pbrt_bs.string(json$19, "change", "updated_at")));
          break;
      case "url" :
          var json$20 = json["url"];
          v.url = Pbrt_bs.string(json$20, "change", "url");
          break;
      default:
        
    }
  }
  return {
          change_id: v.change_id,
          author: v.author,
          title: v.title,
          url: v.url,
          repository_fullname: v.repository_fullname,
          state: v.state,
          branch: v.branch,
          target_branch: v.target_branch,
          created_at: v.created_at,
          updated_at: v.updated_at,
          merged_at: v.merged_at,
          merged_by_m: v.merged_by_m,
          text: v.text,
          additions: v.additions,
          deletions: v.deletions,
          approval: v.approval,
          assignees: v.assignees,
          labels: v.labels,
          draft: v.draft,
          mergeable: v.mergeable,
          changed_files: v.changed_files,
          changed_files_count: v.changed_files_count,
          commits: v.commits,
          commits_count: v.commits_count,
          task_data: v.task_data,
          ttm: v.ttm
        };
}

function decode_changes(json) {
  var v = /* [] */0;
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    if (match === "changes") {
      var a = json["changes"];
      var a$1 = Pbrt_bs.array_(a, "changes", "changes");
      v = $$Array.to_list($$Array.map((function (json) {
                  return decode_change(Pbrt_bs.object_(json, "changes", "changes"));
                }), a$1));
    }
    
  }
  return {
          changes: v
        };
}

function decode_ratio(json) {
  var v = 0;
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    if (match === "ratio") {
      var json$1 = json["ratio"];
      v = Pbrt_bs.$$float(json$1, "ratio", "ratio");
    }
    
  }
  return {
          ratio: v
        };
}

function decode_change_event(json) {
  var v = {
    id: "",
    type_: "",
    change_id: "",
    created_at: undefined,
    on_created_at: undefined,
    author: "",
    on_author: "",
    branch: ""
  };
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    switch (match) {
      case "author" :
          var json$1 = json["author"];
          v.author = Pbrt_bs.string(json$1, "change_event", "author");
          break;
      case "branch" :
          var json$2 = json["branch"];
          v.branch = Pbrt_bs.string(json$2, "change_event", "branch");
          break;
      case "change_id" :
          var json$3 = json["change_id"];
          v.change_id = Pbrt_bs.string(json$3, "change_event", "change_id");
          break;
      case "created_at" :
          var json$4 = json["created_at"];
          v.created_at = Caml_option.some(TimestampBs$Web.decode_timestamp(Pbrt_bs.string(json$4, "change_event", "created_at")));
          break;
      case "id" :
          var json$5 = json["id"];
          v.id = Pbrt_bs.string(json$5, "change_event", "id");
          break;
      case "on_author" :
          var json$6 = json["on_author"];
          v.on_author = Pbrt_bs.string(json$6, "change_event", "on_author");
          break;
      case "on_created_at" :
          var json$7 = json["on_created_at"];
          v.on_created_at = Caml_option.some(TimestampBs$Web.decode_timestamp(Pbrt_bs.string(json$7, "change_event", "on_created_at")));
          break;
      case "type" :
          var json$8 = json["type"];
          v.type_ = Pbrt_bs.string(json$8, "change_event", "type_");
          break;
      default:
        
    }
  }
  return {
          id: v.id,
          type_: v.type_,
          change_id: v.change_id,
          created_at: v.created_at,
          on_created_at: v.on_created_at,
          author: v.author,
          on_author: v.on_author,
          branch: v.branch
        };
}

function decode_change_and_events(json) {
  var v = {
    change: undefined,
    events: /* [] */0
  };
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    switch (match) {
      case "change" :
          var json$1 = json["change"];
          v.change = decode_change(Pbrt_bs.object_(json$1, "change_and_events", "change"));
          break;
      case "events" :
          var a = json["events"];
          var a$1 = Pbrt_bs.array_(a, "change_and_events", "events");
          v.events = $$Array.to_list($$Array.map((function (json) {
                      return decode_change_event(Pbrt_bs.object_(json, "change_and_events", "events"));
                    }), a$1));
          break;
      default:
        
    }
  }
  return {
          change: v.change,
          events: v.events
        };
}

function decode_review_count(json) {
  var v = {
    authors_count: 0,
    events_count: 0
  };
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    switch (match) {
      case "authors_count" :
          var json$1 = json["authors_count"];
          v.authors_count = Pbrt_bs.int32(json$1, "review_count", "authors_count");
          break;
      case "events_count" :
          var json$2 = json["events_count"];
          v.events_count = Pbrt_bs.int32(json$2, "review_count", "events_count");
          break;
      default:
        
    }
  }
  return {
          authors_count: v.authors_count,
          events_count: v.events_count
        };
}

function decode_review_stats(json) {
  var v = {
    comment_count: undefined,
    review_count: undefined,
    comment_delay: 0,
    review_delay: 0,
    comment_histo: /* [] */0,
    review_histo: /* [] */0
  };
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    switch (match) {
      case "comment_count" :
          var json$1 = json["comment_count"];
          v.comment_count = decode_review_count(Pbrt_bs.object_(json$1, "review_stats", "comment_count"));
          break;
      case "comment_delay" :
          var json$2 = json["comment_delay"];
          v.comment_delay = Pbrt_bs.int32(json$2, "review_stats", "comment_delay");
          break;
      case "comment_histo" :
          var a = json["comment_histo"];
          var a$1 = Pbrt_bs.array_(a, "review_stats", "comment_histo");
          v.comment_histo = $$Array.to_list($$Array.map((function (json) {
                      return MetricBs$Web.decode_histo_int(Pbrt_bs.object_(json, "review_stats", "comment_histo"));
                    }), a$1));
          break;
      case "review_count" :
          var json$3 = json["review_count"];
          v.review_count = decode_review_count(Pbrt_bs.object_(json$3, "review_stats", "review_count"));
          break;
      case "review_delay" :
          var json$4 = json["review_delay"];
          v.review_delay = Pbrt_bs.int32(json$4, "review_stats", "review_delay");
          break;
      case "review_histo" :
          var a$2 = json["review_histo"];
          var a$3 = Pbrt_bs.array_(a$2, "review_stats", "review_histo");
          v.review_histo = $$Array.to_list($$Array.map((function (json) {
                      return MetricBs$Web.decode_histo_int(Pbrt_bs.object_(json, "review_stats", "review_histo"));
                    }), a$3));
          break;
      default:
        
    }
  }
  return {
          comment_count: v.comment_count,
          review_count: v.review_count,
          comment_delay: v.comment_delay,
          review_delay: v.review_delay,
          comment_histo: v.comment_histo,
          review_histo: v.review_histo
        };
}

function decode_activity_stats(json) {
  var v = {
    change_authors: 0,
    comment_authors: 0,
    review_authors: 0,
    comments_histo: /* [] */0,
    reviews_histo: /* [] */0,
    changes_histo: /* [] */0
  };
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    switch (match) {
      case "change_authors" :
          var json$1 = json["change_authors"];
          v.change_authors = Pbrt_bs.int32(json$1, "activity_stats", "change_authors");
          break;
      case "changes_histo" :
          var a = json["changes_histo"];
          var a$1 = Pbrt_bs.array_(a, "activity_stats", "changes_histo");
          v.changes_histo = $$Array.to_list($$Array.map((function (json) {
                      return MetricBs$Web.decode_histo_int(Pbrt_bs.object_(json, "activity_stats", "changes_histo"));
                    }), a$1));
          break;
      case "comment_authors" :
          var json$2 = json["comment_authors"];
          v.comment_authors = Pbrt_bs.int32(json$2, "activity_stats", "comment_authors");
          break;
      case "comments_histo" :
          var a$2 = json["comments_histo"];
          var a$3 = Pbrt_bs.array_(a$2, "activity_stats", "comments_histo");
          v.comments_histo = $$Array.to_list($$Array.map((function (json) {
                      return MetricBs$Web.decode_histo_int(Pbrt_bs.object_(json, "activity_stats", "comments_histo"));
                    }), a$3));
          break;
      case "review_authors" :
          var json$3 = json["review_authors"];
          v.review_authors = Pbrt_bs.int32(json$3, "activity_stats", "review_authors");
          break;
      case "reviews_histo" :
          var a$4 = json["reviews_histo"];
          var a$5 = Pbrt_bs.array_(a$4, "activity_stats", "reviews_histo");
          v.reviews_histo = $$Array.to_list($$Array.map((function (json) {
                      return MetricBs$Web.decode_histo_int(Pbrt_bs.object_(json, "activity_stats", "reviews_histo"));
                    }), a$5));
          break;
      default:
        
    }
  }
  return {
          change_authors: v.change_authors,
          comment_authors: v.comment_authors,
          review_authors: v.review_authors,
          comments_histo: v.comments_histo,
          reviews_histo: v.reviews_histo,
          changes_histo: v.changes_histo
        };
}

function decode_repo_summary(json) {
  var v = {
    fullname: "",
    created_changes: 0,
    abandoned_changes: 0,
    merged_changes: 0,
    updated_changes: 0,
    open_changes: 0
  };
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    switch (match) {
      case "abandoned_changes" :
          var json$1 = json["abandoned_changes"];
          v.abandoned_changes = Pbrt_bs.int32(json$1, "repo_summary", "abandoned_changes");
          break;
      case "created_changes" :
          var json$2 = json["created_changes"];
          v.created_changes = Pbrt_bs.int32(json$2, "repo_summary", "created_changes");
          break;
      case "fullname" :
          var json$3 = json["fullname"];
          v.fullname = Pbrt_bs.string(json$3, "repo_summary", "fullname");
          break;
      case "merged_changes" :
          var json$4 = json["merged_changes"];
          v.merged_changes = Pbrt_bs.int32(json$4, "repo_summary", "merged_changes");
          break;
      case "open_changes" :
          var json$5 = json["open_changes"];
          v.open_changes = Pbrt_bs.int32(json$5, "repo_summary", "open_changes");
          break;
      case "updated_changes" :
          var json$6 = json["updated_changes"];
          v.updated_changes = Pbrt_bs.int32(json$6, "repo_summary", "updated_changes");
          break;
      default:
        
    }
  }
  return {
          fullname: v.fullname,
          created_changes: v.created_changes,
          abandoned_changes: v.abandoned_changes,
          merged_changes: v.merged_changes,
          updated_changes: v.updated_changes,
          open_changes: v.open_changes
        };
}

function decode_repos_summary(json) {
  var v = /* [] */0;
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    if (match === "reposum") {
      var a = json["reposum"];
      var a$1 = Pbrt_bs.array_(a, "repos_summary", "reposum");
      v = $$Array.to_list($$Array.map((function (json) {
                  return decode_repo_summary(Pbrt_bs.object_(json, "repos_summary", "reposum"));
                }), a$1));
    }
    
  }
  return {
          reposum: v
        };
}

function decode_author_peer(json) {
  var v = {
    author: "",
    peer: "",
    strength: 0
  };
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    switch (match) {
      case "author" :
          var json$1 = json["author"];
          v.author = Pbrt_bs.string(json$1, "author_peer", "author");
          break;
      case "peer" :
          var json$2 = json["peer"];
          v.peer = Pbrt_bs.string(json$2, "author_peer", "peer");
          break;
      case "strength" :
          var json$3 = json["strength"];
          v.strength = Pbrt_bs.int32(json$3, "author_peer", "strength");
          break;
      default:
        
    }
  }
  return {
          author: v.author,
          peer: v.peer,
          strength: v.strength
        };
}

function decode_authors_peers(json) {
  var v = /* [] */0;
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    if (match === "author_peer") {
      var a = json["author_peer"];
      var a$1 = Pbrt_bs.array_(a, "authors_peers", "author_peer");
      v = $$Array.to_list($$Array.map((function (json) {
                  return decode_author_peer(Pbrt_bs.object_(json, "authors_peers", "author_peer"));
                }), a$1));
    }
    
  }
  return {
          author_peer: v
        };
}

function decode_lifecycle_stats(json) {
  var v = default_lifecycle_stats_mutable(undefined);
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    switch (match) {
      case "abandoned" :
          var json$1 = json["abandoned"];
          v.abandoned = Pbrt_bs.int32(json$1, "lifecycle_stats", "abandoned");
          break;
      case "abandoned_histo" :
          var a = json["abandoned_histo"];
          var a$1 = Pbrt_bs.array_(a, "lifecycle_stats", "abandoned_histo");
          v.abandoned_histo = $$Array.to_list($$Array.map((function (json) {
                      return MetricBs$Web.decode_histo_int(Pbrt_bs.object_(json, "lifecycle_stats", "abandoned_histo"));
                    }), a$1));
          break;
      case "changes_with_tests" :
          var json$2 = json["changes_with_tests"];
          v.changes_with_tests = Pbrt_bs.$$float(json$2, "lifecycle_stats", "changes_with_tests");
          break;
      case "commits_per_change" :
          var json$3 = json["commits_per_change"];
          v.commits_per_change = Pbrt_bs.$$float(json$3, "lifecycle_stats", "commits_per_change");
          break;
      case "created" :
          var json$4 = json["created"];
          v.created = decode_review_count(Pbrt_bs.object_(json$4, "lifecycle_stats", "created"));
          break;
      case "created_histo" :
          var a$2 = json["created_histo"];
          var a$3 = Pbrt_bs.array_(a$2, "lifecycle_stats", "created_histo");
          v.created_histo = $$Array.to_list($$Array.map((function (json) {
                      return MetricBs$Web.decode_histo_int(Pbrt_bs.object_(json, "lifecycle_stats", "created_histo"));
                    }), a$3));
          break;
      case "iterations_per_change" :
          var json$5 = json["iterations_per_change"];
          v.iterations_per_change = Pbrt_bs.$$float(json$5, "lifecycle_stats", "iterations_per_change");
          break;
      case "merged" :
          var json$6 = json["merged"];
          v.merged = Pbrt_bs.int32(json$6, "lifecycle_stats", "merged");
          break;
      case "merged_histo" :
          var a$4 = json["merged_histo"];
          var a$5 = Pbrt_bs.array_(a$4, "lifecycle_stats", "merged_histo");
          v.merged_histo = $$Array.to_list($$Array.map((function (json) {
                      return MetricBs$Web.decode_histo_int(Pbrt_bs.object_(json, "lifecycle_stats", "merged_histo"));
                    }), a$5));
          break;
      case "self_merged" :
          var json$7 = json["self_merged"];
          v.self_merged = Pbrt_bs.int32(json$7, "lifecycle_stats", "self_merged");
          break;
      case "self_mergedRatio" :
          var json$8 = json["self_mergedRatio"];
          v.self_merged_ratio = Pbrt_bs.$$float(json$8, "lifecycle_stats", "self_merged_ratio");
          break;
      case "ttm_mean" :
          var json$9 = json["ttm_mean"];
          v.ttm_mean = Pbrt_bs.$$float(json$9, "lifecycle_stats", "ttm_mean");
          break;
      case "ttm_variability" :
          var json$10 = json["ttm_variability"];
          v.ttm_variability = Pbrt_bs.$$float(json$10, "lifecycle_stats", "ttm_variability");
          break;
      case "updated_histo" :
          var a$6 = json["updated_histo"];
          var a$7 = Pbrt_bs.array_(a$6, "lifecycle_stats", "updated_histo");
          v.updated_histo = $$Array.to_list($$Array.map((function (json) {
                      return MetricBs$Web.decode_histo_int(Pbrt_bs.object_(json, "lifecycle_stats", "updated_histo"));
                    }), a$7));
          break;
      case "updates_of_changes" :
          var json$11 = json["updates_of_changes"];
          v.updates_of_changes = Pbrt_bs.int32(json$11, "lifecycle_stats", "updates_of_changes");
          break;
      default:
        
    }
  }
  return {
          created_histo: v.created_histo,
          updated_histo: v.updated_histo,
          merged_histo: v.merged_histo,
          abandoned_histo: v.abandoned_histo,
          created: v.created,
          abandoned: v.abandoned,
          merged: v.merged,
          self_merged: v.self_merged,
          self_merged_ratio: v.self_merged_ratio,
          ttm_mean: v.ttm_mean,
          ttm_variability: v.ttm_variability,
          updates_of_changes: v.updates_of_changes,
          changes_with_tests: v.changes_with_tests,
          iterations_per_change: v.iterations_per_change,
          commits_per_change: v.commits_per_change
        };
}

function decode_changes_tops(json) {
  var v = {
    authors: undefined,
    repos: undefined,
    approvals: undefined
  };
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    switch (match) {
      case "approvals" :
          var json$1 = json["approvals"];
          v.approvals = MetricBs$Web.decode_terms_count_int(Pbrt_bs.object_(json$1, "changes_tops", "approvals"));
          break;
      case "authors" :
          var json$2 = json["authors"];
          v.authors = MetricBs$Web.decode_terms_count_int(Pbrt_bs.object_(json$2, "changes_tops", "authors"));
          break;
      case "repos" :
          var json$3 = json["repos"];
          v.repos = MetricBs$Web.decode_terms_count_int(Pbrt_bs.object_(json$3, "changes_tops", "repos"));
          break;
      default:
        
    }
  }
  return {
          authors: v.authors,
          repos: v.repos,
          approvals: v.approvals
        };
}

function decode_query_response(json) {
  var keys = Object.keys(json);
  var _i = keys.length - 1 | 0;
  while(true) {
    var i = _i;
    if (i === -1) {
      return Pbrt_bs.E.malformed_variant("query_response");
    }
    var match = keys[i];
    switch (match) {
      case "activity_stats" :
          var json$1 = json["activity_stats"];
          return {
                  TAG: /* Activity_stats */8,
                  _0: decode_activity_stats(Pbrt_bs.object_(json$1, "query_response", "Activity_stats"))
                };
      case "authors_peers" :
          var json$2 = json["authors_peers"];
          return {
                  TAG: /* Authors_peers */4,
                  _0: decode_authors_peers(Pbrt_bs.object_(json$2, "query_response", "Authors_peers"))
                };
      case "change_events" :
          var json$3 = json["change_events"];
          return {
                  TAG: /* Change_events */9,
                  _0: decode_change_and_events(Pbrt_bs.object_(json$3, "query_response", "Change_events"))
                };
      case "changes" :
          var json$4 = json["changes"];
          return {
                  TAG: /* Changes */1,
                  _0: decode_changes(Pbrt_bs.object_(json$4, "query_response", "Changes"))
                };
      case "changes_tops" :
          var json$5 = json["changes_tops"];
          return {
                  TAG: /* Changes_tops */10,
                  _0: decode_changes_tops(Pbrt_bs.object_(json$5, "query_response", "Changes_tops"))
                };
      case "error" :
          var json$6 = json["error"];
          return {
                  TAG: /* Error */0,
                  _0: decode_query_error(Pbrt_bs.object_(json$6, "query_response", "Error"))
                };
      case "histo" :
          var json$7 = json["histo"];
          return {
                  TAG: /* Histo */12,
                  _0: MetricBs$Web.decode_histo_int_stat(Pbrt_bs.object_(json$7, "query_response", "Histo"))
                };
      case "lifecycle_stats" :
          var json$8 = json["lifecycle_stats"];
          return {
                  TAG: /* Lifecycle_stats */7,
                  _0: decode_lifecycle_stats(Pbrt_bs.object_(json$8, "query_response", "Lifecycle_stats"))
                };
      case "new_authors" :
          var json$9 = json["new_authors"];
          return {
                  TAG: /* New_authors */5,
                  _0: MetricBs$Web.decode_terms_count_int(Pbrt_bs.object_(json$9, "query_response", "New_authors"))
                };
      case "ratio" :
          var json$10 = json["ratio"];
          return {
                  TAG: /* Ratio */11,
                  _0: Pbrt_bs.$$float(json$10, "query_response", "Ratio")
                };
      case "repos_summary" :
          var json$11 = json["repos_summary"];
          return {
                  TAG: /* Repos_summary */2,
                  _0: decode_repos_summary(Pbrt_bs.object_(json$11, "query_response", "Repos_summary"))
                };
      case "review_stats" :
          var json$12 = json["review_stats"];
          return {
                  TAG: /* Review_stats */6,
                  _0: decode_review_stats(Pbrt_bs.object_(json$12, "query_response", "Review_stats"))
                };
      case "top_authors" :
          var json$13 = json["top_authors"];
          return {
                  TAG: /* Top_authors */3,
                  _0: MetricBs$Web.decode_terms_count_int(Pbrt_bs.object_(json$13, "query_response", "Top_authors"))
                };
      default:
        _i = i - 1 | 0;
        continue ;
    }
  };
}

function encode_task_data(v) {
  var json = {};
  var v$1 = v.updated_at;
  if (v$1 !== undefined) {
    var json$p = TimestampBs$Web.encode_timestamp(Caml_option.valFromOption(v$1));
    json["updated_at"] = json$p;
  }
  json["change_url"] = v.change_url;
  var a = $$Array.map((function (prim) {
          return prim;
        }), $$Array.of_list(v.ttype));
  json["ttype"] = a;
  json["tid"] = v.tid;
  json["url"] = v.url;
  json["title"] = v.title;
  json["severity"] = v.severity;
  json["priority"] = v.priority;
  json["score"] = v.score;
  json["prefix"] = v.prefix;
  return json;
}

function encode_suggestions_request(v) {
  var json = {};
  json["index"] = v.index;
  return json;
}

function encode_suggestions_response(v) {
  var json = {};
  var a = $$Array.map((function (prim) {
          return prim;
        }), $$Array.of_list(v.task_types));
  json["task_types"] = a;
  var a$1 = $$Array.map((function (prim) {
          return prim;
        }), $$Array.of_list(v.authors));
  json["authors"] = a$1;
  var a$2 = $$Array.map((function (prim) {
          return prim;
        }), $$Array.of_list(v.approvals));
  json["approvals"] = a$2;
  var a$3 = $$Array.map((function (prim) {
          return prim;
        }), $$Array.of_list(v.priorities));
  json["priorities"] = a$3;
  var a$4 = $$Array.map((function (prim) {
          return prim;
        }), $$Array.of_list(v.severities));
  json["severities"] = a$4;
  var a$5 = $$Array.map((function (prim) {
          return prim;
        }), $$Array.of_list(v.projects));
  json["projects"] = a$5;
  var a$6 = $$Array.map((function (prim) {
          return prim;
        }), $$Array.of_list(v.groups));
  json["groups"] = a$6;
  var a$7 = $$Array.map((function (prim) {
          return prim;
        }), $$Array.of_list(v.labels));
  json["labels"] = a$7;
  return json;
}

function encode_fields_request(v) {
  var json = {};
  json["version"] = v.version;
  return json;
}

function encode_field_type(v) {
  switch (v) {
    case /* Field_date */0 :
        return "FIELD_DATE";
    case /* Field_number */1 :
        return "FIELD_NUMBER";
    case /* Field_text */2 :
        return "FIELD_TEXT";
    case /* Field_bool */3 :
        return "FIELD_BOOL";
    case /* Field_regex */4 :
        return "FIELD_REGEX";
    
  }
}

function encode_field(v) {
  var json = {};
  json["name"] = v.name;
  json["description"] = v.description;
  json["type"] = encode_field_type(v.type_);
  return json;
}

function encode_fields_response(v) {
  var json = {};
  var fields$p = $$Array.map(encode_field, $$Array.of_list(v.fields));
  json["fields"] = fields$p;
  return json;
}

function encode_query_error(v) {
  var json = {};
  json["message"] = v.message;
  json["position"] = v.position;
  return json;
}

function encode_check_request(v) {
  var json = {};
  json["index"] = v.index;
  json["username"] = v.username;
  json["query"] = v.query;
  return json;
}

function encode_check_response(v) {
  var json = {};
  if (v.TAG === /* Success */0) {
    json["success"] = v._0;
  } else {
    var json$p = encode_query_error(v._0);
    json["error"] = json$p;
  }
  return json;
}

function encode_author(v) {
  var json = {};
  json["muid"] = v.muid;
  var a = $$Array.map((function (prim) {
          return prim;
        }), $$Array.of_list(v.aliases));
  json["aliases"] = a;
  var a$1 = $$Array.map((function (prim) {
          return prim;
        }), $$Array.of_list(v.groups));
  json["groups"] = a$1;
  return json;
}

function encode_author_request(v) {
  var json = {};
  json["index"] = v.index;
  json["query"] = v.query;
  return json;
}

function encode_author_response(v) {
  var json = {};
  var authors$p = $$Array.map(encode_author, $$Array.of_list(v.authors));
  json["authors"] = authors$p;
  return json;
}

function encode_order_direction(v) {
  if (v) {
    return "DESC";
  } else {
    return "ASC";
  }
}

function encode_order(v) {
  var json = {};
  json["field"] = v.field;
  json["direction"] = v.direction ? "DESC" : "ASC";
  return json;
}

function encode_query_request_query_type(v) {
  switch (v) {
    case /* Query_change */0 :
        return "QUERY_CHANGE";
    case /* Query_repos_summary */1 :
        return "QUERY_REPOS_SUMMARY";
    case /* Query_top_authors_changes_created */2 :
        return "QUERY_TOP_AUTHORS_CHANGES_CREATED";
    case /* Query_top_authors_changes_merged */3 :
        return "QUERY_TOP_AUTHORS_CHANGES_MERGED";
    case /* Query_top_authors_changes_reviewed */4 :
        return "QUERY_TOP_AUTHORS_CHANGES_REVIEWED";
    case /* Query_top_authors_changes_commented */5 :
        return "QUERY_TOP_AUTHORS_CHANGES_COMMENTED";
    case /* Query_top_reviewed_authors */6 :
        return "QUERY_TOP_REVIEWED_AUTHORS";
    case /* Query_top_commented_authors */7 :
        return "QUERY_TOP_COMMENTED_AUTHORS";
    case /* Query_top_authors_peers */8 :
        return "QUERY_TOP_AUTHORS_PEERS";
    case /* Query_new_changes_authors */9 :
        return "QUERY_NEW_CHANGES_AUTHORS";
    case /* Query_changes_review_stats */10 :
        return "QUERY_CHANGES_REVIEW_STATS";
    case /* Query_changes_lifecycle_stats */11 :
        return "QUERY_CHANGES_LIFECYCLE_STATS";
    case /* Query_active_authors_stats */12 :
        return "QUERY_ACTIVE_AUTHORS_STATS";
    case /* Query_change_and_events */13 :
        return "QUERY_CHANGE_AND_EVENTS";
    case /* Query_changes_tops */14 :
        return "QUERY_CHANGES_TOPS";
    case /* Query_ratio_commits_vs_reviews */15 :
        return "QUERY_RATIO_COMMITS_VS_REVIEWS";
    case /* Query_histo_commits */16 :
        return "QUERY_HISTO_COMMITS";
    case /* Query_histo_reviews_and_comments */17 :
        return "QUERY_HISTO_REVIEWS_AND_COMMENTS";
    
  }
}

function encode_query_request(v) {
  var json = {};
  json["index"] = v.index;
  json["username"] = v.username;
  json["query"] = v.query;
  json["query_type"] = encode_query_request_query_type(v.query_type);
  var v$1 = v.order;
  if (v$1 !== undefined) {
    var json$p = encode_order(v$1);
    json["order"] = json$p;
  }
  json["limit"] = v.limit;
  json["change_id"] = v.change_id;
  return json;
}

function encode_file(v) {
  var json = {};
  json["additions"] = v.additions;
  json["deletions"] = v.deletions;
  json["path"] = v.path;
  return json;
}

function encode_commit(v) {
  var json = {};
  json["sha"] = v.sha;
  json["title"] = v.title;
  json["author"] = v.author;
  var v$1 = v.authored_at;
  if (v$1 !== undefined) {
    var json$p = TimestampBs$Web.encode_timestamp(Caml_option.valFromOption(v$1));
    json["authored_at"] = json$p;
  }
  json["committer"] = v.committer;
  var v$2 = v.committed_at;
  if (v$2 !== undefined) {
    var json$p$1 = TimestampBs$Web.encode_timestamp(Caml_option.valFromOption(v$2));
    json["committed_at"] = json$p$1;
  }
  json["additions"] = v.additions;
  json["deletions"] = v.deletions;
  return json;
}

function encode_change_merged_by_m(v) {
  var json = {};
  json["merged_by"] = v._0;
  return json;
}

function encode_change_ttm(v) {
  var json = {};
  json["duration"] = v._0;
  return json;
}

function encode_change(v) {
  var json = {};
  json["change_id"] = v.change_id;
  json["author"] = v.author;
  json["title"] = v.title;
  json["url"] = v.url;
  json["repository_fullname"] = v.repository_fullname;
  json["state"] = v.state;
  json["branch"] = v.branch;
  json["target_branch"] = v.target_branch;
  var v$1 = v.created_at;
  if (v$1 !== undefined) {
    var json$p = TimestampBs$Web.encode_timestamp(Caml_option.valFromOption(v$1));
    json["created_at"] = json$p;
  }
  var v$2 = v.updated_at;
  if (v$2 !== undefined) {
    var json$p$1 = TimestampBs$Web.encode_timestamp(Caml_option.valFromOption(v$2));
    json["updated_at"] = json$p$1;
  }
  var v$3 = v.merged_at;
  if (v$3 !== undefined) {
    var json$p$2 = TimestampBs$Web.encode_timestamp(Caml_option.valFromOption(v$3));
    json["merged_at"] = json$p$2;
  }
  var v$4 = v.merged_by_m;
  json["merged_by"] = v$4._0;
  json["text"] = v.text;
  json["additions"] = v.additions;
  json["deletions"] = v.deletions;
  var a = $$Array.map((function (prim) {
          return prim;
        }), $$Array.of_list(v.approval));
  json["approval"] = a;
  var a$1 = $$Array.map((function (prim) {
          return prim;
        }), $$Array.of_list(v.assignees));
  json["assignees"] = a$1;
  var a$2 = $$Array.map((function (prim) {
          return prim;
        }), $$Array.of_list(v.labels));
  json["labels"] = a$2;
  json["draft"] = v.draft;
  json["mergeable"] = v.mergeable;
  var changed_files$p = $$Array.map(encode_file, $$Array.of_list(v.changed_files));
  json["changed_files"] = changed_files$p;
  json["changed_files_count"] = v.changed_files_count;
  var commits$p = $$Array.map(encode_commit, $$Array.of_list(v.commits));
  json["commits"] = commits$p;
  json["commits_count"] = v.commits_count;
  var task_data$p = $$Array.map(encode_task_data, $$Array.of_list(v.task_data));
  json["task_data"] = task_data$p;
  var v$5 = v.ttm;
  json["duration"] = v$5._0;
  return json;
}

function encode_changes(v) {
  var json = {};
  var changes$p = $$Array.map(encode_change, $$Array.of_list(v.changes));
  json["changes"] = changes$p;
  return json;
}

function encode_ratio(v) {
  var json = {};
  json["ratio"] = v.ratio;
  return json;
}

function encode_change_event(v) {
  var json = {};
  json["id"] = v.id;
  json["type"] = v.type_;
  json["change_id"] = v.change_id;
  var v$1 = v.created_at;
  if (v$1 !== undefined) {
    var json$p = TimestampBs$Web.encode_timestamp(Caml_option.valFromOption(v$1));
    json["created_at"] = json$p;
  }
  var v$2 = v.on_created_at;
  if (v$2 !== undefined) {
    var json$p$1 = TimestampBs$Web.encode_timestamp(Caml_option.valFromOption(v$2));
    json["on_created_at"] = json$p$1;
  }
  json["author"] = v.author;
  json["on_author"] = v.on_author;
  json["branch"] = v.branch;
  return json;
}

function encode_change_and_events(v) {
  var json = {};
  var v$1 = v.change;
  if (v$1 !== undefined) {
    var json$p = encode_change(v$1);
    json["change"] = json$p;
  }
  var events$p = $$Array.map(encode_change_event, $$Array.of_list(v.events));
  json["events"] = events$p;
  return json;
}

function encode_review_count(v) {
  var json = {};
  json["authors_count"] = v.authors_count;
  json["events_count"] = v.events_count;
  return json;
}

function encode_review_stats(v) {
  var json = {};
  var v$1 = v.comment_count;
  if (v$1 !== undefined) {
    var json$p = encode_review_count(v$1);
    json["comment_count"] = json$p;
  }
  var v$2 = v.review_count;
  if (v$2 !== undefined) {
    var json$p$1 = encode_review_count(v$2);
    json["review_count"] = json$p$1;
  }
  json["comment_delay"] = v.comment_delay;
  json["review_delay"] = v.review_delay;
  var comment_histo$p = $$Array.map(MetricBs$Web.encode_histo_int, $$Array.of_list(v.comment_histo));
  json["comment_histo"] = comment_histo$p;
  var review_histo$p = $$Array.map(MetricBs$Web.encode_histo_int, $$Array.of_list(v.review_histo));
  json["review_histo"] = review_histo$p;
  return json;
}

function encode_activity_stats(v) {
  var json = {};
  json["change_authors"] = v.change_authors;
  json["comment_authors"] = v.comment_authors;
  json["review_authors"] = v.review_authors;
  var comments_histo$p = $$Array.map(MetricBs$Web.encode_histo_int, $$Array.of_list(v.comments_histo));
  json["comments_histo"] = comments_histo$p;
  var reviews_histo$p = $$Array.map(MetricBs$Web.encode_histo_int, $$Array.of_list(v.reviews_histo));
  json["reviews_histo"] = reviews_histo$p;
  var changes_histo$p = $$Array.map(MetricBs$Web.encode_histo_int, $$Array.of_list(v.changes_histo));
  json["changes_histo"] = changes_histo$p;
  return json;
}

function encode_repo_summary(v) {
  var json = {};
  json["fullname"] = v.fullname;
  json["created_changes"] = v.created_changes;
  json["abandoned_changes"] = v.abandoned_changes;
  json["merged_changes"] = v.merged_changes;
  json["updated_changes"] = v.updated_changes;
  json["open_changes"] = v.open_changes;
  return json;
}

function encode_repos_summary(v) {
  var json = {};
  var reposum$p = $$Array.map(encode_repo_summary, $$Array.of_list(v.reposum));
  json["reposum"] = reposum$p;
  return json;
}

function encode_author_peer(v) {
  var json = {};
  json["author"] = v.author;
  json["peer"] = v.peer;
  json["strength"] = v.strength;
  return json;
}

function encode_authors_peers(v) {
  var json = {};
  var author_peer$p = $$Array.map(encode_author_peer, $$Array.of_list(v.author_peer));
  json["author_peer"] = author_peer$p;
  return json;
}

function encode_lifecycle_stats(v) {
  var json = {};
  var created_histo$p = $$Array.map(MetricBs$Web.encode_histo_int, $$Array.of_list(v.created_histo));
  json["created_histo"] = created_histo$p;
  var updated_histo$p = $$Array.map(MetricBs$Web.encode_histo_int, $$Array.of_list(v.updated_histo));
  json["updated_histo"] = updated_histo$p;
  var merged_histo$p = $$Array.map(MetricBs$Web.encode_histo_int, $$Array.of_list(v.merged_histo));
  json["merged_histo"] = merged_histo$p;
  var abandoned_histo$p = $$Array.map(MetricBs$Web.encode_histo_int, $$Array.of_list(v.abandoned_histo));
  json["abandoned_histo"] = abandoned_histo$p;
  var v$1 = v.created;
  if (v$1 !== undefined) {
    var json$p = encode_review_count(v$1);
    json["created"] = json$p;
  }
  json["abandoned"] = v.abandoned;
  json["merged"] = v.merged;
  json["self_merged"] = v.self_merged;
  json["self_mergedRatio"] = v.self_merged_ratio;
  json["ttm_mean"] = v.ttm_mean;
  json["ttm_variability"] = v.ttm_variability;
  json["updates_of_changes"] = v.updates_of_changes;
  json["changes_with_tests"] = v.changes_with_tests;
  json["iterations_per_change"] = v.iterations_per_change;
  json["commits_per_change"] = v.commits_per_change;
  return json;
}

function encode_changes_tops(v) {
  var json = {};
  var v$1 = v.authors;
  if (v$1 !== undefined) {
    var json$p = MetricBs$Web.encode_terms_count_int(v$1);
    json["authors"] = json$p;
  }
  var v$2 = v.repos;
  if (v$2 !== undefined) {
    var json$p$1 = MetricBs$Web.encode_terms_count_int(v$2);
    json["repos"] = json$p$1;
  }
  var v$3 = v.approvals;
  if (v$3 !== undefined) {
    var json$p$2 = MetricBs$Web.encode_terms_count_int(v$3);
    json["approvals"] = json$p$2;
  }
  return json;
}

function encode_query_response(v) {
  var json = {};
  switch (v.TAG | 0) {
    case /* Error */0 :
        var json$p = encode_query_error(v._0);
        json["error"] = json$p;
        break;
    case /* Changes */1 :
        var json$p$1 = encode_changes(v._0);
        json["changes"] = json$p$1;
        break;
    case /* Repos_summary */2 :
        var json$p$2 = encode_repos_summary(v._0);
        json["repos_summary"] = json$p$2;
        break;
    case /* Top_authors */3 :
        var json$p$3 = MetricBs$Web.encode_terms_count_int(v._0);
        json["top_authors"] = json$p$3;
        break;
    case /* Authors_peers */4 :
        var json$p$4 = encode_authors_peers(v._0);
        json["authors_peers"] = json$p$4;
        break;
    case /* New_authors */5 :
        var json$p$5 = MetricBs$Web.encode_terms_count_int(v._0);
        json["new_authors"] = json$p$5;
        break;
    case /* Review_stats */6 :
        var json$p$6 = encode_review_stats(v._0);
        json["review_stats"] = json$p$6;
        break;
    case /* Lifecycle_stats */7 :
        var json$p$7 = encode_lifecycle_stats(v._0);
        json["lifecycle_stats"] = json$p$7;
        break;
    case /* Activity_stats */8 :
        var json$p$8 = encode_activity_stats(v._0);
        json["activity_stats"] = json$p$8;
        break;
    case /* Change_events */9 :
        var json$p$9 = encode_change_and_events(v._0);
        json["change_events"] = json$p$9;
        break;
    case /* Changes_tops */10 :
        var json$p$10 = encode_changes_tops(v._0);
        json["changes_tops"] = json$p$10;
        break;
    case /* Ratio */11 :
        json["ratio"] = v._0;
        break;
    case /* Histo */12 :
        var json$p$11 = MetricBs$Web.encode_histo_int_stat(v._0);
        json["histo"] = json$p$11;
        break;
    
  }
  return json;
}

exports.encode_task_data = encode_task_data;
exports.encode_suggestions_request = encode_suggestions_request;
exports.encode_suggestions_response = encode_suggestions_response;
exports.encode_fields_request = encode_fields_request;
exports.encode_field_type = encode_field_type;
exports.encode_field = encode_field;
exports.encode_fields_response = encode_fields_response;
exports.encode_query_error = encode_query_error;
exports.encode_check_request = encode_check_request;
exports.encode_check_response = encode_check_response;
exports.encode_author = encode_author;
exports.encode_author_request = encode_author_request;
exports.encode_author_response = encode_author_response;
exports.encode_order_direction = encode_order_direction;
exports.encode_order = encode_order;
exports.encode_query_request_query_type = encode_query_request_query_type;
exports.encode_query_request = encode_query_request;
exports.encode_file = encode_file;
exports.encode_commit = encode_commit;
exports.encode_change_merged_by_m = encode_change_merged_by_m;
exports.encode_change_ttm = encode_change_ttm;
exports.encode_change = encode_change;
exports.encode_changes = encode_changes;
exports.encode_ratio = encode_ratio;
exports.encode_change_event = encode_change_event;
exports.encode_change_and_events = encode_change_and_events;
exports.encode_review_count = encode_review_count;
exports.encode_review_stats = encode_review_stats;
exports.encode_activity_stats = encode_activity_stats;
exports.encode_repo_summary = encode_repo_summary;
exports.encode_repos_summary = encode_repos_summary;
exports.encode_author_peer = encode_author_peer;
exports.encode_authors_peers = encode_authors_peers;
exports.encode_lifecycle_stats = encode_lifecycle_stats;
exports.encode_changes_tops = encode_changes_tops;
exports.encode_query_response = encode_query_response;
exports.decode_task_data = decode_task_data;
exports.decode_suggestions_request = decode_suggestions_request;
exports.decode_suggestions_response = decode_suggestions_response;
exports.decode_fields_request = decode_fields_request;
exports.decode_field_type = decode_field_type;
exports.decode_field = decode_field;
exports.decode_fields_response = decode_fields_response;
exports.decode_query_error = decode_query_error;
exports.decode_check_request = decode_check_request;
exports.decode_check_response = decode_check_response;
exports.decode_author = decode_author;
exports.decode_author_request = decode_author_request;
exports.decode_author_response = decode_author_response;
exports.decode_order_direction = decode_order_direction;
exports.decode_order = decode_order;
exports.decode_query_request_query_type = decode_query_request_query_type;
exports.decode_query_request = decode_query_request;
exports.decode_file = decode_file;
exports.decode_commit = decode_commit;
exports.decode_change_merged_by_m = decode_change_merged_by_m;
exports.decode_change_ttm = decode_change_ttm;
exports.decode_change = decode_change;
exports.decode_changes = decode_changes;
exports.decode_ratio = decode_ratio;
exports.decode_change_event = decode_change_event;
exports.decode_change_and_events = decode_change_and_events;
exports.decode_review_count = decode_review_count;
exports.decode_review_stats = decode_review_stats;
exports.decode_activity_stats = decode_activity_stats;
exports.decode_repo_summary = decode_repo_summary;
exports.decode_repos_summary = decode_repos_summary;
exports.decode_author_peer = decode_author_peer;
exports.decode_authors_peers = decode_authors_peers;
exports.decode_lifecycle_stats = decode_lifecycle_stats;
exports.decode_changes_tops = decode_changes_tops;
exports.decode_query_response = decode_query_response;
/* Pbrt_bs Not a pure module */
