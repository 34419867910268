'use strict';

var React = require("react");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Store$Web = require("./Store.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Prelude$Web = require("./Prelude.bs.js");
var ChartjsJsx = require("./chartjs.jsx");
var ReactIcons = require("@patternfly/react-icons");

var make = ChartjsJsx.ConnectionDiagram;

function adapt(peers) {
  var go = function (_xs, _acc) {
    while(true) {
      var acc = _acc;
      var xs = _xs;
      if (!xs) {
        return acc;
      }
      var x = xs.hd;
      var match = Belt_List.partition(xs.tl, (function(x){
          return function (e) {
            if (x.author === e.peer) {
              return x.peer === e.author;
            } else {
              return false;
            }
          }
          }(x)));
      var otherLink = match[0];
      var extraStrength = otherLink && !otherLink.tl ? otherLink.hd.strength : 0;
      _acc = Belt_List.add(acc, {
            a1: x.author,
            a2: x.peer,
            s: x.strength + extraStrength | 0
          });
      _xs = match[1];
      continue ;
    };
  };
  return Belt_List.toArray(go(peers, /* [] */0));
}

var ConnectionDiagram = {
  make: make,
  adapt: adapt
};

function PeersStrengthView$PeersStrengthTable(Props) {
  var items = Props.items;
  var columnNames = [
    "Change author",
    "Peer reviewer",
    "Strength"
  ];
  var isOrdered = function (first, second, index) {
    switch (index) {
      case 0 :
          return first.author < second.author;
      case 1 :
          return first.peer < second.peer;
      case 2 :
          return first.strength < second.strength;
      default:
        return false;
    }
  };
  var formatters_0 = function (item) {
    return Prelude$Web.str(item.author);
  };
  var formatters_1 = {
    hd: (function (item) {
        return Prelude$Web.str(item.peer);
      }),
    tl: {
      hd: (function (item) {
          return Prelude$Web.str(Prelude$Web.int32_str(item.strength));
        }),
      tl: /* [] */0
    }
  };
  var formatters = {
    hd: formatters_0,
    tl: formatters_1
  };
  return React.createElement(Prelude$Web.SortableTable.make, {
              items: items,
              defaultSortedColumn: 2,
              columnNames: columnNames,
              isOrdered: isOrdered,
              formatters: formatters
            });
}

var PeersStrengthTable = {
  make: PeersStrengthView$PeersStrengthTable
};

function PeersStrengthView(Props) {
  var store = Props.store;
  var stacked = Props.stacked;
  var extraQuery = Props.extraQuery;
  var state = store[0];
  var match = React.useState(function () {
        return 25;
      });
  var limit = match[0];
  var baseRequest = Store$Web.mkSearchRequest(state, /* Query_top_authors_peers */8);
  var request_index = baseRequest.index;
  var request_username = baseRequest.username;
  var request_query = extraQuery !== undefined ? Prelude$Web.addQuery(baseRequest.query, extraQuery) : baseRequest.query;
  var request_query_type = baseRequest.query_type;
  var request_order = baseRequest.order;
  var request_change_id = baseRequest.change_id;
  var request = {
    index: request_index,
    username: request_username,
    query: request_query,
    query_type: request_query_type,
    order: request_order,
    limit: limit,
    change_id: request_change_id
  };
  var trigger = state.query + String(limit) + Belt_Option.getWithDefault(extraQuery, "");
  var limitSelector = React.createElement(Prelude$Web.LimitSelector.make, {
        limit: limit,
        setLimit: match[1],
        default: 25,
        values: {
          hd: 10,
          tl: {
            hd: 25,
            tl: {
              hd: 50,
              tl: {
                hd: 100,
                tl: {
                  hd: 500,
                  tl: /* [] */0
                }
              }
            }
          }
        }
      });
  var icon = React.createElement(ReactIcons.IntegrationIcon, {});
  var match$1 = function (resp) {
    if (resp.TAG === /* Authors_peers */4) {
      return resp._0;
    }
    
  };
  var childrenBuilder = function (data) {
    if (stacked) {
      return React.createElement(Prelude$Web.MStack.make, {
                  children: null
                }, React.createElement(Prelude$Web.MStackItem.make, {
                      children: React.createElement(make, {
                            data: adapt(data.author_peer)
                          })
                    }), React.createElement(Prelude$Web.MStackItem.make, {
                      children: React.createElement(PeersStrengthView$PeersStrengthTable, {
                            items: data.author_peer
                          })
                    }));
    } else {
      return React.createElement(Prelude$Web.MGrid.make, {
                  children: null
                }, React.createElement(Prelude$Web.MGridItemXl5.make, {
                      children: React.createElement(PeersStrengthView$PeersStrengthTable, {
                            items: data.author_peer
                          })
                    }), React.createElement(Prelude$Web.MGridItemXl7.make, {
                      children: React.createElement(make, {
                            data: adapt(data.author_peer)
                          })
                    }));
    }
  };
  return React.createElement(Prelude$Web.QueryRenderCard.make, {
              request: request,
              trigger: trigger,
              title: "Peers Strength",
              tooltip_content: "This shows the strength between change authors and peer reviewers. Each line of the table shows a strength value between a change's author and a peer reviewer. The strength value is the count of comments + the count of reviews performed by the peer on the author' changes.",
              icon: icon,
              limitSelector: limitSelector,
              match: match$1,
              childrenBuilder: childrenBuilder
            });
}

var make$1 = PeersStrengthView;

var $$default = PeersStrengthView;

exports.ConnectionDiagram = ConnectionDiagram;
exports.PeersStrengthTable = PeersStrengthTable;
exports.make = make$1;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
/* make Not a pure module */
