'use strict';

var React = require("react");
var MLink$Web = require("./MLink.bs.js");
var Store$Web = require("./Store.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Prelude$Web = require("./Prelude.bs.js");
var ReactIcons = require("@patternfly/react-icons");

function getFilter(name, cStateM) {
  return "repo:" + name + " " + (
          cStateM !== undefined ? "state:" + cStateM : ""
        );
}

function createFilter(entity) {
  var toScoped = function (n, tabType) {
    return [
            "repo:" + n,
            {
              TAG: /* SetAuthorScopedTab */5,
              _0: tabType
            }
          ];
  };
  switch (entity.TAG | 0) {
    case /* AllChanges */0 :
        return [
                getFilter(entity._0, undefined),
                undefined
              ];
    case /* OpenChanges */1 :
        return [
                getFilter(entity._0, "open"),
                undefined
              ];
    case /* ScopedOpenChanges */2 :
        return toScoped(entity._0, /* OpenChanges */2);
    case /* MergedChanges */3 :
        return [
                getFilter(entity._0, "merged"),
                undefined
              ];
    case /* ScopedMergedChanges */4 :
        return toScoped(entity._0, /* MergedChanges */3);
    case /* AbandonedChanges */5 :
        return [
                getFilter(entity._0, "abandoned"),
                undefined
              ];
    case /* ScopedAbandonedChanges */6 :
        return toScoped(entity._0, /* AbandonedChanges */4);
    
  }
}

function ReposView$ChangeLink(Props) {
  var store = Props.store;
  var entity = Props.entity;
  var path = Props.path;
  var name = Props.name;
  var match = createFilter(entity);
  var tmp = {
    store: store,
    filter: match[0],
    name: name
  };
  if (path !== undefined) {
    tmp.path = path;
  }
  var tmp$1 = match[1];
  if (tmp$1 !== undefined) {
    tmp.action = Caml_option.valFromOption(tmp$1);
  }
  return React.createElement(MLink$Web.MonoLink.make, tmp);
}

var ChangeLink = {
  getFilter: getFilter,
  createFilter: createFilter,
  make: ReposView$ChangeLink
};

function ReposView$RepoSummaryTable(Props) {
  var store = Props.store;
  var repos = Props.repos;
  var isScoped = Props.isScoped;
  var columnNames = [
    "Repository",
    "Open changes",
    "Created changes",
    "Updated changes",
    "Merged changes",
    "Abandoned changes"
  ];
  var scoped = isScoped !== undefined && isScoped ? true : false;
  var isOrdered = function (first, second, index) {
    switch (index) {
      case 0 :
          return first.fullname < second.fullname;
      case 1 :
          return first.open_changes < second.open_changes;
      case 2 :
          return first.created_changes < second.created_changes;
      case 3 :
          return first.updated_changes < second.updated_changes;
      case 4 :
          return first.merged_changes < second.merged_changes;
      case 5 :
          return first.abandoned_changes < second.abandoned_changes;
      default:
        return false;
    }
  };
  var mkLink = function (entity, label) {
    if (scoped) {
      return React.createElement(ReposView$ChangeLink, {
                  store: store,
                  entity: entity,
                  name: label
                });
    } else {
      return React.createElement(ReposView$ChangeLink, {
                  store: store,
                  entity: entity,
                  path: "changes",
                  name: label
                });
    }
  };
  var formatters_0 = function (repo) {
    return Prelude$Web.str(repo.fullname);
  };
  var formatters_1 = {
    hd: (function (repo) {
        return mkLink(scoped ? ({
                        TAG: /* ScopedOpenChanges */2,
                        _0: repo.fullname
                      }) : ({
                        TAG: /* OpenChanges */1,
                        _0: repo.fullname
                      }), Prelude$Web.int32_str(repo.open_changes));
      }),
    tl: {
      hd: (function (repo) {
          return Prelude$Web.str(Prelude$Web.int32_str(repo.created_changes));
        }),
      tl: {
        hd: (function (repo) {
            return Prelude$Web.str(Prelude$Web.int32_str(repo.updated_changes));
          }),
        tl: {
          hd: (function (repo) {
              return mkLink(scoped ? ({
                              TAG: /* ScopedMergedChanges */4,
                              _0: repo.fullname
                            }) : ({
                              TAG: /* MergedChanges */3,
                              _0: repo.fullname
                            }), Prelude$Web.int32_str(repo.merged_changes));
            }),
          tl: {
            hd: (function (repo) {
                return mkLink(scoped ? ({
                                TAG: /* ScopedAbandonedChanges */6,
                                _0: repo.fullname
                              }) : ({
                                TAG: /* AbandonedChanges */5,
                                _0: repo.fullname
                              }), Prelude$Web.int32_str(repo.abandoned_changes));
              }),
            tl: /* [] */0
          }
        }
      }
    }
  };
  var formatters = {
    hd: formatters_0,
    tl: formatters_1
  };
  return React.createElement(Prelude$Web.SortableTable.make, {
              items: repos,
              defaultSortedColumn: 2,
              columnNames: columnNames,
              isOrdered: isOrdered,
              formatters: formatters
            });
}

var RepoSummaryTable = {
  make: ReposView$RepoSummaryTable
};

function ReposView(Props) {
  var store = Props.store;
  var extraQuery = Props.extraQuery;
  var isScoped = Props.isScoped;
  var state = store[0];
  var baseRequest = Store$Web.mkSearchRequest(state, /* Query_repos_summary */1);
  var request_index = baseRequest.index;
  var request_username = baseRequest.username;
  var request_query = extraQuery !== undefined ? Prelude$Web.addQuery(baseRequest.query, extraQuery) : baseRequest.query;
  var request_query_type = baseRequest.query_type;
  var request_order = baseRequest.order;
  var request_limit = baseRequest.limit;
  var request_change_id = baseRequest.change_id;
  var request = {
    index: request_index,
    username: request_username,
    query: request_query,
    query_type: request_query_type,
    order: request_order,
    limit: request_limit,
    change_id: request_change_id
  };
  var trigger = state.query + Belt_Option.getWithDefault(extraQuery, "");
  var icon = React.createElement(ReactIcons.RepositoryIcon, {});
  var match = function (resp) {
    if (resp.TAG === /* Repos_summary */2) {
      return resp._0.reposum;
    }
    
  };
  var childrenBuilder = function (repos) {
    var tmp = {
      store: store,
      repos: repos
    };
    if (isScoped !== undefined) {
      tmp.isScoped = isScoped;
    }
    return React.createElement(ReposView$RepoSummaryTable, tmp);
  };
  return React.createElement(Prelude$Web.QueryRenderCard.make, {
              request: request,
              trigger: trigger,
              title: "Repository summary",
              tooltip_content: "This shows the list of repositories (which received some activities) along with some metrics",
              icon: icon,
              match: match,
              childrenBuilder: childrenBuilder
            });
}

var make = ReposView;

var $$default = ReposView;

exports.ChangeLink = ChangeLink;
exports.RepoSummaryTable = RepoSummaryTable;
exports.make = make;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
/* react Not a pure module */
