'use strict';

var Dexie = require("dexie").default;
var Version$Dexie = require("@dusty-phillips/rescript-dexie/src/Version.bs.js");

function make(id, hidden_at) {
  return {
          id: id,
          hidden_at: hidden_at,
          ctype: "Change"
        };
}

var HiddenChangeSchema = {
  tableName: "hidden",
  make: make
};

function make$1(id) {
  return {
          id: id,
          ctype: "Change"
        };
}

var PinnedChangeSchema = {
  tableName: "pinned",
  make: make$1
};

function make$2(id) {
  return {
          id: id,
          ctype: "Change"
        };
}

var MaskedChangeSchema = {
  tableName: "masked",
  make: make$2
};

function mkDexie(param) {
  var dexie = new Dexie("Monocle");
  var schema = [
    [
      "hidden",
      "&id,ctype"
    ],
    [
      "pinned",
      "&id,ctype"
    ],
    [
      "masked",
      "&id,ctype"
    ]
  ];
  Version$Dexie.stores(dexie.version(3), schema);
  return dexie;
}

exports.HiddenChangeSchema = HiddenChangeSchema;
exports.PinnedChangeSchema = PinnedChangeSchema;
exports.MaskedChangeSchema = MaskedChangeSchema;
exports.mkDexie = mkDexie;
/* dexie Not a pure module */
