'use strict';

var $$Array = require("rescript/lib/js/array.js");
var Pbrt_bs = require("bs-ocaml-protoc-json/src/pbrt_bs.bs.js");
var MetricTypes$Web = require("./MetricTypes.bs.js");

function default_get_request_mutable(param) {
  return {
          index: "",
          username: "",
          query: "",
          metric: "",
          options: {
            TAG: /* Compute */0,
            _0: MetricTypes$Web.default_compute(undefined, undefined)
          }
        };
}

function decode_metric_info(json) {
  var v = {
    name: "",
    description: "",
    long_description: "",
    metric: ""
  };
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    switch (match) {
      case "description" :
          var json$1 = json["description"];
          v.description = Pbrt_bs.string(json$1, "metric_info", "description");
          break;
      case "long_description" :
          var json$2 = json["long_description"];
          v.long_description = Pbrt_bs.string(json$2, "metric_info", "long_description");
          break;
      case "metric" :
          var json$3 = json["metric"];
          v.metric = Pbrt_bs.string(json$3, "metric_info", "metric");
          break;
      case "name" :
          var json$4 = json["name"];
          v.name = Pbrt_bs.string(json$4, "metric_info", "name");
          break;
      default:
        
    }
  }
  return {
          name: v.name,
          description: v.description,
          long_description: v.long_description,
          metric: v.metric
        };
}

function decode_list_request(json) {
  var v = "";
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    if (match === "void") {
      var json$1 = json["void"];
      v = Pbrt_bs.string(json$1, "list_request", "void");
    }
    
  }
  return {
          void: v
        };
}

function decode_list_response(json) {
  var v = /* [] */0;
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    if (match === "metrics") {
      var a = json["metrics"];
      var a$1 = Pbrt_bs.array_(a, "list_response", "metrics");
      v = $$Array.to_list($$Array.map((function (json) {
                  return decode_metric_info(Pbrt_bs.object_(json, "list_response", "metrics"));
                }), a$1));
    }
    
  }
  return {
          metrics: v
        };
}

function decode_trend(json) {
  var v = "";
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    if (match === "interval") {
      var json$1 = json["interval"];
      v = Pbrt_bs.string(json$1, "trend", "interval");
    }
    
  }
  return {
          interval: v
        };
}

function decode_top(json) {
  var v = 0;
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    if (match === "limit") {
      var json$1 = json["limit"];
      v = Pbrt_bs.int32(json$1, "top", "limit");
    }
    
  }
  return {
          limit: v
        };
}

function decode_compute(json) {
  var v = "";
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    if (match === "void") {
      var json$1 = json["void"];
      v = Pbrt_bs.string(json$1, "compute", "void");
    }
    
  }
  return {
          void: v
        };
}

function decode_get_request_options(json) {
  var keys = Object.keys(json);
  var _i = keys.length - 1 | 0;
  while(true) {
    var i = _i;
    if (i === -1) {
      return Pbrt_bs.E.malformed_variant("get_request_options");
    }
    var match = keys[i];
    switch (match) {
      case "compute" :
          var json$1 = json["compute"];
          return {
                  TAG: /* Compute */0,
                  _0: decode_compute(Pbrt_bs.object_(json$1, "get_request_options", "Compute"))
                };
      case "top" :
          var json$2 = json["top"];
          return {
                  TAG: /* Top */2,
                  _0: decode_top(Pbrt_bs.object_(json$2, "get_request_options", "Top"))
                };
      case "trend" :
          var json$3 = json["trend"];
          return {
                  TAG: /* Trend */1,
                  _0: decode_trend(Pbrt_bs.object_(json$3, "get_request_options", "Trend"))
                };
      default:
        _i = i - 1 | 0;
        continue ;
    }
  };
}

function decode_get_request(json) {
  var v = default_get_request_mutable(undefined);
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    switch (match) {
      case "compute" :
          var json$1 = json["compute"];
          v.options = {
            TAG: /* Compute */0,
            _0: decode_compute(Pbrt_bs.object_(json$1, "get_request", "options"))
          };
          break;
      case "index" :
          var json$2 = json["index"];
          v.index = Pbrt_bs.string(json$2, "get_request", "index");
          break;
      case "metric" :
          var json$3 = json["metric"];
          v.metric = Pbrt_bs.string(json$3, "get_request", "metric");
          break;
      case "query" :
          var json$4 = json["query"];
          v.query = Pbrt_bs.string(json$4, "get_request", "query");
          break;
      case "top" :
          var json$5 = json["top"];
          v.options = {
            TAG: /* Top */2,
            _0: decode_top(Pbrt_bs.object_(json$5, "get_request", "options"))
          };
          break;
      case "trend" :
          var json$6 = json["trend"];
          v.options = {
            TAG: /* Trend */1,
            _0: decode_trend(Pbrt_bs.object_(json$6, "get_request", "options"))
          };
          break;
      case "username" :
          var json$7 = json["username"];
          v.username = Pbrt_bs.string(json$7, "get_request", "username");
          break;
      default:
        
    }
  }
  return {
          index: v.index,
          username: v.username,
          query: v.query,
          metric: v.metric,
          options: v.options
        };
}

function decode_info_request(json) {
  var v = "";
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    if (match === "metric") {
      var json$1 = json["metric"];
      v = Pbrt_bs.string(json$1, "info_request", "metric");
    }
    
  }
  return {
          metric: v
        };
}

function decode_histo_int(json) {
  var v = {
    date: "",
    count: 0
  };
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    switch (match) {
      case "count" :
          var json$1 = json["count"];
          v.count = Pbrt_bs.int32(json$1, "histo_int", "count");
          break;
      case "date" :
          var json$2 = json["date"];
          v.date = Pbrt_bs.string(json$2, "histo_int", "date");
          break;
      default:
        
    }
  }
  return {
          date: v.date,
          count: v.count
        };
}

function decode_histo_float(json) {
  var v = {
    date: "",
    count: 0
  };
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    switch (match) {
      case "count" :
          var json$1 = json["count"];
          v.count = Pbrt_bs.$$float(json$1, "histo_float", "count");
          break;
      case "date" :
          var json$2 = json["date"];
          v.date = Pbrt_bs.string(json$2, "histo_float", "date");
          break;
      default:
        
    }
  }
  return {
          date: v.date,
          count: v.count
        };
}

function decode_histo_int_stat(json) {
  var v = /* [] */0;
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    if (match === "histo") {
      var a = json["histo"];
      var a$1 = Pbrt_bs.array_(a, "histo_int_stat", "histo");
      v = $$Array.to_list($$Array.map((function (json) {
                  return decode_histo_int(Pbrt_bs.object_(json, "histo_int_stat", "histo"));
                }), a$1));
    }
    
  }
  return {
          histo: v
        };
}

function decode_histo_float_stat(json) {
  var v = /* [] */0;
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    if (match === "histo") {
      var a = json["histo"];
      var a$1 = Pbrt_bs.array_(a, "histo_float_stat", "histo");
      v = $$Array.to_list($$Array.map((function (json) {
                  return decode_histo_float(Pbrt_bs.object_(json, "histo_float_stat", "histo"));
                }), a$1));
    }
    
  }
  return {
          histo: v
        };
}

function decode_term_count_int(json) {
  var v = {
    term: "",
    count: 0
  };
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    switch (match) {
      case "count" :
          var json$1 = json["count"];
          v.count = Pbrt_bs.int32(json$1, "term_count_int", "count");
          break;
      case "term" :
          var json$2 = json["term"];
          v.term = Pbrt_bs.string(json$2, "term_count_int", "term");
          break;
      default:
        
    }
  }
  return {
          term: v.term,
          count: v.count
        };
}

function decode_terms_count_int(json) {
  var v = {
    termcount: /* [] */0,
    total_hits: 0
  };
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    switch (match) {
      case "termcount" :
          var a = json["termcount"];
          var a$1 = Pbrt_bs.array_(a, "terms_count_int", "termcount");
          v.termcount = $$Array.to_list($$Array.map((function (json) {
                      return decode_term_count_int(Pbrt_bs.object_(json, "terms_count_int", "termcount"));
                    }), a$1));
          break;
      case "total_hits" :
          var json$1 = json["total_hits"];
          v.total_hits = Pbrt_bs.int32(json$1, "terms_count_int", "total_hits");
          break;
      default:
        
    }
  }
  return {
          termcount: v.termcount,
          total_hits: v.total_hits
        };
}

function decode_term_count_float(json) {
  var v = {
    term: "",
    count: 0
  };
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    switch (match) {
      case "count" :
          var json$1 = json["count"];
          v.count = Pbrt_bs.$$float(json$1, "term_count_float", "count");
          break;
      case "term" :
          var json$2 = json["term"];
          v.term = Pbrt_bs.string(json$2, "term_count_float", "term");
          break;
      default:
        
    }
  }
  return {
          term: v.term,
          count: v.count
        };
}

function decode_terms_count_float(json) {
  var v = {
    termcount: /* [] */0,
    total_hits: 0
  };
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    switch (match) {
      case "termcount" :
          var a = json["termcount"];
          var a$1 = Pbrt_bs.array_(a, "terms_count_float", "termcount");
          v.termcount = $$Array.to_list($$Array.map((function (json) {
                      return decode_term_count_float(Pbrt_bs.object_(json, "terms_count_float", "termcount"));
                    }), a$1));
          break;
      case "total_hits" :
          var json$1 = json["total_hits"];
          v.total_hits = Pbrt_bs.int32(json$1, "terms_count_float", "total_hits");
          break;
      default:
        
    }
  }
  return {
          termcount: v.termcount,
          total_hits: v.total_hits
        };
}

function decode_duration(json) {
  var v = 0;
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    if (match === "value") {
      var json$1 = json["value"];
      v = Pbrt_bs.int32(json$1, "duration", "value");
    }
    
  }
  return {
          value: v
        };
}

function decode_histo_duration(json) {
  var v = {
    date: "",
    count: 0
  };
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    switch (match) {
      case "count" :
          var json$1 = json["count"];
          v.count = Pbrt_bs.int32(json$1, "histo_duration", "count");
          break;
      case "date" :
          var json$2 = json["date"];
          v.date = Pbrt_bs.string(json$2, "histo_duration", "date");
          break;
      default:
        
    }
  }
  return {
          date: v.date,
          count: v.count
        };
}

function decode_histo_duration_stat(json) {
  var v = /* [] */0;
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    if (match === "histo") {
      var a = json["histo"];
      var a$1 = Pbrt_bs.array_(a, "histo_duration_stat", "histo");
      v = $$Array.to_list($$Array.map((function (json) {
                  return decode_histo_duration(Pbrt_bs.object_(json, "histo_duration_stat", "histo"));
                }), a$1));
    }
    
  }
  return {
          histo: v
        };
}

function decode_term_count_duration(json) {
  var v = {
    term: "",
    count: 0
  };
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    switch (match) {
      case "count" :
          var json$1 = json["count"];
          v.count = Pbrt_bs.int32(json$1, "term_count_duration", "count");
          break;
      case "term" :
          var json$2 = json["term"];
          v.term = Pbrt_bs.string(json$2, "term_count_duration", "term");
          break;
      default:
        
    }
  }
  return {
          term: v.term,
          count: v.count
        };
}

function decode_terms_count_duration(json) {
  var v = {
    termcount: /* [] */0,
    total_hits: 0
  };
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    switch (match) {
      case "termcount" :
          var a = json["termcount"];
          var a$1 = Pbrt_bs.array_(a, "terms_count_duration", "termcount");
          v.termcount = $$Array.to_list($$Array.map((function (json) {
                      return decode_term_count_duration(Pbrt_bs.object_(json, "terms_count_duration", "termcount"));
                    }), a$1));
          break;
      case "total_hits" :
          var json$1 = json["total_hits"];
          v.total_hits = Pbrt_bs.int32(json$1, "terms_count_duration", "total_hits");
          break;
      default:
        
    }
  }
  return {
          termcount: v.termcount,
          total_hits: v.total_hits
        };
}

function decode_get_response(json) {
  var keys = Object.keys(json);
  var _i = keys.length - 1 | 0;
  while(true) {
    var i = _i;
    if (i === -1) {
      return Pbrt_bs.E.malformed_variant("get_response");
    }
    var match = keys[i];
    switch (match) {
      case "duration_value" :
          var json$1 = json["duration_value"];
          return {
                  TAG: /* Duration_value */7,
                  _0: decode_duration(Pbrt_bs.object_(json$1, "get_response", "Duration_value"))
                };
      case "error" :
          var json$2 = json["error"];
          return {
                  TAG: /* Error */0,
                  _0: Pbrt_bs.string(json$2, "get_response", "Error")
                };
      case "float_value" :
          var json$3 = json["float_value"];
          return {
                  TAG: /* Float_value */1,
                  _0: Pbrt_bs.$$float(json$3, "get_response", "Float_value")
                };
      case "histo_duration" :
          var json$4 = json["histo_duration"];
          return {
                  TAG: /* Histo_duration */8,
                  _0: decode_histo_duration_stat(Pbrt_bs.object_(json$4, "get_response", "Histo_duration"))
                };
      case "histo_float" :
          var json$5 = json["histo_float"];
          return {
                  TAG: /* Histo_float */4,
                  _0: decode_histo_float_stat(Pbrt_bs.object_(json$5, "get_response", "Histo_float"))
                };
      case "histo_int" :
          var json$6 = json["histo_int"];
          return {
                  TAG: /* Histo_int */3,
                  _0: decode_histo_int_stat(Pbrt_bs.object_(json$6, "get_response", "Histo_int"))
                };
      case "int_value" :
          var json$7 = json["int_value"];
          return {
                  TAG: /* Int_value */2,
                  _0: Pbrt_bs.int32(json$7, "get_response", "Int_value")
                };
      case "top_duration" :
          var json$8 = json["top_duration"];
          return {
                  TAG: /* Top_duration */9,
                  _0: decode_terms_count_duration(Pbrt_bs.object_(json$8, "get_response", "Top_duration"))
                };
      case "top_float" :
          var json$9 = json["top_float"];
          return {
                  TAG: /* Top_float */6,
                  _0: decode_terms_count_float(Pbrt_bs.object_(json$9, "get_response", "Top_float"))
                };
      case "top_int" :
          var json$10 = json["top_int"];
          return {
                  TAG: /* Top_int */5,
                  _0: decode_terms_count_int(Pbrt_bs.object_(json$10, "get_response", "Top_int"))
                };
      default:
        _i = i - 1 | 0;
        continue ;
    }
  };
}

function decode_info_response(json) {
  var keys = Object.keys(json);
  var _i = keys.length - 1 | 0;
  while(true) {
    var i = _i;
    if (i === -1) {
      return Pbrt_bs.E.malformed_variant("info_response");
    }
    var match = keys[i];
    switch (match) {
      case "error" :
          var json$1 = json["error"];
          return {
                  TAG: /* Error */0,
                  _0: Pbrt_bs.string(json$1, "info_response", "Error")
                };
      case "info" :
          var json$2 = json["info"];
          return {
                  TAG: /* Info */1,
                  _0: decode_metric_info(Pbrt_bs.object_(json$2, "info_response", "Info"))
                };
      default:
        _i = i - 1 | 0;
        continue ;
    }
  };
}

function encode_metric_info(v) {
  var json = {};
  json["name"] = v.name;
  json["description"] = v.description;
  json["long_description"] = v.long_description;
  json["metric"] = v.metric;
  return json;
}

function encode_list_request(v) {
  var json = {};
  json["void"] = v.void;
  return json;
}

function encode_list_response(v) {
  var json = {};
  var metrics$p = $$Array.map(encode_metric_info, $$Array.of_list(v.metrics));
  json["metrics"] = metrics$p;
  return json;
}

function encode_trend(v) {
  var json = {};
  json["interval"] = v.interval;
  return json;
}

function encode_top(v) {
  var json = {};
  json["limit"] = v.limit;
  return json;
}

function encode_compute(v) {
  var json = {};
  json["void"] = v.void;
  return json;
}

function encode_get_request_options(v) {
  var json = {};
  switch (v.TAG | 0) {
    case /* Compute */0 :
        var json$p = encode_compute(v._0);
        json["compute"] = json$p;
        break;
    case /* Trend */1 :
        var json$p$1 = encode_trend(v._0);
        json["trend"] = json$p$1;
        break;
    case /* Top */2 :
        var json$p$2 = encode_top(v._0);
        json["top"] = json$p$2;
        break;
    
  }
  return json;
}

function encode_get_request(v) {
  var json = {};
  json["index"] = v.index;
  json["username"] = v.username;
  json["query"] = v.query;
  json["metric"] = v.metric;
  var v$1 = v.options;
  switch (v$1.TAG | 0) {
    case /* Compute */0 :
        var json$p = encode_compute(v$1._0);
        json["compute"] = json$p;
        break;
    case /* Trend */1 :
        var json$p$1 = encode_trend(v$1._0);
        json["trend"] = json$p$1;
        break;
    case /* Top */2 :
        var json$p$2 = encode_top(v$1._0);
        json["top"] = json$p$2;
        break;
    
  }
  return json;
}

function encode_info_request(v) {
  var json = {};
  json["metric"] = v.metric;
  return json;
}

function encode_histo_int(v) {
  var json = {};
  json["date"] = v.date;
  json["count"] = v.count;
  return json;
}

function encode_histo_float(v) {
  var json = {};
  json["date"] = v.date;
  json["count"] = v.count;
  return json;
}

function encode_histo_int_stat(v) {
  var json = {};
  var histo$p = $$Array.map(encode_histo_int, $$Array.of_list(v.histo));
  json["histo"] = histo$p;
  return json;
}

function encode_histo_float_stat(v) {
  var json = {};
  var histo$p = $$Array.map(encode_histo_float, $$Array.of_list(v.histo));
  json["histo"] = histo$p;
  return json;
}

function encode_term_count_int(v) {
  var json = {};
  json["term"] = v.term;
  json["count"] = v.count;
  return json;
}

function encode_terms_count_int(v) {
  var json = {};
  var termcount$p = $$Array.map(encode_term_count_int, $$Array.of_list(v.termcount));
  json["termcount"] = termcount$p;
  json["total_hits"] = v.total_hits;
  return json;
}

function encode_term_count_float(v) {
  var json = {};
  json["term"] = v.term;
  json["count"] = v.count;
  return json;
}

function encode_terms_count_float(v) {
  var json = {};
  var termcount$p = $$Array.map(encode_term_count_float, $$Array.of_list(v.termcount));
  json["termcount"] = termcount$p;
  json["total_hits"] = v.total_hits;
  return json;
}

function encode_duration(v) {
  var json = {};
  json["value"] = v.value;
  return json;
}

function encode_histo_duration(v) {
  var json = {};
  json["date"] = v.date;
  json["count"] = v.count;
  return json;
}

function encode_histo_duration_stat(v) {
  var json = {};
  var histo$p = $$Array.map(encode_histo_duration, $$Array.of_list(v.histo));
  json["histo"] = histo$p;
  return json;
}

function encode_term_count_duration(v) {
  var json = {};
  json["term"] = v.term;
  json["count"] = v.count;
  return json;
}

function encode_terms_count_duration(v) {
  var json = {};
  var termcount$p = $$Array.map(encode_term_count_duration, $$Array.of_list(v.termcount));
  json["termcount"] = termcount$p;
  json["total_hits"] = v.total_hits;
  return json;
}

function encode_get_response(v) {
  var json = {};
  switch (v.TAG | 0) {
    case /* Error */0 :
        json["error"] = v._0;
        break;
    case /* Float_value */1 :
        json["float_value"] = v._0;
        break;
    case /* Int_value */2 :
        json["int_value"] = v._0;
        break;
    case /* Histo_int */3 :
        var json$p = encode_histo_int_stat(v._0);
        json["histo_int"] = json$p;
        break;
    case /* Histo_float */4 :
        var json$p$1 = encode_histo_float_stat(v._0);
        json["histo_float"] = json$p$1;
        break;
    case /* Top_int */5 :
        var json$p$2 = encode_terms_count_int(v._0);
        json["top_int"] = json$p$2;
        break;
    case /* Top_float */6 :
        var json$p$3 = encode_terms_count_float(v._0);
        json["top_float"] = json$p$3;
        break;
    case /* Duration_value */7 :
        var json$p$4 = encode_duration(v._0);
        json["duration_value"] = json$p$4;
        break;
    case /* Histo_duration */8 :
        var json$p$5 = encode_histo_duration_stat(v._0);
        json["histo_duration"] = json$p$5;
        break;
    case /* Top_duration */9 :
        var json$p$6 = encode_terms_count_duration(v._0);
        json["top_duration"] = json$p$6;
        break;
    
  }
  return json;
}

function encode_info_response(v) {
  var json = {};
  if (v.TAG === /* Error */0) {
    json["error"] = v._0;
  } else {
    var json$p = encode_metric_info(v._0);
    json["info"] = json$p;
  }
  return json;
}

exports.encode_metric_info = encode_metric_info;
exports.encode_list_request = encode_list_request;
exports.encode_list_response = encode_list_response;
exports.encode_trend = encode_trend;
exports.encode_top = encode_top;
exports.encode_compute = encode_compute;
exports.encode_get_request_options = encode_get_request_options;
exports.encode_get_request = encode_get_request;
exports.encode_info_request = encode_info_request;
exports.encode_histo_int = encode_histo_int;
exports.encode_histo_float = encode_histo_float;
exports.encode_histo_int_stat = encode_histo_int_stat;
exports.encode_histo_float_stat = encode_histo_float_stat;
exports.encode_term_count_int = encode_term_count_int;
exports.encode_terms_count_int = encode_terms_count_int;
exports.encode_term_count_float = encode_term_count_float;
exports.encode_terms_count_float = encode_terms_count_float;
exports.encode_duration = encode_duration;
exports.encode_histo_duration = encode_histo_duration;
exports.encode_histo_duration_stat = encode_histo_duration_stat;
exports.encode_term_count_duration = encode_term_count_duration;
exports.encode_terms_count_duration = encode_terms_count_duration;
exports.encode_get_response = encode_get_response;
exports.encode_info_response = encode_info_response;
exports.decode_metric_info = decode_metric_info;
exports.decode_list_request = decode_list_request;
exports.decode_list_response = decode_list_response;
exports.decode_trend = decode_trend;
exports.decode_top = decode_top;
exports.decode_compute = decode_compute;
exports.decode_get_request_options = decode_get_request_options;
exports.decode_get_request = decode_get_request;
exports.decode_info_request = decode_info_request;
exports.decode_histo_int = decode_histo_int;
exports.decode_histo_float = decode_histo_float;
exports.decode_histo_int_stat = decode_histo_int_stat;
exports.decode_histo_float_stat = decode_histo_float_stat;
exports.decode_term_count_int = decode_term_count_int;
exports.decode_terms_count_int = decode_terms_count_int;
exports.decode_term_count_float = decode_term_count_float;
exports.decode_terms_count_float = decode_terms_count_float;
exports.decode_duration = decode_duration;
exports.decode_histo_duration = decode_histo_duration;
exports.decode_histo_duration_stat = decode_histo_duration_stat;
exports.decode_term_count_duration = decode_term_count_duration;
exports.decode_terms_count_duration = decode_terms_count_duration;
exports.decode_get_response = decode_get_response;
exports.decode_info_response = decode_info_response;
/* Pbrt_bs Not a pure module */
