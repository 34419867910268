'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var MLink$Web = require("./MLink.bs.js");
var Store$Web = require("./Store.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Prelude$Web = require("./Prelude.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var ReactIcons = require("@patternfly/react-icons");

function ActivePeopleView$TopTermsTable(Props) {
  var store = Props.store;
  var items = Props.items;
  var columnNames = Props.columnNames;
  var link = Props.link;
  var dispatch = store[1];
  var state = store[0];
  var isOrdered = function (first, second, index) {
    if (index !== 0) {
      if (index !== 1) {
        return false;
      } else {
        return first.count < second.count;
      }
    } else {
      return first.term < second.term;
    }
  };
  var mkFilter = function (author, filter) {
    return "author:\"" + author + "\"" + (
            filter !== undefined ? " " + filter : ""
          );
  };
  var mkAuthorLink = function (name, extraFilter) {
    return React.createElement(MLink$Web.MonoLink.make, {
                store: store,
                filter: mkFilter(name, extraFilter),
                path: "changes",
                name: name
              });
  };
  var formatters_0 = function (item) {
    if (typeof link !== "number") {
      return mkAuthorLink(item.term, link._0);
    }
    switch (link) {
      case /* NoLink */0 :
          return Prelude$Web.str(item.term);
      case /* AuthorLink */1 :
          return mkAuthorLink(item.term, undefined);
      case /* ScopedAuthorLink */2 :
          return React.createElement("a", {
                      onClick: (function (e) {
                          var link = "/" + state.index + "/author/" + encodeURIComponent(item.term);
                          e.preventDefault();
                          Curry._1(dispatch, {
                                TAG: /* SetAuthorScopedTab */5,
                                _0: /* ChangeActivity */0
                              });
                          return RescriptReactRouter.push(link);
                        })
                    }, Prelude$Web.str(item.term));
      
    }
  };
  var formatters_1 = {
    hd: (function (item) {
        return Prelude$Web.str(Prelude$Web.int32_str(item.count));
      }),
    tl: /* [] */0
  };
  var formatters = {
    hd: formatters_0,
    tl: formatters_1
  };
  return React.createElement(Prelude$Web.SortableTable.make, {
              items: items,
              defaultSortedColumn: 1,
              columnNames: columnNames,
              isOrdered: isOrdered,
              formatters: formatters
            });
}

var TopTermsTable = {
  make: ActivePeopleView$TopTermsTable
};

function ActivePeopleView$MostActiveAuthor(Props) {
  var store = Props.store;
  var qtype = Props.qtype;
  var title = Props.title;
  var tooltip_content = Props.tooltip_content;
  var link = Props.link;
  var extraQuery = Props.extraQuery;
  var state = store[0];
  var match = React.useState(function () {
        return 10;
      });
  var limit = match[0];
  var columnNames = [
    "Name",
    "Count"
  ];
  var baseRequest = Store$Web.mkSearchRequest(state, qtype);
  var request_index = baseRequest.index;
  var request_username = baseRequest.username;
  var request_query = extraQuery !== undefined ? Prelude$Web.addQuery(baseRequest.query, extraQuery) : baseRequest.query;
  var request_query_type = baseRequest.query_type;
  var request_order = baseRequest.order;
  var request_change_id = baseRequest.change_id;
  var request = {
    index: request_index,
    username: request_username,
    query: request_query,
    query_type: request_query_type,
    order: request_order,
    limit: limit,
    change_id: request_change_id
  };
  var trigger = state.query + String(limit);
  var limitSelector = React.createElement(Prelude$Web.LimitSelector.make, {
        limit: limit,
        setLimit: match[1],
        default: 10,
        values: {
          hd: 10,
          tl: {
            hd: 25,
            tl: {
              hd: 50,
              tl: {
                hd: 100,
                tl: {
                  hd: 500,
                  tl: /* [] */0
                }
              }
            }
          }
        }
      });
  var icon = React.createElement(ReactIcons.TrendUpIcon, {});
  var match$1 = function (resp) {
    if (resp.TAG === /* Top_authors */3) {
      return resp._0;
    }
    
  };
  var childrenBuilder = function (data) {
    return React.createElement(ActivePeopleView$TopTermsTable, {
                store: store,
                items: data.termcount,
                columnNames: columnNames,
                link: link
              });
  };
  return React.createElement(Prelude$Web.QueryRenderCard.make, {
              request: request,
              trigger: trigger,
              title: title,
              tooltip_content: tooltip_content,
              icon: icon,
              limitSelector: limitSelector,
              match: match$1,
              childrenBuilder: childrenBuilder
            });
}

var MostActiveAuthor = {
  make: ActivePeopleView$MostActiveAuthor
};

function getQD(qt) {
  switch (qt) {
    case /* ByChangeCreated */0 :
        return [
                /* Query_top_authors_changes_created */2,
                "By changes created",
                "This shows a list of change' authors ordered by the amount of changes they created",
                /* AuthorLink */1
              ];
    case /* ByChangeMerged */1 :
        return [
                /* Query_top_authors_changes_merged */3,
                "By changes merged",
                "This shows a list of change' authors ordered by the amount of changes they merged",
                /* AuthorWithFilter */{
                  _0: "state:merged"
                }
              ];
    case /* ByChangeReviewed */2 :
        return [
                /* Query_top_authors_changes_reviewed */4,
                "By reviews",
                "This shows a list of review' authors ordered by the amount of reviews they performed",
                /* NoLink */0
              ];
    case /* ByChangeCommented */3 :
        return [
                /* Query_top_authors_changes_commented */5,
                "By comments",
                "This shows a list of comment' authors ordered by the amount of comments they wrote",
                /* NoLink */0
              ];
    case /* ByMostReviewed */4 :
        return [
                /* Query_top_reviewed_authors */6,
                "By changes reviewed",
                "This shows a list of change' authors ordered by the amount of reviews they got",
                /* NoLink */0
              ];
    case /* ByMostCommented */5 :
        return [
                /* Query_top_commented_authors */7,
                "By changes commented",
                "This shows a list of change' authors ordered by the amount of comments they received",
                /* NoLink */0
              ];
    
  }
}

var TopMetricsInfo = {
  getQD: getQD
};

function ActivePeopleView(Props) {
  var store = Props.store;
  var layout = [
    [
      /* ByChangeCreated */0,
      /* ByChangeMerged */1
    ],
    [
      /* ByChangeReviewed */2,
      /* ByChangeCommented */3
    ],
    [
      /* ByMostReviewed */4,
      /* ByMostCommented */5
    ]
  ];
  var getItem = function (item) {
    var match = getQD(item);
    return React.createElement(ActivePeopleView$MostActiveAuthor, {
                store: store,
                qtype: match[0],
                title: match[1],
                tooltip_content: match[2],
                link: match[3]
              });
  };
  var getItemL = function (param) {
    return getItem(param[0]);
  };
  var getItemR = function (param) {
    return getItem(param[1]);
  };
  return React.createElement(Prelude$Web.MCenteredContent.make, {
              children: React.createElement(Prelude$Web.MStack.make, {
                    children: Belt_Array.mapWithIndex(layout, (function (i, e) {
                            return React.createElement(Prelude$Web.MStackItem.make, {
                                        children: React.createElement(Prelude$Web.MGrid.make, {
                                              children: null
                                            }, React.createElement(Prelude$Web.MGridItemXl6.make, {
                                                  children: getItemL(e)
                                                }), React.createElement(Prelude$Web.MGridItemXl6.make, {
                                                  children: getItemR(e)
                                                })),
                                        key: String(i)
                                      });
                          }))
                  })
            });
}

var make = ActivePeopleView;

var $$default = ActivePeopleView;

exports.TopTermsTable = TopTermsTable;
exports.MostActiveAuthor = MostActiveAuthor;
exports.TopMetricsInfo = TopMetricsInfo;
exports.make = make;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
/* react Not a pure module */
