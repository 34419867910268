'use strict';

var React = require("react");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Store$Web = require("./Store.bs.js");
var WebApi$Web = require("./WebApi.bs.js");
var Prelude$Web = require("./Prelude.bs.js");
var ReactCore = require("@patternfly/react-core");
var ReactIcons = require("@patternfly/react-icons");

function Indices$Indice(Props) {
  var store = Props.store;
  var name = Props.name;
  var onClick = function (param) {
    return Store$Web.changeIndex(store, name);
  };
  return React.createElement("a", {
              onClick: onClick
            }, name);
}

function card(store, ws) {
  return React.createElement(Prelude$Web.MSimpleCard.make, {
              children: Indices$Indice({
                    store: store,
                    name: ws.name
                  }),
              key: ws.name
            });
}

var Indice = {
  make: Indices$Indice,
  card: card
};

function Indices$Indices(Props) {
  var store = Props.store;
  var icon = React.createElement(ReactIcons.BundleIcon, {});
  return React.createElement(Prelude$Web.MCenteredContent.make, {
              children: React.createElement(Prelude$Web.MonoCard.make, {
                    title: "Workspaces",
                    tooltip_content: "This shows the list of available workspaces",
                    icon: icon,
                    children: React.createElement(Prelude$Web.NetworkRender.make, {
                          get: (function (param) {
                              return WebApi$Web.Config.getWorkspaces({
                                          void: ""
                                        });
                            }),
                          trigger: "",
                          render: (function (resp) {
                              if (Belt_List.length(resp.workspaces) > 0) {
                                return Belt_List.toArray(Belt_List.map(resp.workspaces, (function (param) {
                                                  return card(store, param);
                                                })));
                              } else {
                                return React.createElement(ReactCore.Alert, {
                                            title: "Please create a workspace.",
                                            variant: "warning"
                                          });
                              }
                            })
                        })
                  })
            });
}

var Indices = {
  make: Indices$Indices
};

var $$default = Indices$Indices;

exports.Indice = Indice;
exports.Indices = Indices;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
/* react Not a pure module */
