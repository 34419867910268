'use strict';

var Caml_option = require("rescript/lib/js/caml_option.js");

function default_project_definition(nameOpt, repository_regexOpt, branch_regexOpt, file_regexOpt, param) {
  var name = nameOpt !== undefined ? nameOpt : "";
  var repository_regex = repository_regexOpt !== undefined ? repository_regexOpt : "";
  var branch_regex = branch_regexOpt !== undefined ? branch_regexOpt : "";
  var file_regex = file_regexOpt !== undefined ? file_regexOpt : "";
  return {
          name: name,
          repository_regex: repository_regex,
          branch_regex: branch_regex,
          file_regex: file_regex
        };
}

function default_get_projects_request(indexOpt, param) {
  var index = indexOpt !== undefined ? indexOpt : "";
  return {
          index: index
        };
}

function default_get_projects_response(projectsOpt, param) {
  var projects = projectsOpt !== undefined ? projectsOpt : /* [] */0;
  return {
          projects: projects
        };
}

function default_workspace(nameOpt, param) {
  var name = nameOpt !== undefined ? nameOpt : "";
  return {
          name: name
        };
}

function default_get_workspaces_request(voidOpt, param) {
  var $$void = voidOpt !== undefined ? voidOpt : "";
  return {
          void: $$void
        };
}

function default_get_workspaces_response(workspacesOpt, param) {
  var workspaces = workspacesOpt !== undefined ? workspacesOpt : /* [] */0;
  return {
          workspaces: workspaces
        };
}

function default_about_about_link(nameOpt, urlOpt, categoryOpt, param) {
  var name = nameOpt !== undefined ? nameOpt : "";
  var url = urlOpt !== undefined ? urlOpt : "";
  var category = categoryOpt !== undefined ? categoryOpt : "";
  return {
          name: name,
          url: url,
          category: category
        };
}

function default_about_auth_config(force_loginOpt, issuerOpt, provider_nameOpt, param) {
  var force_login = force_loginOpt !== undefined ? force_loginOpt : false;
  var issuer = issuerOpt !== undefined ? issuerOpt : "";
  var provider_name = provider_nameOpt !== undefined ? provider_nameOpt : "";
  return {
          force_login: force_login,
          issuer: issuer,
          provider_name: provider_name
        };
}

function default_about_auth(param) {
  return /* Auth_config */{
          _0: default_about_auth_config(undefined, undefined, undefined, undefined)
        };
}

function default_about(versionOpt, linksOpt, authOpt, param) {
  var version = versionOpt !== undefined ? versionOpt : "";
  var links = linksOpt !== undefined ? linksOpt : /* [] */0;
  var auth = authOpt !== undefined ? authOpt : /* Auth_config */({
        _0: default_about_auth_config(undefined, undefined, undefined, undefined)
      });
  return {
          version: version,
          links: links,
          auth: auth
        };
}

function default_get_about_request(voidOpt, param) {
  var $$void = voidOpt !== undefined ? voidOpt : "";
  return {
          void: $$void
        };
}

function default_get_about_response(aboutOpt, param) {
  var about = aboutOpt !== undefined ? Caml_option.valFromOption(aboutOpt) : undefined;
  return {
          about: about
        };
}

function default_group_definition(nameOpt, membersOpt, param) {
  var name = nameOpt !== undefined ? nameOpt : "";
  var members = membersOpt !== undefined ? membersOpt : 0;
  return {
          name: name,
          members: members
        };
}

function default_get_groups_request(indexOpt, param) {
  var index = indexOpt !== undefined ? indexOpt : "";
  return {
          index: index
        };
}

function default_get_groups_response(itemsOpt, param) {
  var items = itemsOpt !== undefined ? itemsOpt : /* [] */0;
  return {
          items: items
        };
}

function default_get_group_members_request(indexOpt, groupOpt, param) {
  var index = indexOpt !== undefined ? indexOpt : "";
  var group = groupOpt !== undefined ? groupOpt : "";
  return {
          index: index,
          group: group
        };
}

function default_get_group_members_response(membersOpt, param) {
  var members = membersOpt !== undefined ? membersOpt : /* [] */0;
  return {
          members: members
        };
}

exports.default_project_definition = default_project_definition;
exports.default_get_projects_request = default_get_projects_request;
exports.default_get_projects_response = default_get_projects_response;
exports.default_workspace = default_workspace;
exports.default_get_workspaces_request = default_get_workspaces_request;
exports.default_get_workspaces_response = default_get_workspaces_response;
exports.default_about_about_link = default_about_about_link;
exports.default_about_auth_config = default_about_auth_config;
exports.default_about_auth = default_about_auth;
exports.default_about = default_about;
exports.default_get_about_request = default_get_about_request;
exports.default_get_about_response = default_get_about_response;
exports.default_group_definition = default_group_definition;
exports.default_get_groups_request = default_get_groups_request;
exports.default_get_groups_response = default_get_groups_response;
exports.default_get_group_members_request = default_get_group_members_request;
exports.default_get_group_members_response = default_get_group_members_response;
/* No side effect */
