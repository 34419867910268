'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Prelude$Web = require("./Prelude.bs.js");
var Table$Dexie = require("@dusty-phillips/rescript-dexie/src/Table.bs.js");
var MonoIndexedDB$Web = require("./MonoIndexedDB.bs.js");
var ReactCore = require("@patternfly/react-core");

var Table = Table$Dexie.MakeTable(MonoIndexedDB$Web.HiddenChangeSchema);

function getAll(dexie) {
  return Curry._2(Table.findByCriteria, dexie, {
                ctype: "Change"
              }).toArray();
}

function remove(dexie, key) {
  Curry._2(Table.$$delete, dexie, key);
  
}

function getStatus(dexie, change) {
  return Curry._2(Table.getById, dexie, change.change_id).then(function (hiddenM) {
              return Promise.resolve([
                          hiddenM !== undefined ? (
                              Caml_obj.caml_lessequal(Prelude$Web.getDate(change.updated_at), hiddenM.hidden_at) ? /* Hidden */0 : /* Updated */2
                            ) : /* Visible */1,
                          change
                        ]);
            });
}

function removeHiddenFromArray(xs, dexie) {
  return Promise.all(Belt_Array.map(xs, (function (param) {
                    return getStatus(dexie, param);
                  })));
}

function setStatus(newStatus, change, xs) {
  return Belt_Array.map(xs, (function (param) {
                var x = param[1];
                if (x.change_id === change.change_id) {
                  return [
                          newStatus,
                          x
                        ];
                } else {
                  return [
                          param[0],
                          x
                        ];
                }
              }));
}

function useToggle(param) {
  var match = React.useState(function () {
        return false;
      });
  var setToggle = match[1];
  var isChecked = match[0];
  var label = isChecked ? "Hide changes" : "Show hidden changes";
  var onChange = function (param, param$1) {
    return Curry._1(setToggle, (function (x) {
                  return !x;
                }));
  };
  var isChangeVisible = function (status) {
    if (isChecked) {
      return true;
    } else {
      return status !== /* Hidden */0;
    }
  };
  return [
          React.createElement("span", {
                style: {
                  float: "right"
                }
              }, React.createElement(ReactCore.Tooltip, {
                    children: React.createElement(ReactCore.Checkbox, {
                          id: "hidden-toggle",
                          isChecked: isChecked,
                          label: label,
                          onChange: onChange
                        }),
                    content: "Set to show or hide hidden changes"
                  })),
          isChangeVisible
        ];
}

function use(dexie, xs) {
  var match = React.useState(function () {
        return [];
      });
  var setHidden = match[1];
  React.useEffect((function () {
          removeHiddenFromArray(xs, dexie).then(function (xs) {
                return Promise.resolve(Curry._1(setHidden, (function (param) {
                                  return xs;
                                })));
              });
          
        }), [xs]);
  var hideChange = function (change) {
    Curry._2(Table.put, dexie, {
            id: change.change_id,
            hidden_at: Prelude$Web.getCurrentTime(undefined),
            ctype: "Change"
          }).then(function (param) {
          return Promise.resolve(Curry._1(setHidden, (function (param) {
                            return setStatus(/* Hidden */0, change, param);
                          })));
        });
    
  };
  var revealChange = function (change) {
    Curry._2(Table.$$delete, dexie, change.change_id).then(function (param) {
          return Promise.resolve(Curry._1(setHidden, (function (param) {
                            return setStatus(/* Visible */1, change, param);
                          })));
        });
    
  };
  return [
          match[0],
          [
            hideChange,
            revealChange
          ]
        ];
}

exports.Table = Table;
exports.getAll = getAll;
exports.remove = remove;
exports.getStatus = getStatus;
exports.removeHiddenFromArray = removeHiddenFromArray;
exports.setStatus = setStatus;
exports.useToggle = useToggle;
exports.use = use;
/* Table Not a pure module */
