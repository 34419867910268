'use strict';

var React = require("react");
var Prelude$Web = require("./Prelude.bs.js");
var ReactCore = require("@patternfly/react-core");
var ReactIcons = require("@patternfly/react-icons");

function AuthorSearch(Props) {
  var store = Props.store;
  var icon = React.createElement(ReactIcons.UsersIcon, {});
  var hxvals = "{\"index\": \"".concat(store[0].index, "\"}");
  return React.createElement(ReactCore.Card, {
              children: null,
              isCompact: true
            }, React.createElement(ReactCore.CardTitle, {
                  children: React.createElement(Prelude$Web.MGrid.make, {
                        children: React.createElement(Prelude$Web.MGridItemXl9.make, {
                              children: React.createElement(ReactCore.Title, {
                                    children: null,
                                    headingLevel: "h3"
                                  }, React.createElement(ReactCore.Tooltip, {
                                        children: icon,
                                        content: "Use this form to search authors over all event authors"
                                      }), Prelude$Web.str(" Search authors"))
                            })
                      })
                }), React.createElement(ReactCore.CardBody, {
                  children: React.createElement(Prelude$Web.HTMXGetHook.make, {
                        children: React.createElement("p", undefined, Prelude$Web.str("loading...")),
                        url: "/api/2/htmx/authors_search",
                        trigger: "load",
                        hxVals: hxvals
                      })
                }));
}

var make = AuthorSearch;

var $$default = AuthorSearch;

exports.make = make;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
/* react Not a pure module */
