'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");

function drawScale(pos, width, height, ctx) {
  ctx.clearRect(0, 0, width, height);
  ctx.fillStyle = "black";
  ctx.lineWidth = 0.5;
  ctx.beginPath();
  ctx.moveTo(5, height / 2 | 0);
  ctx.lineTo(width - 5 | 0, height / 2 | 0);
  var drawTick = function (pos, offset) {
    ctx.moveTo(pos, 5 + offset | 0);
    ctx.lineTo(pos, (height - 5 | 0) - offset | 0);
    
  };
  drawTick(5, 0);
  drawTick(width / 2 | 0, 1);
  drawTick(width - 5 | 0, 0);
  ctx.stroke();
  var axisWidth = width - 10 | 0;
  var posX = 5 + Math.imul(pos, axisWidth) / 100.0;
  ctx.lineWidth = 1.0;
  ctx.strokeStyle = "#cb4b16";
  ctx.beginPath();
  ctx.rect(posX - 1.5, 5, 1.5 * 2.0, height - 10 | 0);
  ctx.stroke();
  
}

function Canvas$Dom(Props) {
  var width = Props.width;
  var height = Props.height;
  var onDraw = Props.onDraw;
  var el = React.useRef(null);
  var setRef = function (element) {
    el.current = element;
    Belt_Option.flatMap((element == null) ? undefined : Caml_option.some(element), (function (element) {
            return Caml_option.some(Curry._3(onDraw, width, height, element.getContext("2d")));
          }));
    
  };
  return React.createElement("canvas", {
              ref: setRef,
              height: String(height) + "px",
              width: String(width) + "px"
            });
}

var Dom = {
  make: Canvas$Dom
};

exports.drawScale = drawScale;
exports.Dom = Dom;
/* react Not a pure module */
