'use strict';

var React = require("react");
var App$Web = require("./App.bs.js");
var ReactDom = require("react-dom");

import '@patternfly/react-core/dist/styles/base.css'
import '@patternfly/react-styles/css/components/Table/table.css'
// This global import is need for having access to the REScriptRouter from injected script (HTMX)
globalThis.RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
import './index.css'
import 'htmx.org';
;

var root = document.querySelector("#root");

if (root == null) {
  console.log("can't find root element");
} else {
  ReactDom.render(React.createElement(App$Web.make, {}), root);
}

/*  Not a pure module */
