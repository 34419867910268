'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var ReactCore = require("@patternfly/react-core");

function use(param) {
  var match = React.useState(function () {
        return false;
      });
  var setToggle = match[1];
  var isChecked = match[0];
  var label = isChecked ? "Hide hidden/masked changes" : "Reveal hidden/masked changes";
  var onChange = function (param, param$1) {
    return Curry._1(setToggle, (function (x) {
                  return !x;
                }));
  };
  var isChangeVisible = function (hiddenStatus, maskedStatus) {
    if (isChecked || hiddenStatus !== 0 && maskedStatus) {
      return true;
    } else {
      return false;
    }
  };
  var toggleButton = React.createElement("span", {
        style: {
          float: "right"
        }
      }, React.createElement(ReactCore.Tooltip, {
            children: React.createElement(ReactCore.Checkbox, {
                  id: "hidden-toggle",
                  isChecked: isChecked,
                  label: label,
                  onChange: onChange
                }),
            content: "Set to show or hide hidden/masked changes"
          }));
  return [
          toggleButton,
          isChangeVisible
        ];
}

exports.use = use;
/* react Not a pure module */
