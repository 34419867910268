'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Table$Dexie = require("@dusty-phillips/rescript-dexie/src/Table.bs.js");
var MonoIndexedDB$Web = require("./MonoIndexedDB.bs.js");

var Table = Table$Dexie.MakeTable(MonoIndexedDB$Web.PinnedChangeSchema);

function remove(dexie, key) {
  Curry._2(Table.$$delete, dexie, key);
  
}

function getStatus(dexie, change) {
  return Curry._2(Table.getById, dexie, change.change_id).then(function (pinnedM) {
              return Promise.resolve([
                          pinnedM !== undefined ? /* Pinned */0 : /* Unpinned */1,
                          change
                        ]);
            });
}

function simpleGetStatus(xs, change) {
  var pinnedM = Belt_Array.get(Belt_Array.keep(xs, (function (param) {
              return change.change_id === param[1].change_id;
            })), 0);
  if (pinnedM !== undefined) {
    return pinnedM[0];
  } else {
    return /* Unpinned */1;
  }
}

function style(pinnedStatus) {
  if (pinnedStatus) {
    return {};
  } else {
    return {
            background: "papayawhip"
          };
  }
}

function getStatusFromChanges(xs, dexie) {
  return Promise.all(Belt_Array.map(xs, (function (param) {
                    return getStatus(dexie, param);
                  })));
}

function setStatus(newStatus, change, xs) {
  return Belt_Array.map(xs, (function (param) {
                var x = param[1];
                if (x.change_id === change.change_id) {
                  return [
                          newStatus,
                          x
                        ];
                } else {
                  return [
                          param[0],
                          x
                        ];
                }
              }));
}

function use(dexie, xs) {
  var match = React.useState(function () {
        return [];
      });
  var setPinned = match[1];
  React.useEffect((function () {
          getStatusFromChanges(xs, dexie).then(function (xs) {
                return Promise.resolve(Curry._1(setPinned, (function (param) {
                                  return xs;
                                })));
              });
          
        }), [xs]);
  var pinChange = function (change) {
    Curry._2(Table.put, dexie, {
            id: change.change_id,
            ctype: "Change"
          }).then(function (param) {
          return Promise.resolve(Curry._1(setPinned, (function (param) {
                            return setStatus(/* Pinned */0, change, param);
                          })));
        });
    
  };
  var unPinChange = function (change) {
    Curry._2(Table.$$delete, dexie, change.change_id).then(function (param) {
          return Promise.resolve(Curry._1(setPinned, (function (param) {
                            return setStatus(/* Unpinned */1, change, param);
                          })));
        });
    
  };
  return [
          match[0],
          [
            pinChange,
            unPinChange
          ]
        ];
}

exports.Table = Table;
exports.remove = remove;
exports.getStatus = getStatus;
exports.simpleGetStatus = simpleGetStatus;
exports.style = style;
exports.getStatusFromChanges = getStatusFromChanges;
exports.setStatus = setStatus;
exports.use = use;
/* Table Not a pure module */
