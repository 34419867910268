'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Store$Web = require("./Store.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var WebApi$Web = require("./WebApi.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Prelude$Web = require("./Prelude.bs.js");
var HelpSearch$Web = require("./HelpSearch.bs.js");
var ReactCore = require("@patternfly/react-core");
var ReactIcons = require("@patternfly/react-icons");

function Search$FieldSelectorModal$FieldSelector(Props) {
  var store = Props.store;
  var fieldName = Props.fieldName;
  var setFieldName = Props.setFieldName;
  var fieldValue = Props.fieldValue;
  var setFieldValue = Props.setFieldValue;
  var fromValue = Props.fromValue;
  var setFromValue = Props.setFromValue;
  var toValue = Props.toValue;
  var setToValue = Props.setToValue;
  var match = Store$Web.Fetch.suggestions(store);
  var match$1 = Store$Web.Fetch.fields(store);
  if (match !== undefined && match.TAG === /* Ok */0 && match$1 !== undefined) {
    var suggestions = match._0;
    if (match$1.TAG === /* Ok */0) {
      var fields = match$1._0;
      var get = function (name) {
        return Belt_List.getBy(fields, (function (field) {
                      return field.name === name;
                    }));
      };
      var getValues = function (name) {
        switch (name) {
          case "approval" :
              return suggestions.approvals;
          case "author" :
              return suggestions.authors;
          case "group" :
              return suggestions.groups;
          case "project" :
              return suggestions.projects;
          case "state" :
              return {
                      hd: "open",
                      tl: {
                        hd: "merged",
                        tl: {
                          hd: "self_merged",
                          tl: {
                            hd: "abandoned",
                            tl: /* [] */0
                          }
                        }
                      }
                    };
          case "tag" :
              return suggestions.labels;
          case "task.priority" :
              return suggestions.priorities;
          case "task.severity" :
              return suggestions.severities;
          case "task.tag" :
              return suggestions.task_types;
          default:
            return /* [] */0;
        }
      };
      var onChange = function (v, param) {
        return Curry._1(setFieldValue, (function (param) {
                      return v;
                    }));
      };
      var freeFormFields = function (f) {
        return !Prelude$Web.elemText({
                    hd: "from",
                    tl: {
                      hd: "to",
                      tl: {
                        hd: "created_at",
                        tl: {
                          hd: "updated_at",
                          tl: /* [] */0
                        }
                      }
                    }
                  }, f.name);
      };
      var field = get(fieldName);
      var tmp;
      if (field !== undefined) {
        var xs = getValues(fieldName);
        tmp = xs ? React.createElement(Prelude$Web.MSelect.make, {
                value: fieldValue,
                placeholder: field.description,
                isCreatable: true,
                options: xs,
                valueChanged: (function (v) {
                    return Curry._1(setFieldValue, (function (param) {
                                  return v;
                                }));
                  })
              }) : React.createElement(ReactCore.TextInput, {
                id: "field-input",
                onChange: onChange,
                placeholder: field.description,
                value: fieldValue
              });
      } else {
        tmp = null;
      }
      return React.createElement(React.Fragment, undefined, React.createElement(ReactCore.FormGroup, {
                      children: null,
                      fieldId: "date-range-form",
                      hasNoPaddingTop: false,
                      label: "Date range"
                    }, React.createElement(ReactCore.DatePicker, {
                          id: "from-date",
                          onChange: (function (v, param) {
                              return Curry._1(setFromValue, (function (param) {
                                            return v;
                                          }));
                            }),
                          placeholder: "From date",
                          value: fromValue
                        }), React.createElement(ReactCore.DatePicker, {
                          id: "to-date",
                          onChange: (function (v, param) {
                              return Curry._1(setToValue, (function (param) {
                                            return v;
                                          }));
                            }),
                          placeholder: "To date",
                          value: toValue
                        })), React.createElement("br", undefined), React.createElement(ReactCore.FormGroup, {
                      children: null,
                      fieldId: "query-filter",
                      hasNoPaddingTop: false,
                      label: "Extra filter"
                    }, React.createElement(Prelude$Web.MSelect.make, {
                          value: fieldName,
                          placeholder: "Pick a field",
                          multi: false,
                          options: Belt_List.map(Belt_List.keep(fields, freeFormFields), (function (f) {
                                  return f.name;
                                })),
                          valueChanged: (function (v) {
                              return Curry._1(setFieldName, (function (param) {
                                            return v;
                                          }));
                            })
                        }), tmp), React.createElement("br", undefined), React.createElement("br", undefined));
    }
    
  }
  return React.createElement(ReactCore.Spinner, {});
}

var FieldSelector = {
  make: Search$FieldSelectorModal$FieldSelector
};

function Search$FieldSelectorModal(Props) {
  var isOpen = Props.isOpen;
  var onClose = Props.onClose;
  var store = Props.store;
  var match = React.useState(function () {
        return "";
      });
  var setFieldName = match[1];
  var fieldName = match[0];
  var match$1 = React.useState(function () {
        return "";
      });
  var setFieldValue = match$1[1];
  var fieldValue = match$1[0];
  var match$2 = React.useState(function () {
        return "";
      });
  var setFromValue = match$2[1];
  var fromValue = match$2[0];
  var match$3 = React.useState(function () {
        return "";
      });
  var setToValue = match$3[1];
  var toValue = match$3[0];
  var submit = function (res) {
    Curry._1(setFromValue, (function (param) {
            return "";
          }));
    Curry._1(setToValue, (function (param) {
            return "";
          }));
    Curry._1(setFieldName, (function (param) {
            return "";
          }));
    Curry._1(setFieldValue, (function (param) {
            return "";
          }));
    return Curry._1(onClose, res);
  };
  var onConfirm = function (param) {
    var field;
    if (fieldValue === "") {
      field = "";
    } else {
      var xs = Belt_Array.map(fieldValue.split(","), (function (fieldValue) {
              return fieldName + ":" + Prelude$Web.quoteValue(fieldValue);
            }));
      field = xs.length !== 1 ? "(" + xs.join(" or ") + ")" : xs[0];
    }
    var setExpr = function (value, field) {
      if (value === "") {
        return "";
      } else {
        return field + ":" + value;
      }
    };
    var fromExpr = setExpr(fromValue, "from");
    var toExpr = setExpr(toValue, "to");
    var expr = Prelude$Web.concatSep(Belt_List.keep({
              hd: field,
              tl: {
                hd: fromExpr,
                tl: {
                  hd: toExpr,
                  tl: /* [] */0
                }
              }
            }, (function (v) {
                return v !== "";
              })), " ");
    return submit(expr);
  };
  var onCancel = function (param) {
    return submit(undefined);
  };
  return React.createElement(ReactCore.Modal, {
              children: React.createElement("div", {
                    style: {
                      height: "400px"
                    }
                  }, React.createElement(Search$FieldSelectorModal$FieldSelector, {
                        store: store,
                        fieldName: fieldName,
                        setFieldName: setFieldName,
                        fieldValue: fieldValue,
                        setFieldValue: setFieldValue,
                        fromValue: fromValue,
                        setFromValue: setFromValue,
                        toValue: toValue,
                        setToValue: setToValue
                      })),
              actions: [
                React.createElement(ReactCore.Button, {
                      children: Prelude$Web.str("Confirm"),
                      onClick: onConfirm,
                      variant: "primary",
                      key: "confirm"
                    }),
                React.createElement(ReactCore.Button, {
                      children: Prelude$Web.str("Cancel"),
                      onClick: onCancel,
                      variant: "link",
                      key: "cancel"
                    })
              ],
              isOpen: isOpen,
              onClose: (function (param) {
                  return Curry._1(onClose, undefined);
                }),
              title: "Add search filter",
              variant: "large"
            });
}

var FieldSelectorModal = {
  FieldSelector: FieldSelector,
  make: Search$FieldSelectorModal
};

var TextInputUp = {};

function Search$Bar(Props) {
  var store = Props.store;
  var value = Props.value;
  var setValue = Props.setValue;
  var onSave = Props.onSave;
  var showTooltipsOpt = Props.showTooltips;
  var error = Props.error;
  var displayApplyOpt = Props.displayApply;
  var isApplyEnabled = Props.isApplyEnabled;
  var showTooltips = showTooltipsOpt !== undefined ? showTooltipsOpt : true;
  var displayApply = displayApplyOpt !== undefined ? displayApplyOpt : true;
  var match = React.useState(function () {
        return false;
      });
  var setShowFieldSelector = match[1];
  var appendExpr = function (expr) {
    if (expr !== undefined) {
      var prefix = expr === "" ? "" : " ";
      Curry._1(onSave, expr + prefix + value);
    }
    return Curry._1(setShowFieldSelector, (function (param) {
                  return false;
                }));
  };
  var onKeyUp = function (v) {
    var match = v.key;
    if (match === "Enter") {
      return Curry._1(onSave, value);
    }
    
  };
  var id = "col-search";
  var onChange = function (v, param) {
    return Curry._1(setValue, v);
  };
  var inputText = error !== undefined ? React.createElement(ReactCore.TextInput, {
          validated: "warning",
          id: id,
          onChange: onChange,
          onKeyUp: onKeyUp,
          type: "text",
          value: value
        }) : React.createElement(ReactCore.TextInput, {
          iconVariant: "search",
          id: id,
          onChange: onChange,
          onKeyUp: onKeyUp,
          type: "text",
          value: value
        });
  var icons = React.createElement(React.Fragment, undefined, showTooltips ? React.createElement(HelpSearch$Web.Tooltip.make, {
              store: store
            }) : null, Prelude$Web.str(" "), React.createElement("a", {
            style: {
              color: "#007bff"
            },
            onClick: (function (param) {
                return Curry._1(setShowFieldSelector, (function (param) {
                              return true;
                            }));
              })
          }, React.createElement(ReactCore.Tooltip, {
                children: React.createElement(ReactIcons.PlusCircleIcon, {}),
                content: Prelude$Web.str("Show query editor modal")
              })), Prelude$Web.str(" "));
  return React.createElement(Prelude$Web.MGrid.make, {
              children: null
            }, React.createElement(Search$FieldSelectorModal, {
                  isOpen: match[0],
                  onClose: appendExpr,
                  store: store
                }), displayApply ? React.createElement(React.Fragment, undefined, React.createElement(Prelude$Web.MGridItemXl10.make, {
                        children: inputText
                      }), React.createElement(Prelude$Web.MGridItemXl2.make, {
                        children: null
                      }, icons, isApplyEnabled !== undefined ? React.createElement(ReactCore.Button, {
                              children: Prelude$Web.str("Apply"),
                              onClick: isApplyEnabled,
                              type: "submit"
                            }) : null)) : React.createElement(React.Fragment, undefined, React.createElement(Prelude$Web.MGridItemXl11.make, {
                        children: inputText
                      }), React.createElement(Prelude$Web.MGridItemXl1.make, {
                        children: icons
                      })));
}

var Bar = {
  make: Search$Bar
};

function Search$OrderSelectorModal$OrderSelector(Props) {
  var fieldName = Props.fieldName;
  var setFieldName = Props.setFieldName;
  var directionValue = Props.directionValue;
  var setDirectionValue = Props.setDirectionValue;
  var directionName = directionValue ? "Descending" : "Ascending";
  return React.createElement(React.Fragment, undefined, React.createElement(Prelude$Web.MSelect.make, {
                  value: fieldName,
                  placeholder: "Pick a field",
                  multi: false,
                  options: {
                    hd: "created_at",
                    tl: {
                      hd: "updated_at",
                      tl: {
                        hd: "repo",
                        tl: {
                          hd: "task.score",
                          tl: /* [] */0
                        }
                      }
                    }
                  },
                  valueChanged: (function (v) {
                      return Curry._1(setFieldName, (function (param) {
                                    return v;
                                  }));
                    })
                }), React.createElement(Prelude$Web.MSelect.make, {
                  value: directionName,
                  placeholder: "Ascending",
                  multi: false,
                  options: {
                    hd: "Ascending",
                    tl: {
                      hd: "Descending",
                      tl: /* [] */0
                    }
                  },
                  valueChanged: (function (v) {
                      return Curry._1(setDirectionValue, (function (param) {
                                    switch (v) {
                                      case "Ascending" :
                                          return /* Asc */0;
                                      case "Descending" :
                                          return /* Desc */1;
                                      default:
                                        return /* Asc */0;
                                    }
                                  }));
                    })
                }));
}

var OrderSelector = {
  make: Search$OrderSelectorModal$OrderSelector
};

function Search$OrderSelectorModal(Props) {
  var isOpen = Props.isOpen;
  var value = Props.value;
  var onClose = Props.onClose;
  var getOrder = function (valueM, selector, defaultValue) {
    return Belt_Option.getWithDefault(Belt_Option.flatMap(valueM, (function (v) {
                      return Caml_option.some(Curry._1(selector, v));
                    })), defaultValue);
  };
  var match = React.useState(function () {
        return getOrder(value, (function (v) {
                      return v.field;
                    }), "");
      });
  var fieldName = match[0];
  var match$1 = React.useState(function () {
        return getOrder(value, (function (v) {
                      return v.direction;
                    }), /* Asc */0);
      });
  var directionValue = match$1[0];
  var onConfirm = function (param) {
    var tmp = fieldName === "" ? undefined : ({
          field: fieldName,
          direction: directionValue
        });
    return Curry._1(onClose, tmp);
  };
  var onCancel = function (param) {
    return Curry._1(onClose, undefined);
  };
  return React.createElement(ReactCore.Modal, {
              children: React.createElement("div", {
                    style: {
                      height: "400px"
                    }
                  }, React.createElement(Search$OrderSelectorModal$OrderSelector, {
                        fieldName: fieldName,
                        setFieldName: match[1],
                        directionValue: directionValue,
                        setDirectionValue: match$1[1]
                      })),
              actions: [
                React.createElement(ReactCore.Button, {
                      children: Prelude$Web.str("Confirm"),
                      onClick: onConfirm,
                      variant: "primary",
                      key: "confirm"
                    }),
                React.createElement(ReactCore.Button, {
                      children: Prelude$Web.str("Cancel"),
                      onClick: onCancel,
                      variant: "link",
                      key: "cancel"
                    })
              ],
              isOpen: isOpen,
              onClose: (function (param) {
                  return Curry._1(onClose, undefined);
                }),
              title: "Order selector",
              variant: "small"
            });
}

var OrderSelectorModal = {
  OrderSelector: OrderSelector,
  make: Search$OrderSelectorModal
};

function Search$Order(Props) {
  var value = Props.value;
  var setValue = Props.setValue;
  var match = React.useState(function () {
        return false;
      });
  var setShowOrderSelector = match[1];
  var setOrder = function (v) {
    Curry._1(setValue, v);
    return Curry._1(setShowOrderSelector, (function (param) {
                  return false;
                }));
  };
  var onClick = function (param) {
    return Curry._1(setShowOrderSelector, (function (param) {
                  return true;
                }));
  };
  var orderToString = function (order) {
    return Prelude$Web.str("by " + order.field + Prelude$Web.orderDirToString(order.direction));
  };
  return React.createElement(React.Fragment, undefined, React.createElement(Search$OrderSelectorModal, {
                  isOpen: match[0],
                  value: value,
                  onClose: setOrder
                }), value !== undefined ? React.createElement("span", undefined, React.createElement(ReactCore.Button, {
                        children: orderToString(value),
                        onClick: onClick,
                        variant: "tertiary"
                      })) : React.createElement(ReactCore.Button, {
                    children: Prelude$Web.str("Set order"),
                    onClick: onClick
                  }));
}

var Order = {
  make: Search$Order
};

function formatError(query, message, position) {
  return message + (
          position === 0 ? "" : " (" + query.slice(position) + ")"
        );
}

function Search$QSErrorModal(Props) {
  var error = Props.error;
  var showErrorModal = Props.showErrorModal;
  var setShowErrorModal = Props.setShowErrorModal;
  var onClose = function (param) {
    return Curry._1(setShowErrorModal, (function (param) {
                  return false;
                }));
  };
  return React.createElement(ReactCore.Modal, {
              children: error !== undefined ? React.createElement(ReactCore.Alert, {
                      title: error,
                      tooltipPosition: "top",
                      variant: "danger"
                    }) : null,
              isOpen: showErrorModal,
              onClose: onClose,
              title: "Query error",
              variant: "large"
            });
}

var QSErrorModal = {
  make: Search$QSErrorModal
};

function Search$Top(Props) {
  var store = Props.store;
  var dispatch = store[1];
  var state = store[0];
  var match = React.useState(function () {
        return state.query;
      });
  var setValue$p = match[1];
  var value = match[0];
  var match$1 = React.useState(function () {
        return state.query;
      });
  var setSavedValue = match$1[1];
  var match$2 = React.useState(function () {
        
      });
  var setError = match$2[1];
  var error = match$2[0];
  var setValue = function (v) {
    return Curry._1(setValue$p, (function (param) {
                  return v;
                }));
  };
  var match$3 = React.useState(function () {
        return false;
      });
  var setShowErrorModal = match$3[1];
  React.useEffect((function () {
          if (state.query !== value) {
            Curry._1(setValue$p, (function (param) {
                    return state.query;
                  }));
            Curry._1(setSavedValue, (function (param) {
                    return state.query;
                  }));
          }
          
        }), [state.query]);
  var onSave = function (newValue) {
    WebApi$Web.Search.check({
            index: state.index,
            username: Belt_Option.getWithDefault(state.username, ""),
            query: value
          }).then(function (param) {
          var match = param.data;
          var tmp;
          if (match.TAG === /* Success */0) {
            Curry._1(setError, (function (param) {
                    
                  }));
            Curry._1(setSavedValue, (function (param) {
                    return newValue;
                  }));
            tmp = Curry._1(dispatch, {
                  TAG: /* SetQuery */1,
                  _0: newValue
                });
          } else {
            var match$1 = match._0;
            var position = match$1.position;
            var message = match$1.message;
            Curry._1(setError, (function (param) {
                    return formatError(newValue, message, position);
                  }));
            tmp = Curry._1(setShowErrorModal, (function (param) {
                    return true;
                  }));
          }
          return Promise.resolve(tmp);
        });
    
  };
  var onClick = function (param) {
    return onSave(value);
  };
  var isApplyEnabled = match$1[0] === value ? undefined : onClick;
  return React.createElement(React.Fragment, {
              children: null
            }, React.createElement(Search$QSErrorModal, {
                  error: error,
                  showErrorModal: match$3[0],
                  setShowErrorModal: setShowErrorModal
                }), React.createElement(Prelude$Web.MGrid.make, {
                  children: React.createElement(Prelude$Web.MCenteredContent.make, {
                        children: React.createElement(Search$Bar, {
                              store: store,
                              value: value,
                              setValue: setValue,
                              onSave: onSave,
                              error: error,
                              displayApply: true,
                              isApplyEnabled: isApplyEnabled
                            })
                      })
                }));
}

var Top = {
  make: Search$Top
};

function Search$Filter(Props) {
  var store = Props.store;
  var dispatch = store[1];
  var state = store[0];
  var match = React.useState(function () {
        return state.order;
      });
  var setOrder = match[1];
  var setValue = function (v) {
    Curry._1(dispatch, {
          TAG: /* SetOrder */4,
          _0: v
        });
    return Curry._1(setOrder, (function (param) {
                  return v;
                }));
  };
  return React.createElement("div", {
              style: {
                whiteSpace: "nowrap",
                width: "1024px"
              }
            }, React.createElement(Search$Order, {
                  value: match[0],
                  setValue: setValue
                }), state.filter === "" ? null : React.createElement(React.Fragment, undefined, React.createElement(ReactCore.Button, {
                        children: Prelude$Web.str("Clear Filter"),
                        onClick: (function (param) {
                            return Curry._1(dispatch, {
                                        TAG: /* SetFilter */2,
                                        _0: ""
                                      });
                          })
                      }), React.createElement(ReactCore.TextInput, {
                        iconVariant: "search",
                        id: "col-filter",
                        isDisabled: true,
                        type: "text",
                        value: state.filter
                      })));
}

var Filter = {
  make: Search$Filter
};

var startWithFieldModalOpen = false;

var startWithOrderModalOpen = false;

exports.startWithFieldModalOpen = startWithFieldModalOpen;
exports.startWithOrderModalOpen = startWithOrderModalOpen;
exports.FieldSelectorModal = FieldSelectorModal;
exports.TextInputUp = TextInputUp;
exports.Bar = Bar;
exports.OrderSelectorModal = OrderSelectorModal;
exports.Order = Order;
exports.formatError = formatError;
exports.QSErrorModal = QSErrorModal;
exports.Top = Top;
exports.Filter = Filter;
/* react Not a pure module */
