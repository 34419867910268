'use strict';

var Js_dict = require("rescript/lib/js/js_dict.js");
var Caml_js_exceptions = require("rescript/lib/js/caml_js_exceptions.js");

import jwt_decode from "jwt-decode";
;

var jwtDecode = (function(token) {
    return jwt_decode(token)
  });

function jwtToAuthenticatedUser(jwt) {
  try {
    var decodedJwt = jwtDecode(jwt);
    return {
            jwt: jwt,
            jwt_exp: new Date(decodedJwt.dat.aAuthUntil * 1000.0),
            defaultMuid: decodedJwt.dat.aDefaultMuid,
            muidMap: decodedJwt.dat.aMuidMap
          };
  }
  catch (raw_err){
    var err = Caml_js_exceptions.internalToOCamlException(raw_err);
    console.log("Unable to decode Monocle JWT due to:", err);
    return ;
  }
}

function getMuidByIndex(au, index) {
  return Js_dict.get(au.muidMap, index);
}

exports.jwtDecode = jwtDecode;
exports.jwtToAuthenticatedUser = jwtToAuthenticatedUser;
exports.getMuidByIndex = getMuidByIndex;
/*  Not a pure module */
