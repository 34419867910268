'use strict';

var Caml_option = require("rescript/lib/js/caml_option.js");

function decode_timestamp(json) {
  return Caml_option.some(new Date(json));
}

function encode_timestamp(v) {
  if (v !== undefined) {
    return Caml_option.valFromOption(v).toJSON();
  } else {
    return "1970-01-01T01:01:01Z";
  }
}

exports.decode_timestamp = decode_timestamp;
exports.encode_timestamp = encode_timestamp;
/* No side effect */
