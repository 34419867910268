'use strict';

var React = require("react");
var Store$Web = require("./Store.bs.js");
var Canvas$Web = require("./Canvas.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Prelude$Web = require("./Prelude.bs.js");
var ReactCore = require("@patternfly/react-core");
var ReactIcons = require("@patternfly/react-icons");

function CommitsReviewsRatio(Props) {
  var store = Props.store;
  var extraQuery = Props.extraQuery;
  var icon = React.createElement(ReactIcons.ResourcesAlmostFullIcon, {});
  var state = store[0];
  var baseRequest = Store$Web.mkSearchRequest(state, /* Query_ratio_commits_vs_reviews */15);
  var request_index = baseRequest.index;
  var request_username = baseRequest.username;
  var request_query = extraQuery !== undefined ? Prelude$Web.addQuery(baseRequest.query, extraQuery) : baseRequest.query;
  var request_query_type = baseRequest.query_type;
  var request_order = baseRequest.order;
  var request_limit = baseRequest.limit;
  var request_change_id = baseRequest.change_id;
  var request = {
    index: request_index,
    username: request_username,
    query: request_query,
    query_type: request_query_type,
    order: request_order,
    limit: request_limit,
    change_id: request_change_id
  };
  var trigger = state.query + Belt_Option.getWithDefault(extraQuery, "");
  var match = function (resp) {
    if (resp.TAG === /* Ratio */11) {
      return resp._0;
    }
    
  };
  var childrenBuilder = function (ratio) {
    var partial_arg = Math.round(ratio);
    return React.createElement(ReactCore.Flex, {
                children: null
              }, React.createElement(ReactCore.FlexItem, {
                    children: Prelude$Web.str("Commits")
                  }), React.createElement(ReactCore.FlexItem, {
                    children: React.createElement(Canvas$Web.Dom.make, {
                          width: 200,
                          height: 20,
                          onDraw: (function (param, param$1, param$2) {
                              return Canvas$Web.drawScale(partial_arg, param, param$1, param$2);
                            })
                        })
                  }), React.createElement(ReactCore.FlexItem, {
                    children: Prelude$Web.str("Reviews")
                  }));
  };
  return React.createElement(Prelude$Web.QueryRenderCard.make, {
              request: request,
              trigger: trigger,
              title: "Commits VS Reviews Ratio",
              tooltip_content: "This displays a ratio that show the amount of code review activities compared to the commit activities. When it tends to the left it means more commits than code review is performed.",
              icon: icon,
              match: match,
              childrenBuilder: childrenBuilder
            });
}

var make = CommitsReviewsRatio;

var $$default = CommitsReviewsRatio;

exports.make = make;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
/* react Not a pure module */
