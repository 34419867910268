'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var MLink$Web = require("./MLink.bs.js");
var Store$Web = require("./Store.bs.js");
var Prelude$Web = require("./Prelude.bs.js");
var ReactCore = require("@patternfly/react-core");
var ReactIcons = require("@patternfly/react-icons");

function cmp(s, x, y) {
  return Caml_obj.caml_lessthan(Curry._1(s, x), Curry._1(s, y));
}

function ProjectsView$ProjectsSummaryTable(Props) {
  var store = Props.store;
  var projects = Props.projects;
  var columnNames = [
    "Name",
    "Repository",
    "Branch",
    "File"
  ];
  var isOrdered = function (first, second, index) {
    switch (index) {
      case 0 :
          return cmp((function (x) {
                        return x.name;
                      }), first, second);
      case 1 :
          return cmp((function (x) {
                        return x.repository_regex;
                      }), first, second);
      case 2 :
          return cmp((function (x) {
                        return x.branch_regex;
                      }), first, second);
      case 3 :
          return cmp((function (x) {
                        return x.file_regex;
                      }), first, second);
      default:
        return false;
    }
  };
  var formatters_0 = function (project) {
    return React.createElement(MLink$Web.MonoLink.make, {
                store: store,
                filter: "project:" + project.name,
                path: "changes",
                name: project.name
              });
  };
  var formatters_1 = {
    hd: (function (project) {
        return Prelude$Web.str(project.repository_regex);
      }),
    tl: {
      hd: (function (project) {
          return Prelude$Web.str(project.branch_regex);
        }),
      tl: {
        hd: (function (project) {
            return Prelude$Web.str(project.file_regex);
          }),
        tl: /* [] */0
      }
    }
  };
  var formatters = {
    hd: formatters_0,
    tl: formatters_1
  };
  return React.createElement(Prelude$Web.SortableTable.make, {
              items: projects,
              defaultSortedColumn: 2,
              columnNames: columnNames,
              isOrdered: isOrdered,
              formatters: formatters
            });
}

var ProjectsSummaryTable = {
  make: ProjectsView$ProjectsSummaryTable
};

function ProjectsView(Props) {
  var store = Props.store;
  var icon = React.createElement(ReactIcons.ProjectIcon, {});
  var match = Store$Web.Fetch.projects(store);
  if (match === undefined) {
    return React.createElement(ReactCore.Spinner, {});
  }
  if (match.TAG !== /* Ok */0) {
    return React.createElement(ReactCore.Alert, {
                title: match._0,
                variant: "danger"
              });
  }
  var projects = match._0.projects;
  if (projects) {
    return React.createElement(Prelude$Web.MCenteredContent.make, {
                children: React.createElement(Prelude$Web.MonoCard.make, {
                      title: "Projects",
                      tooltip_content: "This shows the list of defined projects. A project is composed of multiple repositories and can also be set on a specific branch.",
                      icon: icon,
                      children: React.createElement(ProjectsView$ProjectsSummaryTable, {
                            store: store,
                            projects: projects
                          })
                    })
              });
  } else {
    return React.createElement(ReactCore.Alert, {
                title: "Please define projects.",
                variant: "warning"
              });
  }
}

var make = ProjectsView;

var $$default = ProjectsView;

exports.cmp = cmp;
exports.ProjectsSummaryTable = ProjectsSummaryTable;
exports.make = make;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
/* react Not a pure module */
