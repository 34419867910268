'use strict';

var Pbrt_bs = require("bs-ocaml-protoc-json/src/pbrt_bs.bs.js");

function decode_login_validation_request(json) {
  var v = "";
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    if (match === "username") {
      var json$1 = json["username"];
      v = Pbrt_bs.string(json$1, "login_validation_request", "username");
    }
    
  }
  return {
          username: v
        };
}

function decode_login_validation_response_validation_result(json) {
  var match = Pbrt_bs.string(json, "login_validation_response_validation_result", "value");
  switch (match) {
    case "KnownIdent" :
        return /* Known_ident */1;
    case "" :
    case "UnknownIdent" :
        return /* Unknown_ident */0;
    default:
      return Pbrt_bs.E.malformed_variant("login_validation_response_validation_result");
  }
}

function decode_login_validation_response(json) {
  var keys = Object.keys(json);
  var _i = keys.length - 1 | 0;
  while(true) {
    var i = _i;
    if (i === -1) {
      return Pbrt_bs.E.malformed_variant("login_validation_response");
    }
    var match = keys[i];
    if (match === "validation_result") {
      var json$1 = json["validation_result"];
      return /* Validation_result */{
              _0: decode_login_validation_response_validation_result(json$1)
            };
    }
    _i = i - 1 | 0;
    continue ;
  };
}

function encode_login_validation_request(v) {
  var json = {};
  json["username"] = v.username;
  return json;
}

function encode_login_validation_response_validation_result(v) {
  if (v) {
    return "KnownIdent";
  } else {
    return "UnknownIdent";
  }
}

function encode_login_validation_response(v) {
  var json = {};
  json["validation_result"] = v._0 ? "KnownIdent" : "UnknownIdent";
  return json;
}

exports.encode_login_validation_request = encode_login_validation_request;
exports.encode_login_validation_response_validation_result = encode_login_validation_response_validation_result;
exports.encode_login_validation_response = encode_login_validation_response;
exports.decode_login_validation_request = decode_login_validation_request;
exports.decode_login_validation_response_validation_result = decode_login_validation_response_validation_result;
exports.decode_login_validation_response = decode_login_validation_response;
/* Pbrt_bs Not a pure module */
