'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Store$Web = require("./Store.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Change$Web = require("./Change.bs.js");
var Search$Web = require("./Search.bs.js");
var WebApi$Web = require("./WebApi.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Prelude$Web = require("./Prelude.bs.js");
var HiddenChanges$Web = require("./HiddenChanges.bs.js");
var MaskedChanges$Web = require("./MaskedChanges.bs.js");
var PinnedChanges$Web = require("./PinnedChanges.bs.js");
var VisibleChanges$Web = require("./VisibleChanges.bs.js");
var ReactCore = require("@patternfly/react-core");
var ReactIcons = require("@patternfly/react-icons");

var noChangeFound = React.createElement("tr", {
      role: "row"
    }, React.createElement("td", {
          role: "cell"
        }, React.createElement("p", undefined, Prelude$Web.str("No change found"))));

function Board$Column$Row$RView(Props) {
  var store = Props.store;
  var changesAll = Props.changesAll;
  var isChangeVisible = Props.isChangeVisible;
  var state = store[0];
  var match = HiddenChanges$Web.use(state.dexie, changesAll);
  var hiddenDispatchChange = match[1];
  var hiddenChanges = match[0];
  var match$1 = PinnedChanges$Web.use(state.dexie, changesAll);
  var pinnedDispatchChange = match$1[1];
  var pinnedChanges = match$1[0];
  var match$2 = MaskedChanges$Web.use(state.dexie, changesAll);
  var maskedDispatchChange = match$2[1];
  var maskedChanges = match$2[0];
  var match$3 = hiddenChanges.length;
  if (match$3 !== 0) {
    return Belt_Array.map(hiddenChanges, (function (param) {
                  var change = param[1];
                  var hiddenStatus = param[0];
                  var maskState = MaskedChanges$Web.simpleGetStatus(maskedChanges, change);
                  var match = Curry._2(isChangeVisible, hiddenStatus, maskState);
                  if (match) {
                    return React.createElement(Change$Web.RowItem.make, {
                                store: store,
                                change: change,
                                hiddenStatus: hiddenStatus,
                                hiddenDispatchChange: hiddenDispatchChange,
                                pinnedStatus: PinnedChanges$Web.simpleGetStatus(pinnedChanges, change),
                                pinnedDispatchChange: pinnedDispatchChange,
                                maskedStatus: MaskedChanges$Web.simpleGetStatus(maskedChanges, change),
                                maskedDispatchChange: maskedDispatchChange,
                                key: change.url
                              });
                  } else {
                    return null;
                  }
                }));
  } else {
    return noChangeFound;
  }
}

var RView = {
  make: Board$Column$Row$RView
};

function Board$Column$Row(Props) {
  var store = Props.store;
  var column = Props.column;
  var isChangeVisible = Props.isChangeVisible;
  var state = store[0];
  var match = React.useState(function () {
        
      });
  var setResult = match[1];
  var result = match[0];
  var handleOk = function (resp) {
    return Promise.resolve(Curry._1(setResult, (function (param) {
                      return resp.data;
                    })));
  };
  var query = Prelude$Web.addQuery(column.query, state.query);
  React.useEffect((function () {
          if (query !== "") {
            var init = Store$Web.mkSearchRequest(state, /* Query_change */0);
            WebApi$Web.Search.query({
                    index: init.index,
                    username: init.username,
                    query: query,
                    query_type: init.query_type,
                    order: column.order,
                    limit: init.limit,
                    change_id: init.change_id
                  }).then(handleOk);
          }
          
        }), [
        query,
        Prelude$Web.orderToQS(column.order),
        String(state.limit)
      ]);
  if (result === undefined) {
    return null;
  }
  switch (result.TAG | 0) {
    case /* Error */0 :
        var err = result._0;
        return React.createElement(ReactCore.Alert, {
                    title: err.message + " at " + String(err.position),
                    variant: "danger"
                  });
    case /* Changes */1 :
        return React.createElement(Board$Column$Row$RView, {
                    store: store,
                    changesAll: Belt_List.toArray(result._0.changes),
                    isChangeVisible: isChangeVisible
                  });
    default:
      return null;
  }
}

var Row = {
  RView: RView,
  make: Board$Column$Row
};

function Board$Column$CView(Props) {
  var store = Props.store;
  var changesAll = Props.changesAll;
  var isChangeVisible = Props.isChangeVisible;
  var state = store[0];
  var match = HiddenChanges$Web.use(state.dexie, changesAll);
  var hiddenDispatchChange = match[1];
  var hiddenChanges = match[0];
  var match$1 = PinnedChanges$Web.use(state.dexie, changesAll);
  var pinnedDispatchChange = match$1[1];
  var pinnedChanges = match$1[0];
  var match$2 = MaskedChanges$Web.use(state.dexie, changesAll);
  var maskedDispatchChange = match$2[1];
  var maskedChanges = match$2[0];
  var match$3 = hiddenChanges.length;
  if (match$3 !== 0) {
    return React.createElement(ReactCore.DataList, {
                children: Belt_Array.map(hiddenChanges, (function (param) {
                        var change = param[1];
                        var status = param[0];
                        var maskedState = MaskedChanges$Web.simpleGetStatus(maskedChanges, change);
                        if (Curry._2(isChangeVisible, status, maskedState)) {
                          return React.createElement(Change$Web.DataItem.make, {
                                      store: store,
                                      change: change,
                                      hiddenStatus: status,
                                      hiddenDispatchChange: hiddenDispatchChange,
                                      pinnedStatus: PinnedChanges$Web.simpleGetStatus(pinnedChanges, change),
                                      pinnedDispatchChange: pinnedDispatchChange,
                                      maskedStatus: MaskedChanges$Web.simpleGetStatus(maskedChanges, change),
                                      maskedDispatchChange: maskedDispatchChange,
                                      key: change.url
                                    });
                        } else {
                          return null;
                        }
                      })),
                isCompact: true
              });
  } else {
    return noChangeFound;
  }
}

var CView = {
  make: Board$Column$CView
};

function Board$Column(Props) {
  var store = Props.store;
  var column = Props.column;
  var isChangeVisible = Props.isChangeVisible;
  var state = store[0];
  var match = React.useState(function () {
        
      });
  var setResult = match[1];
  var result = match[0];
  var handleOk = function (resp) {
    return Promise.resolve(Curry._1(setResult, (function (param) {
                      return resp.data;
                    })));
  };
  var query = Prelude$Web.addQuery(column.query, state.query);
  React.useEffect((function () {
          if (query !== "") {
            var init = Store$Web.mkSearchRequest(state, /* Query_change */0);
            WebApi$Web.Search.query({
                    index: init.index,
                    username: init.username,
                    query: query,
                    query_type: init.query_type,
                    order: column.order,
                    limit: init.limit,
                    change_id: init.change_id
                  }).then(handleOk);
          }
          
        }), [
        query,
        Prelude$Web.orderToQS(column.order),
        String(state.limit)
      ]);
  var tmp;
  if (result !== undefined) {
    switch (result.TAG | 0) {
      case /* Error */0 :
          var err = result._0;
          tmp = React.createElement(ReactCore.Alert, {
                title: err.message + " at " + String(err.position),
                variant: "danger"
              });
          break;
      case /* Changes */1 :
          tmp = React.createElement(Board$Column$CView, {
                store: store,
                changesAll: Belt_List.toArray(result._0.changes),
                isChangeVisible: isChangeVisible
              });
          break;
      default:
        tmp = null;
    }
  } else {
    tmp = null;
  }
  return React.createElement(ReactCore.Card, {
              children: null
            }, React.createElement(ReactCore.CardHeader, {
                  children: Prelude$Web.str(column.name)
                }), React.createElement(ReactCore.CardBody, {
                  children: tmp
                }));
}

function mk(str) {
  return {
          name: str,
          query: "",
          order: undefined
        };
}

var Column = {
  noChangeFound: noChangeFound,
  Row: Row,
  CView: CView,
  make: Board$Column,
  mk: mk
};

function Board$ColumnEditor(Props) {
  var store = Props.store;
  var pos = Props.pos;
  var count = Props.count;
  var nameRef = Props.nameRef;
  var queryRef = Props.queryRef;
  var orderRef = Props.orderRef;
  var onRemove = Props.onRemove;
  var onSwap = Props.onSwap;
  var match = React.useState(function () {
        return 0;
      });
  var doRender = match[1];
  var setAndRender = function (r, v) {
    r.contents = v;
    return Curry._1(doRender, (function (x) {
                  return x + 1 | 0;
                }));
  };
  var setName = function (v, param) {
    return setAndRender(nameRef, v);
  };
  return React.createElement(Prelude$Web.MGrid.make, {
              children: null
            }, React.createElement(Prelude$Web.MGridItemXl1.make, {
                  children: Prelude$Web.maybeRender(count > 1, React.createElement(React.Fragment, undefined, React.createElement(ReactCore.Tooltip, {
                                children: React.createElement(ReactCore.Button, {
                                      children: React.createElement(ReactIcons.TrashIcon, {}),
                                      isSmall: true,
                                      onClick: (function (param) {
                                          return Curry._1(onRemove, pos);
                                        }),
                                      variant: "danger"
                                    }),
                                content: "Remove"
                              }), Prelude$Web.maybeRender((pos + 1 | 0) < count, React.createElement(ReactCore.Tooltip, {
                                    children: React.createElement(ReactCore.Button, {
                                          children: React.createElement(ReactIcons.ArrowsAltVIcon, {}),
                                          isSmall: true,
                                          onClick: (function (param) {
                                              return Curry._1(onSwap, pos);
                                            }),
                                          variant: "control"
                                        }),
                                    content: Prelude$Web.str("Swap")
                                  }))))
                }), React.createElement(Prelude$Web.MGridItemXl2.make, {
                  children: React.createElement(ReactCore.TextInput, {
                        id: "col-name",
                        onChange: setName,
                        type: "text",
                        value: nameRef.contents
                      })
                }), React.createElement(Prelude$Web.MGridItemXl7.make, {
                  children: React.createElement(Search$Web.Bar.make, {
                        store: store,
                        value: queryRef.contents,
                        setValue: (function (v) {
                            return setAndRender(queryRef, v);
                          }),
                        onSave: (function (v) {
                            return setAndRender(queryRef, v);
                          }),
                        showTooltips: false,
                        error: undefined,
                        displayApply: false,
                        isApplyEnabled: undefined
                      })
                }), React.createElement(Prelude$Web.MGridItemXl2.make, {
                  children: React.createElement(Search$Web.Order.make, {
                        value: orderRef.contents,
                        setValue: (function (v) {
                            return setAndRender(orderRef, v);
                          })
                      })
                }));
}

var ColumnEditor = {
  make: Board$ColumnEditor
};

var defaultNegativeApprovals = "(approval:CHANGE_REQUESTED or approval:Verified-1 or approval:Workflow-1 or approval:Code-Review-1 or approval:Code-Review-2)";

var defaultPositiveApprovals = "(approval:APPROVED or approval:Code-Review+1 or approval:Code-Review+2)";

function mkOrder(field, dirM) {
  return {
          field: field,
          direction: Belt_Option.getWithDefault(dirM, /* Asc */0)
        };
}

var default_columns = {
  hd: {
    name: "To Review",
    query: "state:open and updated_at>now-3week  and not (approval:CHANGE_REQUESTED or approval:Verified-1 or approval:Workflow-1 or approval:Code-Review-1 or approval:Code-Review-2)",
    order: mkOrder("created_at", undefined)
  },
  tl: {
    hd: {
      name: "To Approve",
      query: "state:open and updated_at>now-3week and not (approval:CHANGE_REQUESTED or approval:Verified-1 or approval:Workflow-1 or approval:Code-Review-1 or approval:Code-Review-2)and (approval:APPROVED or approval:Code-Review+1 or approval:Code-Review+2)",
      order: mkOrder("created_at", undefined)
    },
    tl: {
      hd: {
        name: "Oldies",
        query: "state:open and updated_at<now-3week",
        order: mkOrder("updated_at", /* Desc */1)
      },
      tl: {
        hd: {
          name: "Done",
          query: "state:merged and updated_at>now-1week",
          order: mkOrder("updated_at", /* Desc */1)
        },
        tl: /* [] */0
      }
    }
  }
};

var $$default = {
  title: "Reviewer Board",
  columns: default_columns,
  style: /* Table */1
};

var userColumn = {
  name: "My changes",
  query: "state:open and author:self",
  order: undefined
};

function columnsArray(board) {
  var arr = Belt_List.toArray(board.columns);
  return [
          arr,
          arr.length
        ];
}

function orderToQS(order) {
  var match = order.direction;
  return order.field + "#" + (
          match ? "D" : "A"
        );
}

function saveToUrl(board, query) {
  Prelude$Web.resetLocationSearch(undefined);
  if (board.style === /* Kanban */0) {
    Prelude$Web.setLocationSearch("s", "kanban");
  }
  Prelude$Web.setLocationSearch("t", board.title);
  Prelude$Web.setLocationSearch("q", query);
  Belt_List.mapWithIndex(board.columns, (function (index, column) {
          var posStr = String(index);
          Prelude$Web.setLocationSearch("n" + posStr, column.name);
          Prelude$Web.setLocationSearch("q" + posStr, column.query);
          var order = column.order;
          if (order !== undefined) {
            Prelude$Web.setLocationSearch("o" + posStr, orderToQS(order));
            return ;
          }
          
        }));
  return board;
}

var addUserColumn_columns = Belt_List.add(default_columns, userColumn);

var addUserColumn = {
  title: "Reviewer Board",
  columns: addUserColumn_columns,
  style: /* Table */1
};

function loadFromUrl(store) {
  var state = store[0];
  var params = Prelude$Web.$$URLSearchParams.current(undefined);
  var go = function (pos) {
    var posStr = String(pos);
    var match = params.get("n" + posStr);
    var match$1 = params.get("q" + posStr);
    var match$2 = params.get("o" + posStr);
    if (!(match == null) && !(match$1 == null)) {
      return Belt_List.add(go(pos + 1 | 0), {
                  name: (match == null) ? undefined : Caml_option.some(match),
                  query: (match$1 == null) ? undefined : Caml_option.some(match$1),
                  order: Belt_Option.flatMap((match$2 == null) ? undefined : Caml_option.some(match$2), Prelude$Web.orderFromQS)
                });
    } else {
      return /* [] */0;
    }
  };
  var match = params.get("s");
  var style = (match == null) || (
    (match == null) ? undefined : Caml_option.some(match)
  ) !== "kanban" ? /* Table */1 : /* Kanban */0;
  var match$1 = params.get("t");
  var match$2 = go(0);
  if (!(match$1 == null) && match$2) {
    return {
            title: (match$1 == null) ? undefined : Caml_option.some(match$1),
            columns: match$2,
            style: style
          };
  }
  var match$3 = state.username;
  var match$4 = state.authenticated_user;
  if (match$3 !== undefined || match$4 !== undefined) {
    return addUserColumn;
  } else {
    return $$default;
  }
}

function reducer(board, action) {
  if (typeof action === "number") {
    var newName = "Column #" + String(Belt_List.length(board.columns) + 1 | 0);
    return {
            title: board.title,
            columns: Belt_List.concat(board.columns, {
                  hd: {
                    name: newName,
                    query: "",
                    order: undefined
                  },
                  tl: /* [] */0
                }),
            style: board.style
          };
  }
  switch (action.TAG | 0) {
    case /* RemoveColumn */0 :
        var pos = action._0;
        return {
                title: board.title,
                columns: Belt_List.keepWithIndex(board.columns, (function (param, index) {
                        return index !== pos;
                      })),
                style: board.style
              };
    case /* SwapColumn */1 :
        var pos$1 = action._0;
        var swap = function (_l, pos, _index, _ret) {
          while(true) {
            var ret = _ret;
            var index = _index;
            var l = _l;
            if (!l) {
              return l;
            }
            var xs = l.tl;
            var x = l.hd;
            if (pos !== index) {
              if (ret !== undefined) {
                return Belt_List.add(Belt_List.add(swap(xs, pos, index + 1 | 0, undefined), ret), x);
              } else {
                return Belt_List.add(swap(xs, pos, index + 1 | 0, undefined), x);
              }
            }
            _ret = x;
            _index = index + 1 | 0;
            _l = xs;
            continue ;
          };
        };
        var columns = (pos$1 + 1 | 0) < Belt_List.length(board.columns) ? swap(board.columns, pos$1, 0, undefined) : board.columns;
        return {
                title: board.title,
                columns: columns,
                style: board.style
              };
    case /* SetStyle */2 :
        return {
                title: board.title,
                columns: board.columns,
                style: action._0
              };
    case /* Save */3 :
        return saveToUrl({
                    title: action._1,
                    columns: Belt_List.fromArray(Belt_Array.map(action._2, (function (param) {
                                return {
                                        name: param[0].contents,
                                        query: param[1].contents,
                                        order: param[2].contents
                                      };
                              }))),
                    style: board.style
                  }, action._0);
    
  }
}

function use(state) {
  return React.useReducer(reducer, loadFromUrl(state));
}

function Board$Board$Editor(Props) {
  var store = Props.store;
  var board = Props.board;
  var columns = Props.columns;
  var dispatch = Props.dispatch;
  var state = store[0];
  var match = React.useState(function () {
        return false;
      });
  var setShowColumnEditor = match[1];
  var showColumnEditor = match[0];
  var match$1 = React.useState(function () {
        return board.title;
      });
  var setTitle = match$1[1];
  var title = match$1[0];
  var columnsCount = columns.length;
  var columnsRefs = Belt_Array.map(columns, (function (column) {
          return [
                  {
                    contents: column.name
                  },
                  {
                    contents: column.query
                  },
                  {
                    contents: column.order
                  }
                ];
        }));
  var match$2 = React.useState(function () {
        return board.style === /* Table */1;
      });
  var setStyle = match$2[1];
  var doSave = function (param) {
    return Curry._1(dispatch, {
                TAG: /* Save */3,
                _0: state.query,
                _1: title,
                _2: columnsRefs
              });
  };
  var onRemove = function (pos) {
    doSave(undefined);
    return Curry._1(dispatch, {
                TAG: /* RemoveColumn */0,
                _0: pos
              });
  };
  var onSwap = function (pos) {
    doSave(undefined);
    return Curry._1(dispatch, {
                TAG: /* SwapColumn */1,
                _0: pos
              });
  };
  var topRow = React.createElement(Prelude$Web.MGrid.make, {
        children: null
      }, React.createElement(Prelude$Web.MGridItemXl6.make, {
            children: React.createElement(ReactCore.TextInput, {
                  id: "board-name",
                  isDisabled: !showColumnEditor,
                  onChange: (function (v, param) {
                      return Curry._1(setTitle, (function (param) {
                                    return v;
                                  }));
                    }),
                  type: "text",
                  value: title
                })
          }), React.createElement(Prelude$Web.MGridItemXl3.make, {
            children: React.createElement(ReactCore.Button, {
                  children: showColumnEditor ? Prelude$Web.str("Switch board style") : Prelude$Web.str("Board mode: " + (
                          match$2[0] ? "Row" : "Kanban"
                        )),
                  isDisabled: !showColumnEditor,
                  onClick: (function (param) {
                      Curry._1(setStyle, (function (param) {
                              return board.style !== /* Table */1;
                            }));
                      var match = board.style;
                      return Curry._1(dispatch, {
                                  TAG: /* SetStyle */2,
                                  _0: match ? /* Kanban */0 : /* Table */1
                                });
                    }),
                  variant: "tertiary"
                })
          }), showColumnEditor ? React.createElement(Prelude$Web.MGridItemXl3.make, {
              children: React.createElement(ReactCore.Button, {
                    children: Prelude$Web.str("Save"),
                    onClick: (function (param) {
                        doSave(undefined);
                        return Curry._1(setShowColumnEditor, (function (param) {
                                      return false;
                                    }));
                      }),
                    type: "submit"
                  })
            }) : React.createElement(Prelude$Web.MGridItemXl3.make, {
              children: React.createElement(ReactCore.Button, {
                    children: Prelude$Web.str("Edit"),
                    onClick: (function (param) {
                        return Curry._1(setShowColumnEditor, (function (param) {
                                      return true;
                                    }));
                      }),
                    variant: "tertiary"
                  })
            }));
  var match$3 = board.style;
  var bottomRow = React.createElement(ReactCore.Button, {
        children: match$3 ? Prelude$Web.str("Add Row") : Prelude$Web.str("Add Column"),
        onClick: (function (param) {
            doSave(undefined);
            return Curry._1(dispatch, /* AddColumn */0);
          })
      });
  var columnsEditor = showColumnEditor ? React.createElement(Prelude$Web.MStack.make, {
          children: null
        }, React.createElement(Prelude$Web.MStackItem.make, {
              children: Belt_Array.mapWithIndex(columnsRefs, (function (pos, param) {
                      var nameRef = param[0];
                      return React.createElement(Board$ColumnEditor, {
                                  store: store,
                                  pos: pos,
                                  count: columnsCount,
                                  nameRef: nameRef,
                                  queryRef: param[1],
                                  orderRef: param[2],
                                  onRemove: onRemove,
                                  onSwap: onSwap,
                                  key: nameRef.contents + String(pos)
                                });
                    }))
            }), React.createElement(Prelude$Web.MStackItem.make, {
              children: bottomRow
            })) : null;
  return React.createElement(Prelude$Web.MCenteredContent.make, {
              children: React.createElement(Prelude$Web.MStack.make, {
                    children: null
                  }, React.createElement(Prelude$Web.MStackItem.make, {
                        children: React.createElement(ReactCore.Bullseye, {
                              children: topRow
                            })
                      }), React.createElement(Prelude$Web.MStackItem.make, {
                        children: columnsEditor
                      }))
            });
}

var Editor = {
  make: Board$Board$Editor
};

var Board = {
  defaultNegativeApprovals: defaultNegativeApprovals,
  defaultPositiveApprovals: defaultPositiveApprovals,
  mkOrder: mkOrder,
  $$default: $$default,
  userColumn: userColumn,
  columnsArray: columnsArray,
  orderToQS: orderToQS,
  saveToUrl: saveToUrl,
  addUserColumn: addUserColumn,
  loadFromUrl: loadFromUrl,
  reducer: reducer,
  use: use,
  Editor: Editor
};

function Board$1(Props) {
  var store = Props.store;
  var match = React.useReducer(reducer, loadFromUrl(store));
  var board = match[0];
  var columns = Belt_List.toArray(board.columns);
  var editor = React.createElement(Board$Board$Editor, {
        store: store,
        board: board,
        columns: columns,
        dispatch: match[1]
      });
  var match$1 = VisibleChanges$Web.use(undefined);
  var isChangeVisible = match$1[1];
  var match$2 = board.style;
  var board$1 = match$2 ? React.createElement("table", {
          className: "pf-c-table pf-m-compact pf-m-grid-md",
          role: "grid"
        }, React.createElement(Change$Web.RowItem.Head.make, {}), Belt_Array.mapWithIndex(columns, (function (pos, column) {
                return React.createElement(React.Fragment, {
                            children: React.createElement("tbody", undefined, React.createElement("tr", undefined, React.createElement("td", {
                                          colSpan: 42
                                        }, pos > 0 ? React.createElement("br", undefined) : null, React.createElement("b", undefined, Prelude$Web.str(column.name)), React.createElement("i", undefined, Prelude$Web.str(" : " + column.query)))), React.createElement(Board$Column$Row, {
                                      store: store,
                                      column: column,
                                      isChangeVisible: isChangeVisible
                                    })),
                            key: column.name + String(pos)
                          });
              }))) : React.createElement(ReactCore.Split, {
          children: Belt_Array.mapWithIndex(columns, (function (pos, column) {
                  return React.createElement(ReactCore.SplitItem, {
                              children: React.createElement(Board$Column, {
                                    store: store,
                                    column: column,
                                    isChangeVisible: isChangeVisible
                                  }),
                              key: column.name + String(pos)
                            });
                })),
          hasGutter: true
        });
  return React.createElement(Prelude$Web.MStack.make, {
              children: null
            }, React.createElement(Prelude$Web.MStackItem.make, {
                  children: editor
                }), React.createElement(Prelude$Web.MStackItem.make, {
                  children: null
                }, match$1[0], React.createElement("span", {
                      style: {
                        overflowX: "scroll"
                      }
                    }, board$1)));
}

var startWithEditorOpen = false;

var make = Board$1;

var $$default$1 = Board$1;

exports.startWithEditorOpen = startWithEditorOpen;
exports.Column = Column;
exports.ColumnEditor = ColumnEditor;
exports.Board = Board;
exports.make = make;
exports.$$default = $$default$1;
exports.default = $$default$1;
exports.__esModule = true;
/* noChangeFound Not a pure module */
