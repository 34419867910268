'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Jwt$Web = require("./Jwt.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Board$Web = require("./components/Board.bs.js");
var Store$Web = require("./components/Store.bs.js");
var Metric$Web = require("./components/Metric.bs.js");
var Search$Web = require("./components/Search.bs.js");
var WebApi$Web = require("./components/WebApi.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Indices$Web = require("./components/Indices.bs.js");
var Metrics$Web = require("./components/Metrics.bs.js");
var Prelude$Web = require("./components/Prelude.bs.js");
var Activity$Web = require("./components/Activity.bs.js");
var GroupView$Web = require("./components/GroupView.bs.js");
var ReposView$Web = require("./components/ReposView.bs.js");
var ChangeView$Web = require("./components/ChangeView.bs.js");
var GroupsView$Web = require("./components/GroupsView.bs.js");
var HelpSearch$Web = require("./components/HelpSearch.bs.js");
var ScopedView$Web = require("./components/ScopedView.bs.js");
var NChangeView$Web = require("./components/NChangeView.bs.js");
var AuthorSearch$Web = require("./components/AuthorSearch.bs.js");
var ProjectsView$Web = require("./components/ProjectsView.bs.js");
var LocalSettings$Web = require("./components/LocalSettings.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var ActivePeopleView$Web = require("./components/ActivePeopleView.bs.js");
var PeersStrengthView$Web = require("./components/PeersStrengthView.bs.js");
var ReactCore = require("@patternfly/react-core");
var ReactIcons = require("@patternfly/react-icons");
var NewContributorsView$Web = require("./components/NewContributorsView.bs.js");

function App$MonocleNav(Props) {
  var active = Props.active;
  var store = Props.store;
  var state = store[0];
  var navItem = function (name, dest) {
    var q = state.query;
    var tmp = q === "" ? /* [] */0 : ({
          hd: "q=" + q,
          tl: /* [] */0
        });
    var tmp$1;
    if (dest === "/changes") {
      var match = state.order;
      tmp$1 = match !== undefined ? ({
            hd: "o=" + Prelude$Web.orderToQS(state.order),
            tl: /* [] */0
          }) : /* [] */0;
    } else {
      tmp$1 = /* [] */0;
    }
    var query = Belt_List.concatMany([
          tmp,
          tmp$1
        ]);
    var navUrl = "/" + state.index + dest + (
      query ? "?" + Prelude$Web.concatSep(query, "&") : ""
    );
    return React.createElement(ReactCore.NavItem, {
                children: Prelude$Web.str(name),
                isActive: active === dest,
                onClick: (function (param) {
                    return RescriptReactRouter.push(navUrl);
                  }),
                preventDefault: true,
                to: navUrl,
                key: name
              });
  };
  var navGroup = function (title, xs) {
    return React.createElement(ReactCore.NavGroup, {
                children: Belt_List.toArray(Belt_List.map(xs, (function (param) {
                            return navItem(param[0], param[1]);
                          }))),
                title: title,
                key: title
              });
  };
  return React.createElement(ReactCore.Nav, {
              children: React.createElement(ReactCore.NavList, {
                    children: [
                      navItem("Activity", "/"),
                      navGroup("Changes", {
                            hd: [
                              "Review Board",
                              "/board"
                            ],
                            tl: {
                              hd: [
                                "Browse Changes",
                                "/changes"
                              ],
                              tl: /* [] */0
                            }
                          }),
                      navGroup("Projects", {
                            hd: [
                              "Projects",
                              "/projects"
                            ],
                            tl: {
                              hd: [
                                "Repositories",
                                "/repos"
                              ],
                              tl: /* [] */0
                            }
                          }),
                      navGroup("People", {
                            hd: [
                              "Search authors",
                              "/search_author"
                            ],
                            tl: {
                              hd: [
                                "Active authors",
                                "/active_authors"
                              ],
                              tl: {
                                hd: [
                                  "Peers strength",
                                  "/peers_strength"
                                ],
                                tl: {
                                  hd: [
                                    "New contributors",
                                    "/new_authors"
                                  ],
                                  tl: {
                                    hd: [
                                      "Groups",
                                      "/user_groups"
                                    ],
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }),
                      navGroup("Metrics", {
                            hd: [
                              "Catalog",
                              "/metrics"
                            ],
                            tl: /* [] */0
                          })
                    ]
                  })
            });
}

var MonocleNav = {
  make: App$MonocleNav
};

function isAuthEnforced(state) {
  var auth = state.about.auth;
  var auth$1 = auth._0;
  if (auth$1.provider_name === "") {
    return false;
  } else {
    return auth$1.force_login;
  }
}

function App$Login$Validator(Props) {
  var store = Props.store;
  var usernameValidated = Props.usernameValidated;
  var setShowLoginModal = Props.setShowLoginModal;
  var get = function (param) {
    return WebApi$Web.Login.loginValidation({
                username: usernameValidated
              });
  };
  if (usernameValidated.length === 0) {
    return null;
  }
  var resp = Prelude$Web.useAutoGetOn(get, usernameValidated);
  if (typeof resp === "number") {
    return null;
  }
  switch (resp.TAG | 0) {
    case /* Loading */0 :
        return React.createElement(ReactCore.Spinner, {});
    case /* Loaded */1 :
        if (resp._0._0) {
          Curry._1(store[1], {
                TAG: /* NonAuthenticatedLogin */11,
                _0: usernameValidated
              });
          Curry._1(setShowLoginModal, (function (param) {
                  return false;
                }));
          return null;
        } else {
          return React.createElement(ReactCore.Alert, {
                      title: "Author identity not found",
                      variant: "warning"
                    });
        }
    case /* Failure */2 :
        return React.createElement(ReactCore.Alert, {
                    title: "Unable to verify author identity :(",
                    variant: "danger"
                  });
    
  }
}

var Validator = {
  make: App$Login$Validator
};

function App$Login$NonAuthenticatedLoginModal(Props) {
  var store = Props.store;
  var setShowLoginModal = Props.setShowLoginModal;
  var match = React.useState(function () {
        return "";
      });
  var setUsername = match[1];
  var username = match[0];
  var match$1 = React.useState(function () {
        return "";
      });
  var setUsernameValidated = match$1[1];
  var onChange = function (value, param) {
    return Curry._1(setUsername, (function (param) {
                  return value;
                }));
  };
  var onClick = function (e) {
    e.preventDefault();
    return Curry._1(setUsernameValidated, (function (param) {
                  return username;
                }));
  };
  var close = function (e) {
    e.preventDefault();
    return Curry._1(setShowLoginModal, (function (param) {
                  return false;
                }));
  };
  return React.createElement(ReactCore.LoginPage, {
              children: React.createElement(ReactCore.Form, {
                    children: null
                  }, React.createElement(ReactCore.FormGroup, {
                        children: null,
                        fieldId: "login",
                        label: "Username"
                      }, React.createElement(ReactCore.TextInput, {
                            id: "login",
                            onChange: onChange,
                            value: username
                          }), React.createElement(App$Login$Validator, {
                            store: store,
                            usernameValidated: match$1[0],
                            setShowLoginModal: setShowLoginModal
                          })), React.createElement(ReactCore.ActionGroup, {
                        children: null
                      }, React.createElement(ReactCore.Button, {
                            children: "Login",
                            onClick: onClick,
                            type: "submit",
                            variant: "primary"
                          }), React.createElement(ReactCore.Button, {
                            children: "Close",
                            onClick: close,
                            variant: "primary"
                          }))),
              loginSubtitle: "This is not an authenticated login - Set your username to an author identity to get personalized content.",
              loginTitle: "Login on Monocle"
            });
}

var NonAuthenticatedLoginModal = {
  make: App$Login$NonAuthenticatedLoginModal
};

function App$Login$AuthenticatedLoginModal(Props) {
  var setShowLoginModal = Props.setShowLoginModal;
  var closable = Props.closable;
  var auth = Props.auth;
  var currentLocation = encodeURIComponent(Prelude$Web.readWindowLocationFull(undefined));
  var $$location = WebApi$Web.serverUrl + "/api/2/auth/login?redirectUri=" + currentLocation;
  var onClick = function (e) {
    e.preventDefault();
    return Prelude$Web.replaceWindowLocation($$location);
  };
  var close = function (e) {
    e.preventDefault();
    return Curry._1(setShowLoginModal, (function (param) {
                  return false;
                }));
  };
  return React.createElement(ReactCore.LoginPage, {
              children: React.createElement(ReactCore.Form, {
                    children: React.createElement(ReactCore.ActionGroup, {
                          children: null
                        }, React.createElement(ReactCore.Button, {
                              children: "Authenticate with " + auth.provider_name,
                              icon: React.createElement(ReactIcons.UserIcon, {}),
                              onClick: onClick,
                              type: "submit",
                              variant: "primary"
                            }), closable ? React.createElement(ReactCore.Button, {
                                children: "Close",
                                onClick: close,
                                variant: "primary"
                              }) : null)
                  }),
              loginSubtitle: "Click on the button below to be redirected to identity provider. Once authenticated you'll be redirected to Monocle.",
              loginTitle: "Login on Monocle"
            });
}

var AuthenticatedLoginModal = {
  make: App$Login$AuthenticatedLoginModal
};

function App$Login$LoginModal(Props) {
  var store = Props.store;
  var setShowLoginModal = Props.setShowLoginModal;
  var closableOpt = Props.closable;
  var closable = closableOpt !== undefined ? closableOpt : true;
  var auth = store[0].about.auth;
  var auth$1 = auth._0;
  if (auth$1.provider_name === "") {
    return React.createElement(App$Login$NonAuthenticatedLoginModal, {
                store: store,
                setShowLoginModal: setShowLoginModal
              });
  } else {
    return React.createElement(App$Login$AuthenticatedLoginModal, {
                setShowLoginModal: setShowLoginModal,
                closable: closable,
                auth: auth$1
              });
  }
}

var LoginModal = {
  make: App$Login$LoginModal
};

function displayLoggedStatus(state, username, onClickLogout) {
  return React.createElement(ReactCore.Flex, {
              children: null
            }, React.createElement(ReactCore.FlexItem, {
                  children: state.index !== "" ? React.createElement(ReactCore.Button, {
                          children: username,
                          icon: React.createElement(ReactIcons.UserIcon, {
                                color: "cyan",
                                title: username
                              }),
                          onClick: (function (param) {
                              return RescriptReactRouter.push("/" + state.index + "/author/" + encodeURIComponent(username));
                            }),
                          variant: "tertiary"
                        }) : React.createElement(ReactCore.Button, {
                          children: username,
                          icon: React.createElement(ReactIcons.UserIcon, {
                                color: "cyan",
                                title: username
                              }),
                          isDisabled: true,
                          variant: "tertiary"
                        })
                }), React.createElement(ReactCore.FlexItem, {
                  children: React.createElement("div", {
                        style: {
                          cursor: "pointer"
                        },
                        onClick: onClickLogout
                      }, React.createElement(ReactIcons.ArrowIcon, {
                            color: "coral",
                            title: "Logout"
                          }))
                }));
}

function App$Login$LoginStatus(Props) {
  var store = Props.store;
  var setShowLoginModal = Props.setShowLoginModal;
  var dispatch = store[1];
  var state = store[0];
  var onClickLogin = function (param) {
    return Curry._1(setShowLoginModal, (function (param) {
                  return true;
                }));
  };
  var onClickLogoutNAU = function (param) {
    return Curry._1(dispatch, /* NonAuthenticatedLogout */1);
  };
  var onClickLogoutAU = function (param) {
    return Curry._1(dispatch, /* AuthenticatedLogout */2);
  };
  var displayUID = function (au) {
    var index = state.index;
    if (index === "") {
      return au.defaultMuid;
    }
    var muid = Jwt$Web.getMuidByIndex(au, index);
    if (muid !== undefined) {
      return muid;
    } else {
      return au.defaultMuid;
    }
  };
  var match = state.username;
  var match$1 = state.authenticated_user;
  var tmp;
  var exit = 0;
  if (match !== undefined) {
    if (match$1 !== undefined) {
      exit = 1;
    } else {
      tmp = displayLoggedStatus(state, match, onClickLogoutNAU);
    }
  } else if (match$1 !== undefined) {
    tmp = displayLoggedStatus(state, displayUID(match$1), onClickLogoutAU);
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = isAuthEnforced(state) ? null : React.createElement(ReactCore.Button, {
            children: "Login",
            onClick: onClickLogin,
            variant: "tertiary"
          });
  }
  return React.createElement("div", {
              style: {
                paddingRight: "13px"
              }
            }, tmp);
}

var LoginStatus = {
  displayLoggedStatus: displayLoggedStatus,
  make: App$Login$LoginStatus
};

var Login = {
  Validator: Validator,
  NonAuthenticatedLoginModal: NonAuthenticatedLoginModal,
  AuthenticatedLoginModal: AuthenticatedLoginModal,
  LoginModal: LoginModal,
  LoginStatus: LoginStatus
};

var logoPath = "/logo.png";

function buildLink(url, name) {
  return React.createElement("a", {
              href: url,
              rel: "noopener noreferrer",
              target: "_blank"
            }, Prelude$Web.str(name));
}

var defaultLink = buildLink("https://changemetrics.io", "Learn more about Monocle");

function buildLinkItems(links) {
  return Belt_List.toArray(Belt_List.map(links, (function (l) {
                    return React.createElement(ReactCore.TextListItem, {
                                children: buildLink(l.url, l.name),
                                component: "dt",
                                key: l.url
                              });
                  })));
}

function buildCategoryLinks(links) {
  return Belt_List.toArray(Belt_List.map(Belt_List.reduce(Belt_List.map(links, (function (l) {
                            return l.category;
                          })), /* [] */0, (function (acc, item) {
                        if (Belt_List.has(acc, item, (function (a, b) {
                                  return a === b;
                                }))) {
                          return acc;
                        } else {
                          return Belt_List.add(acc, item);
                        }
                      })), (function (cat) {
                    return React.createElement(ReactCore.TextContent, {
                                children: null,
                                key: cat
                              }, React.createElement("h4", undefined, Prelude$Web.str(cat)), React.createElement(ReactCore.TextList, {
                                    children: buildLinkItems(Belt_List.keep(links, (function (i) {
                                                return i.category === cat;
                                              }))),
                                    component: "dl"
                                  }));
                  })));
}

function App$About(Props) {
  var store = Props.store;
  var isOpen = Props.isOpen;
  var onClose = Props.onClose;
  var state = store[0];
  return React.createElement(ReactCore.AboutModal, {
              children: null,
              brandImageAlt: "Monocle",
              brandImageSrc: logoPath,
              isOpen: isOpen,
              onClose: onClose,
              productName: "Monocle"
            }, React.createElement(ReactCore.TextContent, {
                  children: null
                }, React.createElement("h4", undefined, Prelude$Web.str("About Monocle")), React.createElement(ReactCore.TextList, {
                      children: null,
                      component: "dl"
                    }, React.createElement(ReactCore.TextListItem, {
                          children: Prelude$Web.str("Monocle Version"),
                          component: "dt"
                        }), React.createElement(ReactCore.TextListItem, {
                          children: state.about.version,
                          component: "dd"
                        }), React.createElement(ReactCore.TextListItem, {
                          children: defaultLink,
                          component: "dt"
                        }))), buildCategoryLinks(state.about.links));
}

var About = {
  buildLink: buildLink,
  defaultLink: defaultLink,
  buildLinkItems: buildLinkItems,
  buildCategoryLinks: buildCategoryLinks,
  make: App$About
};

function App$App(Props) {
  var about = Props.about;
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var x = Belt_Option.getWithDefault(Belt_List.head(url.path), "");
  var initIndex = x === "help" ? "" : x;
  var match = url.path;
  var active = match ? "/" + Belt_List.toArray(match.tl).join("/") : "";
  var store = Store$Web.use(initIndex, about);
  var dispatch = store[1];
  var state = store[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var setShowAbout = match$1[1];
  var match$2 = React.useState(function () {
        return false;
      });
  var setShowLoginModal = match$2[1];
  React.useEffect((function () {
          var au = state.authenticated_user;
          if (au !== undefined) {
            var now = Date.now();
            var willExpireAt = au.jwt_exp.getTime();
            var willExpireIn = (willExpireAt - now | 0) - 60 | 0;
            console.log("JWT expiration in", willExpireIn / 1000 | 0, "s");
            setTimeout((function (param) {
                    return Curry._1(dispatch, /* AuthenticatedLogout */2);
                  }), willExpireIn);
          }
          
        }), []);
  var nav = React.createElement(App$MonocleNav, {
        active: active,
        store: store
      });
  var sidebar = state.index === "" ? null : React.createElement(ReactCore.PageSidebar, {
          nav: nav
        });
  var logo = React.createElement("span", {
        onClick: (function (param) {
            return Store$Web.changeIndex(store, "");
          })
      }, React.createElement("img", {
            src: logoPath
          }));
  var headerTools = React.createElement(ReactCore.PageHeaderTools, {
        children: null
      }, React.createElement(App$About, {
            store: store,
            isOpen: match$1[0],
            onClose: (function (param) {
                return Curry._1(setShowAbout, (function (param) {
                              return false;
                            }));
              })
          }), React.createElement(ReactCore.PageHeaderToolsGroup, {
            children: null
          }, React.createElement(ReactCore.PageHeaderToolsItem, {
                children: React.createElement(App$Login$LoginStatus, {
                      store: store,
                      setShowLoginModal: setShowLoginModal
                    })
              }), React.createElement(ReactCore.PageHeaderToolsItem, {
                children: React.createElement("div", {
                      style: {
                        cursor: "pointer"
                      },
                      onClick: (function (param) {
                          return Curry._1(setShowAbout, (function (param) {
                                        return true;
                                      }));
                        })
                    }, React.createElement(ReactIcons.InfoAltIcon, {}))
              })));
  var header = React.createElement(ReactCore.PageHeader, {
        headerTools: headerTools,
        logo: logo,
        showNavToggle: state.index !== ""
      });
  var match$3 = isAuthEnforced(state);
  var match$4 = state.authenticated_user;
  var tmp;
  var exit = 0;
  if (match$2[0]) {
    tmp = React.createElement(App$Login$LoginModal, {
          store: store,
          setShowLoginModal: setShowLoginModal
        });
  } else if (match$3 && match$4 === undefined) {
    tmp = React.createElement(App$Login$LoginModal, {
          store: store,
          setShowLoginModal: setShowLoginModal,
          closable: false
        });
  } else {
    exit = 1;
  }
  if (exit === 1) {
    var match$5 = url.path;
    var tmp$1;
    var exit$1 = 0;
    if (match$5) {
      var exit$2 = 0;
      if (match$5.hd === "help") {
        var match$6 = match$5.tl;
        if (match$6 && match$6.hd === "search") {
          if (match$6.tl) {
            exit$1 = 2;
          } else {
            tmp$1 = React.createElement(HelpSearch$Web.View.make, {
                  store: store
                });
          }
        } else {
          exit$2 = 3;
        }
      } else {
        exit$2 = 3;
      }
      if (exit$2 === 3) {
        var match$7 = match$5.tl;
        if (match$7) {
          switch (match$7.hd) {
            case "active_authors" :
                if (match$7.tl) {
                  exit$1 = 2;
                } else {
                  tmp$1 = React.createElement(ActivePeopleView$Web.make, {
                        store: store
                      });
                }
                break;
            case "author" :
                var match$8 = match$7.tl;
                if (match$8 && !match$8.tl) {
                  tmp$1 = React.createElement(ScopedView$Web.AuthorScopedView.make, {
                        store: store,
                        name: match$8.hd
                      });
                } else {
                  exit$1 = 2;
                }
                break;
            case "board" :
                if (match$7.tl) {
                  exit$1 = 2;
                } else {
                  tmp$1 = React.createElement(Board$Web.make, {
                        store: store
                      });
                }
                break;
            case "change" :
                var match$9 = match$7.tl;
                if (match$9 && !match$9.tl) {
                  tmp$1 = React.createElement(ChangeView$Web.make, {
                        store: store,
                        change: match$9.hd
                      });
                } else {
                  exit$1 = 2;
                }
                break;
            case "changes" :
                if (match$7.tl) {
                  exit$1 = 2;
                } else {
                  tmp$1 = React.createElement(NChangeView$Web.make, {
                        store: store
                      });
                }
                break;
            case "group" :
                var match$10 = match$7.tl;
                if (match$10 && !match$10.tl) {
                  tmp$1 = React.createElement(ScopedView$Web.GroupScopedView.make, {
                        store: store,
                        name: match$10.hd
                      });
                } else {
                  exit$1 = 2;
                }
                break;
            case "metric" :
                var match$11 = match$7.tl;
                if (match$11 && !match$11.tl) {
                  tmp$1 = React.createElement(Metric$Web.make, {
                        store: store,
                        name: match$11.hd
                      });
                } else {
                  exit$1 = 2;
                }
                break;
            case "metrics" :
                if (match$7.tl) {
                  exit$1 = 2;
                } else {
                  tmp$1 = React.createElement(Metrics$Web.make, {
                        store: store
                      });
                }
                break;
            case "new_authors" :
                if (match$7.tl) {
                  exit$1 = 2;
                } else {
                  tmp$1 = React.createElement(NewContributorsView$Web.make, {
                        store: store
                      });
                }
                break;
            case "peers_strength" :
                if (match$7.tl) {
                  exit$1 = 2;
                } else {
                  tmp$1 = React.createElement(PeersStrengthView$Web.make, {
                        store: store,
                        stacked: false
                      });
                }
                break;
            case "projects" :
                if (match$7.tl) {
                  exit$1 = 2;
                } else {
                  tmp$1 = React.createElement(ProjectsView$Web.make, {
                        store: store
                      });
                }
                break;
            case "repos" :
                if (match$7.tl) {
                  exit$1 = 2;
                } else {
                  tmp$1 = React.createElement(ReposView$Web.make, {
                        store: store
                      });
                }
                break;
            case "search_author" :
                if (match$7.tl) {
                  exit$1 = 2;
                } else {
                  tmp$1 = React.createElement(AuthorSearch$Web.make, {
                        store: store
                      });
                }
                break;
            case "settings" :
                if (match$7.tl) {
                  exit$1 = 2;
                } else {
                  tmp$1 = React.createElement(LocalSettings$Web.View.make, {
                        store: store
                      });
                }
                break;
            case "user_groups" :
                var match$12 = match$7.tl;
                if (match$12) {
                  if (match$12.tl) {
                    exit$1 = 2;
                  } else {
                    tmp$1 = React.createElement(GroupView$Web.make, {
                          group: match$12.hd,
                          store: store
                        });
                  }
                } else {
                  tmp$1 = React.createElement(GroupsView$Web.make, {
                        store: store
                      });
                }
                break;
            default:
              exit$1 = 2;
          }
        } else {
          tmp$1 = React.createElement(Activity$Web.make, {
                store: store
              });
        }
      }
      
    } else {
      tmp$1 = React.createElement(Indices$Web.Indices.make, {
            store: store
          });
    }
    if (exit$1 === 2) {
      tmp$1 = React.createElement("p", undefined, Prelude$Web.str("Not found"));
    }
    tmp = React.createElement(ReactCore.PageSection, {
          children: tmp$1,
          isFilled: true
        });
  }
  var bodyPage = React.createElement(ReactCore.Page, {
        children: React.createElement(React.Fragment, {
              children: null
            }, state.index !== "" ? React.createElement(ReactCore.PageSection, {
                    children: React.createElement(Search$Web.Top.make, {
                          store: store
                        }),
                    variant: "dark"
                  }) : null, tmp),
        header: header,
        isManagedSidebar: true,
        sidebar: sidebar
      });
  var toaster = React.createElement("ul", {
        className: "pf-c-alert-group pf-m-toast"
      }, Belt_List.toArray(Belt_List.mapWithIndex(state.toasts, (function (idx, toast) {
                  return React.createElement("li", {
                              className: "pf-c-alert-group__item"
                            }, React.createElement(ReactCore.Alert, {
                                  onTimeout: (function (param) {
                                      return Curry._1(dispatch, {
                                                  TAG: /* RemoveToast */9,
                                                  _0: toast
                                                });
                                    }),
                                  timeout: 4000,
                                  title: toast,
                                  key: String(idx)
                                }));
                }))));
  return React.createElement(React.Fragment, {
              children: null
            }, bodyPage, toaster);
}

var App = {
  make: App$App
};

function App$1(Props) {
  return React.createElement(Prelude$Web.NetworkRender.make, {
              get: (function (param) {
                  return WebApi$Web.Config.getAbout({
                              void: ""
                            });
                }),
              trigger: "",
              render: (function (resp) {
                  var about = resp.about;
                  if (about !== undefined) {
                    return React.createElement(App$App, {
                                about: about
                              });
                  } else {
                    return React.createElement(ReactCore.Alert, {
                                title: "Unable to fetch about data !",
                                variant: "danger"
                              });
                  }
                })
            });
}

var make = App$1;

var $$default = App$1;

exports.MonocleNav = MonocleNav;
exports.isAuthEnforced = isAuthEnforced;
exports.Login = Login;
exports.logoPath = logoPath;
exports.About = About;
exports.App = App;
exports.make = make;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
/* defaultLink Not a pure module */
