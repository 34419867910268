'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Jwt$Web = require("../Jwt.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var WebApi$Web = require("./WebApi.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Belt_Result = require("rescript/lib/js/belt_Result.js");
var Caml_format = require("rescript/lib/js/caml_format.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Dom_storage = require("rescript/lib/js/dom_storage.js");
var Prelude$Web = require("./Prelude.bs.js");
var MonoIndexedDB$Web = require("./MonoIndexedDB.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");

function fmap(m, f) {
  return Belt_Option.flatMap(m, (function (r) {
                return Belt_Result.flatMap(r, (function (d) {
                              return {
                                      TAG: /* Ok */0,
                                      _0: Curry._1(f, d)
                                    };
                            }));
              }));
}

var RemoteData = {
  fmap: fmap
};

function getParamOption(name) {
  var params = Prelude$Web.$$URLSearchParams.current(undefined);
  return Caml_option.nullable_to_opt(params.get(name));
}

function getParam(name) {
  return Belt_Option.getWithDefault(getParamOption(name), "");
}

function getOrder(param) {
  return Belt_Option.getWithDefault(Prelude$Web.orderFromQS(Belt_Option.getWithDefault(getParamOption("o"), "")), {
              field: "updated_at",
              direction: /* Desc */1
            });
}

function getQuery(param) {
  var q = Belt_Option.getWithDefault(getParamOption("q"), "");
  if (q === "") {
    return "from:now-3weeks";
  } else {
    return q;
  }
}

function getFilter(param) {
  return Belt_Option.getWithDefault(getParamOption("f"), "");
}

function getLimit(param) {
  var params = Prelude$Web.$$URLSearchParams.current(undefined);
  return Caml_format.caml_int_of_string(Belt_Option.getWithDefault(Caml_option.nullable_to_opt(params.get("l")), "0"));
}

var UrlData = {
  getParamOption: getParamOption,
  getParam: getParam,
  getOrder: getOrder,
  getQuery: getQuery,
  getFilter: getFilter,
  getLimit: getLimit
};

function getMonocleCookie(param) {
  return Belt_Array.get(Belt_Array.map(Belt_Array.keep(Prelude$Web.getCookies(undefined).split(";"), (function (cookie) {
                        return Belt_Option.getWithDefault(Belt_Array.get(cookie.split("="), 0), "").trim() === "Monocle";
                      })), (function (cookie) {
                    return Belt_Option.getWithDefault(Belt_Array.get(cookie.split("="), 1), "");
                  })), 0);
}

function getAuthenticatedUser(param) {
  return Belt_Option.flatMap(getMonocleCookie(undefined), Jwt$Web.jwtToAuthenticatedUser);
}

function getAuthenticatedUserJWT(state) {
  return Belt_Option.flatMap(state.authenticated_user, (function (au) {
                return au.jwt;
              }));
}

function create(index, about) {
  return {
          index: index,
          query: getQuery(undefined),
          filter: Belt_Option.getWithDefault(getParamOption("f"), ""),
          limit: getLimit(undefined),
          username: Dom_storage.getItem("monocle_username", localStorage),
          authenticated_user: getAuthenticatedUser(undefined),
          order: getOrder(undefined),
          author_scoped_tab: /* ChangeActivity */0,
          suggestions: undefined,
          fields: undefined,
          projects: undefined,
          changes_pies_panel: false,
          about: about,
          dexie: MonoIndexedDB$Web.mkDexie(undefined),
          toasts: /* [] */0
        };
}

function reducer(state, action) {
  if (typeof action === "number") {
    switch (action) {
      case /* ReverseChangesPiePanelState */0 :
          return {
                  index: state.index,
                  query: state.query,
                  filter: state.filter,
                  limit: state.limit,
                  username: state.username,
                  authenticated_user: state.authenticated_user,
                  order: state.order,
                  author_scoped_tab: state.author_scoped_tab,
                  suggestions: state.suggestions,
                  fields: state.fields,
                  projects: state.projects,
                  changes_pies_panel: !state.changes_pies_panel,
                  about: state.about,
                  dexie: state.dexie,
                  toasts: state.toasts
                };
      case /* NonAuthenticatedLogout */1 :
          Dom_storage.removeItem("monocle_username", localStorage);
          return {
                  index: state.index,
                  query: state.query,
                  filter: state.filter,
                  limit: state.limit,
                  username: undefined,
                  authenticated_user: state.authenticated_user,
                  order: state.order,
                  author_scoped_tab: state.author_scoped_tab,
                  suggestions: state.suggestions,
                  fields: state.fields,
                  projects: state.projects,
                  changes_pies_panel: state.changes_pies_panel,
                  about: state.about,
                  dexie: state.dexie,
                  toasts: state.toasts
                };
      case /* AuthenticatedLogout */2 :
          Prelude$Web.delCookie("Monocle");
          return {
                  index: state.index,
                  query: state.query,
                  filter: state.filter,
                  limit: state.limit,
                  username: state.username,
                  authenticated_user: undefined,
                  order: state.order,
                  author_scoped_tab: state.author_scoped_tab,
                  suggestions: state.suggestions,
                  fields: state.fields,
                  projects: state.projects,
                  changes_pies_panel: state.changes_pies_panel,
                  about: state.about,
                  dexie: state.dexie,
                  toasts: state.toasts
                };
      
    }
  } else {
    switch (action.TAG | 0) {
      case /* ChangeIndex */0 :
          var index = action._0;
          RescriptReactRouter.push("/" + index);
          return create(index, state.about);
      case /* SetQuery */1 :
          var query = action._0;
          Prelude$Web.setLocationSearch("q", query);
          return {
                  index: state.index,
                  query: query,
                  filter: state.filter,
                  limit: state.limit,
                  username: state.username,
                  authenticated_user: state.authenticated_user,
                  order: state.order,
                  author_scoped_tab: state.author_scoped_tab,
                  suggestions: state.suggestions,
                  fields: state.fields,
                  projects: state.projects,
                  changes_pies_panel: state.changes_pies_panel,
                  about: state.about,
                  dexie: state.dexie,
                  toasts: state.toasts
                };
      case /* SetFilter */2 :
          var query$1 = action._0;
          Prelude$Web.setLocationSearch("f", query$1);
          return {
                  index: state.index,
                  query: state.query,
                  filter: query$1,
                  limit: state.limit,
                  username: state.username,
                  authenticated_user: state.authenticated_user,
                  order: state.order,
                  author_scoped_tab: state.author_scoped_tab,
                  suggestions: state.suggestions,
                  fields: state.fields,
                  projects: state.projects,
                  changes_pies_panel: state.changes_pies_panel,
                  about: state.about,
                  dexie: state.dexie,
                  toasts: state.toasts
                };
      case /* SetLimit */3 :
          var limit = action._0;
          Prelude$Web.setLocationSearch("l", String(limit));
          return {
                  index: state.index,
                  query: state.query,
                  filter: state.filter,
                  limit: limit,
                  username: state.username,
                  authenticated_user: state.authenticated_user,
                  order: state.order,
                  author_scoped_tab: state.author_scoped_tab,
                  suggestions: state.suggestions,
                  fields: state.fields,
                  projects: state.projects,
                  changes_pies_panel: state.changes_pies_panel,
                  about: state.about,
                  dexie: state.dexie,
                  toasts: state.toasts
                };
      case /* SetOrder */4 :
          var order = action._0;
          Prelude$Web.setLocationSearch("o", Prelude$Web.orderToQS(order));
          return {
                  index: state.index,
                  query: state.query,
                  filter: state.filter,
                  limit: state.limit,
                  username: state.username,
                  authenticated_user: state.authenticated_user,
                  order: order,
                  author_scoped_tab: state.author_scoped_tab,
                  suggestions: state.suggestions,
                  fields: state.fields,
                  projects: state.projects,
                  changes_pies_panel: state.changes_pies_panel,
                  about: state.about,
                  dexie: state.dexie,
                  toasts: state.toasts
                };
      case /* SetAuthorScopedTab */5 :
          return {
                  index: state.index,
                  query: state.query,
                  filter: state.filter,
                  limit: state.limit,
                  username: state.username,
                  authenticated_user: state.authenticated_user,
                  order: state.order,
                  author_scoped_tab: action._0,
                  suggestions: state.suggestions,
                  fields: state.fields,
                  projects: state.projects,
                  changes_pies_panel: state.changes_pies_panel,
                  about: state.about,
                  dexie: state.dexie,
                  toasts: state.toasts
                };
      case /* FetchFields */6 :
          return {
                  index: state.index,
                  query: state.query,
                  filter: state.filter,
                  limit: state.limit,
                  username: state.username,
                  authenticated_user: state.authenticated_user,
                  order: state.order,
                  author_scoped_tab: state.author_scoped_tab,
                  suggestions: state.suggestions,
                  fields: fmap(action._0, (function (resp) {
                          return resp.fields;
                        })),
                  projects: state.projects,
                  changes_pies_panel: state.changes_pies_panel,
                  about: state.about,
                  dexie: state.dexie,
                  toasts: state.toasts
                };
      case /* FetchSuggestions */7 :
          return {
                  index: state.index,
                  query: state.query,
                  filter: state.filter,
                  limit: state.limit,
                  username: state.username,
                  authenticated_user: state.authenticated_user,
                  order: state.order,
                  author_scoped_tab: state.author_scoped_tab,
                  suggestions: action._0,
                  fields: state.fields,
                  projects: state.projects,
                  changes_pies_panel: state.changes_pies_panel,
                  about: state.about,
                  dexie: state.dexie,
                  toasts: state.toasts
                };
      case /* FetchProjects */8 :
          return {
                  index: state.index,
                  query: state.query,
                  filter: state.filter,
                  limit: state.limit,
                  username: state.username,
                  authenticated_user: state.authenticated_user,
                  order: state.order,
                  author_scoped_tab: state.author_scoped_tab,
                  suggestions: state.suggestions,
                  fields: state.fields,
                  projects: action._0,
                  changes_pies_panel: state.changes_pies_panel,
                  about: state.about,
                  dexie: state.dexie,
                  toasts: state.toasts
                };
      case /* RemoveToast */9 :
          var toast = action._0;
          return {
                  index: state.index,
                  query: state.query,
                  filter: state.filter,
                  limit: state.limit,
                  username: state.username,
                  authenticated_user: state.authenticated_user,
                  order: state.order,
                  author_scoped_tab: state.author_scoped_tab,
                  suggestions: state.suggestions,
                  fields: state.fields,
                  projects: state.projects,
                  changes_pies_panel: state.changes_pies_panel,
                  about: state.about,
                  dexie: state.dexie,
                  toasts: Belt_List.keep(state.toasts, (function (x) {
                          return x !== toast;
                        }))
                };
      case /* AddToast */10 :
          var toast$1 = action._0;
          return {
                  index: state.index,
                  query: state.query,
                  filter: state.filter,
                  limit: state.limit,
                  username: state.username,
                  authenticated_user: state.authenticated_user,
                  order: state.order,
                  author_scoped_tab: state.author_scoped_tab,
                  suggestions: state.suggestions,
                  fields: state.fields,
                  projects: state.projects,
                  changes_pies_panel: state.changes_pies_panel,
                  about: state.about,
                  dexie: state.dexie,
                  toasts: Belt_List.add(Belt_List.keep(state.toasts, (function (x) {
                              return x !== toast$1;
                            })), toast$1)
                };
      case /* NonAuthenticatedLogin */11 :
          var username = action._0;
          Dom_storage.setItem("monocle_username", username, localStorage);
          return {
                  index: state.index,
                  query: state.query,
                  filter: state.filter,
                  limit: state.limit,
                  username: username,
                  authenticated_user: state.authenticated_user,
                  order: state.order,
                  author_scoped_tab: state.author_scoped_tab,
                  suggestions: state.suggestions,
                  fields: state.fields,
                  projects: state.projects,
                  changes_pies_panel: state.changes_pies_panel,
                  about: state.about,
                  dexie: state.dexie,
                  toasts: state.toasts
                };
      
    }
  }
}

var Store = {
  getMonocleCookie: getMonocleCookie,
  getAuthenticatedUser: getAuthenticatedUser,
  getAuthenticatedUserJWT: getAuthenticatedUserJWT,
  create: create,
  reducer: reducer
};

function $$fetch(value, get, mkAction, dispatch) {
  var set = function (v) {
    return Promise.resolve(Curry._1(dispatch, Curry._1(mkAction, v)));
  };
  var handleErr = function (err) {
    console.log(err);
    return set({
                TAG: /* Error */1,
                _0: "Network error"
              });
  };
  var handleOk = function (resp) {
    return set({
                TAG: /* Ok */0,
                _0: resp.data
              });
  };
  React.useEffect((function () {
          if (value !== undefined) {
            
          } else {
            Curry._1(get, undefined).then(handleOk).catch(handleErr);
          }
          
        }), []);
  return value;
}

function suggestions(param) {
  var state = param[0];
  return $$fetch(state.suggestions, (function (param) {
                return WebApi$Web.Search.suggestions({
                            index: state.index
                          });
              }), (function (res) {
                return {
                        TAG: /* FetchSuggestions */7,
                        _0: res
                      };
              }), param[1]);
}

function fields(param) {
  return $$fetch(param[0].fields, (function (param) {
                return WebApi$Web.Search.fields({
                            version: "1"
                          });
              }), (function (res) {
                return {
                        TAG: /* FetchFields */6,
                        _0: res
                      };
              }), param[1]);
}

function projects(param) {
  var state = param[0];
  return $$fetch(state.projects, (function (param) {
                return WebApi$Web.Config.getProjects({
                            index: state.index
                          });
              }), (function (res) {
                return {
                        TAG: /* FetchProjects */8,
                        _0: res
                      };
              }), param[1]);
}

var Fetch = {
  $$fetch: $$fetch,
  suggestions: suggestions,
  fields: fields,
  projects: projects
};

function changeIndex(param, name) {
  return Curry._1(param[1], {
              TAG: /* ChangeIndex */0,
              _0: name
            });
}

function mkSearchRequest(state, query_type) {
  return {
          index: state.index,
          username: Belt_Option.getWithDefault(state.username, ""),
          query: state.query,
          query_type: query_type,
          order: state.order,
          limit: state.limit,
          change_id: ""
        };
}

function use(index, about) {
  return React.useReducer(reducer, create(index, about));
}

exports.RemoteData = RemoteData;
exports.UrlData = UrlData;
exports.Store = Store;
exports.Fetch = Fetch;
exports.changeIndex = changeIndex;
exports.mkSearchRequest = mkSearchRequest;
exports.use = use;
/* react Not a pure module */
