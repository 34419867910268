'use strict';

var React = require("react");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Store$Web = require("./Store.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Prelude$Web = require("./Prelude.bs.js");
var ReactCore = require("@patternfly/react-core");
var ReactIcons = require("@patternfly/react-icons");

function HistoBox$HistoBox(Props) {
  var bucket = Props.bucket;
  var count = bucket.count;
  var x = bucket.count;
  var countStr = x !== 0 ? String(x) : "";
  var alpha = Math.round(255.0 * Math.log10(1.0 + count));
  var green = 100 + Math.round(155.0 * Math.log10(1.0 + count)) | 0;
  var style = {
    backgroundColor: "rgba(0, " + String(green) + ", 0, " + String(alpha) + ")",
    border: "1px solid black",
    display: "inline-block",
    height: "20px",
    margin: "2px",
    overflow: "hidden",
    textAlign: "center",
    width: "20px",
    borderRadius: "5px"
  };
  return React.createElement(ReactCore.Tooltip, {
              children: React.createElement("span", {
                    style: style
                  }, Prelude$Web.str(countStr)),
              content: Prelude$Web.str(bucket.date)
            });
}

var HistoBox = {
  make: HistoBox$HistoBox
};

function HistoBox$BaseHistoBox(Props) {
  var store = Props.store;
  var title = Props.title;
  var tooltip_content = Props.tooltip_content;
  var searchType = Props.searchType;
  var extraQuery = Props.extraQuery;
  var icon = React.createElement(ReactIcons.GripHorizontalIcon, {});
  var state = store[0];
  var baseRequest = Store$Web.mkSearchRequest(state, searchType);
  var request_index = baseRequest.index;
  var request_username = baseRequest.username;
  var request_query = extraQuery !== undefined ? Prelude$Web.addQuery(baseRequest.query, extraQuery) : baseRequest.query;
  var request_query_type = baseRequest.query_type;
  var request_order = baseRequest.order;
  var request_limit = baseRequest.limit;
  var request_change_id = baseRequest.change_id;
  var request = {
    index: request_index,
    username: request_username,
    query: request_query,
    query_type: request_query_type,
    order: request_order,
    limit: request_limit,
    change_id: request_change_id
  };
  var trigger = state.query + Belt_Option.getWithDefault(extraQuery, "");
  var match = function (resp) {
    if (resp.TAG === /* Histo */12) {
      return resp._0.histo;
    }
    
  };
  var childrenBuilder = function (data) {
    return Belt_List.toArray(Belt_List.mapWithIndex(data, (function (index, bucket) {
                      return React.createElement(HistoBox$HistoBox, {
                                  bucket: bucket,
                                  key: String(index)
                                });
                    })));
  };
  return React.createElement(Prelude$Web.QueryRenderCard.make, {
              request: request,
              trigger: trigger,
              title: title,
              tooltip_content: tooltip_content,
              icon: icon,
              match: match,
              childrenBuilder: childrenBuilder
            });
}

var BaseHistoBox = {
  make: HistoBox$BaseHistoBox
};

function HistoBox$CommitsHistoBox(Props) {
  var store = Props.store;
  var extraQuery = Props.extraQuery;
  var tmp = {
    store: store,
    title: "Commits activities",
    tooltip_content: "This shows the commits activities",
    searchType: /* Query_histo_commits */16
  };
  if (extraQuery !== undefined) {
    tmp.extraQuery = extraQuery;
  }
  return React.createElement(HistoBox$BaseHistoBox, tmp);
}

var CommitsHistoBox = {
  make: HistoBox$CommitsHistoBox
};

function HistoBox$ReviewsCommentsHistoBox(Props) {
  var store = Props.store;
  var extraQuery = Props.extraQuery;
  var tmp = {
    store: store,
    title: "Reviews activities",
    tooltip_content: "This shows the review activities",
    searchType: /* Query_histo_reviews_and_comments */17
  };
  if (extraQuery !== undefined) {
    tmp.extraQuery = extraQuery;
  }
  return React.createElement(HistoBox$BaseHistoBox, tmp);
}

var ReviewsCommentsHistoBox = {
  make: HistoBox$ReviewsCommentsHistoBox
};

exports.HistoBox = HistoBox;
exports.BaseHistoBox = BaseHistoBox;
exports.CommitsHistoBox = CommitsHistoBox;
exports.ReviewsCommentsHistoBox = ReviewsCommentsHistoBox;
/* react Not a pure module */
