'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var PFTypes = require("@softwarefactory-project/re-patternfly/src/PFTypes.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Patternfly = require("@softwarefactory-project/re-patternfly/src/Patternfly.bs.js");
var WebApi$Web = require("./WebApi.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_format = require("rescript/lib/js/caml_format.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var ReactCore = require("@patternfly/react-core");
var ReactTable = require("@patternfly/react-table");

function readWindowLocationSearch(param) {
  return window.location.search;
}

function readWindowLocationHash(param) {
  return window.location.hash;
}

function readWindowLocationPathname(param) {
  return window.location.pathname;
}

function replaceWindowLocation($$location) {
  window.location.replace($$location);
  
}

function readWindowLocationFull(param) {
  return window.location.pathname + window.location.search + window.location.hash;
}

function getCookies(param) {
  return document.cookie;
}

var delCookie = (function (name) {
  document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"
});

import moment from 'moment'
;

var momentFromNow = (function(d) {
    return moment(d).fromNow()
  });

var momentHumanizeDuration = (function(d) {
    return moment.duration(d, 'seconds').humanize()
  });

var resetLocationSearch = (function() {
    window.history.replaceState(null, null, window.location.origin + window.location.pathname);
  });

var setLocationSearch = (function(q, v) {
    const url = new URL(window.location.href);
    url.searchParams.set(q, v);
    window.history.replaceState(null, null, url);
  });

var Link = {};

var Env = {};

function getNow(prim) {
  return new Date();
}

function getSimpleDate(d) {
  return d.toISOString().split("T")[0];
}

function getDateMinusYear(delta) {
  var now = new Date();
  var curYear = now.getUTCFullYear() | 0;
  var newYear = curYear - delta | 0;
  now.setUTCFullYear(newYear);
  return getSimpleDate(now);
}

function getDateMinusMonth(delta) {
  var now = new Date();
  var curMonth = now.getUTCMonth() | 0;
  var newMonth = curMonth - delta | 0;
  now.setUTCMonth(newMonth);
  return getSimpleDate(now);
}

function getDateMinusWeek(delta) {
  var now = new Date();
  var curHour = now.getUTCHours() | 0;
  var newHour = curHour - Math.imul(Math.imul(delta, 24), 7) | 0;
  now.setUTCHours(newHour);
  return getSimpleDate(now);
}

var Time = {
  getNow: getNow,
  getSimpleDate: getSimpleDate,
  getDateMinusYear: getDateMinusYear,
  getDateMinusMonth: getDateMinusMonth,
  getDateMinusWeek: getDateMinusWeek
};

function current(param) {
  return new URLSearchParams(window.location.search);
}

var $$URLSearchParams$1 = {
  current: current
};

function usePagination(rows) {
  var match = React.useState(function () {
        return 1;
      });
  var setPage = match[1];
  var page = match[0];
  var match$1 = React.useState(function () {
        return 20;
      });
  var setPerPage = match$1[1];
  var perPage = match$1[0];
  var onSetPage = function (param, pageNumber, param$1, param$2, param$3) {
    return Curry._1(setPage, (function (param) {
                  return pageNumber;
                }));
  };
  var onPerPageSelect = function (param, perPage, param$1, param$2, param$3) {
    return Curry._1(setPerPage, (function (param) {
                  return perPage;
                }));
  };
  var itemCount = rows.length;
  if (itemCount > 20) {
    return [
            Belt_Array.slice(rows, Math.imul(page - 1 | 0, perPage), perPage),
            React.createElement(ReactCore.Pagination, {
                  itemCount: itemCount,
                  onPerPageSelect: onPerPageSelect,
                  onSetPage: onSetPage,
                  page: page,
                  perPage: perPage
                })
          ];
  } else {
    return [
            rows,
            null
          ];
  }
}

function useAutoGetOn(get, trigger) {
  var match = React.useState(function () {
        return /* NotAsked */0;
      });
  var set$p = match[1];
  var state = match[0];
  var handleBegin;
  handleBegin = typeof state === "number" || state.TAG !== /* Loaded */1 ? ({
        TAG: /* Loading */0,
        _0: undefined
      }) : ({
        TAG: /* Loading */0,
        _0: Caml_option.some(state._0)
      });
  var handleErr = function (err) {
    console.log(err);
    return Promise.resolve(Curry._1(set$p, (function (param) {
                      return {
                              TAG: /* Failure */2,
                              _0: "Network error"
                            };
                    })));
  };
  var handleOk = function (resp) {
    var x = {
      TAG: /* Loaded */1,
      _0: resp.data
    };
    return Promise.resolve(Curry._1(set$p, (function (param) {
                      return x;
                    })));
  };
  React.useEffect((function () {
          Curry._1(set$p, (function (param) {
                  return handleBegin;
                }));
          Curry._1(get, undefined).then(handleOk).catch(handleErr);
          
        }), [trigger]);
  return state;
}

function useAutoGet(x) {
  return useAutoGetOn(x, "");
}

function Prelude$NetworkRender(Props) {
  var get = Props.get;
  var trigger = Props.trigger;
  var render = Props.render;
  var respM = useAutoGetOn(get, trigger);
  if (typeof respM === "number") {
    return React.createElement(ReactCore.Spinner, {});
  }
  switch (respM.TAG | 0) {
    case /* Loading */0 :
        var respM$1 = respM._0;
        return React.createElement(React.Fragment, undefined, React.createElement(ReactCore.Spinner, {}), respM$1 !== undefined ? Curry._1(render, Caml_option.valFromOption(respM$1)) : null);
    case /* Loaded */1 :
        return Curry._1(render, respM._0);
    case /* Failure */2 :
        return React.createElement(ReactCore.Alert, {
                    title: respM._0,
                    variant: "danger"
                  });
    
  }
}

var NetworkRender = {
  make: Prelude$NetworkRender
};

function getDate(ts) {
  return Belt_Option.getExn(Belt_Option.getExn(ts));
}

function getCurrentTime(param) {
  return new Date(Date.now());
}

function str(prim) {
  return prim;
}

function maybeRender(pred, component) {
  if (pred) {
    return component;
  } else {
    return null;
  }
}

function maybeHide(pred, component) {
  if (pred !== undefined && pred) {
    return null;
  } else {
    return component;
  }
}

function maybeRenderArray(xs, component) {
  if (xs.length !== 0) {
    return component;
  } else {
    return null;
  }
}

function maybeRenderList(xs, component) {
  if (xs) {
    return component;
  } else {
    return null;
  }
}

function lower(s) {
  return s.toLowerCase();
}

function int32_str(i32) {
  return String(i32);
}

function float_str(param) {
  return param.toFixed(1);
}

function quoteValue(v) {
  if (v.includes(" ")) {
    return "\"" + v + "\"";
  } else {
    return v;
  }
}

function take(xs, count) {
  if (xs && count > 0) {
    return Belt_List.add(take(xs.tl, count - 1 | 0), xs.hd);
  } else {
    return /* [] */0;
  }
}

function elemText(xs, x) {
  return Belt_List.has(xs, x, (function (a, b) {
                return a === b;
              }));
}

var fromMaybe = Belt_Option.getWithDefault;

function mapWithKey(xs, f) {
  return Belt_List.mapWithIndex(xs, (function (index, x) {
                return Curry._2(f, String(index), x);
              }));
}

function catMaybes(_xs) {
  while(true) {
    var xs = _xs;
    if (!xs) {
      return /* [] */0;
    }
    var x = xs.hd;
    if (x !== undefined) {
      return Belt_List.add(catMaybes(xs.tl), Caml_option.valFromOption(x));
    }
    _xs = xs.tl;
    continue ;
  };
}

function prependToAll(xs, sep) {
  if (xs) {
    return sep + xs.hd + prependToAll(xs.tl, sep);
  } else {
    return "";
  }
}

function concatSep(xs, sep) {
  if (xs) {
    return xs.hd + prependToAll(xs.tl, sep);
  } else {
    return "";
  }
}

function useToggle($$default) {
  var match = React.useState(function () {
        return $$default;
      });
  var setValue = match[1];
  var toggle = function (param) {
    return Curry._1(setValue, (function (x) {
                  return !x;
                }));
  };
  var set = function (x) {
    return Curry._1(setValue, (function (param) {
                  return x;
                }));
  };
  return [
          match[0],
          toggle,
          set
        ];
}

function addQuery(baseQuery, extraQuery) {
  var match = baseQuery.trim() === "" ? [
      "",
      ""
    ] : [
      "(" + baseQuery + ")",
      " and "
    ];
  return match[0] + (
          extraQuery.trim() === "" ? "" : match[1] + extraQuery
        );
}

function orderFromQS(queryString) {
  var match = queryString.split("#");
  if (match.length !== 2) {
    return ;
  }
  var field = match[0];
  var match$1 = match[1];
  switch (match$1) {
    case "A" :
        return {
                field: field,
                direction: /* Asc */0
              };
    case "D" :
        return {
                field: field,
                direction: /* Desc */1
              };
    default:
      return ;
  }
}

function orderDirToQS(dir) {
  if (dir) {
    return "#D";
  } else {
    return "#A";
  }
}

function orderToQS(order) {
  if (order !== undefined) {
    return order.field + (
            order.direction ? "#D" : "#A"
          );
  } else {
    return "";
  }
}

function orderDirToString(dir) {
  if (dir) {
    return " DESC";
  } else {
    return "";
  }
}

function Prelude$MExpandablePanel(Props) {
  var title = Props.title;
  var children = Props.children;
  var stateControler = Props.stateControler;
  var match = stateControler !== undefined ? [
      stateControler[0],
      stateControler[1]
    ] : React.useState(function () {
          return false;
        });
  var setShow = match[1];
  var show = match[0];
  var toggleProps = {
    id: "toggle-button",
    "aria-label": "Details",
    "aria-labelledby": "titleId toggle-button",
    "aria-expanded": !show,
    icon: null
  };
  return React.createElement(ReactCore.Card, {
              children: null,
              isCompact: true,
              isExpanded: show
            }, React.createElement(ReactCore.CardHeader, {
                  children: React.createElement(ReactCore.CardTitle, {
                        children: title
                      }),
                  onExpand: (function (param, param$1) {
                      return Curry._1(setShow, (function (v) {
                                    return !v;
                                  }));
                    }),
                  toggleButtonProps: toggleProps
                }), React.createElement(ReactCore.CardExpandableContent, {
                  children: React.createElement(ReactCore.CardBody, {
                        children: children
                      })
                }));
}

var MExpandablePanel = {
  make: Prelude$MExpandablePanel
};

function Prelude$MGrid(Props) {
  var children = Props.children;
  return React.createElement(ReactCore.Grid, {
              children: children,
              hasGutter: true
            });
}

var MGrid = {
  make: Prelude$MGrid
};

function Prelude$MGridItem(Props) {
  var children = Props.children;
  return React.createElement(ReactCore.GridItem, {
              children: children,
              md: PFTypes.Column._4
            });
}

var MGridItem = {
  make: Prelude$MGridItem
};

function Prelude$MGridItemXl11(Props) {
  var children = Props.children;
  return React.createElement(ReactCore.GridItem, {
              children: children,
              xl: PFTypes.Column._11
            });
}

var MGridItemXl11 = {
  make: Prelude$MGridItemXl11
};

function Prelude$MGridItemXl10(Props) {
  var children = Props.children;
  return React.createElement(ReactCore.GridItem, {
              children: children,
              xl: PFTypes.Column._10
            });
}

var MGridItemXl10 = {
  make: Prelude$MGridItemXl10
};

function Prelude$MGridItemXl9(Props) {
  var children = Props.children;
  return React.createElement(ReactCore.GridItem, {
              children: children,
              xl: PFTypes.Column._9
            });
}

var MGridItemXl9 = {
  make: Prelude$MGridItemXl9
};

function Prelude$MGridItemXl8(Props) {
  var children = Props.children;
  return React.createElement(ReactCore.GridItem, {
              children: children,
              xl: PFTypes.Column._8
            });
}

var MGridItemXl8 = {
  make: Prelude$MGridItemXl8
};

function Prelude$MGridItemXl7(Props) {
  var children = Props.children;
  return React.createElement(ReactCore.GridItem, {
              children: children,
              xl: PFTypes.Column._7
            });
}

var MGridItemXl7 = {
  make: Prelude$MGridItemXl7
};

function Prelude$MGridItemXl6(Props) {
  var children = Props.children;
  return React.createElement(ReactCore.GridItem, {
              children: children,
              xl: PFTypes.Column._6
            });
}

var MGridItemXl6 = {
  make: Prelude$MGridItemXl6
};

function Prelude$MGridItemXl5(Props) {
  var children = Props.children;
  return React.createElement(ReactCore.GridItem, {
              children: children,
              xl: PFTypes.Column._5
            });
}

var MGridItemXl5 = {
  make: Prelude$MGridItemXl5
};

function Prelude$MGridItemXl4(Props) {
  var children = Props.children;
  return React.createElement(ReactCore.GridItem, {
              children: children,
              xl: PFTypes.Column._4
            });
}

var MGridItemXl4 = {
  make: Prelude$MGridItemXl4
};

function Prelude$MGridItemXl3(Props) {
  var children = Props.children;
  return React.createElement(ReactCore.GridItem, {
              children: children,
              xl: PFTypes.Column._3
            });
}

var MGridItemXl3 = {
  make: Prelude$MGridItemXl3
};

function Prelude$MGridItemXl2(Props) {
  var children = Props.children;
  return React.createElement(ReactCore.GridItem, {
              children: children,
              xl: PFTypes.Column._2
            });
}

var MGridItemXl2 = {
  make: Prelude$MGridItemXl2
};

function Prelude$MGridItemXl1(Props) {
  var children = Props.children;
  return React.createElement(ReactCore.GridItem, {
              children: children,
              xl: PFTypes.Column._1
            });
}

var MGridItemXl1 = {
  make: Prelude$MGridItemXl1
};

function Prelude$MStack(Props) {
  var children = Props.children;
  return React.createElement(ReactCore.Stack, {
              children: children,
              hasGutter: true
            });
}

var MStack = {
  make: Prelude$MStack
};

function Prelude$MStackItem(Props) {
  var children = Props.children;
  return React.createElement(ReactCore.StackItem, {
              children: children
            });
}

var MStackItem = {
  make: Prelude$MStackItem
};

function Prelude$MCenteredContent(Props) {
  var children = Props.children;
  return React.createElement(Prelude$MStack, {
              children: null
            }, React.createElement(Prelude$MStackItem, {
                  children: ""
                }), React.createElement(Prelude$MStackItem, {
                  children: React.createElement(Prelude$MGrid, {
                        children: null
                      }, React.createElement(ReactCore.GridItem, {
                            xl: PFTypes.Column._1
                          }), React.createElement(ReactCore.GridItem, {
                            children: children,
                            xl: PFTypes.Column._10
                          }), React.createElement(ReactCore.GridItem, {
                            xl: PFTypes.Column._1
                          }))
                }));
}

var MCenteredContent = {
  make: Prelude$MCenteredContent
};

function Prelude$SortableTable(Props) {
  var items = Props.items;
  var defaultSortedColumn = Props.defaultSortedColumn;
  var columnNames = Props.columnNames;
  var isOrdered = Props.isOrdered;
  var formatters = Props.formatters;
  var match = React.useState(function () {
        return [];
      });
  var setRows = match[1];
  var rows = match[0];
  var match$1 = React.useState(function () {
        return {
                index: defaultSortedColumn,
                direction: "asc"
              };
      });
  var setSortBy = match$1[1];
  var sortBy = match$1[0];
  var doSort = function (rows) {
    return function (param, param$1) {
      return Patternfly.sortRows(rows, isOrdered, param, param$1);
    };
  };
  var onSort = function (param, index, direction) {
    Curry._1(setRows, (function (param) {
            return doSort(rows)(index, direction);
          }));
    return Curry._1(setSortBy, (function (param) {
                  return {
                          index: index,
                          direction: direction
                        };
                }));
  };
  var columns = Belt_Array.map(columnNames, (function (name) {
          return {
                  title: name,
                  transforms: [ReactTable.sortable]
                };
        }));
  React.useEffect((function () {
          Curry._1(setRows, (function (param) {
                  return doSort(Patternfly.mkRows(items, formatters))(sortBy.index, sortBy.direction);
                }));
          
        }), [items]);
  return React.createElement(ReactTable.Table, {
              rows: rows,
              cells: columns,
              sortBy: sortBy,
              onSort: onSort,
              caption: " ",
              variant: "compact",
              children: null
            }, React.createElement(ReactTable.TableHeader, {}), React.createElement(ReactTable.TableBody, {}));
}

var SortableTable = {
  make: Prelude$SortableTable
};

function Prelude$MSelect(Props) {
  var value = Props.value;
  var placeholder = Props.placeholder;
  var multiOpt = Props.multi;
  var isCreatableOpt = Props.isCreatable;
  var options = Props.options;
  var valueChanged = Props.valueChanged;
  var multi = multiOpt !== undefined ? multiOpt : true;
  var isCreatable = isCreatableOpt !== undefined ? isCreatableOpt : false;
  var match = useToggle(false);
  var onToggle = match[2];
  var selections = value !== "" ? value.split(",") : [];
  var onSelect = function (param, newValue, param$1) {
    var nextValues = multi ? (
        selections.includes(newValue) ? selections.filter(function (v) {
                return v !== newValue;
              }) : selections.concat([newValue])
      ) : [newValue];
    Curry._1(valueChanged, nextValues.join(","));
    return Curry._1(onToggle, false);
  };
  var onClear = function (param) {
    return Curry._1(valueChanged, "");
  };
  var onCreateOption = function (v) {
    Curry._1(valueChanged, selections.concat([v]).join(","));
    return Curry._1(onToggle, false);
  };
  var variant = multi ? "Typeaheadmulti" : "Single";
  var inlineFilterPlaceholderText = value !== "" ? placeholder + ": " + value : "";
  var onFilter = function (param, currentInput) {
    var tmp = currentInput === "" ? options : Belt_List.keep(options, (function (opt) {
              return opt.toLowerCase().startsWith(currentInput.toLowerCase());
            }));
    return Belt_List.toArray(Belt_List.map(take(tmp, 20), (function (s) {
                      return React.createElement(ReactCore.SelectOption, {
                                  value: s,
                                  key: s
                                });
                    })));
  };
  return React.createElement(ReactCore.Select, {
              children: Belt_List.toArray(mapWithKey(take(options, 20), (function (key, name) {
                          return React.createElement(ReactCore.SelectOption, {
                                      value: name,
                                      key: key
                                    });
                        }))),
              inlineFilterPlaceholderText: inlineFilterPlaceholderText,
              isCreatable: isCreatable,
              isOpen: match[0],
              onClear: onClear,
              onCreateOption: onCreateOption,
              onFilter: onFilter,
              onSelect: onSelect,
              onToggle: onToggle,
              placeholderText: placeholder,
              selections: selections,
              variant: (function () {
                    switch (variant) {
                      case "Single" :
                          return "single";
                      case "Checkbox" :
                          return "checkbox";
                      case "Typeahead" :
                          return "typeahead";
                      case "Typeaheadmulti" :
                          return "typeaheadmulti";
                      
                    }
                  })()
            });
}

var MSelect = {
  maxCount: 20,
  make: Prelude$MSelect
};

function addProp(props, valueM, mkProp) {
  return Object.assign(Belt_Option.getWithDefault(Belt_Option.flatMap(valueM, (function (prop) {
                        return Caml_option.some(Curry._1(mkProp, prop));
                      })), {}), props);
}

function Prelude$MSimpleCard(Props) {
  var children = Props.children;
  var style = Props.style;
  var onClick = Props.onClick;
  var cardProps = addProp(addProp({
            isCompact: true
          }, onClick, (function (prop) {
              return {
                      onClick: prop
                    };
            })), style, (function (prop) {
          return {
                  style: prop
                };
        }));
  return React.createElement(ReactCore.Card, cardProps, React.createElement(ReactCore.CardBody, {}, children));
}

var MSimpleCard = {
  make: Prelude$MSimpleCard
};

function Prelude$LimitSelector(Props) {
  var limit = Props.limit;
  var setLimit = Props.setLimit;
  var $$default = Props.default;
  var values = Props.values;
  var setLimit$p = function (str) {
    var v = str === "" ? $$default : Caml_format.caml_int_of_string(str);
    return Curry._1(setLimit, (function (param) {
                  return v;
                }));
  };
  return React.createElement(ReactCore.Tooltip, {
              children: React.createElement(Prelude$MSelect, {
                    value: limit > 0 ? String(limit) : "",
                    placeholder: "Set limit",
                    multi: false,
                    options: Belt_List.map(values, (function (prim) {
                            return String(prim);
                          })),
                    valueChanged: setLimit$p
                  }),
              content: "Select the amount of item to display"
            });
}

var LimitSelector = {
  make: Prelude$LimitSelector
};

var horizontalSpacing = {
  paddingRight: "5px",
  paddingLeft: "5px"
};

function Prelude$ExternalLink(Props) {
  var href = Props.href;
  var title = Props.title;
  return React.createElement("a", {
              style: horizontalSpacing,
              href: href,
              rel: "noopener noreferre",
              target: "_blank"
            }, "🔗 " + title);
}

var ExternalLink = {
  make: Prelude$ExternalLink
};

function Prelude$MonoCard(Props) {
  var title = Props.title;
  var tooltip_content = Props.tooltip_content;
  var icon = Props.icon;
  var limitSelector = Props.limitSelector;
  var children = Props.children;
  return React.createElement(ReactCore.Card, {
              children: null,
              isCompact: true
            }, React.createElement(ReactCore.CardTitle, {
                  children: React.createElement(Prelude$MGrid, {
                        children: null
                      }, React.createElement(Prelude$MGridItemXl9, {
                            children: React.createElement(ReactCore.Title, {
                                  children: null,
                                  headingLevel: "h3"
                                }, React.createElement(ReactCore.Tooltip, {
                                      children: icon,
                                      content: tooltip_content
                                    }), " " + title)
                          }), Belt_Option.isSome(limitSelector) ? React.createElement(Prelude$MGridItemXl3, {
                              children: limitSelector
                            }) : null)
                }), React.createElement(ReactCore.CardBody, {
                  children: children
                }));
}

var MonoCard = {
  make: Prelude$MonoCard
};

function Prelude$QueryRender(Props) {
  var request = Props.request;
  var trigger = Props.trigger;
  var render = Props.render;
  return React.createElement(Prelude$NetworkRender, {
              get: (function (param) {
                  return WebApi$Web.Search.query(request);
                }),
              trigger: trigger,
              render: (function (resp) {
                  if (resp.TAG !== /* Error */0) {
                    return Curry._1(render, resp);
                  }
                  var err = resp._0;
                  return React.createElement(ReactCore.Alert, {
                              title: err.message + " at " + String(err.position),
                              variant: "danger"
                            });
                })
            });
}

var QueryRender = {
  make: Prelude$QueryRender
};

var Renderer = {};

function Prelude$QueryRenderCard(Props) {
  var request = Props.request;
  var trigger = Props.trigger;
  var title = Props.title;
  var tooltip_content = Props.tooltip_content;
  var icon = Props.icon;
  var limitSelector = Props.limitSelector;
  var match = Props.match;
  var childrenBuilder = Props.childrenBuilder;
  var render = function (resp) {
    var data = Curry._1(match, resp);
    if (data === undefined) {
      return null;
    }
    var tmp = {
      title: title,
      tooltip_content: tooltip_content,
      icon: icon,
      children: Curry._1(childrenBuilder, Caml_option.valFromOption(data))
    };
    if (limitSelector !== undefined) {
      tmp.limitSelector = Caml_option.valFromOption(limitSelector);
    }
    return React.createElement(Prelude$MonoCard, tmp);
  };
  return React.createElement(Prelude$QueryRender, {
              request: request,
              trigger: trigger,
              render: render
            });
}

var QueryRenderCard = {
  make: Prelude$QueryRenderCard
};

var MonoTabs = {};

function Prelude$HTMXGetHook(Props) {
  var children = Props.children;
  var url = Props.url;
  var trigger = Props.trigger;
  var hxVals = Props.hxVals;
  var htmxSend = function (elm) {
    return React.cloneElement(elm, {
                "hx-get": url,
                "hx-trigger": trigger,
                "hx-target": "closest div",
                "hx-vals": hxVals
              });
  };
  React.useEffect(function () {
        return (htmx.process(htmx.find("#htmx-component")));
      });
  return React.createElement("div", {
              id: "htmx-component"
            }, htmxSend(children));
}

var HTMXGetHook = {
  make: Prelude$HTMXGetHook
};

var AboutModal = Patternfly.AboutModal;

var AboutModalBox = Patternfly.AboutModalBox;

var AboutModalBoxBrand = Patternfly.AboutModalBoxBrand;

var AboutModalBoxCloseButton = Patternfly.AboutModalBoxCloseButton;

var AboutModalBoxContent = Patternfly.AboutModalBoxContent;

var AboutModalBoxHeader = Patternfly.AboutModalBoxHeader;

var AboutModalBoxHero = Patternfly.AboutModalBoxHero;

var AboutModalContainer = Patternfly.AboutModalContainer;

var Accordion = Patternfly.Accordion;

var AccordionContent = Patternfly.AccordionContent;

var AccordionItem = Patternfly.AccordionItem;

var AccordionToggle = Patternfly.AccordionToggle;

var Alert = Patternfly.Alert;

var AlertActionCloseButton = Patternfly.AlertActionCloseButton;

var AlertActionLink = Patternfly.AlertActionLink;

var AlertIcon = Patternfly.AlertIcon;

var AlertGroup = Patternfly.AlertGroup;

var ApplicationLauncher = Patternfly.ApplicationLauncher;

var ApplicationLauncherContent = Patternfly.ApplicationLauncherContent;

var ApplicationLauncherIcon = Patternfly.ApplicationLauncherIcon;

var ApplicationLauncherItem = Patternfly.ApplicationLauncherItem;

var ApplicationLauncherText = Patternfly.ApplicationLauncherText;

var Avatar = Patternfly.Avatar;

var Backdrop = Patternfly.Backdrop;

var BackgroundImage = Patternfly.BackgroundImage;

var Badge = Patternfly.Badge;

var Banner = Patternfly.Banner;

var Brand = Patternfly.Brand;

var Breadcrumb = Patternfly.Breadcrumb;

var BreadcrumbHeading = Patternfly.BreadcrumbHeading;

var BreadcrumbItem = Patternfly.BreadcrumbItem;

var Button = Patternfly.Button;

var Card = Patternfly.Card;

var CardActions = Patternfly.CardActions;

var CardBody = Patternfly.CardBody;

var CardExpandableContent = Patternfly.CardExpandableContent;

var CardFooter = Patternfly.CardFooter;

var CardHeadMain = Patternfly.CardHeadMain;

var CardHeader = Patternfly.CardHeader;

var CardHeaderMain = Patternfly.CardHeaderMain;

var CardTitle = Patternfly.CardTitle;

var Checkbox = Patternfly.Checkbox;

var ClipboardCopy = Patternfly.ClipboardCopy;

var ClipboardCopyAction = Patternfly.ClipboardCopyAction;

var ClipboardCopyButton = Patternfly.ClipboardCopyButton;

var ClipboardCopyExpanded = Patternfly.ClipboardCopyExpanded;

var ClipboardCopyToggle = Patternfly.ClipboardCopyToggle;

var DataList = Patternfly.DataList;

var DataListAction = Patternfly.DataListAction;

var DataListCell = Patternfly.DataListCell;

var DataListCheck = Patternfly.DataListCheck;

var DataListContent = Patternfly.DataListContent;

var DataListControl = Patternfly.DataListControl;

var DataListDragButton = Patternfly.DataListDragButton;

var DataListItemChild = Patternfly.DataListItemChild;

var DataListItem = Patternfly.DataListItem;

var DataListItemCells = Patternfly.DataListItemCells;

var DataListItemRow = Patternfly.DataListItemRow;

var DataListText = Patternfly.DataListText;

var DataListToggle = Patternfly.DataListToggle;

var DatePicker = Patternfly.DatePicker;

var DescriptionList = Patternfly.DescriptionList;

var DescriptionListDescription = Patternfly.DescriptionListDescription;

var DescriptionListGroup = Patternfly.DescriptionListGroup;

var DescriptionListTerm = Patternfly.DescriptionListTerm;

var Divider = Patternfly.Divider;

var DrawerContext = Patternfly.DrawerContext;

var Drawer = Patternfly.Drawer;

var DrawerActions = Patternfly.DrawerActions;

var DrawerCloseButton = Patternfly.DrawerCloseButton;

var DrawerContent = Patternfly.DrawerContent;

var DrawerContentBody = Patternfly.DrawerContentBody;

var DrawerHead = Patternfly.DrawerHead;

var DrawerMain = Patternfly.DrawerMain;

var DrawerPanelBody = Patternfly.DrawerPanelBody;

var DrawerPanelContent = Patternfly.DrawerPanelContent;

var DrawerSection = Patternfly.DrawerSection;

var BadgeToggle = Patternfly.BadgeToggle;

var Dropdown = Patternfly.Dropdown;

var DropdownGroup = Patternfly.DropdownGroup;

var DropdownItem = Patternfly.DropdownItem;

var Separator = Patternfly.Separator;

var DropdownToggle = Patternfly.DropdownToggle;

var DropdownToggleAction = Patternfly.DropdownToggleAction;

var DropdownToggleCheckbox = Patternfly.DropdownToggleCheckbox;

var InternalDropdownItem = Patternfly.InternalDropdownItem;

var KebabToggle = Patternfly.KebabToggle;

var Toggle = Patternfly.Toggle;

var EmptyState = Patternfly.EmptyState;

var EmptyStateBody = Patternfly.EmptyStateBody;

var EmptyStateIcon = Patternfly.EmptyStateIcon;

var Icon = Patternfly.Icon;

var EmptyStatePrimary = Patternfly.EmptyStatePrimary;

var EmptyStateSecondaryActions = Patternfly.EmptyStateSecondaryActions;

var ActionGroup = Patternfly.ActionGroup;

var Form = Patternfly.Form;

var FormAlert = Patternfly.FormAlert;

var FormFieldGroup = Patternfly.FormFieldGroup;

var FormFieldGroupExpandable = Patternfly.FormFieldGroupExpandable;

var FormFieldGroupHeader = Patternfly.FormFieldGroupHeader;

var FormFieldGroupToggle = Patternfly.FormFieldGroupToggle;

var FormGroup = Patternfly.FormGroup;

var FormHelperText = Patternfly.FormHelperText;

var FormSection = Patternfly.FormSection;

var InternalFormFieldGroup = Patternfly.InternalFormFieldGroup;

var Hint = Patternfly.Hint;

var HintBody = Patternfly.HintBody;

var HintFooter = Patternfly.HintFooter;

var HintTitle = Patternfly.HintTitle;

var InputGroup = Patternfly.InputGroup;

var InputGroupText = Patternfly.InputGroupText;

var Label = Patternfly.Label;

var LabelGroup = Patternfly.LabelGroup;

var List = Patternfly.List;

var ListItem = Patternfly.ListItem;

var Login = Patternfly.Login;

var LoginFooter = Patternfly.LoginFooter;

var LoginFooterItem = Patternfly.LoginFooterItem;

var LoginForm = Patternfly.LoginForm;

var LoginHeader = Patternfly.LoginHeader;

var LoginMainBody = Patternfly.LoginMainBody;

var LoginMainFooter = Patternfly.LoginMainFooter;

var LoginMainFooterBandItem = Patternfly.LoginMainFooterBandItem;

var LoginMainFooterLinksItem = Patternfly.LoginMainFooterLinksItem;

var LoginMainHeader = Patternfly.LoginMainHeader;

var LoginPage = Patternfly.LoginPage;

var DrilldownMenu = Patternfly.DrilldownMenu;

var Menu = Patternfly.Menu;

var MenuBreadcrumb = Patternfly.MenuBreadcrumb;

var MenuContent = Patternfly.MenuContent;

var MenuGroup = Patternfly.MenuGroup;

var MenuInput = Patternfly.MenuInput;

var MenuItem = Patternfly.MenuItem;

var MenuItemAction = Patternfly.MenuItemAction;

var MenuList = Patternfly.MenuList;

var Modal = Patternfly.Modal;

var ModalBox = Patternfly.ModalBox;

var ModalBoxBody = Patternfly.ModalBoxBody;

var ModalBoxCloseButton = Patternfly.ModalBoxCloseButton;

var ModalBoxDescription = Patternfly.ModalBoxDescription;

var ModalBoxFooter = Patternfly.ModalBoxFooter;

var ModalBoxHeader = Patternfly.ModalBoxHeader;

var ModalBoxTitle = Patternfly.ModalBoxTitle;

var ModalContent = Patternfly.ModalContent;

var Nav = Patternfly.Nav;

var NavExpandable = Patternfly.NavExpandable;

var NavGroup = Patternfly.NavGroup;

var NavItem = Patternfly.NavItem;

var NavList = Patternfly.NavList;

var Page = Patternfly.Page;

var PageContext = Patternfly.PageContext;

var PageBreadcrumb = Patternfly.PageBreadcrumb;

var PageGroup = Patternfly.PageGroup;

var PageHeader = Patternfly.PageHeader;

var PageHeaderTools = Patternfly.PageHeaderTools;

var PageHeaderToolsGroup = Patternfly.PageHeaderToolsGroup;

var PageHeaderToolsItem = Patternfly.PageHeaderToolsItem;

var PageNavigation = Patternfly.PageNavigation;

var PageSection = Patternfly.PageSection;

var PageSidebarContext = Patternfly.PageSidebarContext;

var PageSidebar = Patternfly.PageSidebar;

var Navigation = Patternfly.Navigation;

var OptionsToggle = Patternfly.OptionsToggle;

var Pagination = Patternfly.Pagination;

var PaginationOptionsMenu = Patternfly.PaginationOptionsMenu;

var ToggleTemplate = Patternfly.ToggleTemplate;

var Radio = Patternfly.Radio;

var Select = Patternfly.Select;

var SelectGroup = Patternfly.SelectGroup;

var SelectMenu = Patternfly.SelectMenu;

var SelectOption = Patternfly.SelectOption;

var SelectToggle = Patternfly.SelectToggle;

var Spinner = Patternfly.Spinner;

var Tab = Patternfly.Tab;

var TabButton = Patternfly.TabButton;

var TabContent = Patternfly.TabContent;

var TabTitleIcon = Patternfly.TabTitleIcon;

var TabTitleText = Patternfly.TabTitleText;

var Tabs = Patternfly.Tabs;

var $$Text = Patternfly.$$Text;

var TextContent = Patternfly.TextContent;

var TextList = Patternfly.TextList;

var TextListItem = Patternfly.TextListItem;

var TextArea = Patternfly.TextArea;

var TextInput = Patternfly.TextInput;

var Tile = Patternfly.Tile;

var Title = Patternfly.Title;

var Toolbar = Patternfly.Toolbar;

var ToolbarChipGroupContent = Patternfly.ToolbarChipGroupContent;

var ToolbarContent = Patternfly.ToolbarContent;

var ToolbarExpandIconWrapper = Patternfly.ToolbarExpandIconWrapper;

var ToolbarExpandableContent = Patternfly.ToolbarExpandableContent;

var ToolbarFilter = Patternfly.ToolbarFilter;

var ToolbarItem = Patternfly.ToolbarItem;

var ToolbarToggleGroup = Patternfly.ToolbarToggleGroup;

var Tooltip = Patternfly.Tooltip;

var TooltipArrow = Patternfly.TooltipArrow;

var TooltipContent = Patternfly.TooltipContent;

var Column = Patternfly.Column;

var Icons = Patternfly.Icons;

var Layout = Patternfly.Layout;

var mkFormatter = Patternfly.mkFormatter;

var mkRows = Patternfly.mkRows;

var sortRows = Patternfly.sortRows;

var Table = Patternfly.Table;

var TableBody = Patternfly.TableBody;

var TableHeader = Patternfly.TableHeader;

exports.AboutModal = AboutModal;
exports.AboutModalBox = AboutModalBox;
exports.AboutModalBoxBrand = AboutModalBoxBrand;
exports.AboutModalBoxCloseButton = AboutModalBoxCloseButton;
exports.AboutModalBoxContent = AboutModalBoxContent;
exports.AboutModalBoxHeader = AboutModalBoxHeader;
exports.AboutModalBoxHero = AboutModalBoxHero;
exports.AboutModalContainer = AboutModalContainer;
exports.Accordion = Accordion;
exports.AccordionContent = AccordionContent;
exports.AccordionItem = AccordionItem;
exports.AccordionToggle = AccordionToggle;
exports.Alert = Alert;
exports.AlertActionCloseButton = AlertActionCloseButton;
exports.AlertActionLink = AlertActionLink;
exports.AlertIcon = AlertIcon;
exports.AlertGroup = AlertGroup;
exports.ApplicationLauncher = ApplicationLauncher;
exports.ApplicationLauncherContent = ApplicationLauncherContent;
exports.ApplicationLauncherIcon = ApplicationLauncherIcon;
exports.ApplicationLauncherItem = ApplicationLauncherItem;
exports.ApplicationLauncherText = ApplicationLauncherText;
exports.Avatar = Avatar;
exports.Backdrop = Backdrop;
exports.BackgroundImage = BackgroundImage;
exports.Badge = Badge;
exports.Banner = Banner;
exports.Brand = Brand;
exports.Breadcrumb = Breadcrumb;
exports.BreadcrumbHeading = BreadcrumbHeading;
exports.BreadcrumbItem = BreadcrumbItem;
exports.Button = Button;
exports.Card = Card;
exports.CardActions = CardActions;
exports.CardBody = CardBody;
exports.CardExpandableContent = CardExpandableContent;
exports.CardFooter = CardFooter;
exports.CardHeadMain = CardHeadMain;
exports.CardHeader = CardHeader;
exports.CardHeaderMain = CardHeaderMain;
exports.CardTitle = CardTitle;
exports.Checkbox = Checkbox;
exports.ClipboardCopy = ClipboardCopy;
exports.ClipboardCopyAction = ClipboardCopyAction;
exports.ClipboardCopyButton = ClipboardCopyButton;
exports.ClipboardCopyExpanded = ClipboardCopyExpanded;
exports.ClipboardCopyToggle = ClipboardCopyToggle;
exports.DataList = DataList;
exports.DataListAction = DataListAction;
exports.DataListCell = DataListCell;
exports.DataListCheck = DataListCheck;
exports.DataListContent = DataListContent;
exports.DataListControl = DataListControl;
exports.DataListDragButton = DataListDragButton;
exports.DataListItemChild = DataListItemChild;
exports.DataListItem = DataListItem;
exports.DataListItemCells = DataListItemCells;
exports.DataListItemRow = DataListItemRow;
exports.DataListText = DataListText;
exports.DataListToggle = DataListToggle;
exports.DatePicker = DatePicker;
exports.DescriptionList = DescriptionList;
exports.DescriptionListDescription = DescriptionListDescription;
exports.DescriptionListGroup = DescriptionListGroup;
exports.DescriptionListTerm = DescriptionListTerm;
exports.Divider = Divider;
exports.DrawerContext = DrawerContext;
exports.Drawer = Drawer;
exports.DrawerActions = DrawerActions;
exports.DrawerCloseButton = DrawerCloseButton;
exports.DrawerContent = DrawerContent;
exports.DrawerContentBody = DrawerContentBody;
exports.DrawerHead = DrawerHead;
exports.DrawerMain = DrawerMain;
exports.DrawerPanelBody = DrawerPanelBody;
exports.DrawerPanelContent = DrawerPanelContent;
exports.DrawerSection = DrawerSection;
exports.BadgeToggle = BadgeToggle;
exports.Dropdown = Dropdown;
exports.DropdownGroup = DropdownGroup;
exports.DropdownItem = DropdownItem;
exports.Separator = Separator;
exports.DropdownToggle = DropdownToggle;
exports.DropdownToggleAction = DropdownToggleAction;
exports.DropdownToggleCheckbox = DropdownToggleCheckbox;
exports.InternalDropdownItem = InternalDropdownItem;
exports.KebabToggle = KebabToggle;
exports.Toggle = Toggle;
exports.EmptyState = EmptyState;
exports.EmptyStateBody = EmptyStateBody;
exports.EmptyStateIcon = EmptyStateIcon;
exports.Icon = Icon;
exports.EmptyStatePrimary = EmptyStatePrimary;
exports.EmptyStateSecondaryActions = EmptyStateSecondaryActions;
exports.ActionGroup = ActionGroup;
exports.Form = Form;
exports.FormAlert = FormAlert;
exports.FormFieldGroup = FormFieldGroup;
exports.FormFieldGroupExpandable = FormFieldGroupExpandable;
exports.FormFieldGroupHeader = FormFieldGroupHeader;
exports.FormFieldGroupToggle = FormFieldGroupToggle;
exports.FormGroup = FormGroup;
exports.FormHelperText = FormHelperText;
exports.FormSection = FormSection;
exports.InternalFormFieldGroup = InternalFormFieldGroup;
exports.Hint = Hint;
exports.HintBody = HintBody;
exports.HintFooter = HintFooter;
exports.HintTitle = HintTitle;
exports.InputGroup = InputGroup;
exports.InputGroupText = InputGroupText;
exports.Label = Label;
exports.LabelGroup = LabelGroup;
exports.List = List;
exports.ListItem = ListItem;
exports.Login = Login;
exports.LoginFooter = LoginFooter;
exports.LoginFooterItem = LoginFooterItem;
exports.LoginForm = LoginForm;
exports.LoginHeader = LoginHeader;
exports.LoginMainBody = LoginMainBody;
exports.LoginMainFooter = LoginMainFooter;
exports.LoginMainFooterBandItem = LoginMainFooterBandItem;
exports.LoginMainFooterLinksItem = LoginMainFooterLinksItem;
exports.LoginMainHeader = LoginMainHeader;
exports.LoginPage = LoginPage;
exports.DrilldownMenu = DrilldownMenu;
exports.Menu = Menu;
exports.MenuBreadcrumb = MenuBreadcrumb;
exports.MenuContent = MenuContent;
exports.MenuGroup = MenuGroup;
exports.MenuInput = MenuInput;
exports.MenuItem = MenuItem;
exports.MenuItemAction = MenuItemAction;
exports.MenuList = MenuList;
exports.Modal = Modal;
exports.ModalBox = ModalBox;
exports.ModalBoxBody = ModalBoxBody;
exports.ModalBoxCloseButton = ModalBoxCloseButton;
exports.ModalBoxDescription = ModalBoxDescription;
exports.ModalBoxFooter = ModalBoxFooter;
exports.ModalBoxHeader = ModalBoxHeader;
exports.ModalBoxTitle = ModalBoxTitle;
exports.ModalContent = ModalContent;
exports.Nav = Nav;
exports.NavExpandable = NavExpandable;
exports.NavGroup = NavGroup;
exports.NavItem = NavItem;
exports.NavList = NavList;
exports.Page = Page;
exports.PageContext = PageContext;
exports.PageBreadcrumb = PageBreadcrumb;
exports.PageGroup = PageGroup;
exports.PageHeader = PageHeader;
exports.PageHeaderTools = PageHeaderTools;
exports.PageHeaderToolsGroup = PageHeaderToolsGroup;
exports.PageHeaderToolsItem = PageHeaderToolsItem;
exports.PageNavigation = PageNavigation;
exports.PageSection = PageSection;
exports.PageSidebarContext = PageSidebarContext;
exports.PageSidebar = PageSidebar;
exports.Navigation = Navigation;
exports.OptionsToggle = OptionsToggle;
exports.Pagination = Pagination;
exports.PaginationOptionsMenu = PaginationOptionsMenu;
exports.ToggleTemplate = ToggleTemplate;
exports.Radio = Radio;
exports.Select = Select;
exports.SelectGroup = SelectGroup;
exports.SelectMenu = SelectMenu;
exports.SelectOption = SelectOption;
exports.SelectToggle = SelectToggle;
exports.Spinner = Spinner;
exports.Tab = Tab;
exports.TabButton = TabButton;
exports.TabContent = TabContent;
exports.TabTitleIcon = TabTitleIcon;
exports.TabTitleText = TabTitleText;
exports.Tabs = Tabs;
exports.$$Text = $$Text;
exports.TextContent = TextContent;
exports.TextList = TextList;
exports.TextListItem = TextListItem;
exports.TextArea = TextArea;
exports.TextInput = TextInput;
exports.Tile = Tile;
exports.Title = Title;
exports.Toolbar = Toolbar;
exports.ToolbarChipGroupContent = ToolbarChipGroupContent;
exports.ToolbarContent = ToolbarContent;
exports.ToolbarExpandIconWrapper = ToolbarExpandIconWrapper;
exports.ToolbarExpandableContent = ToolbarExpandableContent;
exports.ToolbarFilter = ToolbarFilter;
exports.ToolbarItem = ToolbarItem;
exports.ToolbarToggleGroup = ToolbarToggleGroup;
exports.Tooltip = Tooltip;
exports.TooltipArrow = TooltipArrow;
exports.TooltipContent = TooltipContent;
exports.Column = Column;
exports.Icons = Icons;
exports.Layout = Layout;
exports.mkFormatter = mkFormatter;
exports.mkRows = mkRows;
exports.sortRows = sortRows;
exports.Table = Table;
exports.TableBody = TableBody;
exports.TableHeader = TableHeader;
exports.readWindowLocationSearch = readWindowLocationSearch;
exports.readWindowLocationHash = readWindowLocationHash;
exports.readWindowLocationPathname = readWindowLocationPathname;
exports.replaceWindowLocation = replaceWindowLocation;
exports.readWindowLocationFull = readWindowLocationFull;
exports.getCookies = getCookies;
exports.delCookie = delCookie;
exports.momentFromNow = momentFromNow;
exports.momentHumanizeDuration = momentHumanizeDuration;
exports.resetLocationSearch = resetLocationSearch;
exports.setLocationSearch = setLocationSearch;
exports.Link = Link;
exports.Env = Env;
exports.Time = Time;
exports.$$URLSearchParams = $$URLSearchParams$1;
exports.usePagination = usePagination;
exports.useAutoGetOn = useAutoGetOn;
exports.useAutoGet = useAutoGet;
exports.NetworkRender = NetworkRender;
exports.getDate = getDate;
exports.getCurrentTime = getCurrentTime;
exports.str = str;
exports.maybeRender = maybeRender;
exports.maybeHide = maybeHide;
exports.maybeRenderArray = maybeRenderArray;
exports.maybeRenderList = maybeRenderList;
exports.lower = lower;
exports.int32_str = int32_str;
exports.float_str = float_str;
exports.quoteValue = quoteValue;
exports.take = take;
exports.elemText = elemText;
exports.fromMaybe = fromMaybe;
exports.mapWithKey = mapWithKey;
exports.catMaybes = catMaybes;
exports.prependToAll = prependToAll;
exports.concatSep = concatSep;
exports.useToggle = useToggle;
exports.addQuery = addQuery;
exports.orderFromQS = orderFromQS;
exports.orderDirToQS = orderDirToQS;
exports.orderToQS = orderToQS;
exports.orderDirToString = orderDirToString;
exports.MExpandablePanel = MExpandablePanel;
exports.MGrid = MGrid;
exports.MGridItem = MGridItem;
exports.MGridItemXl11 = MGridItemXl11;
exports.MGridItemXl10 = MGridItemXl10;
exports.MGridItemXl9 = MGridItemXl9;
exports.MGridItemXl8 = MGridItemXl8;
exports.MGridItemXl7 = MGridItemXl7;
exports.MGridItemXl6 = MGridItemXl6;
exports.MGridItemXl5 = MGridItemXl5;
exports.MGridItemXl4 = MGridItemXl4;
exports.MGridItemXl3 = MGridItemXl3;
exports.MGridItemXl2 = MGridItemXl2;
exports.MGridItemXl1 = MGridItemXl1;
exports.MStack = MStack;
exports.MStackItem = MStackItem;
exports.MCenteredContent = MCenteredContent;
exports.SortableTable = SortableTable;
exports.MSelect = MSelect;
exports.addProp = addProp;
exports.MSimpleCard = MSimpleCard;
exports.LimitSelector = LimitSelector;
exports.horizontalSpacing = horizontalSpacing;
exports.ExternalLink = ExternalLink;
exports.MonoCard = MonoCard;
exports.QueryRender = QueryRender;
exports.Renderer = Renderer;
exports.QueryRenderCard = QueryRenderCard;
exports.MonoTabs = MonoTabs;
exports.HTMXGetHook = HTMXGetHook;
/*  Not a pure module */
