'use strict';


function default_metric_info(nameOpt, descriptionOpt, long_descriptionOpt, metricOpt, param) {
  var name = nameOpt !== undefined ? nameOpt : "";
  var description = descriptionOpt !== undefined ? descriptionOpt : "";
  var long_description = long_descriptionOpt !== undefined ? long_descriptionOpt : "";
  var metric = metricOpt !== undefined ? metricOpt : "";
  return {
          name: name,
          description: description,
          long_description: long_description,
          metric: metric
        };
}

function default_list_request(voidOpt, param) {
  var $$void = voidOpt !== undefined ? voidOpt : "";
  return {
          void: $$void
        };
}

function default_list_response(metricsOpt, param) {
  var metrics = metricsOpt !== undefined ? metricsOpt : /* [] */0;
  return {
          metrics: metrics
        };
}

function default_trend(intervalOpt, param) {
  var interval = intervalOpt !== undefined ? intervalOpt : "";
  return {
          interval: interval
        };
}

function default_top(limitOpt, param) {
  var limit = limitOpt !== undefined ? limitOpt : 0;
  return {
          limit: limit
        };
}

function default_compute(voidOpt, param) {
  var $$void = voidOpt !== undefined ? voidOpt : "";
  return {
          void: $$void
        };
}

function default_get_request_options(param) {
  var $$void = "";
  return {
          TAG: /* Compute */0,
          _0: {
            void: $$void
          }
        };
}

function default_get_request(indexOpt, usernameOpt, queryOpt, metricOpt, optionsOpt, param) {
  var index = indexOpt !== undefined ? indexOpt : "";
  var username = usernameOpt !== undefined ? usernameOpt : "";
  var query = queryOpt !== undefined ? queryOpt : "";
  var metric = metricOpt !== undefined ? metricOpt : "";
  var options;
  if (optionsOpt !== undefined) {
    options = optionsOpt;
  } else {
    var $$void = "";
    options = {
      TAG: /* Compute */0,
      _0: {
        void: $$void
      }
    };
  }
  return {
          index: index,
          username: username,
          query: query,
          metric: metric,
          options: options
        };
}

function default_info_request(metricOpt, param) {
  var metric = metricOpt !== undefined ? metricOpt : "";
  return {
          metric: metric
        };
}

function default_histo_int(dateOpt, countOpt, param) {
  var date = dateOpt !== undefined ? dateOpt : "";
  var count = countOpt !== undefined ? countOpt : 0;
  return {
          date: date,
          count: count
        };
}

function default_histo_float(dateOpt, countOpt, param) {
  var date = dateOpt !== undefined ? dateOpt : "";
  var count = countOpt !== undefined ? countOpt : 0;
  return {
          date: date,
          count: count
        };
}

function default_histo_int_stat(histoOpt, param) {
  var histo = histoOpt !== undefined ? histoOpt : /* [] */0;
  return {
          histo: histo
        };
}

function default_histo_float_stat(histoOpt, param) {
  var histo = histoOpt !== undefined ? histoOpt : /* [] */0;
  return {
          histo: histo
        };
}

function default_term_count_int(termOpt, countOpt, param) {
  var term = termOpt !== undefined ? termOpt : "";
  var count = countOpt !== undefined ? countOpt : 0;
  return {
          term: term,
          count: count
        };
}

function default_terms_count_int(termcountOpt, total_hitsOpt, param) {
  var termcount = termcountOpt !== undefined ? termcountOpt : /* [] */0;
  var total_hits = total_hitsOpt !== undefined ? total_hitsOpt : 0;
  return {
          termcount: termcount,
          total_hits: total_hits
        };
}

function default_term_count_float(termOpt, countOpt, param) {
  var term = termOpt !== undefined ? termOpt : "";
  var count = countOpt !== undefined ? countOpt : 0;
  return {
          term: term,
          count: count
        };
}

function default_terms_count_float(termcountOpt, total_hitsOpt, param) {
  var termcount = termcountOpt !== undefined ? termcountOpt : /* [] */0;
  var total_hits = total_hitsOpt !== undefined ? total_hitsOpt : 0;
  return {
          termcount: termcount,
          total_hits: total_hits
        };
}

function default_duration(valueOpt, param) {
  var value = valueOpt !== undefined ? valueOpt : 0;
  return {
          value: value
        };
}

function default_histo_duration(dateOpt, countOpt, param) {
  var date = dateOpt !== undefined ? dateOpt : "";
  var count = countOpt !== undefined ? countOpt : 0;
  return {
          date: date,
          count: count
        };
}

function default_histo_duration_stat(histoOpt, param) {
  var histo = histoOpt !== undefined ? histoOpt : /* [] */0;
  return {
          histo: histo
        };
}

function default_term_count_duration(termOpt, countOpt, param) {
  var term = termOpt !== undefined ? termOpt : "";
  var count = countOpt !== undefined ? countOpt : 0;
  return {
          term: term,
          count: count
        };
}

function default_terms_count_duration(termcountOpt, total_hitsOpt, param) {
  var termcount = termcountOpt !== undefined ? termcountOpt : /* [] */0;
  var total_hits = total_hitsOpt !== undefined ? total_hitsOpt : 0;
  return {
          termcount: termcount,
          total_hits: total_hits
        };
}

function default_get_response(param) {
  return {
          TAG: /* Error */0,
          _0: ""
        };
}

function default_info_response(param) {
  return {
          TAG: /* Error */0,
          _0: ""
        };
}

exports.default_metric_info = default_metric_info;
exports.default_list_request = default_list_request;
exports.default_list_response = default_list_response;
exports.default_trend = default_trend;
exports.default_top = default_top;
exports.default_compute = default_compute;
exports.default_get_request_options = default_get_request_options;
exports.default_get_request = default_get_request;
exports.default_info_request = default_info_request;
exports.default_histo_int = default_histo_int;
exports.default_histo_float = default_histo_float;
exports.default_histo_int_stat = default_histo_int_stat;
exports.default_histo_float_stat = default_histo_float_stat;
exports.default_term_count_int = default_term_count_int;
exports.default_terms_count_int = default_terms_count_int;
exports.default_term_count_float = default_term_count_float;
exports.default_terms_count_float = default_terms_count_float;
exports.default_duration = default_duration;
exports.default_histo_duration = default_histo_duration;
exports.default_histo_duration_stat = default_histo_duration_stat;
exports.default_term_count_duration = default_term_count_duration;
exports.default_terms_count_duration = default_terms_count_duration;
exports.default_get_response = default_get_response;
exports.default_info_response = default_info_response;
/* No side effect */
