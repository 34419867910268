'use strict';

var $$Array = require("rescript/lib/js/array.js");
var Pbrt_bs = require("bs-ocaml-protoc-json/src/pbrt_bs.bs.js");
var ConfigTypes$Web = require("./ConfigTypes.bs.js");

function default_about_mutable(param) {
  return {
          version: "",
          links: /* [] */0,
          auth: /* Auth_config */{
            _0: ConfigTypes$Web.default_about_auth_config(undefined, undefined, undefined, undefined)
          }
        };
}

function decode_project_definition(json) {
  var v = {
    name: "",
    repository_regex: "",
    branch_regex: "",
    file_regex: ""
  };
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    switch (match) {
      case "branch_regex" :
          var json$1 = json["branch_regex"];
          v.branch_regex = Pbrt_bs.string(json$1, "project_definition", "branch_regex");
          break;
      case "file_regex" :
          var json$2 = json["file_regex"];
          v.file_regex = Pbrt_bs.string(json$2, "project_definition", "file_regex");
          break;
      case "name" :
          var json$3 = json["name"];
          v.name = Pbrt_bs.string(json$3, "project_definition", "name");
          break;
      case "repository_regex" :
          var json$4 = json["repository_regex"];
          v.repository_regex = Pbrt_bs.string(json$4, "project_definition", "repository_regex");
          break;
      default:
        
    }
  }
  return {
          name: v.name,
          repository_regex: v.repository_regex,
          branch_regex: v.branch_regex,
          file_regex: v.file_regex
        };
}

function decode_get_projects_request(json) {
  var v = "";
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    if (match === "index") {
      var json$1 = json["index"];
      v = Pbrt_bs.string(json$1, "get_projects_request", "index");
    }
    
  }
  return {
          index: v
        };
}

function decode_get_projects_response(json) {
  var v = /* [] */0;
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    if (match === "projects") {
      var a = json["projects"];
      var a$1 = Pbrt_bs.array_(a, "get_projects_response", "projects");
      v = $$Array.to_list($$Array.map((function (json) {
                  return decode_project_definition(Pbrt_bs.object_(json, "get_projects_response", "projects"));
                }), a$1));
    }
    
  }
  return {
          projects: v
        };
}

function decode_workspace(json) {
  var v = "";
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    if (match === "name") {
      var json$1 = json["name"];
      v = Pbrt_bs.string(json$1, "workspace", "name");
    }
    
  }
  return {
          name: v
        };
}

function decode_get_workspaces_request(json) {
  var v = "";
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    if (match === "void") {
      var json$1 = json["void"];
      v = Pbrt_bs.string(json$1, "get_workspaces_request", "void");
    }
    
  }
  return {
          void: v
        };
}

function decode_get_workspaces_response(json) {
  var v = /* [] */0;
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    if (match === "workspaces") {
      var a = json["workspaces"];
      var a$1 = Pbrt_bs.array_(a, "get_workspaces_response", "workspaces");
      v = $$Array.to_list($$Array.map((function (json) {
                  return decode_workspace(Pbrt_bs.object_(json, "get_workspaces_response", "workspaces"));
                }), a$1));
    }
    
  }
  return {
          workspaces: v
        };
}

function decode_about_about_link(json) {
  var v = {
    name: "",
    url: "",
    category: ""
  };
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    switch (match) {
      case "category" :
          var json$1 = json["category"];
          v.category = Pbrt_bs.string(json$1, "about_about_link", "category");
          break;
      case "name" :
          var json$2 = json["name"];
          v.name = Pbrt_bs.string(json$2, "about_about_link", "name");
          break;
      case "url" :
          var json$3 = json["url"];
          v.url = Pbrt_bs.string(json$3, "about_about_link", "url");
          break;
      default:
        
    }
  }
  return {
          name: v.name,
          url: v.url,
          category: v.category
        };
}

function decode_about_auth_config(json) {
  var v = {
    force_login: false,
    issuer: "",
    provider_name: ""
  };
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    switch (match) {
      case "force_login" :
          var json$1 = json["force_login"];
          v.force_login = Pbrt_bs.bool(json$1, "about_auth_config", "force_login");
          break;
      case "issuer" :
          var json$2 = json["issuer"];
          v.issuer = Pbrt_bs.string(json$2, "about_auth_config", "issuer");
          break;
      case "provider_name" :
          var json$3 = json["provider_name"];
          v.provider_name = Pbrt_bs.string(json$3, "about_auth_config", "provider_name");
          break;
      default:
        
    }
  }
  return {
          force_login: v.force_login,
          issuer: v.issuer,
          provider_name: v.provider_name
        };
}

function decode_about_auth(json) {
  var keys = Object.keys(json);
  var _i = keys.length - 1 | 0;
  while(true) {
    var i = _i;
    if (i === -1) {
      return Pbrt_bs.E.malformed_variant("about_auth");
    }
    var match = keys[i];
    if (match === "auth_config") {
      var json$1 = json["auth_config"];
      return /* Auth_config */{
              _0: decode_about_auth_config(Pbrt_bs.object_(json$1, "about_auth", "Auth_config"))
            };
    }
    _i = i - 1 | 0;
    continue ;
  };
}

function decode_about(json) {
  var v = default_about_mutable(undefined);
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    switch (match) {
      case "auth_config" :
          var json$1 = json["auth_config"];
          v.auth = /* Auth_config */{
            _0: decode_about_auth_config(Pbrt_bs.object_(json$1, "about", "auth"))
          };
          break;
      case "links" :
          var a = json["links"];
          var a$1 = Pbrt_bs.array_(a, "about", "links");
          v.links = $$Array.to_list($$Array.map((function (json) {
                      return decode_about_about_link(Pbrt_bs.object_(json, "about", "links"));
                    }), a$1));
          break;
      case "version" :
          var json$2 = json["version"];
          v.version = Pbrt_bs.string(json$2, "about", "version");
          break;
      default:
        
    }
  }
  return {
          version: v.version,
          links: v.links,
          auth: v.auth
        };
}

function decode_get_about_request(json) {
  var v = "";
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    if (match === "void") {
      var json$1 = json["void"];
      v = Pbrt_bs.string(json$1, "get_about_request", "void");
    }
    
  }
  return {
          void: v
        };
}

function decode_get_about_response(json) {
  var v;
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    if (match === "about") {
      var json$1 = json["about"];
      v = decode_about(Pbrt_bs.object_(json$1, "get_about_response", "about"));
    }
    
  }
  return {
          about: v
        };
}

function decode_group_definition(json) {
  var v = {
    name: "",
    members: 0
  };
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    switch (match) {
      case "members" :
          var json$1 = json["members"];
          v.members = Pbrt_bs.int32(json$1, "group_definition", "members");
          break;
      case "name" :
          var json$2 = json["name"];
          v.name = Pbrt_bs.string(json$2, "group_definition", "name");
          break;
      default:
        
    }
  }
  return {
          name: v.name,
          members: v.members
        };
}

function decode_get_groups_request(json) {
  var v = "";
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    if (match === "index") {
      var json$1 = json["index"];
      v = Pbrt_bs.string(json$1, "get_groups_request", "index");
    }
    
  }
  return {
          index: v
        };
}

function decode_get_groups_response(json) {
  var v = /* [] */0;
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    if (match === "items") {
      var a = json["items"];
      var a$1 = Pbrt_bs.array_(a, "get_groups_response", "items");
      v = $$Array.to_list($$Array.map((function (json) {
                  return decode_group_definition(Pbrt_bs.object_(json, "get_groups_response", "items"));
                }), a$1));
    }
    
  }
  return {
          items: v
        };
}

function decode_get_group_members_request(json) {
  var v = {
    index: "",
    group: ""
  };
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    switch (match) {
      case "group" :
          var json$1 = json["group"];
          v.group = Pbrt_bs.string(json$1, "get_group_members_request", "group");
          break;
      case "index" :
          var json$2 = json["index"];
          v.index = Pbrt_bs.string(json$2, "get_group_members_request", "index");
          break;
      default:
        
    }
  }
  return {
          index: v.index,
          group: v.group
        };
}

function decode_get_group_members_response(json) {
  var v = /* [] */0;
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    if (match === "members") {
      var a = json["members"];
      var a$1 = Pbrt_bs.array_(a, "get_group_members_response", "members");
      v = $$Array.to_list($$Array.map((function (json) {
                  return Pbrt_bs.string(json, "get_group_members_response", "members");
                }), a$1));
    }
    
  }
  return {
          members: v
        };
}

function encode_project_definition(v) {
  var json = {};
  json["name"] = v.name;
  json["repository_regex"] = v.repository_regex;
  json["branch_regex"] = v.branch_regex;
  json["file_regex"] = v.file_regex;
  return json;
}

function encode_get_projects_request(v) {
  var json = {};
  json["index"] = v.index;
  return json;
}

function encode_get_projects_response(v) {
  var json = {};
  var projects$p = $$Array.map(encode_project_definition, $$Array.of_list(v.projects));
  json["projects"] = projects$p;
  return json;
}

function encode_workspace(v) {
  var json = {};
  json["name"] = v.name;
  return json;
}

function encode_get_workspaces_request(v) {
  var json = {};
  json["void"] = v.void;
  return json;
}

function encode_get_workspaces_response(v) {
  var json = {};
  var workspaces$p = $$Array.map(encode_workspace, $$Array.of_list(v.workspaces));
  json["workspaces"] = workspaces$p;
  return json;
}

function encode_about_about_link(v) {
  var json = {};
  json["name"] = v.name;
  json["url"] = v.url;
  json["category"] = v.category;
  return json;
}

function encode_about_auth_config(v) {
  var json = {};
  json["force_login"] = v.force_login;
  json["issuer"] = v.issuer;
  json["provider_name"] = v.provider_name;
  return json;
}

function encode_about_auth(v) {
  var json = {};
  var json$p = encode_about_auth_config(v._0);
  json["auth_config"] = json$p;
  return json;
}

function encode_about(v) {
  var json = {};
  json["version"] = v.version;
  var links$p = $$Array.map(encode_about_about_link, $$Array.of_list(v.links));
  json["links"] = links$p;
  var v$1 = v.auth;
  var json$p = encode_about_auth_config(v$1._0);
  json["auth_config"] = json$p;
  return json;
}

function encode_get_about_request(v) {
  var json = {};
  json["void"] = v.void;
  return json;
}

function encode_get_about_response(v) {
  var json = {};
  var v$1 = v.about;
  if (v$1 !== undefined) {
    var json$p = encode_about(v$1);
    json["about"] = json$p;
  }
  return json;
}

function encode_group_definition(v) {
  var json = {};
  json["name"] = v.name;
  json["members"] = v.members;
  return json;
}

function encode_get_groups_request(v) {
  var json = {};
  json["index"] = v.index;
  return json;
}

function encode_get_groups_response(v) {
  var json = {};
  var items$p = $$Array.map(encode_group_definition, $$Array.of_list(v.items));
  json["items"] = items$p;
  return json;
}

function encode_get_group_members_request(v) {
  var json = {};
  json["index"] = v.index;
  json["group"] = v.group;
  return json;
}

function encode_get_group_members_response(v) {
  var json = {};
  var a = $$Array.map((function (prim) {
          return prim;
        }), $$Array.of_list(v.members));
  json["members"] = a;
  return json;
}

exports.encode_project_definition = encode_project_definition;
exports.encode_get_projects_request = encode_get_projects_request;
exports.encode_get_projects_response = encode_get_projects_response;
exports.encode_workspace = encode_workspace;
exports.encode_get_workspaces_request = encode_get_workspaces_request;
exports.encode_get_workspaces_response = encode_get_workspaces_response;
exports.encode_about_about_link = encode_about_about_link;
exports.encode_about_auth_config = encode_about_auth_config;
exports.encode_about_auth = encode_about_auth;
exports.encode_about = encode_about;
exports.encode_get_about_request = encode_get_about_request;
exports.encode_get_about_response = encode_get_about_response;
exports.encode_group_definition = encode_group_definition;
exports.encode_get_groups_request = encode_get_groups_request;
exports.encode_get_groups_response = encode_get_groups_response;
exports.encode_get_group_members_request = encode_get_group_members_request;
exports.encode_get_group_members_response = encode_get_group_members_response;
exports.decode_project_definition = decode_project_definition;
exports.decode_get_projects_request = decode_get_projects_request;
exports.decode_get_projects_response = decode_get_projects_response;
exports.decode_workspace = decode_workspace;
exports.decode_get_workspaces_request = decode_get_workspaces_request;
exports.decode_get_workspaces_response = decode_get_workspaces_response;
exports.decode_about_about_link = decode_about_about_link;
exports.decode_about_auth_config = decode_about_auth_config;
exports.decode_about_auth = decode_about_auth;
exports.decode_about = decode_about;
exports.decode_get_about_request = decode_get_about_request;
exports.decode_get_about_response = decode_get_about_response;
exports.decode_group_definition = decode_group_definition;
exports.decode_get_groups_request = decode_get_groups_request;
exports.decode_get_groups_response = decode_get_groups_response;
exports.decode_get_group_members_request = decode_get_group_members_request;
exports.decode_get_group_members_response = decode_get_group_members_response;
/* Pbrt_bs Not a pure module */
