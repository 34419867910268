'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_List = require("rescript/lib/js/belt_List.js");
var WebApi$Web = require("./WebApi.bs.js");
var Prelude$Web = require("./Prelude.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var ReactIcons = require("@patternfly/react-icons");

function GroupView$UserItem(Props) {
  var store = Props.store;
  var name = Props.name;
  var dispatch = store[1];
  var link = "/" + store[0].index + "/author/" + encodeURIComponent(name);
  return React.createElement(Prelude$Web.MSimpleCard.make, {
              children: Prelude$Web.str(name),
              style: {
                cursor: "pointer"
              },
              onClick: (function (e) {
                  e.preventDefault();
                  Curry._1(dispatch, {
                        TAG: /* SetAuthorScopedTab */5,
                        _0: /* ChangeActivity */0
                      });
                  return RescriptReactRouter.push(link);
                })
            });
}

var UserItem = {
  make: GroupView$UserItem
};

function GroupView(Props) {
  var group = Props.group;
  var store = Props.store;
  var state = store[0];
  var icon = React.createElement(ReactIcons.UsersIcon, {});
  var toItems = function (resp) {
    return Belt_List.map(resp.members, (function (name) {
                  return React.createElement(GroupView$UserItem, {
                              store: store,
                              name: name,
                              key: name
                            });
                }));
  };
  return React.createElement(Prelude$Web.MonoCard.make, {
              title: "User Group members",
              tooltip_content: "This shows the list of group's members",
              icon: icon,
              children: React.createElement(Prelude$Web.NetworkRender.make, {
                    get: (function (param) {
                        return WebApi$Web.Config.getGroupMembers({
                                    index: state.index,
                                    group: group
                                  });
                      }),
                    trigger: "",
                    render: (function (resp) {
                        return Belt_List.toArray(toItems(resp));
                      })
                  })
            });
}

var make = GroupView;

var $$default = GroupView;

exports.UserItem = UserItem;
exports.make = make;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
/* react Not a pure module */
