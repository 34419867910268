'use strict';

var Pbrt_bs = require("bs-ocaml-protoc-json/src/pbrt_bs.bs.js");

function decode_get_magic_jwt_request(json) {
  var v = "";
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    if (match === "token") {
      var json$1 = json["token"];
      v = Pbrt_bs.string(json$1, "get_magic_jwt_request", "token");
    }
    
  }
  return {
          token: v
        };
}

function decode_get_magic_jwt_error(json) {
  var match = Pbrt_bs.string(json, "get_magic_jwt_error", "value");
  switch (match) {
    case "" :
    case "InvalidAdminToken" :
        return /* Invalid_admin_token */0;
    case "MagicTokenCreateError" :
        return /* Magic_token_create_error */2;
    case "MagicTokenDisabled" :
        return /* Magic_token_disabled */1;
    default:
      return Pbrt_bs.E.malformed_variant("get_magic_jwt_error");
  }
}

function decode_get_magic_jwt_response(json) {
  var keys = Object.keys(json);
  var _i = keys.length - 1 | 0;
  while(true) {
    var i = _i;
    if (i === -1) {
      return Pbrt_bs.E.malformed_variant("get_magic_jwt_response");
    }
    var match = keys[i];
    switch (match) {
      case "error" :
          var json$1 = json["error"];
          return {
                  TAG: /* Error */0,
                  _0: decode_get_magic_jwt_error(json$1)
                };
      case "jwt" :
          var json$2 = json["jwt"];
          return {
                  TAG: /* Jwt */1,
                  _0: Pbrt_bs.string(json$2, "get_magic_jwt_response", "Jwt")
                };
      default:
        _i = i - 1 | 0;
        continue ;
    }
  };
}

function decode_who_ami_request(json) {
  var v = "";
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    if (match === "void") {
      var json$1 = json["void"];
      v = Pbrt_bs.string(json$1, "who_ami_request", "void");
    }
    
  }
  return {
          void: v
        };
}

function decode_who_ami_error(json) {
  var match = Pbrt_bs.string(json, "who_ami_error", "value");
  switch (match) {
    case "" :
    case "UnAuthorized" :
        return /* Un_authorized */0;
    default:
      return Pbrt_bs.E.malformed_variant("who_ami_error");
  }
}

function decode_who_ami_response(json) {
  var keys = Object.keys(json);
  var _i = keys.length - 1 | 0;
  while(true) {
    var i = _i;
    if (i === -1) {
      return Pbrt_bs.E.malformed_variant("who_ami_response");
    }
    var match = keys[i];
    switch (match) {
      case "error" :
          var json$1 = json["error"];
          return {
                  TAG: /* Error */0,
                  _0: decode_who_ami_error(json$1)
                };
      case "uid" :
          var json$2 = json["uid"];
          return {
                  TAG: /* Uid */1,
                  _0: Pbrt_bs.string(json$2, "who_ami_response", "Uid")
                };
      default:
        _i = i - 1 | 0;
        continue ;
    }
  };
}

function encode_get_magic_jwt_request(v) {
  var json = {};
  json["token"] = v.token;
  return json;
}

function encode_get_magic_jwt_error(v) {
  switch (v) {
    case /* Invalid_admin_token */0 :
        return "InvalidAdminToken";
    case /* Magic_token_disabled */1 :
        return "MagicTokenDisabled";
    case /* Magic_token_create_error */2 :
        return "MagicTokenCreateError";
    
  }
}

function encode_get_magic_jwt_response(v) {
  var json = {};
  if (v.TAG === /* Error */0) {
    json["error"] = encode_get_magic_jwt_error(v._0);
  } else {
    json["jwt"] = v._0;
  }
  return json;
}

function encode_who_ami_request(v) {
  var json = {};
  json["void"] = v.void;
  return json;
}

function encode_who_ami_error(v) {
  return "UnAuthorized";
}

function encode_who_ami_response(v) {
  var json = {};
  if (v.TAG === /* Error */0) {
    json["error"] = "UnAuthorized";
  } else {
    json["uid"] = v._0;
  }
  return json;
}

exports.encode_get_magic_jwt_request = encode_get_magic_jwt_request;
exports.encode_get_magic_jwt_error = encode_get_magic_jwt_error;
exports.encode_get_magic_jwt_response = encode_get_magic_jwt_response;
exports.encode_who_ami_request = encode_who_ami_request;
exports.encode_who_ami_error = encode_who_ami_error;
exports.encode_who_ami_response = encode_who_ami_response;
exports.decode_get_magic_jwt_request = decode_get_magic_jwt_request;
exports.decode_get_magic_jwt_error = decode_get_magic_jwt_error;
exports.decode_get_magic_jwt_response = decode_get_magic_jwt_response;
exports.decode_who_ami_request = decode_who_ami_request;
exports.decode_who_ami_error = decode_who_ami_error;
exports.decode_who_ami_response = decode_who_ami_response;
/* Pbrt_bs Not a pure module */
