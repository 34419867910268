'use strict';

var React = require("react");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Store$Web = require("./Store.bs.js");
var Change$Web = require("./Change.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Prelude$Web = require("./Prelude.bs.js");

function ChangeView(Props) {
  var store = Props.store;
  var change = Props.change;
  var state = store[0];
  var init = Store$Web.mkSearchRequest(state, /* Query_change_and_events */13);
  var request_index = init.index;
  var request_username = init.username;
  var request_query = init.query;
  var request_query_type = init.query_type;
  var request_order = init.order;
  var request_limit = init.limit;
  var request = {
    index: request_index,
    username: request_username,
    query: request_query,
    query_type: request_query_type,
    order: request_order,
    limit: request_limit,
    change_id: change
  };
  return React.createElement("div", {
              className: "container"
            }, React.createElement(Prelude$Web.QueryRender.make, {
                  request: request,
                  trigger: state.query,
                  render: (function (resp) {
                      if (resp.TAG !== /* Change_events */9) {
                        return null;
                      }
                      var match = resp._0;
                      return React.createElement(Change$Web.ChangeDetailView.make, {
                                  store: store,
                                  change: Belt_Option.getExn(match.change),
                                  events: Belt_List.toArray(match.events)
                                });
                    })
                }));
}

var make = ChangeView;

var $$default = ChangeView;

exports.make = make;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
/* react Not a pure module */
