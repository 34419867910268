'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Prelude$Web = require("./Prelude.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");

function MLink$MonoLink(Props) {
  var store = Props.store;
  var filter = Props.filter;
  var path = Props.path;
  var name = Props.name;
  var action = Props.action;
  var dispatch = store[1];
  var state = store[0];
  var base = path !== undefined ? "/" + state.index + "/" + path + "?" : Prelude$Web.readWindowLocationPathname(undefined) + "?";
  var query = state.query;
  var query$1 = query === "" ? "" : "q=" + query + "&";
  var href = base + query$1 + "f=" + filter;
  var onClick = function (e) {
    e.preventDefault();
    Curry._1(dispatch, {
          TAG: /* SetFilter */2,
          _0: filter
        });
    if (action !== undefined) {
      Curry._1(dispatch, action);
    }
    return RescriptReactRouter.push(href);
  };
  return React.createElement("a", {
              style: {
                whiteSpace: "nowrap"
              },
              href: href,
              onClick: onClick
            }, Prelude$Web.str(name));
}

var MonoLink = {
  make: MLink$MonoLink
};

function MLink$Direct(Props) {
  var link = Props.link;
  var name = Props.name;
  var onClick = function (e) {
    e.preventDefault();
    return RescriptReactRouter.push(link);
  };
  return React.createElement("a", {
              href: link,
              onClick: onClick
            }, Prelude$Web.str(name));
}

var Direct = {
  make: MLink$Direct
};

var $$default = MLink$MonoLink;

exports.MonoLink = MonoLink;
exports.Direct = Direct;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
/* react Not a pure module */
