'use strict';

var React = require("react");
var MLink$Web = require("./MLink.bs.js");
var Patternfly = require("@softwarefactory-project/re-patternfly/src/Patternfly.bs.js");
var WebApi$Web = require("./WebApi.bs.js");
var Prelude$Web = require("./Prelude.bs.js");
var ReactIcons = require("@patternfly/react-icons");

function Metrics$MetricList(Props) {
  var store = Props.store;
  var items = Props.items;
  var columnNames = [
    "Name",
    "Description"
  ];
  var isOrdered = function (param, param$1, param$2) {
    return false;
  };
  var formatters_0 = function (item) {
    return React.createElement(MLink$Web.MonoLink.make, {
                store: store,
                filter: "",
                path: "metric/" + item.metric,
                name: item.name
              });
  };
  var formatters_1 = {
    hd: (function (item) {
        return Prelude$Web.str(item.description);
      }),
    tl: /* [] */0
  };
  var formatters = {
    hd: formatters_0,
    tl: formatters_1
  };
  return React.createElement(Prelude$Web.SortableTable.make, {
              items: items,
              defaultSortedColumn: 0,
              columnNames: columnNames,
              isOrdered: isOrdered,
              formatters: formatters
            });
}

var MetricList = {
  make: Metrics$MetricList
};

function Metrics(Props) {
  var store = Props.store;
  var icon = React.createElement(ReactIcons.BundleIcon, {});
  return React.createElement(Prelude$Web.MCenteredContent.make, {
              children: React.createElement(Prelude$Web.MonoCard.make, {
                    title: "Metrics",
                    tooltip_content: "This shows the list of available metrics",
                    icon: icon,
                    children: React.createElement(Prelude$Web.NetworkRender.make, {
                          get: (function (param) {
                              return WebApi$Web.Metric.list({
                                          void: ""
                                        });
                            }),
                          trigger: "",
                          render: (function (resp) {
                              return React.createElement(Metrics$MetricList, {
                                          store: store,
                                          items: resp.metrics
                                        });
                            })
                        })
                  })
            });
}

var AboutModal = Patternfly.AboutModal;

var AboutModalBox = Patternfly.AboutModalBox;

var AboutModalBoxBrand = Patternfly.AboutModalBoxBrand;

var AboutModalBoxCloseButton = Patternfly.AboutModalBoxCloseButton;

var AboutModalBoxContent = Patternfly.AboutModalBoxContent;

var AboutModalBoxHeader = Patternfly.AboutModalBoxHeader;

var AboutModalBoxHero = Patternfly.AboutModalBoxHero;

var AboutModalContainer = Patternfly.AboutModalContainer;

var Accordion = Patternfly.Accordion;

var AccordionContent = Patternfly.AccordionContent;

var AccordionItem = Patternfly.AccordionItem;

var AccordionToggle = Patternfly.AccordionToggle;

var Alert = Patternfly.Alert;

var AlertActionCloseButton = Patternfly.AlertActionCloseButton;

var AlertActionLink = Patternfly.AlertActionLink;

var AlertIcon = Patternfly.AlertIcon;

var AlertGroup = Patternfly.AlertGroup;

var ApplicationLauncher = Patternfly.ApplicationLauncher;

var ApplicationLauncherContent = Patternfly.ApplicationLauncherContent;

var ApplicationLauncherIcon = Patternfly.ApplicationLauncherIcon;

var ApplicationLauncherItem = Patternfly.ApplicationLauncherItem;

var ApplicationLauncherText = Patternfly.ApplicationLauncherText;

var Avatar = Patternfly.Avatar;

var Backdrop = Patternfly.Backdrop;

var BackgroundImage = Patternfly.BackgroundImage;

var Badge = Patternfly.Badge;

var Banner = Patternfly.Banner;

var Brand = Patternfly.Brand;

var Breadcrumb = Patternfly.Breadcrumb;

var BreadcrumbHeading = Patternfly.BreadcrumbHeading;

var BreadcrumbItem = Patternfly.BreadcrumbItem;

var Button = Patternfly.Button;

var Card = Patternfly.Card;

var CardActions = Patternfly.CardActions;

var CardBody = Patternfly.CardBody;

var CardExpandableContent = Patternfly.CardExpandableContent;

var CardFooter = Patternfly.CardFooter;

var CardHeadMain = Patternfly.CardHeadMain;

var CardHeader = Patternfly.CardHeader;

var CardHeaderMain = Patternfly.CardHeaderMain;

var CardTitle = Patternfly.CardTitle;

var Checkbox = Patternfly.Checkbox;

var ClipboardCopy = Patternfly.ClipboardCopy;

var ClipboardCopyAction = Patternfly.ClipboardCopyAction;

var ClipboardCopyButton = Patternfly.ClipboardCopyButton;

var ClipboardCopyExpanded = Patternfly.ClipboardCopyExpanded;

var ClipboardCopyToggle = Patternfly.ClipboardCopyToggle;

var DataList = Patternfly.DataList;

var DataListAction = Patternfly.DataListAction;

var DataListCell = Patternfly.DataListCell;

var DataListCheck = Patternfly.DataListCheck;

var DataListContent = Patternfly.DataListContent;

var DataListControl = Patternfly.DataListControl;

var DataListDragButton = Patternfly.DataListDragButton;

var DataListItemChild = Patternfly.DataListItemChild;

var DataListItem = Patternfly.DataListItem;

var DataListItemCells = Patternfly.DataListItemCells;

var DataListItemRow = Patternfly.DataListItemRow;

var DataListText = Patternfly.DataListText;

var DataListToggle = Patternfly.DataListToggle;

var DatePicker = Patternfly.DatePicker;

var DescriptionList = Patternfly.DescriptionList;

var DescriptionListDescription = Patternfly.DescriptionListDescription;

var DescriptionListGroup = Patternfly.DescriptionListGroup;

var DescriptionListTerm = Patternfly.DescriptionListTerm;

var Divider = Patternfly.Divider;

var DrawerContext = Patternfly.DrawerContext;

var Drawer = Patternfly.Drawer;

var DrawerActions = Patternfly.DrawerActions;

var DrawerCloseButton = Patternfly.DrawerCloseButton;

var DrawerContent = Patternfly.DrawerContent;

var DrawerContentBody = Patternfly.DrawerContentBody;

var DrawerHead = Patternfly.DrawerHead;

var DrawerMain = Patternfly.DrawerMain;

var DrawerPanelBody = Patternfly.DrawerPanelBody;

var DrawerPanelContent = Patternfly.DrawerPanelContent;

var DrawerSection = Patternfly.DrawerSection;

var BadgeToggle = Patternfly.BadgeToggle;

var Dropdown = Patternfly.Dropdown;

var DropdownGroup = Patternfly.DropdownGroup;

var DropdownItem = Patternfly.DropdownItem;

var Separator = Patternfly.Separator;

var DropdownToggle = Patternfly.DropdownToggle;

var DropdownToggleAction = Patternfly.DropdownToggleAction;

var DropdownToggleCheckbox = Patternfly.DropdownToggleCheckbox;

var InternalDropdownItem = Patternfly.InternalDropdownItem;

var KebabToggle = Patternfly.KebabToggle;

var Toggle = Patternfly.Toggle;

var EmptyState = Patternfly.EmptyState;

var EmptyStateBody = Patternfly.EmptyStateBody;

var EmptyStateIcon = Patternfly.EmptyStateIcon;

var Icon = Patternfly.Icon;

var EmptyStatePrimary = Patternfly.EmptyStatePrimary;

var EmptyStateSecondaryActions = Patternfly.EmptyStateSecondaryActions;

var ActionGroup = Patternfly.ActionGroup;

var Form = Patternfly.Form;

var FormAlert = Patternfly.FormAlert;

var FormFieldGroup = Patternfly.FormFieldGroup;

var FormFieldGroupExpandable = Patternfly.FormFieldGroupExpandable;

var FormFieldGroupHeader = Patternfly.FormFieldGroupHeader;

var FormFieldGroupToggle = Patternfly.FormFieldGroupToggle;

var FormGroup = Patternfly.FormGroup;

var FormHelperText = Patternfly.FormHelperText;

var FormSection = Patternfly.FormSection;

var InternalFormFieldGroup = Patternfly.InternalFormFieldGroup;

var Hint = Patternfly.Hint;

var HintBody = Patternfly.HintBody;

var HintFooter = Patternfly.HintFooter;

var HintTitle = Patternfly.HintTitle;

var InputGroup = Patternfly.InputGroup;

var InputGroupText = Patternfly.InputGroupText;

var Label = Patternfly.Label;

var LabelGroup = Patternfly.LabelGroup;

var List = Patternfly.List;

var ListItem = Patternfly.ListItem;

var Login = Patternfly.Login;

var LoginFooter = Patternfly.LoginFooter;

var LoginFooterItem = Patternfly.LoginFooterItem;

var LoginForm = Patternfly.LoginForm;

var LoginHeader = Patternfly.LoginHeader;

var LoginMainBody = Patternfly.LoginMainBody;

var LoginMainFooter = Patternfly.LoginMainFooter;

var LoginMainFooterBandItem = Patternfly.LoginMainFooterBandItem;

var LoginMainFooterLinksItem = Patternfly.LoginMainFooterLinksItem;

var LoginMainHeader = Patternfly.LoginMainHeader;

var LoginPage = Patternfly.LoginPage;

var DrilldownMenu = Patternfly.DrilldownMenu;

var Menu = Patternfly.Menu;

var MenuBreadcrumb = Patternfly.MenuBreadcrumb;

var MenuContent = Patternfly.MenuContent;

var MenuGroup = Patternfly.MenuGroup;

var MenuInput = Patternfly.MenuInput;

var MenuItem = Patternfly.MenuItem;

var MenuItemAction = Patternfly.MenuItemAction;

var MenuList = Patternfly.MenuList;

var Modal = Patternfly.Modal;

var ModalBox = Patternfly.ModalBox;

var ModalBoxBody = Patternfly.ModalBoxBody;

var ModalBoxCloseButton = Patternfly.ModalBoxCloseButton;

var ModalBoxDescription = Patternfly.ModalBoxDescription;

var ModalBoxFooter = Patternfly.ModalBoxFooter;

var ModalBoxHeader = Patternfly.ModalBoxHeader;

var ModalBoxTitle = Patternfly.ModalBoxTitle;

var ModalContent = Patternfly.ModalContent;

var Nav = Patternfly.Nav;

var NavExpandable = Patternfly.NavExpandable;

var NavGroup = Patternfly.NavGroup;

var NavItem = Patternfly.NavItem;

var NavList = Patternfly.NavList;

var Page = Patternfly.Page;

var PageContext = Patternfly.PageContext;

var PageBreadcrumb = Patternfly.PageBreadcrumb;

var PageGroup = Patternfly.PageGroup;

var PageHeader = Patternfly.PageHeader;

var PageHeaderTools = Patternfly.PageHeaderTools;

var PageHeaderToolsGroup = Patternfly.PageHeaderToolsGroup;

var PageHeaderToolsItem = Patternfly.PageHeaderToolsItem;

var PageNavigation = Patternfly.PageNavigation;

var PageSection = Patternfly.PageSection;

var PageSidebarContext = Patternfly.PageSidebarContext;

var PageSidebar = Patternfly.PageSidebar;

var Navigation = Patternfly.Navigation;

var OptionsToggle = Patternfly.OptionsToggle;

var Pagination = Patternfly.Pagination;

var PaginationOptionsMenu = Patternfly.PaginationOptionsMenu;

var ToggleTemplate = Patternfly.ToggleTemplate;

var Radio = Patternfly.Radio;

var Select = Patternfly.Select;

var SelectGroup = Patternfly.SelectGroup;

var SelectMenu = Patternfly.SelectMenu;

var SelectOption = Patternfly.SelectOption;

var SelectToggle = Patternfly.SelectToggle;

var Spinner = Patternfly.Spinner;

var Tab = Patternfly.Tab;

var TabButton = Patternfly.TabButton;

var TabContent = Patternfly.TabContent;

var TabTitleIcon = Patternfly.TabTitleIcon;

var TabTitleText = Patternfly.TabTitleText;

var Tabs = Patternfly.Tabs;

var $$Text = Patternfly.$$Text;

var TextContent = Patternfly.TextContent;

var TextList = Patternfly.TextList;

var TextListItem = Patternfly.TextListItem;

var TextArea = Patternfly.TextArea;

var TextInput = Patternfly.TextInput;

var Tile = Patternfly.Tile;

var Title = Patternfly.Title;

var Toolbar = Patternfly.Toolbar;

var ToolbarChipGroupContent = Patternfly.ToolbarChipGroupContent;

var ToolbarContent = Patternfly.ToolbarContent;

var ToolbarExpandIconWrapper = Patternfly.ToolbarExpandIconWrapper;

var ToolbarExpandableContent = Patternfly.ToolbarExpandableContent;

var ToolbarFilter = Patternfly.ToolbarFilter;

var ToolbarItem = Patternfly.ToolbarItem;

var ToolbarToggleGroup = Patternfly.ToolbarToggleGroup;

var Tooltip = Patternfly.Tooltip;

var TooltipArrow = Patternfly.TooltipArrow;

var TooltipContent = Patternfly.TooltipContent;

var Column = Patternfly.Column;

var Icons = Patternfly.Icons;

var Layout = Patternfly.Layout;

var mkFormatter = Patternfly.mkFormatter;

var mkRows = Patternfly.mkRows;

var sortRows = Patternfly.sortRows;

var Table = Patternfly.Table;

var TableBody = Patternfly.TableBody;

var TableHeader = Patternfly.TableHeader;

var make = Metrics;

var $$default = Metrics;

exports.AboutModal = AboutModal;
exports.AboutModalBox = AboutModalBox;
exports.AboutModalBoxBrand = AboutModalBoxBrand;
exports.AboutModalBoxCloseButton = AboutModalBoxCloseButton;
exports.AboutModalBoxContent = AboutModalBoxContent;
exports.AboutModalBoxHeader = AboutModalBoxHeader;
exports.AboutModalBoxHero = AboutModalBoxHero;
exports.AboutModalContainer = AboutModalContainer;
exports.Accordion = Accordion;
exports.AccordionContent = AccordionContent;
exports.AccordionItem = AccordionItem;
exports.AccordionToggle = AccordionToggle;
exports.Alert = Alert;
exports.AlertActionCloseButton = AlertActionCloseButton;
exports.AlertActionLink = AlertActionLink;
exports.AlertIcon = AlertIcon;
exports.AlertGroup = AlertGroup;
exports.ApplicationLauncher = ApplicationLauncher;
exports.ApplicationLauncherContent = ApplicationLauncherContent;
exports.ApplicationLauncherIcon = ApplicationLauncherIcon;
exports.ApplicationLauncherItem = ApplicationLauncherItem;
exports.ApplicationLauncherText = ApplicationLauncherText;
exports.Avatar = Avatar;
exports.Backdrop = Backdrop;
exports.BackgroundImage = BackgroundImage;
exports.Badge = Badge;
exports.Banner = Banner;
exports.Brand = Brand;
exports.Breadcrumb = Breadcrumb;
exports.BreadcrumbHeading = BreadcrumbHeading;
exports.BreadcrumbItem = BreadcrumbItem;
exports.Button = Button;
exports.Card = Card;
exports.CardActions = CardActions;
exports.CardBody = CardBody;
exports.CardExpandableContent = CardExpandableContent;
exports.CardFooter = CardFooter;
exports.CardHeadMain = CardHeadMain;
exports.CardHeader = CardHeader;
exports.CardHeaderMain = CardHeaderMain;
exports.CardTitle = CardTitle;
exports.Checkbox = Checkbox;
exports.ClipboardCopy = ClipboardCopy;
exports.ClipboardCopyAction = ClipboardCopyAction;
exports.ClipboardCopyButton = ClipboardCopyButton;
exports.ClipboardCopyExpanded = ClipboardCopyExpanded;
exports.ClipboardCopyToggle = ClipboardCopyToggle;
exports.DataList = DataList;
exports.DataListAction = DataListAction;
exports.DataListCell = DataListCell;
exports.DataListCheck = DataListCheck;
exports.DataListContent = DataListContent;
exports.DataListControl = DataListControl;
exports.DataListDragButton = DataListDragButton;
exports.DataListItemChild = DataListItemChild;
exports.DataListItem = DataListItem;
exports.DataListItemCells = DataListItemCells;
exports.DataListItemRow = DataListItemRow;
exports.DataListText = DataListText;
exports.DataListToggle = DataListToggle;
exports.DatePicker = DatePicker;
exports.DescriptionList = DescriptionList;
exports.DescriptionListDescription = DescriptionListDescription;
exports.DescriptionListGroup = DescriptionListGroup;
exports.DescriptionListTerm = DescriptionListTerm;
exports.Divider = Divider;
exports.DrawerContext = DrawerContext;
exports.Drawer = Drawer;
exports.DrawerActions = DrawerActions;
exports.DrawerCloseButton = DrawerCloseButton;
exports.DrawerContent = DrawerContent;
exports.DrawerContentBody = DrawerContentBody;
exports.DrawerHead = DrawerHead;
exports.DrawerMain = DrawerMain;
exports.DrawerPanelBody = DrawerPanelBody;
exports.DrawerPanelContent = DrawerPanelContent;
exports.DrawerSection = DrawerSection;
exports.BadgeToggle = BadgeToggle;
exports.Dropdown = Dropdown;
exports.DropdownGroup = DropdownGroup;
exports.DropdownItem = DropdownItem;
exports.Separator = Separator;
exports.DropdownToggle = DropdownToggle;
exports.DropdownToggleAction = DropdownToggleAction;
exports.DropdownToggleCheckbox = DropdownToggleCheckbox;
exports.InternalDropdownItem = InternalDropdownItem;
exports.KebabToggle = KebabToggle;
exports.Toggle = Toggle;
exports.EmptyState = EmptyState;
exports.EmptyStateBody = EmptyStateBody;
exports.EmptyStateIcon = EmptyStateIcon;
exports.Icon = Icon;
exports.EmptyStatePrimary = EmptyStatePrimary;
exports.EmptyStateSecondaryActions = EmptyStateSecondaryActions;
exports.ActionGroup = ActionGroup;
exports.Form = Form;
exports.FormAlert = FormAlert;
exports.FormFieldGroup = FormFieldGroup;
exports.FormFieldGroupExpandable = FormFieldGroupExpandable;
exports.FormFieldGroupHeader = FormFieldGroupHeader;
exports.FormFieldGroupToggle = FormFieldGroupToggle;
exports.FormGroup = FormGroup;
exports.FormHelperText = FormHelperText;
exports.FormSection = FormSection;
exports.InternalFormFieldGroup = InternalFormFieldGroup;
exports.Hint = Hint;
exports.HintBody = HintBody;
exports.HintFooter = HintFooter;
exports.HintTitle = HintTitle;
exports.InputGroup = InputGroup;
exports.InputGroupText = InputGroupText;
exports.Label = Label;
exports.LabelGroup = LabelGroup;
exports.List = List;
exports.ListItem = ListItem;
exports.Login = Login;
exports.LoginFooter = LoginFooter;
exports.LoginFooterItem = LoginFooterItem;
exports.LoginForm = LoginForm;
exports.LoginHeader = LoginHeader;
exports.LoginMainBody = LoginMainBody;
exports.LoginMainFooter = LoginMainFooter;
exports.LoginMainFooterBandItem = LoginMainFooterBandItem;
exports.LoginMainFooterLinksItem = LoginMainFooterLinksItem;
exports.LoginMainHeader = LoginMainHeader;
exports.LoginPage = LoginPage;
exports.DrilldownMenu = DrilldownMenu;
exports.Menu = Menu;
exports.MenuBreadcrumb = MenuBreadcrumb;
exports.MenuContent = MenuContent;
exports.MenuGroup = MenuGroup;
exports.MenuInput = MenuInput;
exports.MenuItem = MenuItem;
exports.MenuItemAction = MenuItemAction;
exports.MenuList = MenuList;
exports.Modal = Modal;
exports.ModalBox = ModalBox;
exports.ModalBoxBody = ModalBoxBody;
exports.ModalBoxCloseButton = ModalBoxCloseButton;
exports.ModalBoxDescription = ModalBoxDescription;
exports.ModalBoxFooter = ModalBoxFooter;
exports.ModalBoxHeader = ModalBoxHeader;
exports.ModalBoxTitle = ModalBoxTitle;
exports.ModalContent = ModalContent;
exports.Nav = Nav;
exports.NavExpandable = NavExpandable;
exports.NavGroup = NavGroup;
exports.NavItem = NavItem;
exports.NavList = NavList;
exports.Page = Page;
exports.PageContext = PageContext;
exports.PageBreadcrumb = PageBreadcrumb;
exports.PageGroup = PageGroup;
exports.PageHeader = PageHeader;
exports.PageHeaderTools = PageHeaderTools;
exports.PageHeaderToolsGroup = PageHeaderToolsGroup;
exports.PageHeaderToolsItem = PageHeaderToolsItem;
exports.PageNavigation = PageNavigation;
exports.PageSection = PageSection;
exports.PageSidebarContext = PageSidebarContext;
exports.PageSidebar = PageSidebar;
exports.Navigation = Navigation;
exports.OptionsToggle = OptionsToggle;
exports.Pagination = Pagination;
exports.PaginationOptionsMenu = PaginationOptionsMenu;
exports.ToggleTemplate = ToggleTemplate;
exports.Radio = Radio;
exports.Select = Select;
exports.SelectGroup = SelectGroup;
exports.SelectMenu = SelectMenu;
exports.SelectOption = SelectOption;
exports.SelectToggle = SelectToggle;
exports.Spinner = Spinner;
exports.Tab = Tab;
exports.TabButton = TabButton;
exports.TabContent = TabContent;
exports.TabTitleIcon = TabTitleIcon;
exports.TabTitleText = TabTitleText;
exports.Tabs = Tabs;
exports.$$Text = $$Text;
exports.TextContent = TextContent;
exports.TextList = TextList;
exports.TextListItem = TextListItem;
exports.TextArea = TextArea;
exports.TextInput = TextInput;
exports.Tile = Tile;
exports.Title = Title;
exports.Toolbar = Toolbar;
exports.ToolbarChipGroupContent = ToolbarChipGroupContent;
exports.ToolbarContent = ToolbarContent;
exports.ToolbarExpandIconWrapper = ToolbarExpandIconWrapper;
exports.ToolbarExpandableContent = ToolbarExpandableContent;
exports.ToolbarFilter = ToolbarFilter;
exports.ToolbarItem = ToolbarItem;
exports.ToolbarToggleGroup = ToolbarToggleGroup;
exports.Tooltip = Tooltip;
exports.TooltipArrow = TooltipArrow;
exports.TooltipContent = TooltipContent;
exports.Column = Column;
exports.Icons = Icons;
exports.Layout = Layout;
exports.mkFormatter = mkFormatter;
exports.mkRows = mkRows;
exports.sortRows = sortRows;
exports.Table = Table;
exports.TableBody = TableBody;
exports.TableHeader = TableHeader;
exports.MetricList = MetricList;
exports.make = make;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
/* react Not a pure module */
