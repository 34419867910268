'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Store$Web = require("./Store.bs.js");
var Prelude$Web = require("./Prelude.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var ReactCore = require("@patternfly/react-core");
var ReactIcons = require("@patternfly/react-icons");

function renderFieldType(ft) {
  switch (ft) {
    case /* Field_date */0 :
        return "date";
    case /* Field_number */1 :
        return "number";
    case /* Field_text */2 :
        return "text";
    case /* Field_bool */3 :
        return "boolean";
    case /* Field_regex */4 :
        return "regex";
    
  }
}

function renderField(f) {
  return React.createElement("li", {
              key: f.name
            }, React.createElement("b", undefined, Prelude$Web.str(f.name)), Prelude$Web.str(" (" + renderFieldType(f.type_) + "): " + f.description));
}

function li(s) {
  return React.createElement("li", undefined, Prelude$Web.str(s));
}

function HelpSearch$Content$FakeInput(Props) {
  var value = Props.value;
  return React.createElement("li", undefined, React.createElement(ReactCore.TextInput, {
                  iconVariant: "search",
                  id: "fake",
                  type: "text",
                  value: value
                }));
}

var FakeInput = {
  make: HelpSearch$Content$FakeInput
};

function HelpSearch$Content$Code(Props) {
  var value = Props.value;
  return React.createElement("span", {
              style: {
                background: "white",
                marginRight: "2px",
                marginLeft: "2px",
                paddingRight: "3px",
                paddingLeft: "3px"
              }
            }, Prelude$Web.str(value));
}

var Code = {
  make: HelpSearch$Content$Code
};

function HelpSearch$Content(Props) {
  var fields = Props.fields;
  return React.createElement("div", {
              style: {
                textAlign: "left"
              }
            }, React.createElement("h2", undefined, Prelude$Web.str("Monocle search language")), React.createElement("p", undefined, Prelude$Web.str("Use the query syntax to filter the metrics. Learn more in the "), React.createElement("a", {
                      href: "https://github.com/change-metrics/monocle/blob/master/doc/query-language.md#monocle-query-language"
                    }, Prelude$Web.str("specification")), Prelude$Web.str(".")), React.createElement("p", undefined, Prelude$Web.str("Use the `Add Field` button to get suggestions.")), React.createElement("h4", undefined, Prelude$Web.str("Example")), React.createElement("ul", undefined, React.createElement(HelpSearch$Content$FakeInput, {
                      value: "state:open approved task.score>42"
                    }), React.createElement(HelpSearch$Content$FakeInput, {
                      value: "(repo:openstack/nova or repo:openstack/neutron) and group:qa and updated_at>2020"
                    }), React.createElement(HelpSearch$Content$FakeInput, {
                      value: "author:\"Foo B.*\" and not state:open"
                    }), React.createElement(HelpSearch$Content$FakeInput, {
                      value: "author:self and not state:open"
                    })), React.createElement("h4", undefined, Prelude$Web.str("Syntax")), React.createElement("p", undefined, Prelude$Web.str("Queries are defined using expressions such as "), React.createElement(HelpSearch$Content$Code, {
                      value: "state:open"
                    }), Prelude$Web.str(". Expressions can be combined using boolean operators: "), React.createElement(HelpSearch$Content$Code, {
                      value: "and"
                    }), Prelude$Web.str(", "), React.createElement(HelpSearch$Content$Code, {
                      value: "not"
                    }), Prelude$Web.str(" and "), React.createElement(HelpSearch$Content$Code, {
                      value: "or"
                    }), Prelude$Web.str(". The priority can be set with parenthesis. The boolean operator can be omitted, then it defaults to "), React.createElement(HelpSearch$Content$Code, {
                      value: "and"
                    }), Prelude$Web.str(".")), React.createElement("p", undefined, Prelude$Web.str("An expression is composed of a field name, a match operator and a value. For text or regex type, use the: "), React.createElement(HelpSearch$Content$Code, {
                      value: ":"
                    }), Prelude$Web.str(" match operator. For date or numeric type, use one of: "), React.createElement(HelpSearch$Content$Code, {
                      value: ">"
                    }), React.createElement(HelpSearch$Content$Code, {
                      value: ">="
                    }), React.createElement(HelpSearch$Content$Code, {
                      value: "<"
                    }), React.createElement(HelpSearch$Content$Code, {
                      value: "<="
                    }), Prelude$Web.str(". Date value can be absolute, in the form of "), React.createElement(HelpSearch$Content$Code, {
                      value: "YYYY"
                    }), React.createElement(HelpSearch$Content$Code, {
                      value: "YYYY-MM"
                    }), React.createElement(HelpSearch$Content$Code, {
                      value: "YYYY-MM-DD"
                    }), Prelude$Web.str(", or relative using this syntax: "), React.createElement(HelpSearch$Content$Code, {
                      value: "now-Xdays"
                    }), React.createElement(HelpSearch$Content$Code, {
                      value: "now-Xweeks"
                    }), Prelude$Web.str(".")), React.createElement("p", undefined, Prelude$Web.str("An expression can also be defined using aliases."), Prelude$Web.str("In the example above, "), React.createElement(HelpSearch$Content$Code, {
                      value: "approved"
                    }), Prelude$Web.str(" is an alias that can be defined as a compound expression for a given project.")), React.createElement("h4", undefined, Prelude$Web.str("Fields")), React.createElement("ul", undefined, Belt_List.toArray(Belt_List.map(fields, renderField))), React.createElement("h4", undefined, Prelude$Web.str("Aliases")), React.createElement("ul", undefined, li("TBD")));
}

var Content = {
  renderFieldType: renderFieldType,
  renderField: renderField,
  li: li,
  FakeInput: FakeInput,
  Code: Code,
  make: HelpSearch$Content
};

function HelpSearch$View(Props) {
  var store = Props.store;
  var match = Store$Web.Fetch.fields(store);
  var tmp;
  var exit = 0;
  if (match !== undefined && match.TAG === /* Ok */0) {
    tmp = React.createElement(HelpSearch$Content, {
          fields: match._0
        });
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = React.createElement(ReactCore.Spinner, {});
  }
  return React.createElement("div", {
              className: "container"
            }, tmp);
}

var View = {
  make: HelpSearch$View
};

var href = "/help/search";

function HelpSearch$HelpLink(Props) {
  var onClose = Props.onClose;
  var onClick = function (e) {
    e.preventDefault();
    RescriptReactRouter.push(href);
    return Curry._1(onClose, undefined);
  };
  return React.createElement("a", {
              style: {
                paddingRight: "5px",
                paddingLeft: "5px"
              },
              href: href,
              target: "_blank",
              onClick: onClick
            }, Prelude$Web.str("🔗"));
}

var HelpLink = {
  href: href,
  make: HelpSearch$HelpLink
};

function HelpSearch$SearchHelpModal(Props) {
  var isOpen = Props.isOpen;
  var onClose = Props.onClose;
  var store = Props.store;
  return React.createElement(ReactCore.Modal, {
              children: React.createElement(HelpSearch$View, {
                    store: store
                  }),
              header: React.createElement(HelpSearch$HelpLink, {
                    onClose: onClose
                  }),
              isOpen: isOpen,
              onClose: onClose,
              title: "Search help",
              variant: "large"
            });
}

var SearchHelpModal = {
  make: HelpSearch$SearchHelpModal
};

function HelpSearch$Tooltip(Props) {
  var store = Props.store;
  var match = React.useState(function () {
        return false;
      });
  var setShowHelpModal = match[1];
  return React.createElement(React.Fragment, undefined, React.createElement(HelpSearch$SearchHelpModal, {
                  isOpen: match[0],
                  onClose: (function (param) {
                      return Curry._1(setShowHelpModal, (function (param) {
                                    return false;
                                  }));
                    }),
                  store: store
                }), React.createElement("a", {
                  style: {
                    color: "#007bff"
                  },
                  onClick: (function (param) {
                      return Curry._1(setShowHelpModal, (function (param) {
                                    return true;
                                  }));
                    })
                }, React.createElement(ReactCore.Tooltip, {
                      children: React.createElement(ReactIcons.OutlinedQuestionCircleIcon, {}),
                      content: "Show query language syntax"
                    })));
}

var Tooltip = {
  make: HelpSearch$Tooltip
};

var $$default = HelpSearch$View;

exports.Content = Content;
exports.View = View;
exports.HelpLink = HelpLink;
exports.SearchHelpModal = SearchHelpModal;
exports.Tooltip = Tooltip;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
/* react Not a pure module */
